export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

export const removeUrlDomain = url => {
    const m = url.match(/^[^#]*?:\/\/.*?(\/.*)$/);
    return m.length > 1 ? m[1] : url;
};

export const formatIeCode = v => {
    const bin = '308';
    const maxLength = 16;

    return (bin + v.replace(/[^\d]/g, '').substr(bin.length)).substr(0, maxLength);
};

export const formatBarCode = v => {
    const bin = '2';
    const maxLength = 13;

    return (bin + v.replace(/[^\d]/g, '').substr(bin.length)).substr(0, maxLength);
};

export const formatBirthDate = v => {
    return v.replace(/[^\d]/g, '').substr(0, 8).match(/^([0-9]{0,2})([0-9]{0,2})([0-9]{0,4})$/).slice(1, 4).filter(p => p).join('/');
};

export const compareIgnoringAccents = (a, b) => a.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().indexOf(b.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()) > -1;