import React from 'react';
import TinySlider from 'tiny-slider-react';
import uniqid from 'uniqid';
import { AnalyticsPromos } from '../utils/analytics';
import SliderArrows from './SliderArrows';
import SliderContainer from './SliderContainer';


type Promo = {
  id:number,
  title:string,
  url:string,
  target:string,
  image_v2:string,
};

type Props = {
  promos:Promo[]
};

let navId = '';

class PromosA extends React.Component<Props> {
  private trackedIndexes = [];
  private ref:React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props:Props) {
    super(props);

    this.onIndexChanged = this.onIndexChanged.bind(this);
    this.onPromoClick = this.onPromoClick.bind(this);
  }

  onIndexChanged(info:any) {
    const {promos} = this.props;
    let i = info.navCurrentIndex + 1;

    if (i >= promos.length) i = 0;
    
    this.trackPromo(promos[i]);
  }

  trackPromo(promo:Promo) {
    if (!this.ref.current) return;
    AnalyticsPromos.trackView('promo_a', promo.id, promo.title, this.ref.current);
  }

  onPromoClick(slide:any, info:any, event:any) {
    const {promos} = this.props;
    const id = parseInt(slide.getAttribute('data-promo-id'));
    let promo;

    for (let i = 0; i < promos.length; i++) {
      if (promos[i].id === id) {
        promo = promos[i];
        break;
      }
    }

    if (promo) {
      const openInNewWindow = promo.target !== '_self' || event.altKey || event.ctrlKey || event.shiftKey;
      AnalyticsPromos.trackClick('promo_a', promo.id, promo.title, openInNewWindow ? null : () => {
        document.location = promo.url;
      });
      if (!openInNewWindow) event.preventDefault();
    }
  }

  shouldComponentUpdate(nextProps:Props) {
    return this.props.promos.map(promo => promo.id).join(',') !== nextProps.promos.map(promo => promo.id).join(',');
  }

  componentWillMount() {
    navId = uniqid();
    AnalyticsPromos.reset('promo_a');
  }

  render() {
    const settings = {
      autoplay: true,
      controlsContainer: '#' + navId,
      nav: true,
      navPosition: 'bottom',
    };

    if (!this.props.promos.length) return '';

    this.trackPromo(this.props.promos[0]);

    return (
        <div ref={this.ref}>
          <SliderContainer margin={false} className="u-rounded-mask">
            <TinySlider settings={settings} className="c-slider c-slider--nav" onIndexChanged={this.onIndexChanged} onClick={this.onPromoClick}>
              {this.props.promos.filter(promo => !!promo.image_v2).map(promo => (
                  <div className="c-slider__item" key={promo.id} data-promo-id={promo.id}>
                    <a href={promo.url} target={promo.target} className="o-ad-w-100">
                      <img src={promo.image_v2} alt={promo.title} />
                    </a>
                  </div>
              ))}
            </TinySlider>
            <SliderArrows navId={navId}/>
          </SliderContainer>
        </div>
    );
  }
}

export default PromosA;