import React from 'react';
import {connect} from 'react-redux';
import {getFeaturedMovie} from '../reducers';
import FullColumnLayout from "./FullColumnLayout";
import HomePageContent from "./HomePageContent";

class HomePage extends React.Component {
  render() {
    return <FullColumnLayout Content={HomePageContent} includeSearch={true} feat={this.props.movieFeatured ? true : false} />
  }
}

const mapStateToProps = state => ({
  movieFeatured: getFeaturedMovie(state)
});

export default connect(mapStateToProps)(HomePage);