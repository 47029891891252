import React from 'react';
import styled from 'styled-components';
import {breakpoints} from '../utils/styles';
import img from '../assets/img/main-movie-grid-empty-bg.png';

const Container = styled.div`
    padding: 3em 0;
    position: relative;
    text-align: center;

    @media screen and (min-width: ${breakpoints.tablet}px) {
        background: url(${img}) 50% top no-repeat;
        min-height: 629px;
        padding: 0;
        text-align: left;
    }
`;

const Title = styled.p`
    background: rgb(243,0,60);
    background: linear-gradient(228deg, rgba(243,0,60,1) 0%, rgba(179,13,79,1) 100%);
    background: -webkit-linear-gradient(#f3003c, #b30d4f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    font-weight: 200;
    line-height: 1em;
    text-transform: uppercase;
    
    @media screen and (min-width: ${breakpoints.tablet}px) {
        position: absolute;
        top: 130px;
        font-size: 150px;
    }
`;

const Message = styled.p`
    font-size: 18px;
    
    @media screen and (min-width: ${breakpoints.tablet}px) {
        position: absolute;
        left: 220px;
        top: 190px;
        max-width: 350px;
    }
`;

const NoHomeMoviesMessage = props => {
    return <Container>
        <Title>Lo<br/>sentimos</Title>
        <Message>Parece que el cine no tiene sesiones programadas. ¡Vuelve a checar más tarde!</Message>
    </Container>;
};

export default NoHomeMoviesMessage;