import {combineReducers} from 'redux';
import * as actions from '../actions/types';
import authPanel from './ui/authPanel';
import iePanel from './ui/iePanel';

const cinemaSelectorOpen = (state = false, action) => {
  switch (action.type) {
    case actions.UI_CINEMA_SELECTOR_OPEN:
      return true;
    case actions.CHANGE_PREFERRED_CINEMA:
    case actions.UI_CINEMA_SELECTOR_CLOSE:
      return false;
    default:
      return state;
  }
};

const areaSelectorOpen = (state = false, action) => {
  switch (action.type) {
    case actions.UI_AREA_SELECTOR_OPEN:
      return true;
    case actions.UI_AREA_SELECTOR_CLOSE:
      return false;
    default:
      return state;
  }
};

const areaSelectorOptions = (state = {}, action) => {
  switch (action.type) {
    case actions.UI_AREA_SELECTOR_OPEN:
      return action.options || {};
    case actions.UI_AREA_SELECTOR_CLOSE:
      return null;
    default:
      return state;
  }
};

const landingSelectedArea = (state = null, action) => {
  switch (action.type) {
    case actions.UI_LANDING_SELECT_AREA:
      return action.areaId;
    default:
      return state;
  }
}

const userProfileEdit = (state = null, action) => {
  switch (action.type) {
    case actions.UI_USER_PROFILE_EDIT:
      return action.value;
    case actions.IE_NIP_UPDATE_SUCCESS:
      return null;
    default:
      return state;
  }
}

const postRegisterPopup = (state = false, action) => {
  switch (action.type) {
    case actions.AUTH_REGISTER_POST_SUCCESS:
    case actions.POST_PURCHASE_IE_POPUP_SHOW:
      return true;
    case actions.POST_REGISTER_POPUP_CLOSE:
      return false;
    default:
      return state;
  }
};

const postPurchaseIePopup = (state = null, action) => {
  switch (action.type) {
    case actions.POST_PURCHASE_IE_POPUP_SHOW:
      return Object.assign({}, action.data);
    case actions.POST_REGISTER_POPUP_CLOSE:
      return null;
    default:
      return state;
  }
};

const customLoadingAnimation = (state = null, action) => {
  switch (action.type) {
    case actions.UI_CUSTOM_LOADING_FETCH_SUCCESS:
      return action.data.data;
    case actions.CHECKOUT_ABANDON:
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  cinemaSelectorOpen,
  areaSelectorOptions,
  areaSelectorOpen,
  authPanel,
  customLoadingAnimation,
  iePanel,
  landingSelectedArea,
  postPurchaseIePopup,
  postRegisterPopup,
  userProfileEdit
});