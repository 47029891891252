import moment from 'moment';

export const parseSessionIsoDateForDisplay = isoDate => {
  const date = moment(isoDate);
  const offset = extractTimeZoneFromIsoDate(isoDate);
  date.zone(offset);

  return date;
};

export const extractTimeZoneFromIsoDate = isoDate => isoDate.match(/(-|\+)([0-9]{2}):([0-9]{2})$/)[0];

export const getTimeZoneOffsetMinutesFromIsoDate = isoDate => {
  const offset = extractTimeZoneFromIsoDate(isoDate).split(':');

  return (parseInt(offset[0], 10) * 60) + parseInt(offset[1], 10);
};

export const jsDateToYmd = date => jsDateToYmdPartsGlued(date, '');
export const jsDateToYmdDashed = date => jsDateToYmdPartsGlued(date, '-');

const jsDateToYmdPartsGlued = (date, glue) => {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return [date.getFullYear(),
    (mm < 10 ? '0' : '') + mm,
    (dd < 10 ? '0' : '') + dd
  ].join(glue);
};

export const formatDateDmy= v => {
  v = v.replace(/[^\d]/g, '');
  const parts = [[0,2],[2,2],[4,4]];
  let result = '';

  for (let i = 0; i < 3; i++) {
    const part = parts[i];
    const n = v.substr(part[0], part[1]);
    result += n;
    if (i < 2 && n.length >= part[1]) {
      result += '/'
    }
  }

  return result.replace(/\/+$/, '/');
};

export const formatTimeHm = v => {
  v = v.replace(/[^\d]/g, '');
  const parts = [[0,2],[2,2]];
  let result = '';

  for (let i = 0; i < 2; i++) {
    const part = parts[i];
    const n = v.substr(part[0], part[1]);
    result += n;
    if (i < 1 && n.length >= part[1]) {
      result += ':'
    }
  }

  return result.replace(/\/+$/, '/');
};
