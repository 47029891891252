import React from 'react';
import {connect} from 'react-redux';
import SidebarMovieInfo from "./SidebarMovieInfo";
import SidebarPanel from './SidebarPanel';
import UserCinemaNav from "./UserCinemaNav";
import {movieTabHideToggle,movieTabOpen, movieUpcomingFetch, notificationsMovieUpcomingSubscribe, notificationsMovieUpcomingUnsubscribe} from '../actions/creators';
import {getMovieUpcoming, getNotificationsMovieUpcomingSubscriptions} from '../reducers';
import { getNotificationsToken } from '../actions/creators/notifications';
import { withRouter } from 'react-router-dom';
import { getMoviePermalink } from '../utils/urls';

class MovieUpcomingPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onSubscribeClick = this.onSubscribeClick.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.open();
    !this.props.movie && this.props.fetch(this.props.movieId);
    
    this.redirectIfHasMovie();
  }

  componentDidUpdate(prevProps) {
    if (this.props.movieId !== prevProps.movieId) {
      this.props.open();
      this.props.fetch(this.props.movieId);
      this.ref && this.ref.current && this.ref.current.scrollTop && this.ref.current.scrollTop();
    }
    
    this.redirectIfHasMovie();
  }

  redirectIfHasMovie() {
    this.props.movie && this.props.movie.movie_id && this.props.history.push(getMoviePermalink({id:this.props.movie.movie_id,name:this.props.movie.name}));
  }

  onSubscribeClick() {
    this.props.getToken(this.props.subscribed, token => {
      if (this.props.subscribed) {
        this.props.unsubscribe(this.props.movieId, token);
      } else {
        this.props.subscribe(this.props.movieId, token);
      }
    });
  }

  render() {
    return (
        <SidebarPanel ref={this.ref} onHideToggle={this.props.hideToggle} isHidden={this.props.hidden} maxWidth="90%">
          <UserCinemaNav />
          {this.props.movie && <SidebarMovieInfo movie={this.props.movie} infoVisible={true} onNotificationToggle={this.onSubscribeClick} notificationStatus={this.props.subscribed}/>}
        </SidebarPanel>
    );
  }
}

const mapStateToProps = (state, props) => {
  const movieId = props.match.params.id;

  return {
    hidden: state.movieTab.hidden,
    movieId,
    movie: getMovieUpcoming(state, movieId),
    subscribed: getNotificationsMovieUpcomingSubscriptions(state).indexOf(movieId) > -1,
  };
};

const mapDispatchToProps = dispatch => ({
  hideToggle: () => dispatch(movieTabHideToggle()),
  open: () => dispatch(movieTabOpen()),
  subscribe: (movieId, token) => dispatch(notificationsMovieUpcomingSubscribe({token, resourceId: movieId})),
  unsubscribe: (movieId, token) => dispatch(notificationsMovieUpcomingUnsubscribe({token, resourceId: movieId})),
  getToken: (subscribed, cb) => dispatch(getNotificationsToken(subscribed, cb)),
  fetch: id => dispatch(movieUpcomingFetch(id)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MovieUpcomingPanel));