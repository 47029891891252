export const scorePassword = pass => {
  let score = 0;
  if (!pass || pass.length < 8)
    return score;

  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i=0; i<pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  const numPatternMatch = pass.match(/123|234|345|456|567|678|789/g);
  score -= !numPatternMatch
      ? 0
      : Math.max(numPatternMatch.length, 5) * 5;

  const letterPatternMatch = pass.match(/abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz/gi);
  score -= !letterPatternMatch
      ? 0
      : Math.max(letterPatternMatch.length, 10) * 10;

  return parseInt(score, 10);
};

export const checkPassStrength = pass => {
  const score = scorePassword(pass);
  if (score >= 70)
    return "strong";
  if (score >= 50)
    return "good";
  if (score >= 30)
    return "poor";

  return "weak";
};

export const getPassStrengthLabel = strength => {
  const labels = {
    'strong': 'Contraseña muy segura',
    'good': 'Contraseña segura',
    'poor': 'Contraseña poco segura',
    'weak': 'Ingresa una contraseña segura',
  };
  return labels[strength];
};

export const getPassStrengthColor = strength => {
  const labels = {
    'strong': 'var(--success)',
    'good': 'var(--success)',
    'poor': 'var(--orange)',
    'weak': 'var(--red)',
  };
  return labels[strength];
};