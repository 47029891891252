import {combineReducers} from 'redux';
import * as actions from '../actions/types';

const lastLoadTime = (state = {}, action) => {
  switch (action.type) {
    case actions.POST_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.slug]: new Date().getTime()});
    default:
      return state;
  }
};

const isLoading = (state = {}, action) => {
  switch (action.type) {
    case actions.POST_FETCH:
      return Object.assign({}, state, {[action.slug]: true});
    case actions.POST_FETCH_SUCCESS:
    case actions.POST_FETCH_ERROR:
      return Object.assign({}, state, {[action.slug]: false});
    default:
      return state;
  }
};

export default combineReducers({
  lastLoadTime,
  isLoading,
});