import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MDSpinner from "react-md-spinner";
import UserCinemaNav from './UserCinemaNav';
import SidebarPanel from './SidebarPanel';
import {getNewsSingle} from '../reducers';

class NewsSingleSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.article && !prevProps.article)
        || (!this.props.article && prevProps.article)
        || (this.props.article.id !== prevProps.article.id)) {
      this.ref && this.ref.current && this.ref.current.scrollTop && this.ref.current.scrollTop();
    }
  }

  render() {
    const {article} = this.props;

    return (
        <SidebarPanel ref={this.ref} onHideToggle={() => this.props.history.push('/novedades')}>
          <UserCinemaNav/>
          {article ? (
              <article key={article.id} className="c-entry p-5 fade-in">
                <h1 className="c-entry__title">{article.title}</h1>
                <div className="c-entry__body">
                  <p><img src={article.cover} className="img-fluid rounded" alt=""/></p>
                  <div dangerouslySetInnerHTML={{__html: article.content}} />
                </div>
              </article>
          ) : (
              <div className="p-5 text-center"><MDSpinner singleColor="var(--primary)"/></div>
          )}
        </SidebarPanel>
    );
  }
}

const mapStateToProps = (state, props) => ({
  article: getNewsSingle(state, props.match.params.id),
});

export default withRouter(connect(
    mapStateToProps
)(NewsSingleSidebar));