import * as actions from '../types';
import {dialogAlert} from './dialogs';
import CMX from '../../api';
import {isIeBenefitsLoading,getIeBenefitsLastLoadTime, getIeCodeValidateCardNumber, getIeNewNip} from '../../reducers';

export const ieCodePanelShow = () => ({
  type: actions.IE_CODE_PANEL_SHOW,
});

export const ieCodePanelBegin = () => ({
  type: actions.IE_CODE_BEGIN
});

export const ieCodePanelCancel = () => ({
  type: actions.IE_CODE_CANCEL
});

export const ieCodePanelPrevStep = () => ({
  type: actions.IE_CODE_PANEL_PREV_STEP
});

export const ieCodeChange = code => ({
  type: actions.IE_CODE_CHANGE,
  code
});

export const ieBarCodeChange = code => ({
  type: actions.IE_BARCODE_CHANGE,
  code
});

export const ieBirthDateChange = code => ({
  type: actions.IE_BIRTH_DATE_CHANGE,
  code
});

export const ieCodeValidateSet = code => ({
  type: actions.IE_CODE_VALIDATE_SET,
  code
});

export const ieCodeValidateSubmit = code => ({
  type: actions.IE_CODE_VALIDATE_SUBMIT,
  code
});

export const ieCodeValidateSuccess = data => ({
  type: actions.IE_CODE_VALIDATE_SUCCESS,
  ...data
});

export const ieCodeValidateFailure = error => ({
  type: actions.IE_CODE_VALIDATE_FAILURE,
  error
});

export const ieCodeSubmit = ({code, memberId, barCode, birthDate}) => (dispatch, getState) => {
  dispatch({
    type: actions.IE_CODE_SUBMIT,
    code,
    memberId,
    barCode,
    birthDate,
  });

  CMX.post('/me/iecode', {iecode: code, member_id: memberId, barcode: barCode, birth_date: birthDate})
      .then(data => dispatch(ieCodeSubmitSuccess(memberId, data.email_sent)))
      .catch(error => dispatch(ieCodeSubmitFailure(error.message)))
};

const ieCodeSubmitSuccess = (memberId, emailSent) => ({
  type: actions.IE_CODE_SUBMIT_SUCCESS,
  memberId,
  emailSent,
});

const ieCodeSubmitFailure = error => dispatch => {
  dispatch(dialogAlert(error));
  dispatch({
    type: actions.IE_CODE_SUBMIT_FAILURE,
    error
  });
};

export const ieCodeDelete = () => dispatch => {
  dispatch({
    type: actions.IE_CODE_DELETE,
  });

  CMX.delete('/me/iecode')
      .then(data => dispatch(ieCodeDeleteSuccess()))
      .catch(error => dispatch(ieCodeDeleteFailure(error.message)))
};

const ieCodeDeleteSuccess = () => ({
  type: actions.IE_CODE_DELETE_SUCCESS,
});

const ieCodeDeleteFailure = error => dispatch => {
  dispatch(dialogAlert(error));
  dispatch({
    type: actions.IE_CODE_DELETE_FAILURE,
    error
  });
};

export const nipCodeUpdateSubmit = ({code}) => ({
  type: actions.IE_NIP_UPDATE_SUBMIT,
  code
});

export const nipCodeUpdateSuccess = () => dispatch => {
  dispatch(dialogAlert('El NIP fue modificado con éxito.'));
  dispatch({
    type: actions.IE_NIP_UPDATE_SUCCESS,
  })
};

export const nipCodeUpdateFailure = error => ({
  type: actions.IE_NIP_UPDATE_FAILURE,
  error
});


export const ieBenefitsFetch = () => (dispatch, getState) => {
  const state = getState();

  if (isIeBenefitsLoading(state)) {
    return;
  }

  if ((new Date()).getTime() - getIeBenefitsLastLoadTime(state) < 60 * 60 * 1000) {
    return;
  }

  dispatch({
    type: actions.IE_BENEFITS_FETCH,
  });

  CMX.get('/ie/benefits')
      .then(data => dispatch(ieBenefitsFetchSuccess(data)))
      .catch(error => dispatch(ieBenefitsFetchError(error)))
};

const ieBenefitsFetchSuccess = data => ({
  type: actions.IE_BENEFITS_FETCH_SUCCESS,
  data: data,
});

const ieBenefitsFetchError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.IE_BENEFITS_FETCH_ERROR,
    error,
  })
};


export const iePinPost = (pin, recaptcha_code) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: actions.IE_CODE_VALIDATE_SUBMIT,
    iecode: getIeCodeValidateCardNumber(state),
    auth_pin: pin,
    captcha: recaptcha_code
  });
};


export const ieNipUpdatePost = (pin, recaptcha_code) => (dispatch, getState) => {
  const state = getState();
console.log('new nip', getIeNewNip(state));
  dispatch({
    type: actions.IE_NIP_UPDATE_SUBMIT,
    code: getIeNewNip(state),
    auth_pin: pin,
    captcha: recaptcha_code
  });
};

export const fetchIeSignUpOptionsSuccess = data => ({
  type: actions.IE_FETCH_SIGN_UP_OPTIONS_SUCCESS,
  data
});

export const fetchIeSignUpOptionsError = error => ({
  type: actions.IE_FETCH_SIGN_UP_OPTIONS_ERROR,
  error
});

export const getIePaymentUrlSuccess = data => ({
  type: actions.IE_GET_PAYMENT_URL_SUCCESS,
  data
});