import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {getDateFilterDisplayString} from '../utils/showtimes';
import {ucFirst} from '../utils/strings';

const PaddedContainer = styled.div`
  padding: 1rem 2.5rem;
`;

const DatesScroller = styled(PaddedContainer)`
  border-bottom: 1px solid transparent;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  /* Customizes the track of the scrollbar */
  &::-webkit-scrollbar-track {
      background: #fcfcfc; /* Color of the track */
  }

  /* Customizes the thumb of the scrollbar */
  &::-webkit-scrollbar-thumb {
      background: #eee; /* Color of the thumb */
  }

  /* Changes the thumb color on hover */
  &::-webkit-scrollbar-thumb:hover {
      background: #ddd; /* Darker color on hover */
  }

  scrollbar-width: thin; /* Can be "auto", "thin", or "none" */
  scrollbar-color: #eee #fcfcfc;

  .o-btn-group__item { margin-bottom: 0; }
  .shadow { box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.1) !important }
`;

const DateButton = styled.button`
  font-size: 0.9em;
  padding-left: 1.5em;
  padding-right: 1.5em;
`;

const getDateTooltipString = date => ucFirst(moment(date).format('dddd DD'));

class ShowtimesDateFilter extends React.Component {
  render() {
    if (!this.props.dates.length) {
      return <PaddedContainer>Aún no hay horarios para esta película en el cine seleccionado.</PaddedContainer>;
    }

    return (
        <DatesScroller className={'o-btn-group o-btn-group--scroll pr-0 border-bottom border-white' + (this.props.noBg === true ? '' : ' bg-light')}>
          {this.props.dates.map(date => (
              <DateButton
                  key={date}
                  className={'o-btn-group__item btn ' + (date === this.props.date ? 'btn-primary' : 'btn-white shadow')}
                  onClick={() => (this.props.onDateSelect(date))} title={getDateTooltipString(date)}
              >
                {getDateFilterDisplayString(date)}
              </DateButton>
          ))}
        </DatesScroller>
    );
  }
}

export default ShowtimesDateFilter;