import * as actions from '../../types';

export const setWalletStep = step => ({
    type: actions.WALLET_SET_STEP,
    step
});

export const updateAddCardState = params => ({
    type: actions.WALLET_CARD_ADD_CHANGE,
    params
});

export const setSelectedCard = card => ({
    type: actions.CHECKOUT_WALLET_SELECT_CARD,
    card
});