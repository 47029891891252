import React from "react";
import styled from "styled-components";
import IeCardBase from "./IeCardBase";

const CardFront = styled(IeCardBase)`
    color: white;
    ${props => props.flip ? `transform: rotate3d(0, 1, 0, 180deg);` : ''}
`;

const CardHolderName = styled.div`
    position: absolute;
    right: 2.5em;
    bottom: 2em;
    max-width: 52%;
    overflow: hidden;
    text-align: right;
    line-height: 1.2em;
    font-size: 0.75rem;
    font-weight: 700;
    text-shadow: 0 0 3px ${props => props.color};
`;

function IeCardFront(props) {
    const {data, flip} = props;

    return <CardFront flip={flip}>
        <img src={data.card_front} al="" />
        <CardHolderName color={data.color}>{data.cardholder_name}</CardHolderName>
    </CardFront>;
}

export default IeCardFront;