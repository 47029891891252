import React from 'react';
import styled from 'styled-components';

const BottomLegend = styled.div`
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 3em;
`;

const Number = styled.span`
    display: inline-block;
    font-size: 1.5em;
    margin: 0 0.2em;
    ${props => props.active ? 'color: var(--green);' : ''}
`;

const Icon = styled.i`
    font-size: 1.75em;
    display: block;
    margin: 0.3em 0 0.5em 0;

    ${props => props.valid ? 'color: var(--green);' : (props.invalid ? 'color: var(--primary);' : '')}
`;

function RemainingSeatsLegend({ total, selected }) {
    if (selected === 0) {
      return <>
        <Icon className="fa-light fa-hand-back-point-up"></Icon>
        <p>Por favor selecciona {total === 1 ? 'tu asiento' : 'tus asientos'} dando clic.</p>
      </>;
    }

    if (selected < total) {
      const remainder = total - selected;
      return <>
        <Icon className="fa-light fa-circle-exclamation" invalid></Icon>
        <p>¡Todavía te falta seleccionar {remainder} asiento{remainder !== 1 ? 's' : ''}!</p>
      </>;
    }

    return <>
      <Icon className="fa-light fa-circle-check" valid></Icon>
      <p>¡Has seleccionado todos los asientos!<br/><strong>Disfruta la película</strong></p>
    </>;
}

function SeatsSelectionCount({ total, selected }) {
    return <BottomLegend>
    <p>
        <Number active={selected > 0}>{selected}</Number> de <Number>{total}</Number> {total === 1 ? 'asiento seleccionado' : 'asientos seleccionados'}.
    </p>
    <RemainingSeatsLegend total={total} selected={selected} />
  </BottomLegend>;
}

export default SeatsSelectionCount;