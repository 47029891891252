import * as actions from '../../types';
import CMX from '../../../api';
import {dialogAlert, dialogClose, dialogOpen} from '../dialogs';
import {
  getCheckoutT1SessionId,
  getCheckoutPaymentMethod,
  getCheckoutSession,
  getCheckoutSelectedPaymentOption,
  getCheckoutTransactionId,
  getWalletUserId,
  isCheckoutCCRequired,
  getCheckoutNipCode,
  getWalletAddCardInfo,
  getCheckoutWalletSelectedCard,
  getFingerprint,
  getPayPalClientMetadataId,
  getCheckoutPaymentFormParams,
  isCheckoutTermsChecked,
} from '../../../reducers';
import {paymentMethodSelect} from '../checkout';
import {maskCardNumber} from '../../../utils/checkout';

export const paymentMethods = {
  movieCard: 'moviecard',
  creditCard: 'credit-card',
  masterPass: 'masterpass',
  payPal: 'paypal',
};

export const getPaymentParams = state => {
  const {order} = state.checkoutData;
  const items = getCheckoutSelectedPaymentOption(state);
  const params = Object.assign({}, getCheckoutPaymentFormParams(state));

  params.fingerprint = getFingerprint(state);
  params.session_id = getCheckoutSession(state).id;
  params.transaction_id = getCheckoutTransactionId(state);
  params.coupon_code = state.checkoutData.promoCode.code;
  params.tickets = items.tickets;
  params.candybar = items.candybar;
  params.seats = order.seats;
  params.terms_check = isCheckoutTermsChecked(state) ? '1' : '0';

  if (!params.nip) { // If no NIP provided in the payment form, use NIP from NIP request form.
    params.nip = getCheckoutNipCode(state);
  }

  if ('paypal' in params) {
    params.paypal.client_metadata_id = getPayPalClientMetadataId(state);
  }

  return params;
};

export const paymentFormSubmit = params => ({
  type: actions.CHECKOUT_PAYMENT_FORM_SUBMIT,
  params
});


export const paymentPost = () => (dispatch, getState) => {
  const state = getState();
  const params = getPaymentParams(state);
  const isCCRequired = isCheckoutCCRequired(state);
  const isMovieCardPayment = getCheckoutPaymentMethod(state) === paymentMethods.movieCard;
  const isMasterCardPayment = getCheckoutPaymentMethod(state) === paymentMethods.masterPass;
  const isPayPalPayment = getCheckoutPaymentMethod(state) === paymentMethods.payPal;
  const isCreditCardPayment = isCCRequired && !isMovieCardPayment && !isMasterCardPayment && !isPayPalPayment;

  dispatch(paymentIsProcessing(true));

  if (state.checkoutData.useT1 && isCreditCardPayment) {
    dispatch(t1TokenPayment(params));
  } else {
    dispatch(actualPaymentPost(params));
  }
};

export const t1TokenPayment = (cardParams, tokenData, args = {}) => (dispatch, getState) => {
  const params = getPaymentParams(getState());
  params.t1 = {
    token_id: tokenData.token,
    device_id: getCheckoutT1SessionId(getState()),
  };
  params.name = cardParams.name;
  params.email = cardParams.email;
  params.cc = maskCardNumber(tokenData.pan.replace(/\*/g, '0'));
  params.csc = cardParams.csc;
  params['expire-month'] = '00';
  params['expire-year'] = '00';
  
  if ('updateCvv' in args && args.updateCvv) {
    dispatch(updateCvvBeforePaymentPost(params));
  } else {
    dispatch(actualPaymentPost(params));
  }
};

export const t1ExistingCardPayment = () => (dispatch, getState) => {
  const state = getState();
  const tokenData = getCheckoutWalletSelectedCard(state);
  const addCardParams = getWalletAddCardInfo(state).params;
  const cardParams = {
    name: tokenData.nombre,
    email: addCardParams.email,
    csc: addCardParams.csc,
  };

  dispatch(t1TokenPayment(cardParams,tokenData, {updateCvv: true}));
};

export const t1NewCardPayment = () => (dispatch, getState) => {
  const state = getState();
  const addCardParams = getWalletAddCardInfo(state).params;

  dispatch(t1AddCard(addCardParams, t1TokenPayment));
};

export const t1AddCard = (params, callback) => (dispatch, getState) => {
  let status = 0;
  let success = false;
  
  const tokenParams = {
    "pan": params.cc.replace(/[^\d]+/g, ''),
    "nombre": params.name,
    "expiracion_anio": params['expire-year'].substr(2),
    "expiracion_mes": params['expire-month'],
    "cvv2": params.csc,
  };
  const walletUId = getWalletUserId(getState());

  if (walletUId) {
    tokenParams.cliente_id = walletUId;
  }

  const t1CreateTokenSuccess = result => {
    dispatch({
      type: actions.WALLET_CARD_ADD_SUCCESS,
      result
    });

    return result;
  };

  const t1CreateTokenFailure = err => {
    dispatch({
      type: actions.WALLET_CARD_ADD_ERROR
    });
    dispatch(paymentError({message: err.message || 'Ocurrio un error. Por favor, inténtalo nuevamente.'}));
  };

  dispatch({
    type: actions.WALLET_CARD_ADD
  });

  fetch(
      'https://api.sandbox.claropagos.com/v1/tarjeta/',
      {
        headers: {
          "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY2ZDUwYTA2ZjI4YzVkZDc1YzYyMmQ5Y2FlYTRmYmExM2NkMGM0MzEyNDA4ZjE2ZDc4OGFjMmRhNzFhYjI3ODQ0MWYwOGM0M2JhNmMwNjAwIn0.eyJhdWQiOiIyIiwianRpIjoiNjZkNTBhMDZmMjhjNWRkNzVjNjIyZDljYWVhNGZiYTEzY2QwYzQzMTI0MDhmMTZkNzg4YWMyZGE3MWFiMjc4NDQxZjA4YzQzYmE2YzA2MDAiLCJpYXQiOjE1OTQ2ODYwNzYsIm5iZiI6MTU5NDY4NjA3NiwiZXhwIjoxNjU3NzU4MDc2LCJzdWIiOiI4OCIsInNjb3BlcyI6WyJjbGllbnRlLXRhcmpldGFzIl19.aS818EUNWhK_TtjsXu3OOui8zIhUMA_HqFt7LGJ-RyCygEiMh_puCPgR4lLLLrDcgq0itPw8nGvUVdudn2LMhVhhbGbBGosTPE7IrMhTuD6DCPedkIOg2GwPmjuSu4Cd1nOASWHoXQUD_nMzj_GXPpXlZ8yaouXAjCFmdDOaFt4IWU_SKTOKp-6er_GpJAhtHClzv8LC-JW_6bIfMMBx5y6528wCk06YJu-tMowX4fgplJq0oPRW_401S_t4PqoIJ7a7RwABWVT_2cxt7IWpBMnkj0DV5se7X7qGLFBSrNnq1QQineBrlRZlSSJ-vTm0QJFHtqTZoRaIkXY53bQS1nx4RwQuCCD5N0zU9dafTThZOSyUhjNIXxtd4R5Q_Vh6Oz-IQa3n7043ozEJgrAEm6_RBrAVt7_8DQn-htMrjA7SZ2IO9MUS6WmcAb7Az3HwewEY4IGRqam9Ul2DQJ86U-XgopnOovtWkPg5b5OP0X_0M0O0WIgDGpthnoWs5Rs9ieVkkMvobBjoF_UyVES5AS4hRdL5Bjx4H0LQQ-Pg6nUxLJjghlBqQ7eYV6KIEeAej7Was-XXLYjsRIbVMHJK4jCo83tffW_DEw1UWrjR5p5pwpRP89Sw7HRYEX6LCJ37-R8SVQIYQI4DC62RoZ14QP2cKXyzSl_ox0-couAsisM",
          "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify(tokenParams)
      }
  )
      .then(res => {
        status = res.status;
        success = res.ok;

        return res.json();
      })
      .then(json => {
        if (!success || status !== 200) {
          throw new Error(json.error.message);
        }

        if (!json || json.status !== "success" || !json.data.tarjeta) {
          throw new Error();
        }

        return json.data.tarjeta;
      })
      .then(t1CreateTokenSuccess)
      .then(tokenData => callback && dispatch(callback(params, tokenData, {updateCvv: false})))
      .catch(t1CreateTokenFailure);
};

const updateCvvBeforePaymentPost = params => dispatch => {
  let status = 0;
  let success = false;

  dispatch(paymentIsProcessing(true));
  fetch(
    'https://api.sandbox.claropagos.com/v1/tarjeta/' + params.t1.token_id,
    {
      headers: {
        "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY2ZDUwYTA2ZjI4YzVkZDc1YzYyMmQ5Y2FlYTRmYmExM2NkMGM0MzEyNDA4ZjE2ZDc4OGFjMmRhNzFhYjI3ODQ0MWYwOGM0M2JhNmMwNjAwIn0.eyJhdWQiOiIyIiwianRpIjoiNjZkNTBhMDZmMjhjNWRkNzVjNjIyZDljYWVhNGZiYTEzY2QwYzQzMTI0MDhmMTZkNzg4YWMyZGE3MWFiMjc4NDQxZjA4YzQzYmE2YzA2MDAiLCJpYXQiOjE1OTQ2ODYwNzYsIm5iZiI6MTU5NDY4NjA3NiwiZXhwIjoxNjU3NzU4MDc2LCJzdWIiOiI4OCIsInNjb3BlcyI6WyJjbGllbnRlLXRhcmpldGFzIl19.aS818EUNWhK_TtjsXu3OOui8zIhUMA_HqFt7LGJ-RyCygEiMh_puCPgR4lLLLrDcgq0itPw8nGvUVdudn2LMhVhhbGbBGosTPE7IrMhTuD6DCPedkIOg2GwPmjuSu4Cd1nOASWHoXQUD_nMzj_GXPpXlZ8yaouXAjCFmdDOaFt4IWU_SKTOKp-6er_GpJAhtHClzv8LC-JW_6bIfMMBx5y6528wCk06YJu-tMowX4fgplJq0oPRW_401S_t4PqoIJ7a7RwABWVT_2cxt7IWpBMnkj0DV5se7X7qGLFBSrNnq1QQineBrlRZlSSJ-vTm0QJFHtqTZoRaIkXY53bQS1nx4RwQuCCD5N0zU9dafTThZOSyUhjNIXxtd4R5Q_Vh6Oz-IQa3n7043ozEJgrAEm6_RBrAVt7_8DQn-htMrjA7SZ2IO9MUS6WmcAb7Az3HwewEY4IGRqam9Ul2DQJ86U-XgopnOovtWkPg5b5OP0X_0M0O0WIgDGpthnoWs5Rs9ieVkkMvobBjoF_UyVES5AS4hRdL5Bjx4H0LQQ-Pg6nUxLJjghlBqQ7eYV6KIEeAej7Was-XXLYjsRIbVMHJK4jCo83tffW_DEw1UWrjR5p5pwpRP89Sw7HRYEX6LCJ37-R8SVQIYQI4DC62RoZ14QP2cKXyzSl_ox0-couAsisM",
        "Content-Type": "application/json"
      },
      method: 'PUT',
      body: JSON.stringify({cvv2: params.csc})
    }
  )
    .then(res => {
      status = res.status;
      success = res.ok;

      return res.json();
    })
    .then(json => {
      if (!success || status !== 200) {
        throw new Error(json.error.message);
      }

      if (!json || json.status !== "success" || !json.data.tarjeta) {
        throw new Error();
      }

      return json.data.tarjeta;
    })
    .then(() => dispatch(actualPaymentPost(params)))
    .catch(() =>dispatch(paymentError({})));
}

export const actualPaymentPost = params => dispatch => {
  dispatch(paymentIsProcessing(true));
  CMX.post('buy/complete', params)
      .then(data => dispatch(paymentSuccess(data)))
      .then(() => dispatch(paymentIsProcessing(false)))
      .catch(error => dispatch(paymentError(error, params)));
};

export const paymentIsProcessing = (isLoading) => ({
  type: actions.CHECKOUT_PAYMENT_IS_PROCESSING,
  isLoading,
});

const paymentError = (error, params) => dispatch => {
  if (!error) error = {};
  const errorcode = (('response' in error) && ('errorcode' in error.response)) ? error.response.errorcode : undefined;

  dispatch({
    type: actions.CHECKOUT_PAYMENT_ERROR,
    errorcode,
    error
  });
};

export const paymentSuccess = data => ({
  type: actions.CHECKOUT_PAYMENT_SUCCESS,
  data,
});

export const payPayAuthorizeSuccess = data => ({
  type: actions.CHECKOUT_PAYMENT_FORM_SUBMIT,
  params: {
    payment_method: 'paypal',
    paypal: {
      payment_id: ('paymentToken' in data) ? data.paymentToken : data.paymentID,
      payer_id: data.payerID
    }
  }
});

export const payPayBillingAgreementSuccess = data => ({
  type: actions.CHECKOUT_PAYMENT_FORM_SUBMIT,
  params: {
    payment_method: 'paypal',
    paypal: {
      billing_token: data.billingToken
    }
  }
});

export const payPalOneClickPayment = () => ({
  type: actions.CHECKOUT_PAYMENT_FORM_SUBMIT,
  params: {
    payment_method: 'paypal',
    paypal: {
      one_click: true
    }
  }
});

export const fetchSmartIframeUrl = (extraParams) => (dispatch, getState) => {
  const state = getState();
  const params = getPaymentParams(getState());
  params.nip = getCheckoutNipCode(state);
  params.fingerprint = getFingerprint(state);

  if (extraParams && ('user_info' in extraParams)) {
    params.user_info = extraParams.user_info;
  }

  dispatch(getSmartIframeUrlIsProcessing());

  CMX.post('buy/getSmartUrl', params)
      .then(data => dispatch(getSmartIframeUrlSuccess(data)))
      .catch(error => dispatch(getSmartIframeUrlError(error, params)));
};

const getSmartIframeUrlIsProcessing = () => ({
  type: actions.GET_SMART_URL
});

const getSmartIframeUrlSuccess = data => ({
  type: actions.GET_SMART_URL_SUCCESS,
  data,
});

const getSmartIframeUrlError = () => dispatch => {
  dispatch(dialogAlert('Ocurrió un error. Por favor, inténtalo nuevamente.'));
  dispatch({
    type: actions.GET_SMART_URL_ERROR
  });
};

export const smartPaymentError = error => dispatch => {
  dispatch({
    type: actions.CHECKOUT_SMART_PAYMENT_ERROR
  });
  if (!error.cancelled) {
    if (!error.retry) {
      dispatch(paymentError(error.data));
      return;
    }

    dispatch(dialogOpen({
      message: error.data.message,
      buttons: [
        {
          label: 'Continuar',
          primary: true,
          onClick: (dialog) => {
            dispatch(dialogClose(dialog.id));
            setTimeout(() => {
              dispatch(fetchSmartIframeUrl({user_info: error.data.user_info}));
              dispatch(paymentMethodSelect('credit-card'));
            }, 250);
          }
        }
      ]
    }));
  }
};

export const t1DeleteCard = card => dispatch => {
  dispatch(dialogOpen({
    message: '¿Confirmas que deseas eliminar esta tarjeta?',
    buttons: [
      {
        label: 'Cancelar',
        onClick: (dialog) => {
          dispatch(dialogClose(dialog.id));
        }
      },
      {
        label: 'Confirmar',
        primary: true,
        onClick: (dialog) => {
          dispatch(dialogClose(dialog.id));          
          dispatch({
            type: actions.WALLET_CARD_DELETE,
            card
          });
          fetch(
            'https://api.sandbox.claropagos.com/v1/tarjeta/' + card.token,
            {
              headers: {
                "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY2ZDUwYTA2ZjI4YzVkZDc1YzYyMmQ5Y2FlYTRmYmExM2NkMGM0MzEyNDA4ZjE2ZDc4OGFjMmRhNzFhYjI3ODQ0MWYwOGM0M2JhNmMwNjAwIn0.eyJhdWQiOiIyIiwianRpIjoiNjZkNTBhMDZmMjhjNWRkNzVjNjIyZDljYWVhNGZiYTEzY2QwYzQzMTI0MDhmMTZkNzg4YWMyZGE3MWFiMjc4NDQxZjA4YzQzYmE2YzA2MDAiLCJpYXQiOjE1OTQ2ODYwNzYsIm5iZiI6MTU5NDY4NjA3NiwiZXhwIjoxNjU3NzU4MDc2LCJzdWIiOiI4OCIsInNjb3BlcyI6WyJjbGllbnRlLXRhcmpldGFzIl19.aS818EUNWhK_TtjsXu3OOui8zIhUMA_HqFt7LGJ-RyCygEiMh_puCPgR4lLLLrDcgq0itPw8nGvUVdudn2LMhVhhbGbBGosTPE7IrMhTuD6DCPedkIOg2GwPmjuSu4Cd1nOASWHoXQUD_nMzj_GXPpXlZ8yaouXAjCFmdDOaFt4IWU_SKTOKp-6er_GpJAhtHClzv8LC-JW_6bIfMMBx5y6528wCk06YJu-tMowX4fgplJq0oPRW_401S_t4PqoIJ7a7RwABWVT_2cxt7IWpBMnkj0DV5se7X7qGLFBSrNnq1QQineBrlRZlSSJ-vTm0QJFHtqTZoRaIkXY53bQS1nx4RwQuCCD5N0zU9dafTThZOSyUhjNIXxtd4R5Q_Vh6Oz-IQa3n7043ozEJgrAEm6_RBrAVt7_8DQn-htMrjA7SZ2IO9MUS6WmcAb7Az3HwewEY4IGRqam9Ul2DQJ86U-XgopnOovtWkPg5b5OP0X_0M0O0WIgDGpthnoWs5Rs9ieVkkMvobBjoF_UyVES5AS4hRdL5Bjx4H0LQQ-Pg6nUxLJjghlBqQ7eYV6KIEeAej7Was-XXLYjsRIbVMHJK4jCo83tffW_DEw1UWrjR5p5pwpRP89Sw7HRYEX6LCJ37-R8SVQIYQI4DC62RoZ14QP2cKXyzSl_ox0-couAsisM",
                "Content-Type": "application/json"
              },
              method: 'DELETE'
            }
          )
            .then(res => {
              dispatch({
                type: actions.WALLET_CARD_DELETE_SUCCESS,
                card
              });
            });
        }
      }
    ]
  }));
};


export const paymentPinPost = (pin) => (dispatch, getState) => {
  const state = getState();
  const params = getCheckoutPaymentFormParams(state);
  params.auth_pin = pin;

  dispatch({
    type: actions.CHECKOUT_PAYMENT_FORM_SUBMIT,
    params
  });
};
