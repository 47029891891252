import React from 'react';
import uniqid from 'uniqid';
import styled from 'styled-components';
import {setModalDisplayedFlag} from '../utils/modals';
import { AnalyticsModals } from '../utils/analytics';

let navId = '';
let timer;
let timeoutTime;

const Anchor = styled.a`
    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 10%;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
    }
`;

const onVolToggle = e => {
    e.preventDefault();
    const a = e.currentTarget;
    const vid = a.previousElementSibling.firstElementChild;
    const ico = a.firstElementChild;
    if (vid.tagName !== 'VIDEO') return;
    vid.muted = !vid.muted;
    if (vid.muted) {
        ico.classList.remove('fa-volume-up');
        ico.classList.add('fa-volume-mute');
    } else {
        ico.classList.remove('fa-volume-mute');
        ico.classList.add('fa-volume-up');        
    }
}

class ModalSidebar extends React.Component {    
    constructor(props) {
        super(props);

        this.onIndexChanged = this.onIndexChanged.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.onTimeout = this.onTimeout.bind(this);
        this.onClick = this.onClick.bind(this);
        this.close = this.close.bind(this);
    }
        
    componentDidUpdate() {
        if (!navId) navId = uniqid();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    onIndexChanged(info) {
        const slide = info.slideItems[info.index];
        const id = parseInt(slide.getAttribute('data-id'), 10);
        let matchedModal;

        for (let i = 0; i < this.props.modals.length; i++) {
            const modal = this.props.modals[i];
            if (modal.id === id) {
                matchedModal = modal;
                break;
            }
        }

        const child = slide.children[0];
        if (child.nodeName === 'VIDEO') {
            child.currentTime = 0;
            child.play();
        }

        setModalDisplayedFlag(matchedModal);
        this.startTimer();
    };

    startTimer() {
        this.stopTimer();
        timer = setTimeout(this.onTimeout, timeoutTime);
    }

    onTimeout() {
        this.close();
    }

    onClick(event) {
        const modal = this.props.modals[0];
        const openInNewWindow = event.altKey || event.ctrlKey || event.shiftKey;
        AnalyticsModals.trackClick(modal.id, modal.name, 'sidebar', openInNewWindow ? null : () => {
            document.location = modal.url;
        });
        if (!openInNewWindow) event.preventDefault();
    }
    
    close() {
        AnalyticsModals.trackClose(this.props.modals[0].id, this.props.modals[0].name, 'sidebar');
        this.props.onClose();
    }

    stopTimer() {
        timer && clearTimeout(timer);
    }

    render() {
        const {modals} = this.props;
        
        if (!modals.length) return;

        const modal = modals[0];
        
        timeoutTime = modals.reduce((carry, modal) => Math.max(carry, modal.timeout), 0) * 1000;
        this.startTimer();
        AnalyticsModals.trackView(modal.id, modal.name, 'sidebar');
      
        return <div className="l-wrapper__sidebar-over">
            <a href="#" className="l-wrapper__sidebar-over-close" onClick={this.close}><i className="fal fa-times"></i></a>
            <div className="c-slider__item" key={modal.id} data-id={modal.id}>{this.renderModal(modal)}</div>
        </div>;
    }
        
    renderModal(modal) {
        switch (modal.type) {
            case 'image':
                return this.renderImageModal(modal);
            case 'video':
                return this.renderVideoModal(modal);
            default:
                return null;
        }
    }
    
    renderImageModal(modal) {
        return <Anchor href={modal.url || '#'} target={modal.target} className="o-ad-cover" style={{backgroundImage:'url(' + modal.file + ')'}} onClick={this.onClick}>{modal.name}</Anchor>
    }
    
    renderVideoModal(modal) {
        const file = modal.file.replace(/\.zip$/, '') + '/video';
    
        return (
            <React.Fragment>
                <Anchor href={modal.url || '#'} target={modal.target} onClick={this.onClick}>
                    <video width="100%" height="100%" autoPlay muted preload="true" onEnded={this.onTimeout}>
                        <source src={`${file}.mp4`} type="video/mp4"/>
                        <source src={`${file}.webm`} type="video/webm"/>
                        Tu browser no soporta video.
                    </video>
                </Anchor>
                <a href="#" style={{position: 'absolute',color: 'white',top: 0,right: '50px',width: '50px',lineHeight: '50px',textAlign: 'center'}} onClick={onVolToggle}><i class="fal fa-volume-mute"></i></a>
            </React.Fragment>
        );
    }
}

export default ModalSidebar;