import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { asset } from '../../utils/misc';
import { centsToCurrencyString } from '../../utils/numbers';
import { breakpoints } from '../../utils/styles';

const LevelsContainer = styled.div`
  margin-bottom: 2em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    ${props => props.extraWidth ? 'width: 120%;' : ''}
  }
`;
const LevelIcon = styled.img`
  opacity: 0.5;
  position: absolute;
  right: -2rem;
  top: -2rem;
  width: 195px;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    left: 50%;
    opacity: 1;
    top: 1em;
    transform: translateX(-50%);
    width: auto;
  }
`;
const LevelEmoji = styled.p`
  position: absolute;
  right: 2rem;
  top: 0.75rem;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    position: relative;
    right: auto;
    top: auto;
    font-size: 2.5em;
    margin: 0.25em 0 !important;
  }
`;
const LevelTitle = styled.h2`
  color: #000 !important;
  font-size: 1.75em;
  font-weight: 700;
  margin: 0.5em 0 1em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font-size: 1.75em !important;
  }
`;
const LevelBenefits = styled.ul`
  margin-bottom: 1em !important;
  padding: 0 0 0 1.5em;
  text-align: left;

  & > li { margin-bottom: 0.5em; }
  & > li > strong { color: #333; }
`;
const LevelPrice = styled.p`
  color: #000 !important;
  font-size: 1.75em;
  font-weight: 700;
  margin: 0.75em 0 0.5em 0 !important;
`;
const LevelContent = styled.div`
  border: 2px solid transparent;
  border-radius: 24px;
  color: #555;
  line-height: 1.5;
  padding: 1em 2em;
  transition: border-color 0.25s ease-out;

  & p {
    margin: 1em 0;
  }
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    border-radius: 24px 24px 0 0;
    border-bottom-color: transparent !important;
    font-size: 0.7em;
    height: 101%;
  }
`;
const LevelButton = styled.button`
  background: #AAA;
  border: 0;
  bottom: 1.5em;
  color: white;
  font-size: 1.2em;
  outline: none;
  padding: 0.25em 1em 0.35em;
  position: absolute;
  right: 1.5em;
  transition: background-color 0.25s ease-out;
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    border-radius: 0 0 24px 24px;
    bottom: 0;
    margin-top: -2px;
    right: 0;
    width: 100%;
  }
`;
const LevelBox = styled.div`
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
    text-align: center;
    padding-bottom: 2.5rem;
  }

  & ${LevelTitle} { ${props => props.altStyles ? 'font-size: 1.2em;' : ''}
  & ${LevelEmoji} { }

  &:hover {
    & ${LevelContent} { border-color: #000; }
    & ${LevelButton} { background: var(--primary); }
  }

  & ${LevelContent} { ${props => props.selected ? 'border-color: #000;' : ''} }
  & ${LevelButton} { ${props => props.selected ? 'background: var(--primary);' : ''} }
`;

const TermsLinks = styled.p`
  font-size: 0.8em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font-size: 1em;
  }
`;

function getIconImage(optionId) {
  if (optionId.substr(0, 4) === 'one-') optionId = 'one';
  if (optionId.substr(0, 9) === 'buen-fin-') optionId = 'basic';
  return 'logo-cinemex-icon-' + optionId + '.svg'
}

function getOptionTitle(option) {
  let title = '';
  
  if (('is_current' in option) && option.is_current === false) {
    title = 'Subir a ';
  }

  title += option.name;

  return title;
}

function formatText(text) {
  return text.replace(/\*(.+)\*/, '<strong>$1</strong>');
}

function IeSignUpOptions(props) {
    return <LevelsContainer>
        {props.levels.map(option => (
            <LevelBox key={option.id} onClick={e => { e.stopPropagation(); props.onLevelSelect(option); }} selected={props.selected && props.selected.id === option.id} className="fade-in" altStyles={props.altStyles}>
                <LevelContent>
                  {option.icon ? <LevelEmoji>{option.icon}</LevelEmoji> : <LevelIcon src={asset('/dist/images/' + getIconImage(option.id))}/>}
                  <LevelTitle>{getOptionTitle(option)}</LevelTitle>
                  {option.benefits.length > 1
                    ? <LevelBenefits>
                          {option.benefits.map(benefit => <li dangerouslySetInnerHTML={{__html: formatText(benefit)}}/>)}
                      </LevelBenefits>
                    : <p>{option.benefits[0]}</p>}
                  <TermsLinks>
                    {option.terms_url ? <React.Fragment><a href={option.terms_url}>Consulta términos y condiciones.</a><br/></React.Fragment> : ''}
                    <Link to="/invitado-especial/niveles-beneficios">Ver todos los beneficios.</Link>
                  </TermsLinks>
                  {!props.hidePriceZero ? <LevelPrice>{centsToCurrencyString(option.price)}<small>/año</small></LevelPrice> : ''}
                </LevelContent>
                <LevelButton className='btn btn-primary' type='button'>Seleccionar</LevelButton>
            </LevelBox>
        ))}
    </LevelsContainer>;
}

export default IeSignUpOptions;