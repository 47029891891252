import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import scriptLoader from '@kevinkl3/react-script-loader';
import CMX from '../../api';
import {dialogAlert} from '../../actions/creators/dialogs';
import {payPayAuthorizeSuccess, payPayBillingAgreementSuccess} from '../../actions/creators/checkout/payment';
import {getCheckoutSession,getCheckoutTransactionId,getCheckoutSelectedPaymentOption, getBootstrapConfig, isUserLogged} from '../../reducers';

class PayPalButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showButton: false,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;

    this.onPayPalAuthorize = this.onPayPalAuthorize.bind(this);
    this.payPalPaymentFunction = this.payPalPaymentFunction.bind(this);
  }

  componentDidMount() {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed,
    } = nextProps;

    const isLoadedButWasntLoadedBefore =
        !this.state.showButton &&
        !this.props.isScriptLoaded &&
        isScriptLoaded;

    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }

  render() {
    const paypal = window.paypal;
    const client = '';

    return (
        <div className="p-5">
          <div className="mt-5 text-center">
            {!this.state.showButton ? (
                <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>
            ) : (
                <React.Fragment>
                  <h3 className="h4 mb-5">Haz click en el botón para comenzar el pago con PayPal</h3>

                  <paypal.Button.react
                      client={client}
                      commit={true}
                      env={this.props.paypalConfig.env}
                      locale="es_ES"
                      payment={this.payPalPaymentFunction}
                      onAuthorize={this.onPayPalAuthorize}
                  />
                </React.Fragment>
            )}
          </div>
        </div>
    );
  }

  payPalPaymentFunction() {
    const paypal = window.paypal;
    let url, params;
    
    if (this.props.isUserLogged) {
      url = '/paypal/createBillingAgreement';
      params = {};
    } else {
      url = '/paypal/createPayment';
      params = {
        session_id: this.props.sessionId,
        transaction_id: this.props.transactionId,
        tickets: this.props.ticketsOrder,
        candybar: {
          items: this.props.candybarOrder,
        },
      };
    }

    return new paypal.Promise((resolve, reject) => {
      CMX.post(
          url,
          params
      )
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            reject(new Error('Create payment error'));
            this.props.dialogAlert(error.message);
          });
    })
        .then(res => this.props.isUserLogged ? res.token_id : res.payment_id);
  }

  onPayPalAuthorize(data) {
    const paypal = window.paypal;

    return new paypal.Promise((resolve, reject) => {
      this.props.isUserLogged
        ? this.props.payPayAuthorizeAgreementSuccess(data)
        : this.props.payPayAuthorizeSuccess(data)
      resolve();
    });
  }
}

const mapStateToProps = state => {
  const items = getCheckoutSelectedPaymentOption(state);

  return {
    sessionId: getCheckoutSession(state).id,
    transactionId: getCheckoutTransactionId(state),
    ticketsOrder: items.tickets,
    candybarOrder: items.candybar,
    isUserLogged: isUserLogged(state),
    paypalConfig: getBootstrapConfig(state).paypal
  }
};

const mapDispatchToProps = dispatch => ({
  dialogAlert: message => dispatch(dialogAlert(message)),
  payPayAuthorizeSuccess: data => dispatch(payPayAuthorizeSuccess(data)),
  payPayAuthorizeAgreementSuccess: data => dispatch(payPayBillingAgreementSuccess(data)),
});

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(connect(
    mapStateToProps,
    mapDispatchToProps
)(PayPalButton));