import * as actions from '../types';
import { dialogClose, dialogOpen } from './dialogs';

export const getNotificationsToken = (subscribed, cb) => dispatch => {    
    console.log(subscribed, cb);
    const getToken = () => {
        if (Notification.permission === 'denied') {
            onPermissionDenied();
            return;
        }

        if (Notification.permission === 'default') {
            console.log('mostrar cartelito onda whatsapp');
        }

        messaging.getToken({vapidKey: window.localConfig.firebase.vapidKey}).then((currentToken) => {
            if (currentToken) {
                onPermissionGranted(currentToken);
            } else {
                requestPermission();
            }
        }).catch((err) => {
            if (err.code === 'messaging/permission-blocked') {
                return onPermissionDenied();
            } else {
                console.log(err.code);
                requestPermission();
            }
        });
    };

    const requestPermission = () => {
        console.log('Requesting permission...');
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                return getToken();
            } else {
                onPermissionDenied();
            }
        });
    };

    const onPermissionGranted = token => {
        cb(token);
        dispatch({
            type: actions.NOTIFICATIONS_TOKEN_REQUEST_GRANTED,
            token
        });
    };

    const onPermissionDenied = () => {
        dispatch({
            type: actions.NOTIFICATIONS_TOKEN_REQUEST_DENIED
        });
    };

    const messaging = window.firebase.messaging();

    return new Promise((resolve, reject) => {
        dispatch(dialogOpen({
          message: '<div style="font-size:2rem;margin-bottom:0.5em;"><i class="fal fa-bell"></i></div>' + (subscribed ? '¿Quieres eliminar el recordatorio del estreno de esta película?' : '¿Deseas guardar un recordatorio para cuando esta película estrene?'),
          buttons: [
            {
              label: 'Cancelar',
              onClick: (dialog) => {
                dispatch(dialogClose(dialog.id));
                reject();
              }
            },
            {
              label: 'Confirmar',
              primary: true,
              onClick: (dialog) => {
                dispatch(dialogClose(dialog.id));
                dispatch({
                    type: actions.NOTIFICATIONS_TOKEN_REQUEST,
                    callback: cb,
                    permission: Notification.permission,
                });
                getToken();
                resolve();
              }
            }
          ]
        }));
    });
};

export const getNotificationsAbort = () => ({
    type: actions.NOTIFICATIONS_TOKEN_REQUEST_ABORT,
});
