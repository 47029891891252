import * as actions from '../actions/types';
import CMX from '../api';
import { getUiAreaSelectorOptions } from '../reducers';

export const landingsMiddleware = ({ getState, dispatch }) => next => async action => {
    pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function pre(action, getState, dispatch) {
    switch (action.type) {
        case actions.UI_AREA_SELECTOR_SELECT_AREA:
            return onAreaSelect(action, getState, dispatch);
    }
}

function post(action, getState, dispatch) {
    switch (action.type) {}
}

function onAreaSelect({areaId}, getState, dispatch) {
    const state = getState();
    const context = getUiAreaSelectorOptions(state).context;
    if (context !== 'landing') return;
    dispatch({
        type: actions.UI_LANDING_SELECT_AREA,
        areaId
    })
}