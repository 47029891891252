import React from 'react';
import ImageContent from './LandingContentImage';

export default ({ content }) => {
  return content.promos.map((promo, index) => {
    const content = {
      image: promo.image_path,
      image_alt: promo.name
    };

    return <ImageContent key={index} content={content} />;
  });
};