import * as actions from '../../actions/types';
import { combineReducers } from 'redux';

const tickets = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_QTY_INCREASE:
      return Object.assign({}, state, getTicketQuantityUpdatedObject(state, action.ticket, 1));
    case actions.CHECKOUT_TICKET_QTY_DECREASE:
      return Object.assign({}, state, getTicketQuantityUpdatedObject(state, action.ticket, -1));
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return {};
    default:
      return state;
  }
};

const getTicketQuantityUpdatedObject = (state, ticket, quantity) => {
  const ticketId = ticket.id;
  let result = {};

  if (state[ticketId]) {
    result = Object.assign({}, state[ticketId]);
  } else {
    result = {qty: 0};
  }

  result.qty += quantity;

  if (result.qty < 0) {
    result.qty = 0;
  }

  if (result.qty > ticket.max) {
    result.qty = ticket.max;
  }

  return {
    [ticketId]: result
  };
};

const seats = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
    case actions.CHECKOUT_SEATS_SELECTION_SUCCESS:
      return action.data.seats;
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return [];
    default:
      return state;
  }
};

const candybar = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CANDYBAR_ITEM_ADD:
      return Object.assign({}, state, getItemAddedCandybarObject(state, action.item, action.unitId));
    case actions.CHECKOUT_CANDYBAR_ITEM_REMOVE:
      return removeItemAndGetCandybarObject(state, action.itemId, action.unitId);
    case actions.CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL:
      return getBackupCandybarObject(state, action.itemId, action.oldState);
    case actions.CHECKOUT_CANDYBAR_OPTION_SELECT:
      return getOptionSelectedCandybarObject(state, action);
    case actions.CHECKOUT_CANDYBAR_UNIT_COMMENT_UPDATE:
      return getCommentUpdatedCandybarObject(state, action);
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return {};
    default:
      return state;
  }
};

const getItemAddedCandybarObject = (state, item, unitId) => {
  let selection;

  if (item.id in state) {
    selection = Object.assign({}, state[item.id]);
  } else {
    selection = {};
  }

  selection[unitId] = {};

  return {[item.id]: selection};
};

const removeItemAndGetCandybarObject = (state, itemId, unitId) => {
  const result = Object.assign({}, state);

  if (Object.keys(result[itemId]).length > 1) {
    delete result[itemId][unitId];
  } else {
    delete result[itemId];
  }

  return result;
};

const getBackupCandybarObject = (state, itemId, oldItemState) => {
  const result = Object.assign({}, state);

  if (oldItemState && Object.keys(oldItemState).length) {
    result[itemId] = Object.assign({}, oldItemState);
  } else {
    delete result[itemId];
  }

  return result;
};

const getOptionSelectedCandybarObject = (state, {itemId, unitId, config, optionId, selected}) => {
  const result = Object.assign({}, state, {
    [itemId]: Object.assign({}, state[itemId], {
      [unitId]: Object.assign({}, state[itemId][unitId])
    })});
  const unit = result[itemId][unitId];
  const configId = config.id;

  if (selected) {
    if (!(configId in unit)) {
      unit[configId] = [];
    }
    if (config.max === unit[configId].length) {
      if (unit[configId].indexOf(optionId) > -1) {
        return result;
      }

      unit[configId].splice(0,1);
    }
    unit[configId].push(optionId);
  } else {
    const i = unit[configId].indexOf(optionId);
    if (i > -1) {
      delete unit[configId][i];
    }

    if (!unit[configId].length) {
      delete unit[configId];
    }
  }

  return result;
};

const getCommentUpdatedCandybarObject = (state, {itemId, unitId, comments}) => {
  const result = Object.assign({}, state, {
    [itemId]: Object.assign({}, state[itemId], {
      [unitId]: Object.assign({}, state[itemId][unitId], {comments})
    })});

  return result;
};

const candybarTemp = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CANDYBAR_ITEM_BACKUP:
      return action.currentState && getCandybarBackupObject(action.currentState);
    default:
      return state;
  }
};

const getCandybarBackupObject = (itemState) => {
  const result = Object.assign({}, itemState);

  Object.keys(itemState).forEach(unitId => {
    const unit = itemState[unitId];
    const unitCopy = {};

    Object.keys(unit).forEach(configId => {
      unitCopy[configId] = Object.assign([], unit[configId]);
    });

    result[unitId] = unitCopy;
  });

  return result;
};

const transactionId = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return action.data.transaction_id;
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  tickets,
  candybar,
  candybarTemp,
  seats,
  transactionId,
});
