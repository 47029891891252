import * as actions from '../../actions/types';
import { combineReducers } from 'redux';

const isProcessing = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_IS_PROCESSING:
      return action.isLoading;
    case actions.CHECKOUT_PAYMENT_ERROR:
    case actions.CHECKOUT_PAYMENT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const postError = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_ERROR:
      return true;
    case actions.CHECKOUT_PAYMENT_IS_PROCESSING:
    case actions.CHECKOUT_PAYMENT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const formData = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_FORM_SUBMIT:
      return Object.assign({}, action.params);
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
    case actions.CHECKOUT_PAYMENT_SUCCESS: // @todo think other possible cases where this needs to be wiped
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  formData,
  isProcessing,
  postError,
});