import { loginPostFailed, loginPostSuccess, onSignUpCheckEmailError, onSignUpCheckEmailSuccess } from '../actions/creators/auth';
import { userSessionsFetch } from '../actions/creators/user';
import * as actions from '../actions/types';
import CMX from '../api';
import { getFingerprint, getLoginEmail, getLoginPassword } from '../reducers';
import { onApiError } from './misc';

export const authMiddleware = ({ getState, dispatch }) => next => async action => {
    next(action);

    switch (action.type) {
        case actions.AUTH_PIN_SUBMIT:
            return onAuthPinSubmit(action, dispatch, getState);
        case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT:
            return onSignUpCheckEmailSubmit(action, dispatch, getState);
        case actions.AUTH_SIGN_UP_CHECK_EMAIL_ERROR:
            return onApiError(dispatch, action);
        case actions.AUTH_LOGIN_POST_ERROR:
            return onLoginError(dispatch, action);
        case actions.AUTH_LOGIN_POST:
        case actions.AUTH_LOGIN_PIN_POST:
            return onLoginPost(getState, dispatch, action);
        case actions.AUTH_LOGOUT:
            return onLogOut(dispatch);
        case actions.AUTH_LOGOUT_SUCCESS:
            return setTimeout(() => document.location.href = '/', 2000);
        case actions.USER_SESSION_KILL_SUCCESS:
            return dispatch(userSessionsFetch());
        case actions.AUTH_RENEW_TOKEN:
            return renewAuthToken(action, getState, dispatch);
        default:
            return;
    }
};

function onAuthPinSubmit(action, dispatch, getState) {
    if (action.context === 'register') return onSignUpCheckEmailSubmit({email: action.payload.email, auth_pin: action.pin}, dispatch, getState);
}

function onSignUpCheckEmailSubmit(action, dispatch, getState) {
    const params = {
        email: action.email,
        captcha: action.captcha
    };

    if (('auth_pin' in action)) params.auth_pin = action.auth_pin;

    return CMX.post('/register', params)
        .then(res => dispatch(onSignUpCheckEmailSuccess(res)))
        .catch(error => dispatch(onSignUpCheckEmailError(error, {context: 'register', payload: {email: action.email}})));
}

function onLoginPost(getState, dispatch, action) {
    const state = getState();
    const fingerprint = getFingerprint(state);
    const args = {fingerprint};

    if ('apple_signin' in action) {
        args.apple_signin = action.apple_signin;
        args.ref = action.ref;
    } else {    
        args.email = getLoginEmail(state);
        args.password = getLoginPassword(state);
    }

    if (action.pin) args.pin = action.pin;

    CMX.post('/login', args)
        .then(data => dispatch(loginPostSuccess(data)))
        .catch(error => dispatch(loginPostFailed(error)));
}

function onLoginError(dispatch, action) {
    return onApiError(dispatch, Object.assign({context: 'login'}, action));
}


function onLogOut(dispatch) {
    CMX.post('/me/logout')
        .then(() => dispatch({type: actions.AUTH_LOGOUT_SUCCESS}))
        .catch(error => onApiError(dispatch, {error}));
}

function renewAuthToken(action, getState, dispatch) {
    const state = getState();
    const args = {
        token: action.token,
        app_id: action.appId,
        fingerprint: getFingerprint(state)
    };
    
    CMX.post('/renewToken', args)
        .then(data => dispatch(loginPostSuccess(data)))
        .catch(error => dispatch(loginPostFailed(error)));
}