import React from 'react';
import { connect } from 'react-redux';
import { getUserInfo, isUserStuffProcessing } from '../../reducers';
import { fetchUserInfo } from '../../actions/creators/user';
import { authShowLogin } from '../../actions/creators';
import { withRouter } from 'react-router-dom';
import { hasIeAction } from '../../utils/misc';
import Button from '../Button';
import Header from '../Header';

class IeRedirection extends React.Component {
  componentDidMount() {
    this.props.fetchUserInfo();
  }

  shouldComponentUpdate(nextProps) {
    return !this.props.userInfo && nextProps.userInfo;
  }

  render() {
    const { userInfo } = this.props;

    if (this.props.loading) return '';

    if (!userInfo) {
        return this.renderMessage(
            'Para poder ser parte del programa Invitado Especial Cinemex, primero debes iniciar sesión o crear una cuenta.',
            <Button onClick={this.props.login} primary>Continuar</Button>
        );
    }

    if (!userInfo.iecode) {
        this.props.history.push('/usuario/invitado-especial/inscripcion');
        return '';
    }

    if (hasIeAction(userInfo, 'migration')) {
        this.props.history.push('/usuario/invitado-especial/migracion');
        return '';
    }

    if (hasIeAction(userInfo, 'renewal')) {
        this.props.history.push('/usuario/invitado-especial/cambio-de-nivel');
        return '';
    }

    return this.renderMessage(
        '¡Ya no tienes que realizar ninguna acción! Continúa disfrutando La Magia del Cine en Cinemex con Invitado Especial.',
        <Button to="/" primary>Continuar</Button>
    );
  }

  renderMessage(message, button) {
    return <React.Fragment>
        <div className='l-wrapper l-wrapper--toggle' id="the-body">
            <div className="l-wrapper__content">
                <Header withUserCinemaNav={false} />

                <div className="text-center" style={{maxWidth:'700px',margin:'0 auto'}}>
                    <h4 className="my-5 px-5" style={{margin:'5rem auto',lineHeight:'1.5em'}}>
                        {message}
                    </h4>
                    {button}
                </div>
            </div>
        </div>
    </React.Fragment>;
  }
}

const mapStateToProps = state => ({
    loading: isUserStuffProcessing(state),
    userInfo: getUserInfo(state),
  });
  
  const mapDispatchToProps = dispatch => ({
    fetchUserInfo: () => dispatch(fetchUserInfo()),
    login: () => dispatch(authShowLogin()),
  });
  
  export default withRouter(connect(
      mapStateToProps,
      mapDispatchToProps
  )(IeRedirection));