import {combineReducers} from 'redux';
import * as actions from '../../actions/types';

const cardNumber = (state = '', action) => {
    switch (action.type) {
        case actions.IE_CODE_VALIDATE_SET:
            return action.code;
        default:
            return state;
    }
};

const newNip = (state = '', action) => {
    switch (action.type) {
        case actions.IE_NIP_UPDATE_SUBMIT:
            return action.code;
        default:
            return state;
    }
};

export default combineReducers({
    cardNumber,
    newNip
});