import React from "react";
import styled from "styled-components";
import IeCardBase from "./IeCardBase";

const CardBack = styled(IeCardBase)`
    transform: rotate3d(0, 1, 0, 180deg);
    width: 100%;
    ${props => props.flip ? `transform: rotate3d(0, 1, 0, 360deg);` : ''}
`;
const BarCode = styled.div`
    color: #000;
    font-size: 15px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 1.5em;
    width: 100%;
`;
const BarCodeImage = styled.div`
    height: 2.5em;

    & > svg {
        max-width: 61%;
        margin: 0 auto;
    }
`;

function IeCardBack(props) {
    const {data, flip} = props;

    return <CardBack flip={flip}>
        <img src={data.card_back} al="" />
        <BarCode>
            <BarCodeImage dangerouslySetInnerHTML={{__html:data.barcode_image}}/>
            {data.card_number}
        </BarCode>
    </CardBack>;
}

export default IeCardBack;