import React from 'react';
import {connect} from 'react-redux';

const formatTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  return (minutes < 10 ? '0' : '') + minutes.toString()
    + ':'
    + (seconds < 10 ? '0' : '') + seconds.toString();
};

class Timer extends React.PureComponent {
  render() {
    return (
        <span className="h2 text-nowrap text-primary ml-auto">{formatTime(this.props.time)} <i className="fal fa-stopwatch ml-2"></i></span>
    );
  }
}

const mapStateToProps = state => ({
  time: state.checkoutData.timer.time,
});

export default connect(
    mapStateToProps
)(Timer);