export const shuffleArray = array => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortByProperty = prop => (a, b) => {
  const propA = a[prop];
  const propB = b[prop];

  if (propA > propB) {
    return 1;
  } else if (propA < propB) {
    return -1;
  } else {
    return 0;
  }
};

export const sortByName = sortByProperty('name');

export const unique = (value, index, self) => self.indexOf(value) === index;