import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import TwoColumnLayout from './TwoColumnLayout';
import PromosPageContent from './PromosPageContent';
import PromosPageSidebar from './PromosPageSidebar';
import {promosFetch} from '../actions/creators/promos';
import {getPromosPromos,isPromosLoading} from '../reducers';

type Promo = {};
type Props = {
  fetch:Function,
  promos:Array<Promo>
};

function PromosPage(props:Props) {
  const [selectedPromo, setSelectedPromo] = useState<Promo|null>(null);

  useEffect(() => {
    props.fetch();
  });

  return <TwoColumnLayout MainContent={PromosPageContent} SidebarContent={PromosPageSidebar}
      selectedPromo={getSelectedPromo()}
      sidebarOpen={selectedPromo}
      sidebarMobileToggle
      onSelect={onSelect} {...props}/>;

  function getSelectedPromo() {
    if (selectedPromo) {
      return selectedPromo;
    } else if (props.promos.length) {
      return props.promos[0];
    } else {
      return null;
    }
  }

  function onSelect(selectedPromo:Promo) {
    setSelectedPromo(selectedPromo);
  }
}

const mapStateToProps = state => ({
  promos: getPromosPromos(state),
  isLoading: isPromosLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(promosFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromosPage);