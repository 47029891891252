import React from 'react';
import {Link} from 'react-router-dom';
import MDSpinner from "react-md-spinner";
import BillboardMovieBlock from './BillboardMovieBlock';
import {getCinemaPermalink} from '../utils/urls';
import BillboardCinemaHeading from "./BillboardCinemaHeading";
import NoMoviesMessage from "./NoMoviesMessage";
import { AnalyticsBillboards } from '../utils/analytics';

class BillboardCinemaBlock extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.onIntersect = this.onIntersect.bind(this);
    this.observer = new IntersectionObserver(this.onIntersect, {threshold: 1});
    this.tracked = [];
  }

  componentDidMount() {
    this.addListeners();
  }

  componentDidUpdate() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.observer = null;
  }

  addListeners() {
    this.ref.current.querySelectorAll('.billboard-movie-block').forEach(elem => this.observer.observe(elem));
  }

  onIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const movieId = parseInt(entry.target.getAttribute('data-movie-id'), 10);

        if (this.tracked.indexOf(movieId) > -1) return;

        const movie = this.getMovie(movieId);
        if (movie) {
          AnalyticsBillboards.trackMovie(movie, this.props.cinema);
          this.tracked.push(movieId);
        }
      }
    });
  }

  getMovie(movieId) {
    const {movies} = this.props;
    for (let i = 0; i < movies.length; i++) {
      if (movies[i].id === movieId) {
        return movies[i];
      }
    }
  }

  render() {
    const {cinema, movies} = this.props;

    return (
        <div className="list-group mb-4" ref={this.ref}>
          <div className={(this.props.header === 'desktop-only' ? 'd-none d-md-block ' : '') + 'list-group-item list-group-item-tertiary'}>
            <Link to={getCinemaPermalink(cinema)} className="p-5 d-flex align-items-center">
              <BillboardCinemaHeading cinema={cinema}/>
            </Link>
          </div>

          {movies.length ? (
              movies.map(movie => <BillboardMovieBlock key={`${cinema.id}-${movie.id}`} date={this.props.date} movie={movie}/>)
          ) : (
            <div className="list-group-item px-5 py-2"><NoMoviesMessage
                title="No hay películas disponibles con los filtros seleccionados."
                message="Puedes probar con otras opciones de filtrado."
            /></div>
          )}
        </div>
    );
  }
}

export default BillboardCinemaBlock;