import React from 'react';
import Sticky from 'react-stickynode';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const ShadowClipper = styled.div`
  overflow: hidden;
`;

const Header = styled.header`
  & .cinema-head-info { margin-bottom: 1em; }
  & h1,
  & hgroup {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  ${props => props.sticky === Sticky.STATUS_FIXED && `
    width: 100%;
    background: #e6e6e6;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;

    & .cinema-head-info { display: none; }
  `}
`;

class BillboardStickyHeader extends React.Component {
    render() {
        return (
            <HeaderWrapper>
                <Sticky top="#header" bottomBoundary="#the-body" innerZ="1031">{status => (
                    <ShadowClipper>
                        <Header className={this.props.className} sticky={status.status}>
                            {this.props.children}
                        </Header>
                    </ShadowClipper>
                )}</Sticky>
            </HeaderWrapper>
        )
    }
}

export default BillboardStickyHeader;