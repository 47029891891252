import React from 'react';
import Button from '../Button';

class PayPalSingleClickButton extends React.Component {
    render() {
        return (
            <div className="p-5">
              <div className="mt-5 text-center">
                <p className="mb-5">Haz click para completar el pago de forma automática con los datos guardados en tu cuenta de PayPal</p>
                <Button onClick={this.props.onPayClick} paypal>
                  Pagar con
                  <img src={'/dist/images/paypal-logo-color.svg'} alt="PayPal Logo" style={{display:'inline-block',height:'1.75em',marginLeft:'0.5em'}}/>
                </Button>
              </div>
            </div>
        );
    }
}

export default PayPalSingleClickButton;