import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CloseCross from "./CloseCross";
import BodyBlocker from "./BodyBlocker";
import SidebarPanel from "./SidebarPanel";
import Button from './Button';
import CinemaSelectorAutocomplete from './CinemaSelectorAutocomplete';
import {changePreferredCinema} from '../actions/creators';
import {cinemaSelectorClose} from '../actions/creators/ui';
import {sortByProperty} from '../utils/arrays';
import {filterCinemasNearLatLng} from '../utils/location';
import {getCinemas,getStates, getStatesWithCinemas} from '../reducers';

const CinemaSelectBlock = styled.div`
  height: auto;
  opacity: 1;
  transition: opacity 0.5s ease-in;
  
  &.hidden {
    height: 0;
    opacity: 0;
    transition: opacity 0s ease-in;
  }
`;

const CinemaOption = styled.button`
  background: no-repeat;
  border: 0;
  color: var(--primary);
  cursor: pointer;
  display: block;
  outline: none;
  
  &:hover { color: #850227; } 
`;

class CinemaSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectArea = this.selectArea.bind(this);
    this.selectCinema = this.selectCinema.bind(this);
    this.goToGeolocation = this.goToGeolocation.bind(this);
    this.goToManual = this.goToManual.bind(this);
    this.renderScreen = this.renderScreen.bind(this);
    this.renderManualScreen = this.renderManualScreen.bind(this);
    this.renderGeolocationScreen = this.renderGeolocationScreen.bind(this);
    this.renderGeolocationResults = this.renderGeolocationResults.bind(this);
    this.geolocationSuccess = this.geolocationSuccess.bind(this);
    this.geolocationError = this.geolocationError.bind(this);
    this.setAreaAndCinema = this.setAreaAndCinema.bind(this);

    this.state = {
      screen: 'manual',
      areaId: props.areaId || 0,
      cinemaId: props.cinemaId || 0,
      geolocation: {
        position: null,
        error: null,
      },
    };
  }

  getWelcome() {
    if (this.props.user) {
      return <div className="h3">¡Bienvenido!</div>;
    } else {
      return '';
    }
  }

  getAreas() {
    return this.props.states.map((state) => {
      state.areas = Object.values(state.areas).sort(sortByProperty('name'));
      return state;
    });
  }

  getCinemas() {
    const areaId = this.state.areaId;

    return this.props.cinemas.filter(cinema => parseInt(cinema.area.id, 10) === areaId).sort(sortByProperty('name'));
  }

  selectArea(e) {
    const value = parseInt(e.target.value, 10);

    this.setState(() => ({areaId: value, cinemaId: 0}));
  }

  selectCinema(e) {
    const cinemaId = parseInt(e.target.value, 10);
    const {areaId} = this.state;

    this.setAreaAndCinema(areaId, cinemaId);
  }

  setAreaAndCinema(areaId, cinemaId) {
    this.setState(() => ({cinemaId}));

    localStorage.setItem('cmx-state', JSON.stringify({areaId, cinemaId}));
    this.props.changePreferredCinema(areaId, cinemaId);
  }

  goToGeolocation() {
    this.setState(() => ({screen: 'geolocation'}));
    navigator.geolocation.getCurrentPosition(
        this.geolocationSuccess,
        this.geolocationError
    );
  }

  goToManual() {
    this.setState(() => ({screen: 'manual'}));
  }

  geolocationSuccess(position) {
    this.setState(() => ({geolocation: {position, error: null}}));
  }

  geolocationError(error) {
    this.setState(() => ({geolocation: {position: null, error}}));
  }

  render() {
    if (!this.props.open) {
      return '';
    }

    return (
        <React.Fragment>
          <BodyBlocker />
          <SidebarPanel overBlocker={true}>
            <CloseCross onClick={this.props.close} theme="dark" />

            <div className="px-5 py-4 h-100">
              <h3 className="h5 mb-5">
                {this.getWelcome()}
                <div>Escoge tu Zona y Cine de preferencia</div>
              </h3>

              {this.renderScreen()}
            </div>
          </SidebarPanel>
        </React.Fragment>
    );
  }

  renderScreen() {
    if (this.state.screen === 'manual') {
      return this.renderManualScreen();
    } else {
      return this.renderGeolocationScreen();
    }
  }

  renderManualScreen() {
    return (
        <React.Fragment>
          <div className="mb-5">
            <p className="text-muted">Usaremos tu ubicación para ofrecerte las mejores películas en los Cinemex más cercanos.</p>
            <Button onClick={this.goToGeolocation} outline primary large block>Encontrar mi ubicación automáticamente</Button>
          </div>

          <form action="">
            <p>O selecciona tu cine favorito manualmente:</p>
            <div className="form-row mb-5">
              <div className="form-group col-md-6">
                <label htmlFor="preferencesZona">Tu zona</label>
                <select className="form-control" id="preferencesZona" onChange={this.selectArea} value={this.state.areaId}>
                  <option key="0" value="0">Selecciona tu zona</option>
                  {this.getAreas().map((state) => <optgroup key={state.id} label={state.name}>{state.areas.map((area) => <option value={area.id} key={area.id}>{area.name}</option>)}</optgroup>)}
                </select>
              </div>
              <CinemaSelectBlock className={'form-group col-md-6 ' + (this.state.areaId !== 0 ? '' : 'hidden')}>
                <label htmlFor="preferencesCine">Cine favorito</label>
                <select className="form-control" id="preferencesCine" onChange={this.selectCinema} value={this.state.cinemaId}>
                  <option key="0" value="0">Selecciona tu cine</option>
                  {this.getCinemas().map((cinema) => <option value={cinema.id} key={cinema.id}>{cinema.name}</option>)}
                </select>
              </CinemaSelectBlock>
            </div>
          </form>

          <CinemaSelectorAutocomplete onSelect={this.setAreaAndCinema}/>
        </React.Fragment>
    );
  }

  renderGeolocationScreen() {
    const {position, error} = this.state.geolocation;

    return (
        <React.Fragment>
          {!error && !position && <p>Esperando ubicación...</p>}
          {error && <p>Lo sentimos, no se pudo obtener la ubucación.</p>}
          {position && this.renderGeolocationResults()}
          <Button onClick={this.goToManual} outline primary large block>Seleccionar cine manualmente</Button>
        </React.Fragment>
    );
  }

  renderGeolocationResults() {
    const {coords} = this.state.geolocation.position;
    const results = filterCinemasNearLatLng(this.props.cinemas, coords.latitude, coords.longitude, 5);
    return (
        <div className="mb-5">
          {results.map(cinema => (
            <CinemaOption onClick={() => this.setAreaAndCinema(cinema.area.id, cinema.id)}>{cinema.name}</CinemaOption>
          ))}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  cinemas: getCinemas(state),
  states: getStatesWithCinemas(state),
});

const mapDispatchToProps = dispatch => ({
  changePreferredCinema: (areaId, cinemaId) => dispatch(changePreferredCinema(areaId, cinemaId)),
  close: () => dispatch(cinemaSelectorClose()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CinemaSelector);