import React from 'react';
import {connect} from 'react-redux';
import {t1AddCard, t1DeleteCard} from '../../actions/creators/checkout/payment';
import { setSelectedCard, setWalletStep, updateAddCardState } from '../../actions/creators/checkout/wallet';
import {getUserId, getWalletAddCardInfo, getWalletStep} from '../../reducers';
import AddCardForm from './AddCardForm';
import CardCvvForm from './CardCvvForm';
import StepHeader from './StepHeader';
import WalletCard from './WalletCard';
import WalletCardContainer from './WalletCardContainer';

const steps = {
    'selectCard': 1,
    'enterCvv': 2,
    'addCard': 3
};

class Wallet extends React.Component {
    constructor(props) {
        super(props);

        this.onCardSelect = this.onCardSelect.bind(this);
        this.goToAddCard = this.goToAddCard.bind(this);
        this.addCardBack = this.addCardBack.bind(this);
        this.state = {
            selectedCard: null
        };
    }

    onCardSelect(card) {
        this.setState({
            selectedCard: card
        });
        this.props.setWalletStep(steps.enterCvv);
        this.props.selectCard(card);
    }

    goToAddCard() {
        this.props.setWalletStep(steps.addCard);
    }

    render() {
        if (!this.props.userId) {
            return this.renderAddCardForm();
        }
        
        switch (this.props.step) {
            case steps.enterCvv:
                return this.renderCvvForm();
            case steps.addCard:
                return this.renderAddCardForm();
            case steps.selectCard:
            default:
                return this.renderCardSelector();
        }
    }

    renderCardSelector() {
        const colClass = this.props.inCheckout !== false ? 'col-4 col-xl-3' : 'col-4';

        return <React.Fragment>
        {this.props.inCheckout === false
            ? <h1 className="h2 mb-5">Mis tarjetas</h1>
            : <StepHeader onPrevClick={this.props.onCancel} label="Selecciona una tarjeta"/>}

        <div className="row">
            {this.props.cards.map(card => <div className={colClass} key={card.pan}>
                <WalletCard number={card.pan} expYear={card.expiracion_anio}
                    expMonth={card.expiracion_mes} cardHolderName={card.nombre} brand={card.marca}
                    onClick={this.props.inCheckout !== false ? () => this.onCardSelect(card) : null}
                    onDeleteClick={(e) => this.onCardDeleteClick(e, card)}
                />
            </div>)}
            <div className={colClass}><WalletCardContainer onClick={this.goToAddCard}>
                <div style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',lineHeight:1,textAlign:'center'}}>
                    <i className="fal fa-credit-card fa-xs" style={{fontSize:'2em',display:'block',marginBottom:'0.25em',color:'var(--primary)'}}></i>
                    {this.props.inCheckout === false ? 'Agregar nueva tarjeta' : 'Pagar con otra tarjeta'}
                </div>
            </WalletCardContainer></div>
        </div>
        </React.Fragment>
    }

    renderCvvForm() {
        return <React.Fragment>
            <StepHeader onPrevClick={() => this.props.setWalletStep(steps.selectCard)} label="Código de seguridad"/>
            <CardCvvForm onChange={this.props.updateAddCardState} info={this.state.selectedCard}/>
        </React.Fragment>;
    }

    renderAddCardForm() {
        return <React.Fragment>
            <StepHeader onPrevClick={this.addCardBack} label={this.props.userId ? 'Nueva tarjeta de crédito' : 'Datos de pago'} timer={this.props.inCheckout !== false}/>
            <AddCardForm onChange={this.props.updateAddCardState} info={this.props.cardInfo} onSubmit={this.props.inCheckout !== false ? null : this.props.onAddCardSubmit}/>
        </React.Fragment>;
    }

    addCardBack() {
        this.props.setWalletStep(steps.selectCard);

        if (!this.props.userId) {
            this.props.onCancel();
        }
    }

    onCardDeleteClick(e, card) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // @todo prompt user for confirmation
        this.props.deleteCard(card);
    }
}

const mapStateToProps = state => {
    return {
        cardInfo: getWalletAddCardInfo(state),
        step: getWalletStep(state),
        userId: getUserId(state),
    };
}

const mapDispatchToProps = dispatch => ({
    selectCard: card => dispatch(setSelectedCard(card)),
    updateAddCardState: params => dispatch(updateAddCardState(params)),
    setWalletStep: step => dispatch(setWalletStep(step)),
    onAddCardSubmit: params => dispatch(t1AddCard(params)),
    deleteCard: card => dispatch(t1DeleteCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);