import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Link, withRouter} from 'react-router-dom';
import uniqid from 'uniqid';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatDateDmy} from '../utils/dates';
import {ieSignUpError, ieSignUpFormShow, ieSignUpFormSubmit, ieSignUpSuccess} from '../actions/creators';
import {isIeSignUpSubmitted, isUserLogged, getUserInfo, getIeSignUpOptions, getCinemas, getIePaymentUrl, getSelectedCinemaId, getIeCardData} from '../reducers';
import { sortByName } from '../utils/arrays';
import ExternalPaymentPopup from './checkout/ExternalPaymentPopup';
import { Prompt } from 'react-router-dom';
import IeSignUpOptions from './user/IeSignUpOptions';
import IeSignUpSuccessMessage from './user/IeSignUpSuccessMessage';

const getCinemasChoices = (props, state) => props.cinemas;

function getFatherLastName(user) {
  if (('mothers_last_name' in user) && user.mothers_last_name) return user.last_name;
  const lastName = user.last_name;
  const parts = lastName.split(' ').filter(name => !!name);
  if (parts.length < 3) return parts[0];
  return parts[0] + ' ' + parts[1];
}

function getMotherLastName(user) {
  if (('mothers_last_name' in user) && user.mothers_last_name) return user.mothers_last_name;
  const lastName = user.last_name;
  const parts = lastName.split(' ').filter(name => !!name);
  if (parts.legend < 2) return '';
  if (parts.length === 2) return parts[1];
  return parts.slice(2).join(' ');
}

const fields = {
  cinema: {
    label: 'Cine favorito',
    required: true,
    id: uniqid(),
    getChoicesMethod: getCinemasChoices,
    // placeholder: 'Selecciona un cine',
    validationMessage: 'Selecciona un cine.',
    validationFns: [validators.validateNotEmpty],
  },
  first_name: {
    label: 'Nombres',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu nombre.'
  },
  f_last_name: {
    label: 'Apellido paterno',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu apellido paterno.'
  },
  m_last_name: {
    label: 'Apellido materno',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu apellido materno.'
  },
  birth_date: {
    label: 'Fecha de nacimiento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateBirthDate, validators.isAtLeast18],
    validationMessage: ['Ingresa tu fecha de nacimiento.', 'Debes ser mayor de 18 años.'],
    placeholder: 'dd/mm/yyyy',
    formatterFn: formatDateDmy
  },
  gender: {
    label: 'Género',
    required: true,
    id: uniqid(),
    placeholder: 'Selecciona tu género',
    choices: [
      {id: 'female', name: 'Femenino'},
      {id: 'male', name: 'Masculino'},
      {id: 'non-binary', name: 'No binarie'},
      {id: 'no-answer', name: 'Prefiero no decir'},
    ],
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona tu género.',
  },
  cellphone: {
    label: 'Celular',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty, validators.validateNumber10Digits],
    validationMessage: 'Ingresa un número de celular de 10 dígitos.'
  },
  terms: {
    label: <React.Fragment>He leído y acepto el <a href="/privacidadvisitantes" target="_blank">Aviso de Privacidad</a></React.Fragment>,
    required: true,
    id: uniqid(),
    type: 'checkbox',
    validationFns: [validators.validateTrue],
    validationMessage: 'Debes aceptar el Aviso de Privacidad para continuar.'
  }
};

const fieldSets = [
  {
    id: 'info',
    label: '',
    fields: ['first_name', 'f_last_name', 'm_last_name', 'birth_date', 'gender', 'cellphone', 'cinema'],
  },
  {
    id: 'terms',
    label: '',
    fields: ['terms'],
  },
];

class IERegistrationPage extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onExternalPaymentComplete = this.onExternalPaymentComplete.bind(this);
    this.onLevelSelect = this.onLevelSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    this.state = {
      level: null,
      cinema: {value: '', valid: null},
      first_name: {value: '', valid: null},
      f_last_name: {value: '', valid: null},
      m_last_name: {value: '', valid: null},
      birth_date: {value: '', valid: null},
      gender: {value: '', valid: null},
      cellphone: {value: '', valid: null},
      terms: {value: false, valid: null},
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.preFillUserInfo();
    this.props.show();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && (!prevProps.user || this.props.user.id !== prevProps.user.id)) {
      this.preFillUserInfo();
    }
  }

  preFillUserInfo() {
    if (this.props.user) {
      this.setState({
        cinema: {value: (this.props.selectedCinemaId || '').toString(), valid: null},
        first_name: {value: this.props.user.first_name || '', valid: null},
        f_last_name: {value: getFatherLastName(this.props.user) || '', valid: null},
        m_last_name: {value: getMotherLastName(this.props.user) || '', valid: null},
        gender: {value: this.props.user.gender || '', valid: null},
        birth_date: {value: this.props.user.birth_date || '', valid: null},
        cellphone: {value: this.props.user.mobile_number || '', valid: null}
      });
    }
  }

  onLevelSelect(level) {
    if (!level.group) {
      const y = this.formRef.current ? this.formRef.current.offsetTop : 300;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
    this.setState({level});
  }

  render() {
    return <React.Fragment>
        <Helmet><title>Inscripción Invitado Especial - Cinemex</title></Helmet>
        {this.renderContent()}
    </React.Fragment>;
  }

  renderContent() {
    if (this.props.isSubmitted) return this.renderSubmitMessage();
    if (!this.props.isUserLoggedIn) return <p>Por favor, inicia sesión para comenzar.</p>;
    if (this.props.user.iecode) return <p>Ya te encuentras inscripto.</p>;
    
    return <div>
      <p>¡Ahora puedes inscribirte a nuestro programa de lealtad Invitado Especial Cinemex de forma digital!</p>
      <p>{this.state.level && (this.state.level.group || this.state.level.parent) ? 'Selecciona tu beneficio de bienvenida' : 'Selecciona tu nivel'}:</p>
      {this.renderSignUpOptions()}
      <div ref={this.formRef}>{this.state.level && !this.state.level.group ? this.renderForm() : ''}</div>
      {this.state.level ? '' : <p className='small'><Link to="/invitado-especial/programa">Conoce más</Link> sobre el programa Invitado Especial Cinemex.</p>}
      {this.props.paymentUrl ? this.renderExternalPaymentPopup() : ''}
    </div>
  }

  renderSignUpOptions() {
    if (!this.props.levels) return '';

    let levels;
    if (this.state.level && (this.state.level.group || this.state.level.parent)) {
      levels = this.props.levels.filter(level => !!level.parent);
    } else {
      levels = this.props.levels.filter(level => !level.parent);
    }
    
    return <div>
        <IeSignUpOptions levels={levels} onLevelSelect={this.onLevelSelect} selected={this.state.level} extraWidth />
    </div>;
  }

  renderSubmitMessage() {
    return <IeSignUpSuccessMessage level={this.props.user.ie_level} title="Ya eres Invitado Especial 🤩"
        cardData={this.props.cardData}
        content={this.props.isSubmitted === 'already_registered'
          ? <React.Fragment>
              <p>Tu correo ya contaba con una cuenta de Invitado Especial, por lo que no es necesario volver a inscribirte en el programa.</p>
              <p>¡Tus compras y visitas siguen sumando puntos que puedes redimir cuando quieras!</p>
          </React.Fragment>
          : <React.Fragment>
            <p>
              Hemos enviado un correo electrónico con tu NIP de transacción y la tarjeta digital Invitado Especial, para que puedas acumular y canjear tus puntos, además de disfrutar de todos tus beneficios.
            </p>
            <p>
              Además, el beneficio que seleccionaste ya fue cargado en tu tarjeta Invitado Especial y podrás canjearlo en la taquilla y/o CAI de tu Cinemex favorito. 
            </p>
            <p>
              Si todavía no lo hiciste, descarga nuestra app en tu teléfono.
            </p>
            <Link className="btn btn-primary d-inline-block d-md-none" to="/descarga-la-app">Descarga la app</Link>
          </React.Fragment>
      }/>;
  }

  renderForm() {
    return (
        <React.Fragment>
          <form noValidate onSubmit={this.onSubmit} className={`${this.state.validated ? 'was-validated' : ''} fade-in mb-5`}>
            {fieldSets.map(fieldSet => (
                <fieldset key={fieldSet.id} >
                  <legend className="h5">{fieldSet.label}</legend>
                  <div className="form-row">
                    {fieldSet.fields.map(fieldName => {
                      const field = fields[fieldName];
                      const state = this.state[fieldName];
                      console.log(fieldName,state.value);

                      return (
                          <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                            <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                      onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                      onBlur={() => this.validate(fieldName)}
                                      value={state.value}
                                      valid={state.valid}
                                      validationError={state.validationError}
                                      inputProps={field.inputProps || {}}
                                      choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, state.value))}
                                      id={field.id}
                                      required={field.required}
                            />
                          </div>
                      );
                    })}
                  </div>
                </fieldset>
            ))}

            <div className="text-right">
              <button type="submit" name="submit" className="btn btn-outline-primary btn-lg px-5">Enviar</button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  renderExternalPaymentPopup() {
    const url = this.props.paymentUrl;

    return <React.Fragment>
      <Prompt when={true} message={'¡Atención! Por favor no abandones esta página mientras está cargando o si ya has dado click en el botón de pagar. Esto no cancelará el cargo y puede que no recibas tu confirmación de compra. ¿Deseas abandonar el proceso de compra de todas formas?'}/>
      {url
          ? <ExternalPaymentPopup url={url} transactionId={this.props.transactionId} onComplete={this.onExternalPaymentComplete} />
          : 'Cargando...'}
    </React.Fragment>;
  }

  onExternalPaymentComplete(res) {
    if (res.success) {
      this.props.onPaymentSuccess(Object.assign({}, res.data, {level: this.state.level.id, action: 'ie-sign-up'}));
    } else {
      this.props.onPaymentError(res.data);
    }
  }

  onChange(prop, value, element) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (field.type === 'checkbox') {
      value = element.checked;
    }

    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value})}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {};

    Object.keys(fields).forEach(field => params[field] = this.state[field].value);

    this.props.submit({
      level: this.state.level,
      info: params
    });
  }

  validateForm() {
    let validForm = true;

    Object.keys(fields).forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
      validForm &= valid;
    });

    return validForm;
  }

  validate(prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
  }

  validateField(prop) {
    const {value} = this.state[prop];
    const {required, validationFns, validationMessage} = fields[prop];
    let valid = true;
    let msg;

    if ((!validationFns || !validationFns.length) || (!value && !required)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.every((fn, i) => {
      const v = fn(value);
      valid &= v;
      if (!v && Array.isArray(validationMessage)) {
        msg = validationMessage[i];
        return false;
      }
      return true;
    });

    valid = !!valid;
    const validationError = valid ? '' : (msg || validationMessage);

    return {valid, validationError};
  }
}

const mapStateToProps = state => ({
  cardData: getIeCardData(state),
  cinemas: getCinemas(state).sort(sortByName),
  isSubmitted: isIeSignUpSubmitted(state),
  isUserLoggedIn: isUserLogged(state),
  levels: getIeSignUpOptions(state),
  paymentUrl: getIePaymentUrl(state),
  selectedCinemaId: getSelectedCinemaId(state),
  user: getUserInfo(state),
});

const mapDispatchToProps = dispatch => ({
  onPaymentError: error => dispatch(ieSignUpError(error)),
  onPaymentSuccess: data => dispatch(ieSignUpSuccess(data)),
  show: () => dispatch(ieSignUpFormShow('ie-sign-up')),
  submit: data => dispatch(ieSignUpFormSubmit(data, 'ie-sign-up')),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IERegistrationPage));