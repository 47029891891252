import React from 'react';
import { connect } from 'react-redux';
import MDSpinner from "react-md-spinner";
import { Helmet } from 'react-helmet';
import { getUserId, getUserSessions, isUserSessionsLoading } from '../../reducers';
import { userSessionKill, userSessionsFetch } from '../../actions/creators/user';
import moment from 'moment';
import styled from 'styled-components';

const Item = styled.p`
  border-bottom: 1px solid #ddd;
  padding-bottom: 2em;
  margin: 2em 0;
  line-height: 1.6em;

  &:last-child { border-bottom: 0; }
`;

const CurrentBadge = styled.span`
  display: inline-block;
  line-height: 2em;
  background: var(--green);
  color: white;
  padding: 0 0.75em;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-bottom: 0.25em;
`;

const KillSessionBtn = styled.a`
  text-transform: uppercase;
  font-size: 0.8em;
`;

class UserSessionsList extends React.Component {
  componentDidMount() {
    this.props.sessionsListFetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.future !== prevProps.future || this.props.userId !== prevProps.userId) {
      this.props.sessionsListFetch();
    }
  }

  render() {
    if (this.props.isLoading) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)" /></div>;
    }

    return this.renderContent();
  }

  renderContent() {
    const current = this.props.sessions.filter(s => s.current);
    const other = this.props.sessions.filter(s => !s.current);

    return (
      <React.Fragment>
        <Helmet><title>Dispositivos vinculados - Cinemex</title></Helmet>
        <p>Estos son los dispositivos donde se inició sesión con tu cuenta:</p>
        <div>
          {this.props.sessions.length === 0 ?
            (
              <div>No hay sesiones abiertas.</div>
            ) : (
              current.concat(other).map(session => {
                const date = moment(session.date_created);

                return (
                  <Item>
                    {session.current ? <React.Fragment><CurrentBadge>Este dispositivo</CurrentBadge><br/></React.Fragment> : ''}
                    <strong>{session.description}</strong>
                    <br/>
                    {date.format('D [de] MMMM [-] hh:mm A')}
                    
                    {!session.current
                      ? <React.Fragment><br/><KillSessionBtn href="#" className="text-secondary" onClick={() => this.props.sessionKill(session.id)}>Cerrar sesión</KillSessionBtn></React.Fragment>
                      : ''
                    }
                  </Item>
                );
              })
            )
          }
        </div>
      </React.Fragment>
    );
  }

}

const mapStateToProps = state => ({
  userId: getUserId(state),
  sessions: getUserSessions(state),
  isLoading: isUserSessionsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  sessionsListFetch: () => dispatch(userSessionsFetch()),
  sessionKill: sessionId => dispatch(userSessionKill(sessionId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSessionsList);