import React from 'react';
import { AnalyticsPromos } from '../utils/analytics';

type Promo = {
  id:number,
  url:string,
  name:string,
  title:string,
  target:string,
  thumb_v2:string
};

type Props = {
  promo:Promo
};

type State = {
  analytics_setup:boolean
};

class PromosFeatured extends React.PureComponent<Props, State> {
  private ref:React.RefObject<HTMLAnchorElement> = React.createRef();

  constructor(props:Props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.setup = this.setup.bind(this);
    this.state = { analytics_setup: false };
  }

  componentDidMount() {
    this.setup();
  }
  
  componentDidUpdate() {
    this.setup();
  }

  setup() {
    if (!this.ref.current || this.state.analytics_setup) return;

    this.setState({ analytics_setup: true });
    const { promo } = this.props;
    setTimeout(() => {
      AnalyticsPromos.observe('promo_featured', promo.id, promo.title, this.ref.current);
    }, 1500);
  }

  onClick(event:React.MouseEvent<HTMLElement>) {
    const { promo } = this.props;
    const openInNewWindow = promo.target !== '_self' || event.altKey || event.ctrlKey || event.shiftKey;
    AnalyticsPromos.trackClick('promo_featured', promo.id, promo.title, openInNewWindow ? null : () => {
        document.location = promo.url;
    });
    if (!openInNewWindow) event.preventDefault();
  }

  render() {
    const { promo } = this.props;

    if (!promo) {
      return '';
    }

    return (
      <a href={promo.url} className="o-ad-w-100 u-rounded-mask" ref={this.ref} onClick={this.onClick}>
        <img src={promo.thumb_v2} alt={promo.title} />
      </a>
    );
  }
}

export default PromosFeatured;