import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {headerSearchTermChange} from '../actions/creators';
import {getHeaderSearchTerm} from '../reducers';

const SearchBar = styled.li`
  left: auto;
  overflow: hidden;
  right: 0;
  transition: width 0.5s ease;
  width: ${props => props.visible ? '100%' : '0'};
  
  .c-nav__searchform-close { cursor: pointer; }
`;


class HeaderSearch extends React.Component {
  constructor(props) {
    super(props);

    this.showSearch = this.showSearch.bind(this);
    this.hideSearch = this.hideSearch.bind(this);
    this.onChange = this.onChange.bind(this);

    this.inputRef = React.createRef();

    this.state = {visible: false};
  }

  showSearch() {
    this.inputRef.current.focus();
    this.setState(() => { return {visible:true} });
  }

  hideSearch() {
    this.setState(() => { return {visible:false} });

    this.props.onChange('');
  }

  onChange(e) {
    const term = e.target.value;

    if (!this.props.term && term) {
      setTimeout(() => {
        const header = document.getElementById('header');
        const featMovie = document.getElementById('home-feat-movie');
        if (!header || !featMovie) return;
        const headerStyles = getComputedStyle(header);
        const t = parseFloat(getComputedStyle(featMovie).height);
        const pos = headerStyles.position;
        const offset = pos === 'fixed' ? (parseFloat(headerStyles.height) * -1) : 1;
        console.log('offset', offset, pos, t);
        window.scrollTo(0,Math.ceil(t+offset) + 1);
      }, 250);
    }

    this.props.onChange(term);
  }

  render() {
    if (this.props.collapsible !== false) {
      return (
          <React.Fragment>
            <li className="nav-item d-none d-md-block">
              <span onClick={this.showSearch}><i className="fal fa-search"></i></span>
            </li>
            <SearchBar className=" d-none d-md-block c-nav__searchform" visible={this.state.visible}>
              {this.renderForm()}
            </SearchBar>
          </React.Fragment>
      );
    } else {
      return this.renderForm();
    }
  }

  renderForm() {
    return (
        <form className="form">
          <input type="search" className="form-control" placeholder="Buscar películas" ref={this.inputRef}
                 onChange={this.onChange} onFocus={this.props.onFocus} onBlur={this.props.onBlur}
                 value={this.props.term} />
          {this.props.collapsible !== false && (
              <span className="c-nav__searchform-close p-2 text-secondary" onClick={this.hideSearch}>
                <i className="fal fa-times"></i>
              </span>
          )}
        </form>
    );
  }
}

const mapStateToProps = state => ({
  term: getHeaderSearchTerm(state),
});

const mapDispatchToProps = dispatch => ({
  onChange: term => dispatch(headerSearchTermChange(term)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderSearch);