import React from 'react';
import MoviePoster from "../MoviePoster";

export default ({path, content}) => {
  
  if (typeof path !== 'string') {
    path = path[1];
  }
  const movies = content.movies.map(movie => ({
      ...movie,
      url: movie.url.replace(/.*\/pelicula\//, path + '/pelicula/')
  }));

  return (
      <React.Fragment>
        {content.html && <div dangerouslySetInnerHTML={{__html: content.html}} />}
        <div className="row">
          {movies.length ? movies.map(movie => (
              <div key={movie.id} className="col-6 col-md-3 mb-4">
                <MoviePoster movie={movie} noCarousel doNotFixUrl />
              </div>
          )) : "No hay películas en este momento"}
        </div>
      </React.Fragment>
  );
};