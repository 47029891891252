import React from 'react';
import uniqid from 'uniqid';
import FieldRow from '../FieldRow';
import * as validators from '../../utils/validation';
import {formatCc, formatCvv, formatExpireDate} from '../../utils/creditCards';

const fields = {
    name: {label: 'Nombre y apellido', half: false, required: true, id: uniqid(), validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu nombre.'},
    email: {label: 'Email', type: 'email', half: false, required: true, id: uniqid(), validationFns: [validators.validateEmail], validationMessage: 'Ingresa un email válido.'},
    cc: {label: 'Tarjeta de crédito', half: false, required: true, id: uniqid(), validationFns: [validators.validateCc], validationMessage: 'Ingresa un número de tarjeta válido.'},
    expire: {label: 'Fecha de vencimiento', placeholder: "mm/aa", required: true, id: uniqid(), validationFns: [validators.validateExpiry], validationMessage: 'Ingresa una fecha válida, ingresando dos dígitos par el mes y dos dígitos para el año.'},
    csc: {label: 'Código de seguridad', required: true, id: uniqid(), validationFns: [validators.validateCvv], validationMessage: 'Ingresa un código válido.'}
};
  
class AddCardForm extends React.Component {
    
    constructor(props) {
        super(props);
    
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.validateField = this.validateField.bind(this);
    
        this.state = {
            name: {value: '', valid: null},
            email: {value: '', valid: null},
            cc: {value: '', valid: null},
            csc: {value: '', valid: null},
            expire: {value: '', valid: null},
            focused: null,
        };
    }

    render() {
        return (
            <React.Fragment>
              <form noValidate onSubmit={this.onSubmit} className={'pb-5 ' + (this.state.validated ? 'was-validated' : '')} style={{maxWidth: '600px'}}>
                <div className="form-row mb-5">
                  {Object.keys(fields).map(fieldName => {
                    const field = fields[fieldName];
                    const state = this.state[fieldName];
    
                    return (
                        <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                          <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                    onChange={(e) => this.onChange(fieldName, e.target.value)}
                                    onBlur={() => this.validate(fieldName)}
                                    onFocus={() => this.onFocus(fieldName)}
                                    value={state.value}
                                    valid={state.valid}
                                    validationError={state.validationError}
                                    inputProps={field.inputProps || {}}
                                    choices={field.getChoicesMethod && field.getChoicesMethod(this.props)}
                                    id={field.id}
                          />
                        </div>
                    );
                  })}
                </div>
                {this.props.onSubmit && (
                  <div className="text-right">
                    <button type="submit" name="submit" className="btn btn-outline-primary btn-lg px-5">Enviar</button>
                  </div>)}
              </form>
            </React.Fragment>
        );
    }
    
    onChange(prop, value) {
        this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value: this.formatFieldValue(prop, value)})}), () => {
          this.validate(prop);
        });
    }

    onFocus(e) {
      this.setState((state) => ({focused: e}), () => {
        this.props.onChange(this.getParams());
      });
    }

    formatFieldValue(field, value) {
        switch (field) {
            case 'cc': return formatCc(value);
            case 'csc': return formatCvv(value);
            case 'expire': return formatExpireDate(value);
            default: return value;
        }
    }

    onSubmit(e) {
        e.preventDefault();
    
        const isValid = this.validateForm();
    
        if (!isValid) {
          this.setState(() => ({validated: true, valid: false}));
          return;
        }

        this.props.onSubmit && this.props.onSubmit(this.getParams().params);
    }

    getParams() {
      const {focused} = this.state;

      const params = {
        email: this.state.email.value,
        name: this.state.name.value,
        cc: this.state.cc.value,
        csc: this.state.csc.value,
        'expire-year': '20' + this.state.expire.value.substr(3,2),
        'expire-month': this.state.expire.value.substr(0,2)
      };
      let valid = true;

      Object.keys(this.state).forEach(k => k !== 'focused' && (valid &= this.state[k].valid)); 
  
      return {
          params,
          valid,
          focused
      };
    }
    
    validateForm() {
        let requiredFields = ['name', 'email', 'cc', 'csc', 'expire'];
    
        let validForm = true;
    
        requiredFields.forEach(prop => {
          const {valid, validationError} = this.validateField(prop);
          this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
          validForm &= valid;
        });
    
        return validForm;
    }
    
    validate (prop) {
        const {valid, validationError} = this.validateField(prop);
        this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}), () => {
          this.props.onChange(this.getParams());
        });
    }
    
    validateField(prop) {
        const {value} = this.state[prop];
        const {required,validationFns,validationMessage} = fields[prop];
        let valid = true;
    
        if ((!validationFns || !validationFns.length) || (!value && !required)) {
          return {valid: null, validationError: ''};
        }
    
        validationFns && validationFns.forEach(fn => {
          valid &= fn(value);
        });
    
        valid = !!valid;
        const validationError = valid ? '' : validationMessage;
    
        return {valid, validationError};
    }
}

export default AddCardForm;