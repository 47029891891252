import React, { Component } from "react";
import { connect } from "react-redux";
import slugify from "slugify";
import has from "lodash/has";
import { landingFetch } from "../actions/creators";
import NavBarLayout from "./NavBarLayout";
import {getLanding, isLandingLoading} from '../reducers';
import MDSpinner from "react-md-spinner";
import {Helmet} from 'react-helmet';
import ImageContent from './landing/LandingContentImage';
import HtmlContent from './landing/LandingContentHtml';
import PromosContent from './landing/LandingContentPromos';
import MoviesContent from './landing/LandingContentMovies';
import ShowtimesContent from './landing/LandingContentShowtimes';
import CinemasContent from './landing/LandingContentCinemas';
import "./LandingPage.css";

class LandingPage extends Component {
  state = {
    landing: {}
  };

  componentDidMount() {
    this.props.fetch(this.props.landingId);
  }

  render() {
    if (!this.props.landing || this.props.isLoading) {
      return <div className="p-5 text-center"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    const { landing } = this.props;
    const basePath = `/landing/${this.props.landingId}/`;

    let landingRoutes = [];

    if (has(landing, "pages")) {
      landingRoutes = landing.pages.map((page, i) => {
        const { name } = page;
        const slug = slugify(name, {
          replacement: "-",
          lower: true
        });
        let exact = true;

        let path = [basePath + slug];

        if (i === 0) {
          path.splice(0, 0, basePath);
          exact = true;
        }

        if (page.type === 'movies' || page.type === 'showtimes') {
          path.push(basePath + slug + '/pelicula/:params*');
        }

        return {
          ...page,
          title: name,
          path,
          exact,
          render: () => this.getLandingContent(page, path),
        };
      });
    }

    return <NavBarLayout
        fixedTitle={landing.name}
        routes={landingRoutes}
        header={this.props.landing.config.header}
        headerAlt={this.props.landing.config.header_alt}
        entryClass={false}
    />;
  }


  getLandingContent(page, path) {
    return (
        <React.Fragment>
          <Helmet>
            <meta name="description" content={page.meta_description}/>
            {page.meta_title && <title>{page.meta_title} - Cinemex</title>}
          </Helmet>
          {this.getLandingContentComponent(page, path)}
        </React.Fragment>
    );
  }

  getLandingContentComponent(props, path) {
    switch (props.type) {
      case "image":
        return <ImageContent {...props} />;
      case "html":
        return <HtmlContent {...props} />;
      case "promos":
        return <PromosContent {...props} />;
      case "movies":
        return <MoviesContent {...props} path={path} />;
      case "showtimes":
        return <ShowtimesContent {...props} path={path} />;
      case "cinemas":
        return <CinemasContent {...props} mapMarker={this.props.landing.config.marker} />;
      default:
        return <React.Fragment/>;
    }
  }
}

const mapStateToProps = (state, props) => ({
  landingId: props.match.params.id,
  landing: getLanding(state, props.match.params.id),
  isLoading: isLandingLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: landingId => dispatch(landingFetch(landingId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPage);
