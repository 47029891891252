import React from 'react';
import {connect} from 'react-redux';
import StepHeader from './StepHeader';
import {
  ieCodePanelCancel,
  ieCodeChange,
  ieCodePanelPrevStep,
  ieCodeValidateSubmit,
  ieCodeSubmit,
  ieCodeValidateSet
} from '../../actions/creators/ie';
import BodyBlocker from "../BodyBlocker";
import IeCodePanelStep1 from './IeCodePanelStep1';
import IeCodePanelStep2 from './IeCodePanelStep2';
import Button from '../Button';
import Blocker from '../Blocker';
import ModalPanel from '../ModalPanel';
import {getIeCodeResultEmailSent} from '../../reducers';
import { formatBarCode, formatBirthDate, formatIeCode } from '../../utils/strings';

class IeCodePanel extends React.Component {
  constructor(props) {
    super(props);

    this.onPrevClick = this.onPrevClick.bind(this);
    this.onIeCodeChange = this.onIeCodeChange.bind(this);
    this.onBarCodeChange = this.onBarCodeChange.bind(this);
    this.onBirthDateChange = this.onBirthDateChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      code: formatIeCode(''),
      barCode: formatBarCode(''),
      birthDate: '',
    };
  }

  onIeCodeChange(code) {
    this.setState(() => ({code: formatIeCode(code)}));
    this.props.setCardNumber(code);
  }

  onBarCodeChange(barCode) {
    this.setState(() => ({barCode: formatBarCode(barCode)}));
  }

  onBirthDateChange(birthDate) {
    this.setState(() => ({birthDate: formatBirthDate(birthDate)}));
  }

  onPrevClick() {
    this.props.step === 1
        ? this.props.onCancel()
        : this.props.prevStep();
  }

  onSubmit(e) {
    e.preventDefault();

    const params = Object.assign({}, this.state, {memberId: this.props.memberId});
    params.birthDate = params.birthDate.split('/').reverse().join('-');
    this.props.onSubmit(params);
  }

  renderPanel() {
    return this.props.step === 1 ? (
        <IeCodePanelStep1 processing={this.props.processing} code={this.state.code}
                          onChange={this.onIeCodeChange} onSubmit={this.props.onValidateSubmit}
                          checkout={true}/>
    ) : (
        <IeCodePanelStep2
            barCode={this.state.barCode} onBarCodeChange={this.onBarCodeChange}
            birthDate={this.state.birthDate} onBirthDateChange={this.onBirthDateChange}
            onSubmit={this.onSubmit} checkout={true}
        />
    );
  }

  renderConfirmation() {
    return (
        <React.Fragment>
          <div className="rounded bg-primary-gradient text-white text-center p-5 mt-5">
            <div className="row justify-content-center my-5">
              <div className="col-md-8">

                <i className="fal fa-crown fa-2x mb-5"></i>

                <h1 className="h5">Felicitaciones, tu cuenta de Invitado Especial ya se encuentra vinculada.</h1>
                <p style={{margin: '1em 0'}}>&mdash;</p>
                <p style={{lineHeight:'1.4em', fontWeight: 200}}>{this.props.emailSent ? (
                    <React.Fragment>
                      Te llegará un correo electrónico con tu <strong>NIP</strong> de transacciones.
                      <br/>
                      No lo pierdas, ya que deberás ingresarlo cada vez que desees hacer uso de tus puntos y pases
                      corporativos.
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      Puedes usar el mismo NIP que generaste la última vez.
                      <br/>
                      Si olvidaste tu NIP de transacciones puedes modificarlo desde tu perfil.
                    </React.Fragment>
                )}</p>
                <Button className="btn-white mt-5" large onClick={this.props.onCancel}>Continuar</Button>

              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }

  render() {
    return (
        <div>
          <BodyBlocker/>
          <ModalPanel className="l-wrapper">
            {this.props.processing && <Blocker/>}
            <div className="l-wrapper__content bg-tertiary">
              <div className="container">
                {this.props.step === 3 ?
                    this.renderConfirmation()
                    :
                    (
                      <div className="mt-4">
                        <StepHeader timer={false} onPrevClick={this.onPrevClick}
                                    label="Ingresa tu número de Invitado Especial"/>
                        <div className="row mt-4">
                          <div className="col-12 col-lg-7">
                            {this.renderPanel()}
                          </div>
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          </ModalPanel>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  processing: state.checkoutData.ui.ieCodePanel.processing,
  step: state.checkoutData.ui.ieCodePanel.step,
  memberId: state.checkoutData.ui.ieCodePanel.tempMemberId,
  emailSent: getIeCodeResultEmailSent(state),
});

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(ieCodePanelCancel(false)),
  onChange: code => dispatch(ieCodeChange(code)),
  prevStep: () => dispatch(ieCodePanelPrevStep()),
  onValidateSubmit: code => dispatch(ieCodeValidateSubmit(code)),
  onSubmit: payload => dispatch(ieCodeSubmit(payload)),
  setCardNumber: cardNumber => dispatch(ieCodeValidateSet(cardNumber)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IeCodePanel);