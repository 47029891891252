import * as actions from '../actions/types';
import { combineReducers } from 'redux';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIE_IS_LOADING:
      return action.isLoading;
    case actions.CINEMA_MOVIE_FETCH_ERROR:
      return false;
    default:
      return state;
  }
};

const loadError = (state = false, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIE_IS_LOADING:
      return false;
    case actions.CINEMA_MOVIE_FETCH_SUCCESS:
      return false;
    case actions.CINEMA_MOVIE_FETCH_ERROR:
      return true;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIE_FETCH_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const date = (state = null, action) => {
  switch (action.type) {
    // case actions.CINEMA_MOVIE_FETCH_SUCCESS:
    //   return null;
    case actions.MOVIE_SELECT:
      return null;
    case actions.SHOWTIMES_FILTER_DATE_CHANGE:
      return action.date;
    default:
      return state;
  }
};

const filtersSelection = (state = [], action) => {
  switch (action.type) {
    // case actions.CINEMA_MOVIE_FETCH_SUCCESS:
    //   return [];
    // case actions.SHOWTIMES_FILTER_DATE_CHANGE:
    //   return [];
    case actions.MOVIE_SELECT:
    case actions.SHOWTIMES_FILTER_FORMAT_CLEAR:
      return [];
    case actions.SHOWTIMES_FILTER_FORMAT_CHANGE:
      return action.formats;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  loadError,
  data,
  date,
  filtersSelection
});