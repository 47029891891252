import {combineReducers} from 'redux';
import * as actions from '../actions/types';

const sessions = (state = {}, action) => {
  switch (action.type) {
    case actions.NOW_FETCH:
      return Object.assign({}, state, {[action.cinemaId]: null});
    case actions.NOW_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.cinemaId]: action.data});
    default:
      return state;
  }
};

export default combineReducers({
  sessions,
});