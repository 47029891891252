import * as actions from '../types';
import {dialogAlert} from './dialogs';
import CMX from '../../api';
import {isPostLoading,getPostLastLoadTime} from '../../reducers';

export const postFetch = slug => (dispatch, getState) => {
  const state = getState();

  if (isPostLoading(state, slug)) {
    return;
  }

  if ((new Date()).getTime() - getPostLastLoadTime(state, slug) < 60 * 60 * 1000) {
    return;
  }

  dispatch({
    type: actions.POST_FETCH,
    slug,
  });

  CMX.get('/posts/' + slug)
      .then(data => dispatch(postFetchSuccess(slug, data)))
      .catch(error => dispatch(postFetchError(slug, error)))
};

const postFetchSuccess = (slug, data) => ({
  type: actions.POST_FETCH_SUCCESS,
  data: data,
  slug,
});

const postFetchError = (slug, error) => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.POST_FETCH_ERROR,
    error,
    slug,
  })
};
