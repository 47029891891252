import {combineReducers} from 'redux';
import * as actions from '../actions/types';

const data = (state = [], action) => {
    switch (action.type) {
        case actions.USER_WALLET_FETCH_SUCCESS:
            return action.data.data || null;
        case actions.AUTH_LOGOUT_SUCCESS:
        case actions.AUTH_LOGIN_POST_SUCCESS:
        case actions.AUTH_FB_LOGIN_SUCCESS:
        case actions.CHECKOUT_ABANDON:
        case actions.USER_PRE_CHECKOUT_FETCH:
            return [];
        case actions.WALLET_CARD_ADD_SUCCESS:
            return [action.result].concat(state);
        case actions.WALLET_CARD_DELETE_SUCCESS:
            return removeCardFromCollection(action.card, state);
        default:
            return state;
    }
};

const removeCardFromCollection = (card, state) => {
    const collection = state.slice(0);

    for (let i = 0; i < collection.length; i++) {
        if (collection[i].token === card.token) {
            collection.splice(i, 1);
            i--;
        }
    }

    return collection;
};

const uid = (state = null, action) => {
    switch (action.type) {
        case actions.USER_WALLET_FETCH_SUCCESS:
            return action.data.uid || null;
        case actions.AUTH_LOGOUT_SUCCESS:
        case actions.AUTH_LOGIN_POST_SUCCESS:
        case actions.AUTH_FB_LOGIN_SUCCESS:
        case actions.CHECKOUT_ABANDON:
        case actions.USER_PRE_CHECKOUT_FETCH:
            return null;
        default:
            return state;
    }
};

const isProcessing = (state = false, action) => {
    switch (action.type) {
        case actions.USER_PRE_CHECKOUT_FETCH:
        case actions.WALLET_CARD_ADD:
        case actions.WALLET_CARD_DELETE:
            return true;
        case actions.WALLET_CARD_ADD_ERROR:
        case actions.WALLET_CARD_ADD_SUCCESS:
        case actions.WALLET_CARD_DELETE_ERROR:
        case actions.WALLET_CARD_DELETE_SUCCESS:
        case actions.USER_WALLET_FETCH_SUCCESS:
        case actions.USER_POINTS_FETCH_ERROR:
            return false;
        default:
            return state;
    }
};

const step = (state = null, action) => {
    switch (action.type) {
        case actions.WALLET_SET_STEP:
            return action.step;
        case actions.WALLET_SHOW:
        case actions.WALLET_CARD_ADD_SUCCESS:
            return null;
        default:
            return state;
    }
}

const defaultAddCardInfo = {params: {}, valid: false};
const addCardInfo = (state = defaultAddCardInfo, action) => {
    switch (action.type) {
        case actions.WALLET_CARD_ADD_CHANGE:
            return {
                params: Object.assign(state.params, action.params.params),
                valid: action.params.valid,
                focused: action.params.focused
            }
        case actions.CHECKOUT_WALLET_SELECT_CARD:
            return {
                params: Object.assign(state.params, {
                    cc: action.card.pan,
                    name: action.card.nombre,
                    'expire-month': action.card.expiracion_mes,
                    'expire-year': action.card.expiracion_anio,
                }),
                valid: state.valid,
                focused: state.focused
            }
        case actions.WALLET_SET_STEP:
            return {params: {}, valid: false};
        default:
            return state;
    }
};

const selectedCard = (state = null, action) => {
    switch (action.type) {
        case actions.WALLET_SET_STEP:
        case actions.WALLET_SHOW:
            return null;
        case actions.CHECKOUT_WALLET_SELECT_CARD:
            return action.card;
        default:
            return state;
    }
}

export default combineReducers({
    addCardInfo,
    data,
    isProcessing,
    selectedCard,
    step,
    uid,
});