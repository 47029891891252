import React from 'react';
import BodyBlocker from '../BodyBlocker';
import Button from '../Button';
import CloseCross from '../CloseCross';
import FieldRow from '../FieldRow';
import SidebarPanel from '../SidebarPanel';
import {formatNip} from './PaymentSidebar';
import StepHeader from './StepHeader';
import * as validators from '../../utils/validation';

class IeNipPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    //this.onResolve = this.onResolve.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    //this.renderForm = this.renderForm.bind(this);

    this.state = {
      nip: {value: '', valid: null, validationFns: [validators.validateIeNip], validationMessage: 'Ingresa un NIP válido.'},
      validated: false
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    this.props.onSubmit(this.state.nip.value);
  }

  onChange(prop, value) {
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value: this.formatFieldValue(prop, value)})}), () => this.validate(prop));
  }

  formatFieldValue(field, value) {
    switch (field) {
      case 'nip': return formatNip(value);
      default: return value;
    }
  }

  validate (prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
  }

  validateField(prop) {
    const {value,validationFns,validationMessage} = this.state[prop];
    let valid = true;

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }

  validateForm() {
    const requiredFields = ['nip'];
    let validForm = true;

    requiredFields.forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
      validForm &= valid;
    });

    return validForm;
  }

  render() {
    return (
        <React.Fragment>
          <BodyBlocker/>

          <SidebarPanel overBlocker={true} noScroll={true}>
            <CloseCross theme="dark" onClick={this.props.onCancel}/>
            
            <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
              <div className="p-5">
                <div className="d-flex mb-5">
                  <h3 className="h4 mb-0">Ingresa tu NIP para poder utilizar tus puntos y pases de Invitado Especial</h3>
                </div>

                <div className="row">
                  <div className="col-6">
                    <FieldRow label="NIP Invitado Especial"
                              onChange={(e) => this.onChange('nip', e.target.value)}
                              onBlur={() => this.validate('nip')}
                              value={formatNip(this.state.nip.value)}
                              valid={this.state.nip.valid}
                              validationError={this.state.nip.validationError}
                    />
                  </div>
                </div>
              </div>

              <div className="p-5">
                <Button submit primary large block>Continuar</Button>
              </div>
            </form>
          </SidebarPanel>
        </React.Fragment>
    );
  }
}

export default IeNipPanel;