import React from 'react';
import {Link} from 'react-router-dom';
import UserCinemaNav from './UserCinemaNav';

class ContactPageSidebar extends React.Component {
  render() {
    return (
        <React.Fragment>
          <UserCinemaNav/>
          <div className="p-5 border-bottom">
            <h3 className="h5">Tu opinión nos interesa mucho!</h3>
            <p className="text-secondary">Tu opinión es muy importante para nosotros, envíanos tus opiniones y
              comentarios acerca de nuestro servicio para poder mejorar cada día.</p>
          </div>

          <div className="p-5 border-bottom">
            <h3 className="h6 mb-4">Síguenos en nuestras redes sociales:</h3>
            <div className="d-flex justify-content-between">
              <a className="btn btn-outline-secondary btn-icon-circle" href={this.props.config.links.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-fw fa-facebook-f"></i></a>
              <a className="btn btn-outline-secondary btn-icon-circle" href={this.props.config.links.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-fw fa-twitter"></i></a>
              <a className="btn btn-outline-secondary btn-icon-circle" href={this.props.config.links.instagram} target="_blank" rel="noopener noreferrer"><i className="fab fa-fw fa-instagram"></i></a>
              <a className="btn btn-outline-secondary btn-icon-circle" href={this.props.config.links.googleplus} target="_blank" rel="noopener noreferrer"><i className="fab fa-fw fa-google-plus-g"></i></a>
            </div>
          </div>

          <div className="p-5 text-secondary">
            <p><small>Cinemex Desarrollos, S.A. de C.V. (en adelante "Cinemex"), con domicilio en Avenida Javier Barros Sierra No. 540, Torre 1, PH1, Colonia Santa Fe, Delegación Álvaro Obregón, C.P. 01210, México, Ciudad de México, te comunica lo siguiente:</small></p>
            <p><small>Los Datos Personales que le son solicitados, serán tratados con las finalidades primarias de atender y darle seguimiento a sus dudas, quejas, comentarios o sugerencias.</small></p>
            <p><small><Link to="/privacidadvisitantes">Aviso de privacidad</Link><br /> Fecha de última actualización: 5/Enero/2015</small></p>
          </div>
        </React.Fragment>
    );
  }
}

export default ContactPageSidebar;