import React from 'react';
import FieldRow from './FieldRow';
import { checkPassStrength, getPassStrengthLabel, getPassStrengthColor } from '../utils/passwords';

class FieldPasswordRow extends React.Component {
    getPasswordLabel() {
        const pass = this.props.value;
        if (!pass.length) return this.props.label || 'Contraseña';
        return getPassStrengthLabel(checkPassStrength(pass));
    }

    getPasswordColor() {
        const pass = this.props.value;
        if (!pass.length) return null;
        return getPassStrengthColor(checkPassStrength(pass));
    }
    
    render() {
        return <FieldRow type="password" label={this.getPasswordLabel()} onChange={this.props.onChange}
            value={this.props.value}
            valid={this.props.valid}
            validationError={this.props.validationError}
            labelColor={this.getPasswordColor()}
            hint="Ingresa al menos 8 caracteres. Utiliza mayúsculas, minúsculas, números y símbolos. Evita utilizar caracteres y números consecutivos."
            inputProps={{autoComplete: "new-password"}}
            />;
    }
}

export default FieldPasswordRow;