import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Btn = styled.button`
  border: 1px solid #666666;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -0.5em;
  width: 2.5em;
  padding: 0;
  height: 2.5em;

  &.btn-primary { border-color: var(--primary); }
`;

const InfoItem = props => (
    <React.Fragment key={props.label}>
      <hr className="my-4"/>
      <h3 className="h6 text-muted">{props.label}</h3>
      <p>{props.content}</p>
    </React.Fragment>
);

const renderReleaseDate = ({movie, onNotificationToggle, notificationStatus})  => {
  if (!movie.release_date) { return null; }

  const date = moment(movie.release_date, 'YYYY-MM-DD');

  return <div style={{position:'relative'}}>
      <InfoItem label="Fecha de estreno" content={date.format('DD [de] MMMM [de] YYYY')} />
      {('Notification' in window) && onNotificationToggle && <Btn className={`btn btn-${notificationStatus ? 'primary' : 'white'}`} onClick={onNotificationToggle} title={`${notificationStatus ? 'Desactivar' : 'Activar'} notificación`}>
        <i class="fal fa-bell"></i>
      </Btn>}
    </div>;
};

const MovieDetailsBlock = props => {
  const {movie} = props;
  const bullets = [
    {
      label: 'Título original',
      content: movie.info.original_title,
    },
    {
      label: 'Clasificación',
      content: movie.info.rating,
    },
    {
      label: 'Género',
      content: movie.info.genre.join(', '),
    },
    {
      label: 'Director',
      content: movie.info.director,
    },
    {
      label: 'Actores',
      content: movie.info.cast,
    },
    {
      label: 'País',
      content: movie.info.country,
    },
    {
      label: 'Duración',
      content: movie.info.duration,
    },
    {
      label: 'Distribuidor',
      content: movie.info.distributor,
    },
  ];

  return (
      <React.Fragment>
        {renderReleaseDate(props)}
        {movie.info.sinopsis && <React.Fragment><hr className="my-4"/><p>{movie.info.sinopsis}</p></React.Fragment>}

        {bullets.map(bullet => bullet.content && <InfoItem key={bullet.label} {...bullet}/>)}
      </React.Fragment>
  );
};

export default MovieDetailsBlock;