import React from 'react';
import styled from 'styled-components';
import icon from '../assets/svg/no-movie-icon.svg';
import * as vars from '../utils/styles';

const Container = styled.div`
    max-width: 700px;
`;

const Icon = styled.img`
    display: block;
    width: 5em;
`;

const Title = styled.p`
    color: ${vars.colors.main};
    font-size: 1.75em;
    line-height: 1.25em;
    margin-top: 0.75em;

    @media screen and (min-width: ${vars.breakpoints.tablet}px) { max-width: 60%; }
`;

const P = styled.p`
    color: #777;
    font-size: 0.92em;
`;

const NoMoviesMessage = props => {
    return <Container className="py-5">
        <Icon src={icon} />
        <Title>{props.title}</Title>
        <P>{props.message}</P>
    </Container>;
};

export default NoMoviesMessage;