import cookies from 'browser-cookies';
import {getFlagId} from '../utils/modals';

export const pickModals = (modals, args) => filter(modals, args);
export const pickModal = (modals, args) => pickRandom(pickModals(modals, args));

const pickRandom = modals => modals.length && modals[Math.floor(Math.random() * modals.length)];

const filter = (modals, args) => modals.filter(modal => (
    isTypeValid(modal)
    && dayMatch(modal)
    && sectionMatch(modal, args)
    && targetMatch(modal, args)
    && canDisplayAgain(modal)
));

const isTypeValid = modal => {
  return ['image', 'video', 'html5'].indexOf(modal.type) > -1;
};

const dayMatch = modal => {
  const days = modal.days;

  if (!days || !days.length) {
    return true;
  }

  const today = new Date().getDay();
  return days.indexOf(today) >= 0;
};

const sectionMatch = (modal, args) => {
  const sections = modal.sections;
  const section = getCurrentSection(args);

  return sections.indexOf(section) >= 0 || section === 'home' && modal.targets.length || section === 'home' && !sections.length;
};

const getCurrentSection = args => {
  if ('sectionOverride' in args) {
    return args.sectionOverride;
  } else if ('route' in args) {
    return args.route;
  } else {
    return null;
  }
};

const targetMatch = (modal, args) => {
  const targets = modal.targets;

  if (!targets || !targets.length) {
    return true;
  }

  const len = targets.length;
  
  for (let i = 0; i < len; i++) {
    const target = targets[i];
    
    if (args.target.indexOf(target) > -1) {
      modal.matchedTarget = target;
      return true;
    }
  }

  return false;
};

const canDisplayAgain = modal => {
  let lastDisplay = getCookie(getFlagId(modal.id));

  if (modal.show === 'always' || !lastDisplay) {
    return true;
  }

  if (modal.show === 'once' && lastDisplay) {
    return false;
  }

  if (modal.matchedTarget) {
    lastDisplay = getCookie(getFlagId(modal.id + '-' + modal.matchedTarget));
  }

  const now = (new Date()).getTime();
  const windowInMinutes = parseInt(modal.show.match(/^interval_([0-9]+)m$/)[1], 10);
  const windowInMs = windowInMinutes * 60 * 1000;
  const windowLimit = parseInt(lastDisplay, 10) + windowInMs;
  
  return now > windowLimit;
};

const getCookie = id => cookies.get(id);