import uniqid from 'uniqid';
import * as actions from '../../types';
import CMX from '../../../api';
import {dialogOpen, dialogClose, dialogAlert} from '../dialogs';

export const candybarFetch = () => {
  return (dispatch, getState) => {
    dispatch(candybarIsLoading(true));

    const sessionId = getState().checkoutData.data.id;

    CMX.get('candybar/catalog/?session_id=' + sessionId)
        .then(data => dispatch(candybarFetchSuccess(data)))
        .then(() => dispatch(candybarIsLoading(false)))
        .catch(() => dispatch(candybarFetchError()));
  }
};

export const candybarIsLoading = (isLoading) => ({
  type: actions.CHECKOUT_CANDYBAR_IS_LOADING,
  isLoading,
});

export const candybarFetchError = () => ({
  type: actions.CHECKOUT_CANDYBAR_FETCH_ERROR,
});

export const candybarFetchSuccess = (data) => ({
  type: actions.CHECKOUT_CANDYBAR_FETCH_SUCCESS,
  data,
});

export const candybarCategorySelect = (categoryId) => ({
  type: actions.CHECKOUT_CANDYBAR_CATEGORY_SELECT,
  categoryId,
});

export const candybarItemAdd = item => dispatch => {
  const unitId = uniqid();

  dispatch(candybarItemBackup(item.id));
  dispatch({
    type: actions.CHECKOUT_CANDYBAR_ITEM_ADD,
    item,
    unitId,
  });
  item.configs.length && dispatch(candybarItemSelect(item.id, unitId));
};

export const candybarItemEdit = (itemId, unitId) => dispatch => {
  dispatch(candybarItemBackup(itemId));
  dispatch(candybarItemSelect(itemId, unitId));
};

export const candybarItemBackup = itemId => (dispatch, getState) => {
  const state = getState().checkoutData.order.candybar;
  const currentState = itemId in state ? state[itemId] : null;

  dispatch({
    type: actions.CHECKOUT_CANDYBAR_ITEM_BACKUP,
    currentState,
  });
};

export const candybarItemSelect = (itemId, unitId) => ({
  type: actions.CHECKOUT_CANDYBAR_ITEM_SELECT,
  itemId,
  unitId,
});

export const candybarUnitSelect = (unitId) => ({
  type: actions.CHECKOUT_CANDYBAR_UNIT_SELECT,
  unitId,
});

export const candybarOptionSelect = (itemId, unitId, config, optionId, selected) => ({
  type: actions.CHECKOUT_CANDYBAR_OPTION_SELECT,
  itemId,
  unitId,
  config,
  optionId,
  selected,
});

export const candybarUnitCommentUpdate = (itemId, unitId, comments) => ({
  type: actions.CHECKOUT_CANDYBAR_UNIT_COMMENT_UPDATE,
  itemId,
  unitId,
  comments,
});

export const candybarItemEditSubmit = () => (dispatch, getState) => {
  const state = getState();
  const data = state.checkoutData;
  const itemId = data.candybar.selectedItem;
  const order = data.order.candybar[itemId];
  const item = state.entities.candybarItems[data.candybar.selectedItem];

  try {
    validateItemOrder(item, order);
    dispatch(candybarItemSelect());
  } catch (e) {
    dispatch(dialogAlert(e.message));
  }
};

const validateItemOrder = (item, order) => {
  Object.keys(order).forEach(unitId => {
    const unit = order[unitId];

    item.configs.forEach(config => {
      const selection = (config.id in unit) ? unit[config.id] : [];

      if (selection.length < config.min) {
        let message = 'Debes seleccionar '
            + (config.max > config.min ? 'al menos ' : '')
            + (config.min === 1 ? 'una opción' : config.min + ' opciones')
            + ' para ' + config.name;
        throw new Error(message);
      }

      if (config.max < selection.length) {
        let message = 'No puedes seleccionar más de '
            + (config.min === 1 ? 'una opción' : config.min + ' opciones')
            + ' para ' + config.name;
        throw new Error(message);
      }
    })
  });
};

export const candybarItemEditCancel = () => (dispatch, getState) => {
  const state = getState().checkoutData;
  const itemId = state.candybar.selectedItem;
  const oldState = state.order.candybarTemp;

  if (JSON.stringify(oldState) === JSON.stringify(state.order.candybar[itemId])) {
    dispatch({
      type: actions.CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL,
      itemId,
      oldState,
    });
    return;
  }

  dispatch(dialogOpen({
    message: '¿Estás seguro que deseas descartar las modificaciones realizadas?',
    buttons: [
      {
        label: 'Descartar cambios',
        onClick: (dialog) => {
          dispatch({
            type: actions.CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL,
            itemId,
            oldState,
          });
          dispatch(dialogClose(dialog.id));
        }
      },
      {
        label: 'Seguir editando',
        primary: true,
        onClick: (dialog) => {
          dispatch(dialogClose(dialog.id));
        }
      },
    ]
  }));
};

export const candybarItemRemove = (itemId, unitId) => (dispatch, getState) => {
  dispatch({
    type: actions.CHECKOUT_CANDYBAR_ITEM_REMOVE,
    itemId,
    unitId,
  });

  if (!(itemId in getState().checkoutData.order.candybar)) {
    dispatch(candybarItemSelect(null));
  }
};