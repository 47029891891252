import React from 'react';
import styled from 'styled-components';

const StyledCloseCross = styled.span`
  cursor: pointer;
`;

class CloseCross extends React.Component {
  render() {
    return (
        <StyledCloseCross className={'l-wrapper__sidebar-over-close ' + (this.props.theme === 'dark' ? 'text-dark' : '')} onClick={this.props.onClick}>
          <i className="fal fa-times"></i>
        </StyledCloseCross>
    );
  }
}

export default CloseCross;