import React from 'react';
import {Prompt} from 'react-router-dom';
import StepHeader from './StepHeader';
import PaymentOptionButton from './PaymentOptionButton';
import {connect} from 'react-redux';
import {paymentMethodSelect, prevStep} from '../../actions/creators/checkout';
import {paymentSuccess, smartPaymentError} from '../../actions/creators/checkout/payment';
import {parseOrderAmountByPaymentMethod, getCashPaymentMethodsAllowed} from '../../utils/checkout';
import {getCheckoutSelectedPaymentOption, getCheckoutPaymentMethod, getCheckoutTransactionId, getCheckoutSession, getSmartIframeUrl, getWalletCards, getStripeRedirectUrl, getCheckoutPaymentMethods} from '../../reducers';
import CardAnimation from './CardAnimation';
import SmartIframe from './SmartIframe';
import Wallet from './Wallet';
import ExternalPaymentPopup from './ExternalPaymentPopup';
import TermsCheckbox from './TermsCheckbox';

const methodIds = {
  creditCard: 'credit-card',
  movieCard: 'moviecard',
  masterpass: 'masterpass',
  payPal: 'paypal',
};

class PaymentSelection extends React.Component {
  constructor(props) {
    super(props);

    this.onMethodClick = this.onMethodClick.bind(this);
    this.onExternalPaymentComplete = this.onExternalPaymentComplete.bind(this);
    this.onTermsCheckChange = this.onTermsCheckChange.bind(this);
  }

  onExternalPaymentComplete(res) {
    if (res.success) {
      this.props.paymentSuccess(res.data);
    } else {
      this.props.paymentError(res);
    }
  }

  render() {
    return (
        <React.Fragment>
          {this.props.isProcessing && <CardAnimation/>}
          {this.renderContent()}
        </React.Fragment>
    );
  }

  renderContent() {
    if (this.props.selectedPaymentMethod === methodIds.creditCard) {
      switch (this.props.paymentGateway) {
        case 'stripe':
        case 'deuna':
          return this.renderExternalPaymentPopup();
        case 'smart':
          return this.renderSmartIframe();
        case 't1':
          return this.renderCreditCardSelector();
      }
    } else {
      return this.renderMethodSelector();
    }
  }

  renderCreditCardSelector() {
    return <Wallet cards={this.props.walletCards} onCancel={() => this.props.methodSelect(null)}/>;
  }

  renderSmartIframe() {
    const url = this.props.smartIframeUrl;

    return <React.Fragment>
      <Prompt when={true} message={'¡Atención! Por favor no abandones esta página mientras está cargando o si ya has dado click en el botón de pagar. Esto no cancelará el cargo y puede que no recibas tu confirmación de compra. ¿Deseas abandonar el proceso de compra de todas formas?'}/>
      <StepHeader label="Detalle de pago"/>
      {url
          ? <SmartIframe url={url} onComplete={this.onExternalPaymentComplete} />
          : 'Cargando...'}
    </React.Fragment>;
  }

  renderExternalPaymentPopup() {
    const url = this.props.stripeRedirectUrl;

    return <React.Fragment>
      <Prompt when={true} message={'¡Atención! Por favor no abandones esta página mientras está cargando o si ya has dado click en el botón de pagar. Esto no cancelará el cargo y puede que no recibas tu confirmación de compra. ¿Deseas abandonar el proceso de compra de todas formas?'}/>
      <StepHeader label="Detalle de pago"/>
      {url
          ? <ExternalPaymentPopup url={url} transactionId={this.props.transactionId} onComplete={this.onExternalPaymentComplete} />
          : 'Cargando...'}
    </React.Fragment>;
  }

  renderMethodSelector() {
    const methods = this.getMethods();

    return <React.Fragment>
      <StepHeader onPrevClick={this.props.prevStep} label="Medio de pago"/>

      {this.props.cashRequired && (
          <ul className="c-option-list">
            {methods.map(({id, name, image}) => (this.props.allowedPaymentMethods.indexOf(id) > -1) && (
                <PaymentOptionButton key={id} selected={this.props.selected === id} onClick={() => this.onMethodClick(id)}>
                  <span>{name}</span>
                  <img src={`/dist/images/${image}`} alt="" className="ml-auto"/>
                </PaymentOptionButton>
            ))}
          </ul>
      )}
      <div className='mb-5'>
        <TermsCheckbox/>
      </div>
    </React.Fragment>;
  }

  getMethods() {
    const result = [];
    const methods = this.props.paymentMethods;
    const showAll = !methods || !methods.length;

    if (showAll || methods.indexOf('moviecard') > -1) {
      result.push({
        id: methodIds.movieCard,
        name: 'MovieCard',
        image: 'moviecard.png'
      });
    }

    if (showAll || methods.indexOf('paypal') > -1) {
      result.push({
        id: methodIds.payPal,
        name: 'PayPal',
        image: 'paypal.png'
      });
    }

    if (showAll || methods.indexOf('credit-card') > -1) {
      result.push({
        id: methodIds.creditCard,
        name: 'Tarjeta de pago',
        image: 'creditcard.png'
      });
    }

    return result;
  }

  onMethodClick(methodId) {
    const mayNeedExternalUrl = this.props.selected !== methodId && methodId === methodIds.creditCard;
    this.props.methodSelect(methodId, { mayNeedExternalUrl });
  }

  onTermsCheckChange(e) {
    this.props.onTermsCheck(e.target.checked);
  }

}

const mapStateToProps = state => {
  const {isProcessing, postError} = state.checkoutData.payment;
  const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
  const cashRequired = 'cash' in amountByMethod && amountByMethod.cash > 0;
  const allowedPaymentMethods = getCashPaymentMethodsAllowed(state.entities.tickets, state.checkoutData.order.tickets);
  const transactionId = getCheckoutTransactionId(state);
  const sessionId = getCheckoutSession(state).id;
  const smartIframeUrl = getSmartIframeUrl(state);
  const stripeRedirectUrl = getStripeRedirectUrl(state);

  return {
    allowedPaymentMethods,
    cashRequired,
    isProcessing,
    paymentGateway: state.checkoutData.paymentGateway,
    paymentMethods: getCheckoutPaymentMethods(state),
    postError,
    selected: state.checkoutData.paymentMethod,
    selectedPaymentMethod: getCheckoutPaymentMethod(state),
    sessionId,
    smartIframeUrl,//: 'http://127.0.0.1:8080/2.html',
    stripeRedirectUrl,
    transactionId,
    walletCards: getWalletCards(state),
  };
};

const mapDispatchToProps = dispatch => ({
  prevStep: () => dispatch(prevStep()),
  methodSelect: (method, args) => dispatch(paymentMethodSelect(method, args)),
  paymentSuccess: data => dispatch(paymentSuccess(data)),
  paymentError: res => dispatch(smartPaymentError(res)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentSelection);