import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import {Helmet} from 'react-helmet';
import { getUserId, getWalletCards, isWalletProcessing } from '../../reducers';
import { pointsFetch } from '../../actions/creators/user';
import Wallet from '../checkout/Wallet';

class UserWallet extends React.Component {
  componentDidMount() {
    this.props.userWalletFetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      this.props.userWalletFetch();
    }
  }
  
  render() {
    if (this.props.isLoading) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    return this.renderContent();
  }

  renderContent() {
    return (
        <React.Fragment>
            <Helmet><title>Mis tarjetas - Cinemex</title></Helmet>
            <Wallet cards={this.props.walletCards} inCheckout={false}/>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userId: getUserId(state),
  walletCards: getWalletCards(state),
  isLoading: isWalletProcessing(state),
});

const mapDispatchToProps = dispatch => ({
    userWalletFetch: () => dispatch(pointsFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserWallet);