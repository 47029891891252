import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import { getNotificationRequestCallback, getNotificationsPermission } from '../reducers';
import { getNotificationsAbort } from '../actions/creators/notifications';
import BodyBlocker from './BodyBlocker';

const Dialog = styled.div`
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    padding: 2rem;
    max-width: 400px;
`;

const Icon = styled.i`
    font-size: 1.5rem;
    transform: rotate(-45deg);
    margin-bottom: 1em;
`;

class NotificationsBridge extends React.Component {
    render() {
        if (!this.props.callback || this.props.permission === 'granted') return null;
        
        return <BodyBlocker>
            <Dialog>
                {this.props.permission === 'denied' ? this.renderDenied() : this.renderDefault()}
            </Dialog>
        </BodyBlocker>;
    }

    renderDenied() {
        return <div>
            <Icon className="fal fa-arrow-up"></Icon>
            <p>Si deseas recibir notificaciones modifica los permisos de tu navegador haciendo clic en el candado a la izquierda de la barra de direcciones.</p>
            <button className="btn btn-primary btn-block" onClick={this.props.clearCallback}>Entendido</button>
        </div>
    }

    renderDefault() {
        return <div>
            <Icon className="fal fa-arrow-up"></Icon>
            <h1 className="h3">Permitir notificaciones</h1>
            <p>Para recibir notificaciones haz clic en "Permitir" en la parte superior.</p>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        callback: getNotificationRequestCallback(state),
        permission: getNotificationsPermission(state),
    };
};

const mapDispatchToProps = dispatch => ({
    clearCallback: () => dispatch(getNotificationsAbort())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsBridge);