import React from 'react';

export default ({ content }) => (
    <React.Fragment>
        {content.html && <div dangerouslySetInnerHTML={{__html: content.html}} />}
        <img
            src={content.image}
            alt={content.image_alt}
            className="img-fluid d-block rounded"
        />
    </React.Fragment>
);