import React from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.span`
  cursor: pointer;
  font-size: 1.1em;
`;

const AllLabel = styled.span`
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

class ChoicesFilter extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onExpandedToggle = this.onExpandedToggle.bind(this);

    this.baseId = uniqid();
    this.state = {
      expanded: false,
    };

  }

  shouldComponentUpdate(nextProps, prevState) {
    return this.props.selection.join(',') !== nextProps.selection.join(',')
        || this.props.filters.join(',') !== nextProps.filters.join(',')
        || this.state.expanded !== prevState.expanded;
  }

  onChange(e) {
    const v = e.target.value;

    this.props.onChange(v);
  }

  onExpandedToggle() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  render() {
    if (this.props.filters.length < 1) {
      return '';
    }

    return (
        <div className="px-5 py-3 border-top">
          {this.renderLabel()}
          {this.renderOptions()}
        </div>
    );
  }

  renderLabel() {
    if (this.props.collapsible) {
      return (
          <Button className={'d-flex' + (this.state.expanded ? ' mb-3' : '')} width="100%" onClick={this.onExpandedToggle}>
            <span className="text-secondary mr-2">{this.props.label}:</span>
            <AllLabel>{this.getSelectionLabel()}</AllLabel>
            <i className={'fal mt-1 ml-auto ' + (this.state.expanded ? 'fa-angle-up' : 'fa-angle-down')}></i>
          </Button>
      )
    }

    return <h3 className="h5 text-secondary mb-3">{this.props.label}</h3>;
  }

  getSelectionLabel() {
    const result = [];
    const {filters, selection, attributes, partialSet} = this.props;
    const allSelected = selection.length === 0 || (partialSet !== true && selection.length === filters.length);

    filters.forEach(format => (selection.indexOf(format) > -1) && result.push(attributes[format].display_name));

    if (this.props.allLabelFn) {
      return this.props.allLabelFn(result, allSelected);
    }

    if (allSelected) {
      return this.props.allLabel || 'Todos';
    } else {
      return result.join(', ');
    }
  }

  renderOptions() {
    if (this.props.collapsible && !this.state.expanded) {
      return '';
    }

    const {baseId, props} = this;
    const {filters, selection} = props;
    const type = props.multiple !== false ? 'checkbox' : 'radio';

    return (
        <div className="radio-check-cols fade-in">
          {props.all !== false && (
              <div className="custom-control custom-checkbox custom-control-inline mr-3 mb-2">
                <input className="custom-control-input" type={type} value="*" id={baseId + '-all'}
                       onChange={this.onChange}
                       checked={selection.length === 0}
                />
                <label className="custom-control-label" htmlFor={baseId + '-all'}>Todos</label>
              </div>
          )}
          {filters.map(filter => {
            const id = baseId + filter;
            if (!(filter in props.attributes)) return '';
            
            return (
                <div className="custom-control custom-checkbox custom-control-inline mr-3 mb-2" key={filter}>
                  <input className="custom-control-input" type={type} value={filter} id={id}
                         onChange={this.onChange}
                         checked={selection.indexOf(filter) > -1}
                  />
                  <label className="custom-control-label" htmlFor={id}>{props.attributes[filter].display_name}</label>
                </div>
            );
          })}
        </div>
    );
  }

}

ChoicesFilter.propTypes = {
  all: PropTypes.bool,
  allLabel: PropTypes.string,
  allLabelFn: PropTypes.func,
  attributes: PropTypes.object.isRequired,
  collapsible: PropTypes.bool,
  filters: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  partialSet: PropTypes.bool,
  selection: PropTypes.array.isRequired,
};

export default ChoicesFilter;