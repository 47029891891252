import React from 'react';
import {connect} from 'react-redux';
import {getCinema, getModals, getSelectedCinemaId} from '../reducers';
import {pickModals} from '../reducers/modals';
import {shuffleArray} from '../utils/arrays';
import { setModalDisplayedFlag } from '../utils/modals';
import ModalFullScreen from './ModalFullScreen';
import ModalSidebar from './ModalSidebar';

class Modals extends React.Component {
  constructor(props) {
    super(props);

    this.onSidebarClose = this.onSidebarClose.bind(this);
    this.onFullscreenClose = this.onFullscreenClose.bind(this);

    this.state = {
      sidebarOpen: true,
      fullscreenOpen: true,
    };
  }

  onSidebarClose() {
    this.setState(() => ({sidebarOpen: false}));
  }

  onFullscreenClose() {
    this.setState(() => ({fullscreenOpen: false}));
  }

  shouldComponentUpdate(nextProps, nextState) {
    const sidebarOpen = this.state.sidebarOpen !== nextState.sidebarOpen;
    const fullscreenOpen = (this.state.fullscreenOpen !== nextState.fullscreenOpen);
    const routeChanged = (this.props.route !== nextProps.route);
    const targetChanged = (this.props.target.join(',') !== nextProps.target.join(','));
    const thereWereNoModalsAndNowThereAre = (!this.props.modals && nextProps.modals);
    const thereWereModalsAndNowThereAreNot = (this.props.modals && !nextProps.modals);
    const modalsChanged = this.props.modals.map(modal => modal.id).join(',') !== nextProps.modals.map(modal => modal.id).join(',');
    const shouldUpdate = sidebarOpen || fullscreenOpen || routeChanged || targetChanged || thereWereNoModalsAndNowThereAre || thereWereModalsAndNowThereAreNot || modalsChanged;

    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    if (!this.state.open && ((prevProps.route !== this.props.route) || (prevProps.target !== this.props.target))) {
      this.setState(() => ({
        sidebarOpen: true,
        fullscreenOpen: true
      }));
    }
  }

  render() {
    const modals = pickModals(this.props.modals, {route: this.props.route, target: this.props.target});
    shuffleArray(modals);

    if (!modals.length || (!this.state.sidebarOpen || !this.state.fullscreenOpen)) {
      return '';
    }

    const modal = modals.shift();
    setModalDisplayedFlag(modal);
    
    return (
      <React.Fragment>
        {modal.placement === 'fullscreen'
            ? (this.state.fullscreenOpen && <ModalFullScreen modal={modal} onClose={this.onFullscreenClose}/>)
            : (this.state.sidebarOpen && <ModalSidebar modals={[modal]} onClose={this.onSidebarClose}/>)
        }        
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  let route;
  let target = [];

  if (props.location.pathname === '/') {
    route = 'home';
    const cinemaId = getSelectedCinemaId(state);
    target = ['home'].concat(getCinemaTargets(cinemaId, state));
  } else if (props.location.pathname.match(/^\/cine\/(\d+)/)) {
    route = 'cinemas_archive';
    const cinemaId = props.location.pathname.match(/^\/cine\/(\d+)/)[1];
    target = getCinemaTargets(cinemaId, state);
  }

  const modals = getModals(state);

  return {
    modals,
    route,
    target,
  };
};

function getCinemaTargets(cinemaId, state) {
  if (!cinemaId) return [];

  const cinema = getCinema(state, cinemaId);
  if (!cinema) return [];

  return [
    'cinemaid-' + cinemaId,
    'stateid-' + cinema.state.id,
    'areaid-' + cinema.area.id
  ];
}

export default connect(
    mapStateToProps
)(Modals);