import React from 'react';
import styled from 'styled-components';

const P = styled.p`
    color: #6c757d;
    font-size: 12px;
    line-height: 1.2;
    padding: 1em 0 0;
    margin: 0 !important;
`;

class Hint extends React.PureComponent {
    render() {
        return <P {...this.props}>{this.props.children}</P>;
    }
}

export default Hint;