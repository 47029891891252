import React from 'react';
import NavBarLayout from './NavBarLayout';
import JobsForm from '../components/JobsForm';

const routes = [
  {
    title: 'Nosotros',
    slug: 'nosotros',
    path: '/nosotros',
  },
  {
    title: 'Misión, filosofía y valores',
    slug: 'mision-filosofia-y-valores',
    path: '/nosotros/mision',

  },
  {
    title: 'Conciencia Cinemex',
    slug: 'conciencia-cinemex',
    path: '/nosotros/conciencia',
  },
  {
    title: 'Juramento de Servicio',
    slug: 'juramento-de-servicio',
    path: '/nosotros/juramento-de-servicio',
  },
  {
    title: 'Bolsa de trabajo de cines',
    component: JobsForm,
    path: '/empleos/formulario',
  }
];

export default props => <NavBarLayout fixedTitle="Sobre Cinemex" routes={routes}/>