import React from 'react';
import Button from '../Button';
import * as validators from '../../utils/validation';
import IePanelHint from '../IePanelHint';

class IeCodePanelStep1 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const code = e.target.value;
    this.props.onChange(code);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.code);
  }

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <div className="form-group mb-5">
            <label htmlFor="cardNumber">Número de tarjeta:</label>
            <input type="text" className="form-control form-control-lg" id="cardNumber" autoFocus required
                   value={this.props.code} onChange={this.onChange} disabled={this.props.processing}
            />
          </div>
          <Button submit primary large block disabled={!validators.validateIeCode(this.props.code) || this.props.processing}>Validar</Button>
        </form>
        <IePanelHint step="ie" checkout={this.props.checkout} />
      </React.Fragment>
    );
  }
}

export default IeCodePanelStep1;