import React from 'react';
import styled from 'styled-components';
import { asset } from '../utils/misc';

const StoreLink = styled.a`
  max-width: 40%;
`;

const FooterStoresLinks = () => (
    <div className="d-flex justify-content-around justify-content-lg-between align-items-center pl-lg-5 pb-5">
      <StoreLink href="https://itunes.apple.com/mx/app/cinemex/id418163740" target="_blank" rel="noopener noreferrer"><img src={asset('/dist/images/logo-app-store.png')} className="img-fluid" alt="App Store" /></StoreLink>
      <StoreLink href="https://play.google.com/store/apps/details?id=com.cinemex&hl=es" target="_blank" rel="noopener noreferrer"><img src={asset('/dist/images/logo-google-play.png')} className="img-fluid" alt="Google Play" /></StoreLink>
    </div>
);

export default FooterStoresLinks;