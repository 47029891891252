import React from 'react';
import { connect } from 'react-redux';
import LinkButton from "../LinkButton";
import SignUpManualCheckEmail from './SignUpManualCheckEmail';
import SignUpManualUserInfo from './SignUpManualUserInfo';
import { onSignUpCheckEmailSubmit } from '../../actions/creators/auth';
import { getCinemas, getSignUpManualPreFillInfo, getSignUpManualStep } from '../../reducers';

class SignUpManual extends React.Component {
    render() {
        return <div style={{minHeight:'100%',display:'flex',flexDirection:'column'}} className="px-5 py-4">
            <h3 className="h3 mb-3">
                <LinkButton className="text-muted mr-3" onClick={this.props.showRegister}><i className="fal fa-arrow-left fa-xs"></i></LinkButton>
                Completa tu registro
            </h3>
            <div style={{flexGrow:1,display:'flex',alignItems:'center'}}>
                {this.renderContent()}
            </div>
        </div>;
    }

    renderContent() {
        if (this.props.step === "1" && !this.props.apple) {
            return <SignUpManualCheckEmail onSubmit={this.props.checkEmailPost} onLoginClick={this.props.onLoginClick}/>;
        } else {
            return <SignUpManualUserInfo cinemas={this.props.cinemas} info={this.props.info} onSubmit={this.props.registerPost} apple={this.props.apple} />;
        }
    }
}

const mapStateToProps = state => ({
    cinemas: getCinemas(state),
    info: getSignUpManualPreFillInfo(state),
    step: getSignUpManualStep(state),
});

export default connect(
    mapStateToProps
)(SignUpManual);