import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LinkButton from "../LinkButton";
import {authOpen} from '../../actions/creators';
import {ieCodePanelShow} from '../../actions/creators/ie';
import {isUserLogged,getUserInfo,getUserIePoints} from '../../reducers';
import IePointsBlock from './IePointsBlock';
import { asset } from '../../utils/misc';

const Menu = styled.div`
  display: ${props => props.visible ? 'block' : 'none'} !important;
`;

const IeCardsIcon = styled.img`
  height: 25px;
`;

class IeSidebarBlock extends React.Component {
  constructor(props) {
    super(props);

    this.beginClick = this.beginClick.bind(this);

    this.state = {expanded: false};
  }

  render() {
    if (!this.props.userIsLogged) {
      return this.renderUserNotLogged();
    } else {
      return this.renderUserLoggedIn();
    }
  }

  renderUserNotLogged() {
    return (
        <div className="dropdown mx-5 py-2 border-bottom">
          <LinkButton onClick={() => this.setState(() => ({expanded: !this.state.expanded}))} width="100%" className="d-flex py-2" aria-haspopup="true" aria-expanded="false">
            <IeCardsIcon src={asset('/dist/images/icon-tickets.png')} alt="" className="mr-2"/> ¿Eres Invitado Especial?
            <i className="fal fa-angle-down mt-1 ml-auto mr-3"></i>
          </LinkButton>
          <Menu className="dropdown-menu dropdown-menu-right" visible={this.state.expanded}>
            <Link className="dropdown-item" to="/invitado-especial/programa">¿Qué es Invitado Especial?</Link>
            <Link className="dropdown-item" to="/invitado-especial/niveles-beneficios">Conoce los
              Beneficios</Link>
            <span className="dropdown-item" onClick={this.beginClick}>Ingresar</span>
          </Menu>
        </div>
    );
  }

  beginClick() {
    if (this.props.userIsLogged) {
      this.props.openIePanel();
    } else {
      this.props.openAuthPanel();
    }
  }

  renderUserLoggedIn() {
    if (!this.props.userInfo.iecode) {
      return this.renderUserLoggedWithoutIe();
    } else {
      return this.renderUserLoggedWithIe()
    }
  }

  renderUserLoggedWithoutIe() {
    return this.renderUserNotLogged();
  }

  renderUserLoggedWithIe() {
    if (this.props.points.length) {
      return this.renderUserWithPoints();
    } else {
      return this.renderUserWithoutPoints();
    }
  }

  renderUserWithPoints() {
    return (
        <div className="pt-4 pl-5 pr-5">
          <h3 className="h6 text-secondary mb-3">
            <span className="text-primary">Hola {this.props.userInfo.first_name}</span>,
            {" "}
            tienes para canjear:
          </h3>

          <IePointsBlock points={this.props.points}/>
        </div>
    );
  }

  renderUserWithoutPoints() {
    return (
        <div className="pt-4 pl-5 pr-5">
          <h3 className="h6 text-secondary mb-3">
            <span className="text-primary">Hola {this.props.userInfo.first_name}</span>
            {" "}
            ¡con esta compra sumas puntos de Invitado Especial!
          </h3>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  userIsLogged: isUserLogged(state),
  userInfo: getUserInfo(state),
  points: getUserIePoints(state),
});

const mapDispatchToProps = dispatch => ({
  openIePanel: () => dispatch(ieCodePanelShow()),
  openAuthPanel: () => dispatch(authOpen()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IeSidebarBlock);