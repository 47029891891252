import * as actions from "../actions/types";
import { combineReducers } from "redux";

const signUpOptions = (state = null, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_FORM_SHOW:
            return null;
        case actions.IE_FETCH_SIGN_UP_OPTIONS_SUCCESS:
            return [].concat(action.data.options);
        default:
            return state;
    }
}

const paymentUrl = (state = null, action) => {
    switch (action.type) {
        case actions.IE_GET_PAYMENT_URL_SUCCESS:
            return action.data.url
        case actions.IE_SIGN_UP_ERROR:
        case actions.IE_SIGN_UP_SUCCESS:
            return null;
        default:
            return state;
    }
};

const signUpFormProcessing = (state = false, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_FORM_SHOW:
        case actions.IE_GET_PAYMENT_URL_SUCCESS:
        case actions.IE_SIGN_UP_SUCCESS:
        case actions.IE_SIGN_UP_ERROR:
            return false;
        case actions.IE_SIGN_UP_FORM_SUBMIT:
            return true;
        default:
            return state;
    }
};

const signUpFormSubmitted = (state = false, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_FORM_SHOW:
        case actions.IE_SIGN_UP_FORM_SUBMIT:
        case actions.IE_GET_PAYMENT_URL_SUCCESS:
        case actions.IE_SIGN_UP_ERROR:
            return false;
        case actions.IE_SIGN_UP_SUCCESS:
            return action.data && action.data.already_registered ? 'already_registered' : true;
        default:
            return state;
    }
};

const signUpOptionsLoading = (state = null, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_FORM_SHOW:
            return true;
        case actions.IE_FETCH_SIGN_UP_OPTIONS_ERROR:
        case actions.IE_FETCH_SIGN_UP_OPTIONS_SUCCESS:
            return false;
        default:
            return state;
    }
}

const signUpResult = (state = null, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_SUCCESS:
            return action.data;
        case actions.IE_SIGN_UP_FORM_SHOW:
        case actions.IE_SIGN_UP_FORM_SUBMIT:
        case actions.IE_SIGN_UP_ERROR:
            return null;
        default:
            return state;
    }
}

const userInfo = (state = null, action) => {
    switch (action.type) {
        case actions.IE_SIGN_UP_FORM_SHOW:
            return null;
        case actions.IE_FETCH_SIGN_UP_OPTIONS_SUCCESS:
            return Object.assign({}, action.data.user_info);
        default:
            return state;
    }
}

export default combineReducers({
    signUpOptionsLoading,
    paymentUrl,
    signUpFormProcessing,
    signUpFormSubmitted,
    signUpOptions,
    signUpResult,
    userInfo
});