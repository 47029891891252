import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import uniqid from 'uniqid';
import Button from './Button';
import BodyBlocker from "./BodyBlocker";
import {dialogClose} from '../actions/creators/dialogs'

const Modal = styled.div`
  background: white;
  left: 50%;
  max-width: 500px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 90%;
  z-index: ${props => props.theme.zIndexes.dialogs};
`;

type Props = {
  dialogs:{[key:string]:{
    id:string,
    buttons:[
      {
        label:string,
        onClick:Function,
      }
    ],
    message:string,
    easyClose:boolean,
  }},
  dialogClose:Function,
};

class Dialogs extends React.Component<Props> {
  constructor(props) {
    super(props);

    this.bgClick = this.bgClick.bind(this);
  }

  bgClick() {
    const {dialogs} = this.props;
    const ids = Object.keys(dialogs);
    const lastDialog = dialogs[ids[ids.length - 1]];

    if (lastDialog.easyClose) {
      this.props.dialogClose(lastDialog.id);
    }
  }

  render() {
    const ids = Object.keys(this.props.dialogs);
    if (!ids.length) {
      return '';
    }

    // @todo Fix block z index

    return (
        ids.map(id => {
          const dialog = this.props.dialogs[id];
          return (
              <React.Fragment key={id}>
                <BodyBlocker modal onClick={this.bgClick}/>
                <Modal className="p-3 fade-in" key={id}>
                  <div className="p-3" dangerouslySetInnerHTML={{__html: dialog.message}}/>
                  <div className="p-3">
                    {dialog.buttons && dialog.buttons.map(button => (
                        <Button className="m-1" focus key={uniqid()} {...button}  onClick={() => {button.onClick(dialog)}}>{button.label}</Button>
                    ))}
                  </div>
                </Modal>
              </React.Fragment>
          )
        })
    );
  }
}

const mapStateToProps = ({dialogs}) => ({
  dialogs
});

const mapDispatchToProps = {
  dialogClose
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dialogs);