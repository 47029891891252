import React from 'react';
import {connect} from 'react-redux';
import ContactPageContent from './ContactPageContent';
import ContactPageSidebar from './ContactPageSidebar';
import TwoColumnLayout from './TwoColumnLayout';
import {getBootstrapConfig,getCinemas,isContactFormProcessing,isContactFormSubmitted} from '../reducers';
import {contactFormSubmit,contactFormShow} from '../actions/creators';
import {sortByProperty} from '../utils/arrays';

class ContactPage extends React.Component {
  render() {
    return <TwoColumnLayout MainContent={ContactPageContent} SidebarContent={ContactPageSidebar} {...this.props}/>;
  }
}

const mapStateToProps = state => ({
  cinemas: getCinemas(state).sort(sortByProperty('name')),
  config: getBootstrapConfig(state),
  isProcessing: isContactFormProcessing(state),
  isSubmitted: isContactFormSubmitted(state),
});

const mapDispatchToProps = dispatch => ({
  show: () => dispatch(contactFormShow()),
  submit: data => dispatch(contactFormSubmit(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactPage);