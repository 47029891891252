import React from 'react';
import {Link} from 'react-router-dom';
import MDSpinner from 'react-md-spinner';
import NowMovieBlock from "./NowMovieBlock";
import NoMoviesMessage from './NoMoviesMessage';

class NowPageContent extends React.Component {
  render() {
    return (
        <React.Fragment>
          <h1 className="h2 mb-5">
            <Link to="/" className="text-muted pr-3">
              <i className="fal fa-arrow-left fa-xs"></i>
            </Link>
            A punto de comenzar
          </h1>
          {this.renderContent()}
        </React.Fragment>
    );
  }

  renderContent() {
    if (this.props.sessions === null) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>;
    } else {
      return <div className="list-group mb-4">{this.renderResults()}</div>;
    }
  }

  renderResults() {
    if (this.props.sessions.length) {
      return this.props.sessions.map(session => <NowMovieBlock session={session} key={session.id}/>)
    } else {
      return <NoMoviesMessage
          title="No hay funciones próximas a comenzar cerca del cine seleccionado."
          message="Puedes probar otro cine u otro horario"/>;
    }
  }
}

export default NowPageContent;