import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import NavBarLayout from './NavBarLayout';
import UserProfile from './user/UserProfile';
import UserPurchases from './user/UserPurchases';
import ErrorPage from './ErrorPage';
import {getBootstrapConfig, getUserInfo, isUserLogged, isUserStuffProcessing} from '../reducers';
import {authShowLogin} from '../actions/creators'
import {facebookLogin, logout} from '../actions/creators/auth';
import UserWallet from './user/UserWallet';
import UserDeleteAccount from './user/UserDeleteAccount';
import { deleteAccount } from '../actions/creators/user';
import { openPopup } from '../actions/creators/auth/apple';
import UserSessionsList from './user/UserSessionsList';
import IERegistrationPage from './IERegistrationPage';
import IEMigrationPage from './IEMigrationPage';
import { hasIeAction } from '../utils/misc';

class UserPage extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAccount = this.deleteAccount.bind(this);
  }

  render() {
    const routes = [
      {
        title: 'Mis datos',
        component: UserProfile,
        path: ['/usuario/', '/usuario/cambiar-nip/'],
      },
      {
        title: 'Historial de compras',
        component: UserPurchases,
        path: '/usuario/mis-compras',
      },
      {
        title: 'Boletos activos',
        render: () => <UserPurchases future={true}/>,
        path: '/usuario/mis-compras/tickets-activos',
      },
    ];
  
    if (this.props.wallet === 't1') {
      routes.push({
        title: 'Mis tarjetas',
        showTitle: false,
        component: UserWallet,
        path: '/usuario/mis-tarjetas',
      });
    }

    if (this.props.user) {
      routes.push({
        title: 'Inscripción Invitado Especial',
        component: IERegistrationPage,
        path: '/usuario/invitado-especial/inscripcion',
        nav: !this.props.user.iecode && hasIeAction(this.props.user, 'sign-up')
      });

      if (this.props.user.iecode) {
        routes.push({
          title: 'Cambia tu tarjeta Invitado Especial',
          render: () => <IEMigrationPage action='ie-migration'/>,
          path: '/usuario/invitado-especial/migracion',
          nav: this.props.user.migration_available
        });

        routes.push({
          title: 'Cambio de nivel Invitado Especial',
          render: () => <IEMigrationPage action='ie-renewal'/>,
          path: '/usuario/invitado-especial/renovacion',
          nav: false
        });

        routes.push({
          title: 'Cambio de nivel Invitado Especial',
          render: () => <IEMigrationPage action='ie-renewal'/>,
          path: '/usuario/invitado-especial/cambio-de-nivel',
          nav: this.props.user.renewal_available
        });
      }
    }
    
    routes.push({
      title: 'Dispositivos vinculados',
      showTitle: false,
      render: () => <UserSessionsList />,
      path: '/usuario/sesiones-activas',
      nav: false
    });
  
    routes.push({
      title: 'Eliminar cuenta',
      showTitle: false,
      render: () => <UserDeleteAccount onSubmit={this.deleteAccount} appleLogin={this.props.appleLogin} facebookLogin={this.props.facebookLogin}/>,
      path: '/usuario/eliminar-cuenta',
    });
  
    routes.push({
      title: 'Cerrar sesión',
      onClick: this.props.logout,
    });
  
    if (!this.props.isUserLogged && this.props.location.pathname.indexOf('/tickets-activos') < 0) {
      return <ErrorPage message={<p>No tienes permisos para acceder a esta sección.<br/><span onClick={this.props.authShowLogin} className="text-primary" style={{cursor:'pointer'}}>Accede a tu cuenta</span></p>}/>;
    }

    return <NavBarLayout fixedTitle="Perfil" routes={routes}/>;
  }

  deleteAccount(params) {
    this.props.deleteAccount(this.props.history, params);
  }

}

const mapStateToProps = state => ({
  isUserLogged: isUserLogged(state),
  wallet: getBootstrapConfig(state).wallet,
  user: getUserInfo(state)
});

const mapDispatchToProps = dispatch => ({
  authShowLogin: () => dispatch(authShowLogin()),
  logout: () => dispatch(logout()),
  deleteAccount: (history, params) => dispatch(deleteAccount(history, params)),
  facebookLogin: (callback, options) => dispatch(facebookLogin(callback, options)),
  appleLogin: callback => dispatch(openPopup(callback)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPage));