import * as actions from '../actions/types';
import { combineReducers } from 'redux';

// @todo Associate subscriptions with the token.
const upcomingMovies = (state = [], action) => {
    switch (action.type) {
        case actions.NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_SUCCESS:
            return state.concat([action.resourceId]);
        case actions.NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_SUCCESS:
            return removeIdFromCollection(state, action.resourceId);
        default:
            return state;
    }
}

const removeIdFromCollection = (state, id) => {
    const result = state.concat();
    const i = result.indexOf(id);

    if (i < 0) return result;

    result.splice(i, 1);

    return result;
}

const callback = (state = null, action) => {
    switch (action.type) {
        case actions.NOTIFICATIONS_TOKEN_REQUEST:
            return action.callback;
        case actions.NOTIFICATIONS_TOKEN_REQUEST_GRANTED:
        case actions.NOTIFICATIONS_TOKEN_REQUEST_ABORT:
            return null;
        default:
            return state;
    }
};

const permission = (state = null, action) => {
    switch (action.type) {
        case actions.NOTIFICATIONS_TOKEN_REQUEST:
            return action.permission;
        case actions.NOTIFICATIONS_TOKEN_REQUEST_DENIED:
            return 'denied';
        case actions.NOTIFICATIONS_TOKEN_REQUEST_GRANTED:
            return 'granted';
        default:
            return state;
    }
};

const token = (state = null, action) => {
    switch (action.type) {
        case actions.NOTIFICATIONS_TOKEN_REQUEST_GRANTED:
            return action.token;
        default:
            return state;
    }
};

export default combineReducers({
    callback,
    permission,
    token,
    upcomingMovies
});