import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { cleanForSlug, getAreaBillboardPermalink } from '../utils/urls';
import UserCinemaNavLinks from './UserCinemaNavLinks';
import { breakpoints } from '../utils/styles';
import { onAvatarLoadError } from "../utils/misc";

const ProfilePic = styled.img`
    border-radius: 100%;
    vertical-align: -0.35em !important;
`;

const Drawer = styled.div`
  background: var(--primary);
  background: linear-gradient(228deg, rgba(243,0,60,1) 0%, rgba(179,13,79,1) 100%);
  font-size: 1.2rem;
  height: 100%;
  padding: 3rem 3rem 0.5rem;
  position: fixed;
  right: -90vw;
  top: 0;
  width: 90vw;
  transition: 0.5s ease-out right;
  z-index: 1032;

  .nav-link { color: #fff; }

  ${ProfilePic},
  .fal {
    font-size: 1.15em;
    text-align: center;
    vertical-align: -0.06em;
    width: 1.5em;
}
  }

  ${props => props.open && 'right: 0;'}

  @media screen and (min-width: ${breakpoints.desktop}px) { display: none; }
`;

const CloseBtn = styled.button`
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  padding: 0;
  outline: none;

  &:focus { border: 0; outline: none; }
  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

const Menu = styled.ul`
    font-weight: 200;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 1.5em;
    padding: 0;

    li {
        margin-bottom: 1em;
    }

    a, button {
        color: #fff;
        padding: .5rem 0;
    }

    ${ProfilePic},
    a i {
        margin-right: .5rem;
    }
`;

const UserBox = styled.li`
    border-top: 1px solid rgba(255,255,255,0.5);
    bottom: 0;
    color: white;
    left: 3rem;
    padding: 2.5rem 0;
    position: absolute;
    right: 3rem;
`;

const LogOutBtn = styled.button`
    background: none;
    border: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

class DrawerNav extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onLogInClick = this.onLogInClick.bind(this);
    }

    onClick(e) {
        if (['a', 'button'].indexOf(e.target.nodeName.toLowerCase()) > -1) {
            this.props.onCloseClick();
        }
    }

    onLogInClick(e) {
        e.preventDefault();
        this.props.onLogInClick();
    }

    render() {
        const { cinema } = this.props;

        return <Drawer open={this.props.isOpen}>
            <CloseBtn onClick={this.props.onCloseClick}><i className="fal fa-times"></i></CloseBtn>
            <ul className="nav flex-nowrap justify-content-between c-nav mt-4 pt-3 mb-3 mb-lg-0">
                <UserCinemaNavLinks userIcon={false}/>
            </ul>
            <Menu onClick={this.onClick}>
                {cinema ? <li><Link to={'/por-comenzar/cine-' + cinema.id + '/' + cleanForSlug(cinema.name)}><i className="fal fa-fw fa-stopwatch"></i> A punto de comenzar</Link></li> : null}
                {cinema ? <li><Link to={getAreaBillboardPermalink(cinema.area)}><i className="fal fa-location-dot"></i> Cartelera por zonas</Link></li> : null}
                <li><Link to="/promociones"><i className="fal fa-fw fa-badge-percent"></i> Promociones</Link></li>
                <li><Link to="/landing/preventas/"><i className="fal fa-fw fa-tickets-perforated"></i> Preventas</Link></li>
                <li><Link to="/landing/imax/peliculas"><i className="fal fa-fw fa-screen-users"></i> IMAX</Link></li>
                <li><Link to="/contacto"><i className="fal fa-fw fa-envelope"></i> Contacto</Link></li>
                <li><a href="tel:5552576969"><i className="fal fa-circle-phone"></i> Línea Cinemex</a></li>
                <UserBox>
                    {this.props.user
                        ? <React.Fragment>
                            <Link to="/usuario"><ProfilePic src={this.props.user.avatar} alt="Foto de perfil" onError={onAvatarLoadError} /> Perfil</Link>
                            <LogOutBtn onClick={this.props.onLogOutClick}><i class="fal fa-arrow-right-from-bracket"></i></LogOutBtn>
                        </React.Fragment>
                        : <Link to="/usuario" onClick={this.onLogInClick}><i class="fal fa-circle-user"></i> Ingresar</Link>
                    }
                </UserBox>
            </Menu>
        </Drawer>;
    }
}

export default DrawerNav;