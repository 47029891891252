import React from 'react';
import styled from 'styled-components';
import {breakpoints} from '../utils/styles';

const SliderNav = styled.ul`
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  
  & > .slick-prev {
    left: 0;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    
    &:before { left: 0.5rem; }
  }
  
  & > .slick-next {
    right: 0;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    
    @media screen and (min-width: ${breakpoints.tablet}px) {
      margin-right: ${props => props.sidebarOpen ? '35.5%' : ''};
      transition: 0.25s ease-out all;
    }
    
    &:before { right: 0.5rem; }
  }
  
  & > .slick-prev,
  & > .slick-next {
    background: rgba(0,0,0,0.3);
    font-size: ${(props) => props.size === 'large' ? '3rem' : '2rem'};
    height: 2em;
    padding: 0 0.5rem;
    pointer-events: initial;
    width: 1em;
    
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
  
    &:before {
      font-size: 1em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background: rgba(0,0,0,0.65);
    }
  }
`;

const Label = styled.span`
  display: inline-block;
  text-indent: -9999em;
`;

export default props => (
    <SliderNav aria-label="Carousel Navigation" tabIndex="0" id={props.navId} {...props}>
      <li className="slick-arrow slick-prev" tabIndex="-1" data-controls="prev">
        <Label>Anterior</Label>
      </li>
      <li className="slick-arrow slick-next" tabIndex="-1" data-controls="next">
        <Label>Siguiente</Label>
      </li>
    </SliderNav>
);