import React from 'react';
import Footer from "./Footer";
import Header from './Header';

export default props => (
    <React.Fragment>
      <div className={'l-wrapper l-wrapper--toggle ' + (props.bgWhite ? 'bg-white' : '')} id="the-body">
        <div className="l-wrapper__content">
          <Header withUserCinemaNav={true} feat={props.feat} bgWhite={props.bgWhite} includeSearch={props.includeSearch} transparent={props.headerTransparent} />

          <props.Content {...props}/>
        </div>
      </div>
      <Footer includeContent={props.footerContent !== false}/>
    </React.Fragment>
);