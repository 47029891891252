import React from 'react';
import BodyBlocker from '../BodyBlocker';
import styled from 'styled-components';

const Dialog = styled.div`
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    max-width: 400px;
    text-align: center;
    font-size: 0.92em;
`;

let timer;

class ExternalPaymentPopup extends React.Component {
    constructor(props) {
        super(props);

        this.onMessage = this.onMessage.bind(this);
        this.openWindow = this.openWindow.bind(this);
        this.attachEventListener = this.attachEventListener.bind(this);
        this.win = null;
    }

    componentDidMount() {
        if (this.props.url) {
            this.openWindow();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.url && this.props.url) {
            this.openWindow();
        }
    }

    openWindow() {
        this.win = window.open(this.props.url, 'cinemexExternalPaymentPopup_' + this.props.transactionId, 'popup');
        this.attachEventListener();
    }

    componentWillUnmount() {
        this.removeEventListener();
    }

    attachEventListener() {
        const win = this.win;
        timer = setInterval(function () {
            if (!win.window) {
                this.removeEventListener();
                this.props.onComplete({
                    success: false,
                    cancelled: true
                });
                return;
            }
            try {
                win.postMessage({ message: "requestCinemexPaymentPopupResult" }, "*");
            } catch (e) { }
        }.bind(this), 1000);

        window.addEventListener("message", this.onMessage);
    }

    removeEventListener() {
        clearInterval(timer);
        window.removeEventListener("message", this.onMessage);
        this.win.close();
    }

    onMessage(e) {
        if (e.data.message === "deliverCinemexPaymentPopupResult") {
            this.removeEventListener();
            this.props.onComplete(e.data.result);
        }
    }

    render() {
        return <BodyBlocker>
            <Dialog onClick={e => this.win.window.focus()}>Completa to pago en la ventana emergente. Haz click aquí si no puedes ver el formulario de pago.</Dialog>
        </BodyBlocker>;
    }
}

export default ExternalPaymentPopup;