import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import MoviesGrid from './MoviesGrid';
import MoviePosterPremiere from './MoviePosterPremiere';
import {movieSelect,movieUpcomingSelect,cinemaMoviesFetch,movieTabClose, areaMoviesFetch} from '../actions/creators';
import {promosFetch} from '../actions/creators/promos';
import {
  isMainBillboardLoading,
  isUiMovieTabOpen,
  getMainBillboardMovies,
  getExclusiveMovies,
  getFeaturedMovie,
  getPopularMovies,
  getPremiereMovies,
  getPreSaleMovies,
  getUpcomingMovies,
  getSelectedCinemaId,
  getSelectedCinema,
  getPromosA,
  getPromosFeatured,
  getPromosWide,
  getHeaderSearchTerm,
  getBootstrapConfig,
  getOtherMainAreaMovies,
  getPromosWideFixed
} from '../reducers';
import HeaderSearch from './HeaderSearch';
import PromosA from "./PromosA";
import PromosFeatured from "./PromosFeatured";
import LogoLoading from "./LogoLoading";
import {getCinemaPermalink,getAreaBillboardPermalink, getAbsoluteUrl} from '../utils/urls';
import {askLocationIfNotSelected, cinemaSelectorOpen, postPurchaseIePopupShow} from '../actions/creators/ui';
import HomeFeaturedMovie from "./HomeFeaturedMovie";
import * as vars from '../utils/styles';
import NoHomeMoviesMessage from './NoHomeMoviesMessage';
import NoMoviesMessage from './NoMoviesMessage';
import MDSpinner from 'react-md-spinner';
import { shuffleArray } from '../utils/arrays';
import { AnalyticsPromos, AnalyticsBillboards } from '../utils/analytics';
import PromoWide from './PromoWide';
import { Helmet } from 'react-helmet';
import PromoWideFixed from './PromoWideFixed';

const s = `
  font-size: 0.65em;
  @media (min-width: ${vars.breakpoints.tablet}px) { font-size: 0.5em; }
`;
const BillboardLink = styled(Link)([s]);
const BillboardAnchor = styled.a([s]);

const getCinemaBillboardLink = cinema => cinema && getBillboardLink(getCinemaPermalink(cinema), 'ver todos los horarios de este cine');
const getAreaBillboardLink = cinema => cinema && getBillboardLink(getAreaBillboardPermalink(cinema.area), 'ver cartelera por zona');
const getBillboardLink = (path, anchor) => <BillboardLink key={path} to={path} className="mb-0">{anchor}</BillboardLink>;

const fixAreaMoviesUrl = (area, movies) => movies.map(movie => Object.assign(
  {}, movie, {url: getAreaBillboardPermalink(area) + '/pelicula-' + movie.id}
));

let featuredPromosOrder = [];
const defineFeaturedPromosOrder = () => {
  featuredPromosOrder = [];
  for (var i = 0; i <= 100; i++) {
    featuredPromosOrder.push(i);
  }
  featuredPromosOrder = shuffleArray(featuredPromosOrder);
};

class HomePageContent extends React.Component {
  constructor(props) {
    super(props);

    this.onMobileSearchFocus = this.onMobileSearchFocus.bind(this);
    this.onMobileSearchBlur = this.onMobileSearchBlur.bind(this);

    this.state = {
      searchFocus: false
    };
  }

  componentWillMount() {
    AnalyticsPromos.resetAll();
    defineFeaturedPromosOrder();
  }

  componentDidMount() {
    // this.props.cinemaSelectorOpenIfNeeded();
    this.props.cinemaId && this.props.fetchCinemaMovies(this.props.cinemaId);
    this.props.areaId && this.props.fetchAreaMovies(this.props.areaId);

    this.props.movieTabClose();
    if (this.props.cinema) { AnalyticsBillboards.trackCinema(this.props.cinema); }

    const afterPurchaseIeReminder = localStorage.getItem('after_purchase_ir_reminder');
    if (afterPurchaseIeReminder) {
      const data = JSON.parse(afterPurchaseIeReminder);
      this.props.postPurchaseIePopupShow(data);
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.cinemaId && this.props.cinemaId !== oldProps.cinemaId) {
      this.props.fetchCinemaMovies(this.props.cinemaId);
      if (this.props.cinema) { AnalyticsBillboards.trackCinema(this.props.cinema); }
    }
    if (this.props.areaId && this.props.areaId !== oldProps.areaId) {
      this.props.fetchAreaMovies(this.props.areaId);
    }
  }

  onMobileSearchFocus() {
    window.scrollTo(0,0);
    this.setState({searchFocus: true});
  }

  onMobileSearchBlur() {
    if (!this.props.searchTerm) {
      this.setState({searchFocus: false});
    }
  }

  getFeaturedPromo(position) {
    const availableIndexes = featuredPromosOrder.filter(i => (i in this.props.promosFeatured));
    const feat = [];
    const regular = [];

    for (let h of availableIndexes) {
      if (this.props.promosFeatured[h].featured) {
        feat.push(h);
      } else {
        regular.push(h);
      }
    }

    const orderedIndexes = feat.concat(regular);
    const i = orderedIndexes[position];

    return (i in this.props.promosFeatured) ? this.props.promosFeatured[i] : null;
  }

  getWidePromo() {
    const availableIndexes = featuredPromosOrder.filter(i => (i in this.props.promosWide));
    const i = availableIndexes[0];

    return (i in this.props.promosWide) ? this.props.promosWide[i] : null;
  }

  render() {
    return (
        <div className="l-wrapper l-wrapper--toggle" /*style={{transition:'0.25s ease-out margin-top',marginTop:this.state.searchFocus?'-60vh':0}}*/>
          <Helmet>
            <link rel="canonical" href={getAbsoluteUrl('/')} />
          </Helmet>
          <div className="l-wrapper__content">
            {this.renderFirstBlock()}
            {this.props.searchTerm ? this.renderSearchResults() :
            <React.Fragment>
              {this.renderPromoA()}
              {this.renderSecondBlock()}
              {this.renderPromoWide()}
            </React.Fragment>}

            <MoviesGrid title="Próximos estrenos" emoji="🎥" size="small"
                        movies={this.props.moviesUpcoming}
                        onMovieSelect={this.props.movieUpcomingSelect}
                        searchTerm={this.props.searchTerm}
                        sidebarOpen={this.props.movieTabOpen}
            />
          </div>
        </div>
    )
  }

  renderFirstBlock() {
    if (this.props.isMainBillboardLoading) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    if (this.props.areNoMoviesToShow && !this.props.isMainBillboardLoading) {
      return <div className="container py-3"><NoHomeMoviesMessage/></div>;
    }

    return (
        <React.Fragment>
          <HomeFeaturedMovie movie={this.props.movieFeatured} title={this.props.movieFeaturedTitle} movieTabOpen={this.props.movieTabOpen} onMovieSelect={this.props.movieSelect} hidden={this.state.searchFocus}/>
          {this.renderSearchBar()}
        </React.Fragment>
    );
  }

  getBillboardLinks(section) {
    if (section !== this.props.billboardLinksPlacement) return '';

    return [
      getCinemaBillboardLink(this.props.cinema),
      getAreaBillboardLink(this.props.cinema),
      <BillboardAnchor key="change-cinema" href="#" onClick={this.props.cinemaSelectorOpen} className="mb-0">seleccionar otro cine</BillboardAnchor>
    ];
  }

  renderSearchResults() {
    if (!this.props.searchTerm) return '';

    if (!this.props.movies.length && !this.props.moviesUpcoming.length) {
      return <div className="container"><NoMoviesMessage
          title="Lo sentimos, no hay resultados para tu búsqueda en este cine."
          message="Puedes probar realizando otra búsqueda o cambiando el cine seleccionado." /></div>;
    }

    return this.renderAllMovies();
  }

  renderSearchBar() {
    return (
        <div className="container d-md-none">
          <HeaderSearch collapsible={false} /*onFocus={this.onMobileSearchFocus} onBlur={this.onMobileSearchBlur}*//>
        </div>
    );
  }

  renderSecondBlock() {
    if (this.props.areNoMoviesToShow || this.props.searchTerm) {
      return '';
    }

    const areaMovies = this.props.cinema ? fixAreaMoviesUrl(this.props.cinema.area, this.props.areaMovies) : [];

    return (
        <React.Fragment>
          <MoviesGrid title="Estrenos" emoji="🍿" size="large"
                      movies={this.props.moviesPremieres} onMovieSelect={this.props.movieSelect}
                      loading={this.props.isMainBillboardLoading}
                      loadingComponent={LogoLoading}
                      links={this.getBillboardLinks('premieres')}
                      PosterElement={MoviePosterPremiere}
                      sidebarOpen={this.props.movieTabOpen}
          />

          {this.renderPromoWideFixed()}

          <div className="d-block d-md-none container no-width">
            <div className="row mb-3" style={{marginLeft:'-0.5rem',marginRight:'-0.5rem'}}>
              <div className="col-6 px-2">
                <PromosFeatured promo={this.getFeaturedPromo(0)}/>
              </div>
              <div className="col-6 px-2">
                <PromosFeatured promo={this.getFeaturedPromo(1)}/>
              </div>
            </div>
          </div>

          {this.renderAllMovies()}
          <MoviesGrid title="Exclusivas" emoji="👑" size="small" movies={this.props.moviesExclusive} onMovieSelect={this.props.movieSelect} sidebarOpen={this.props.movieTabOpen}/>
          <MoviesGrid title="Preventa" emoji="🎟" size="small" movies={this.props.moviesPreSale} onMovieSelect={this.props.movieSelect} sidebarOpen={this.props.movieTabOpen}/>
          <MoviesGrid title="Otras películas en cines cercanos" emoji="👀" size="small" movies={areaMovies} onMovieSelect={this.onAreaMovieSelect} sidebarOpen={this.props.movieTabOpen}/>
        </React.Fragment>
    );
  }

  renderPromoA() {
    if (this.props.searchTerm) return '';

    return <section className="pt-3">
      <div className="container no-width">
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <PromosA promos={this.props.promosA}/>
          </div>
          <div className="d-none d-md-block col-6 col-md-3 mb-3">
            <PromosFeatured promo={this.getFeaturedPromo(0)}/>
          </div>
          <div className="d-none d-md-block col-6 col-md-3 mb-3">
            <PromosFeatured promo={this.getFeaturedPromo(1)}/>
          </div>
        </div>
      </div>
    </section>;
  }

  renderPromoWide() {
    if (this.props.searchTerm) return '';
    
    return <section className="py-3">
      <div className="container no-width">
        <div className="row">
          <div className="col-12 col-xxl-9 mb-3">
            <PromoWide promo={this.getWidePromo()}/>
          </div>
          <div className="d-none d-xxl-block col-12 col-xxl-3 mb-3">
            <PromosFeatured promo={this.getFeaturedPromo(2)}/>
          </div>
        </div>
      </div>
    </section>;
  }

  renderPromoWideFixed() {
    if (this.props.searchTerm) return '';
    if (!this.props.promosWideFixed.length) return '';

    const promo = this.props.promosWideFixed[0];

    if (!this.props.cinema || promo.cinemas.indexOf(this.props.cinema.id) === -1) return '';
    
    return <section className="py-3">
      <div className="container no-width">
        <div className="row">
          <div className="col-12 col-xxl-9 mb-3">
            <PromoWideFixed promo={promo}/>
          </div>
          <div className="d-none d-xxl-block col-12 col-xxl-3 mb-3">
            <PromosFeatured promo={this.getFeaturedPromo(3)}/>
          </div>
        </div>
      </div>
    </section>;
  }

  renderAllMovies() {
    return <MoviesGrid title="Todas las películas" emoji="🎬" size="small" movies={this.props.movies}
        links={this.getBillboardLinks('all')}
        onMovieSelect={this.props.movieSelect}
        searchTerm={this.props.searchTerm}
        sidebarOpen={this.props.movieTabOpen} />;
  }

}

const mapStateToProps = state => {
  const cinemaId = getSelectedCinemaId(state);
  const cinema = getSelectedCinema(state);
  const movieFeatured = getFeaturedMovie(state);
  const movies = getMainBillboardMovies(state);
  const moviesExclusive = getExclusiveMovies(state);
  const moviesPopular = getPopularMovies(state);
  const moviesPremieres = getPremiereMovies(state);
  const moviesPreSale = getPreSaleMovies(state);
  const searchTerm = getHeaderSearchTerm(state);

  const areNoMoviesToShow =
      ((searchTerm === '') && !movieFeatured && !movies.length && !moviesExclusive.length && !moviesPopular.length && !moviesPremieres.length && !moviesPreSale.length)
  ;

  let billboardLinksPlacement;
  if (moviesPremieres.length) {
    billboardLinksPlacement = 'premieres';
  } else if (moviesPopular.length) {
    billboardLinksPlacement = 'popular';
  } else {
    billboardLinksPlacement = 'all';
  }

  return {
    areaId: cinema ? cinema.area.id : null,
    areaMovies: getOtherMainAreaMovies(state) || [],
    areNoMoviesToShow: areNoMoviesToShow,
    billboardLinksPlacement,
    isMainBillboardLoading: isMainBillboardLoading(state),
    cinemaId,
    cinema,
    movieFeatured,
    movieFeaturedTitle: getBootstrapConfig(state).sections.featured_movie.title,
    movies,
    moviesExclusive,
    moviesPopular,
    moviesPremieres,
    moviesPreSale,
    movieTabOpen: isUiMovieTabOpen(state),
    moviesUpcoming: getUpcomingMovies(state),
    promosA: getPromosA(state),
    promosFeatured: getPromosFeatured(state),
    promosWide: getPromosWide(state),
    promosWideFixed: getPromosWideFixed(state),
    searchTerm,
  };
};

const mapDispatchToProps = dispatch => ({
  cinemaSelectorOpenIfNeeded: () => dispatch(askLocationIfNotSelected()),
  cinemaSelectorOpen: () => dispatch(cinemaSelectorOpen()),
  fetchAreaMovies: areaId => dispatch(areaMoviesFetch(areaId, true)),
  fetchCinemaMovies: cinemaId => dispatch(cinemaMoviesFetch(cinemaId, true)),
  movieSelect: (movieId, movieUrl, history) => dispatch(movieSelect(movieId, movieUrl, history)),
  movieTabClose: () => dispatch(movieTabClose()),
  movieUpcomingSelect: (movieId, movieUrl, history) => dispatch(movieUpcomingSelect(movieId, movieUrl, history)),
  postPurchaseIePopupShow: data => dispatch(postPurchaseIePopupShow(data)),
  promosFetch: () => dispatch(promosFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePageContent);