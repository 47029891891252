import React from 'react';
import styled from 'styled-components';

const Button = styled.span`
  background: none;
  border: 0;
  color: var(--primary);
  ${props => props.disabled
    ? 'cursor: not-allowed; opacity: 0.5;'
    : 'cursor: pointer; opacity: 1;'}
  ${props => props.paddingDefault ? '' : 'padding: 0;'}
  text-align: inherit;
  width: ${props => props.width || 'auto'}
  
  &:focus { outline: none; }
`;

class LinkButton extends React.PureComponent {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>
  }
}

export default LinkButton;