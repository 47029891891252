import React from 'react';
import styled, {keyframes} from 'styled-components';
import BodyBlocker from "../BodyBlocker";
import BackSvg from '../../assets/svg/card-back.svg';
import FrontSvg from '../../assets/svg/card-front.svg';

const rotate360 = keyframes`
  from {
    transform: rotateY(360deg);
  }

  to {
    transform: rotateY(0deg);
  }
`;

const slideIn = keyframes`
  0% {
    width: 0%;
  }

  25% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
`;

const duration = 3;
const cardWidth = 200;
const cardHeight = cardWidth / 1.586;

const Container = styled.div`
  position: fixed;
  z-index: 1060;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Perspective = styled.div`
  perspective: 500px;
`;

const Card = styled.div`
  background: var(--primary);
  width: ${cardWidth}px;
  height: ${cardHeight}px;
  border-radius: 4px;
  animation: ${rotate360} ${duration}s linear infinite;
  position: relative;
  margin: 0 auto;
  transform-style: preserve-3d;
  transition: all 1.0s linear;
`;

const Face = styled.div`
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: 0.5s;
  position: relative;
  transform: ${props => props.front ? 'rotateY(-180deg)' : 'rotateY(0deg)'};
`;

const Mask = styled.div`
  animation: ${slideIn} ${duration}s linear ${props => (props.front ? 1 : -1) * duration/4}s infinite;
  backface-visibility: hidden;
  overflow: hidden;
  height: ${cardHeight}px;
  position: absolute;
`;

const Img = styled.img`
  backface-visibility: hidden;
`;

const CardAnimation = props => (
    <div style={{zIndex:11100,position:'fixed'}}>
      <BodyBlocker overSidebars solidBg/>
      <Container>
        <p className="h3 mb-5">Estamos procesando tu transacción</p>

        <Perspective>
          <Card>
            <Face front>
              <Mask front>
                <Img src={FrontSvg} width={cardWidth}/>
              </Mask>
            </Face>
            <Face back>
              <Mask back>
                <Img src={BackSvg} width={cardWidth}/>
              </Mask>
            </Face>
          </Card>
        </Perspective>
      </Container>
    </div>
);

export default CardAnimation;