import * as actions from '../../types';
import CMX from '../../../api';
import {dialogAlert} from '../dialogs';
import {authClose} from '../index';
import { AnalyticsUsers } from '../../../utils/analytics';
import { getAuthPinDialogContext, getAuthPinDialogPayload, getFingerprint } from '../../../reducers';

export const loginPost = (email, password, recaptcha_code) => (dispatch, getState) => {
  dispatch({
    type: actions.AUTH_LOGIN_POST,
    email,
    password,
    recaptcha_code
  });
};

export const loginPinPost = (pin) => (dispatch, getState) => {
  dispatch({
    type: actions.AUTH_LOGIN_PIN_POST,
    pin
  });
};

export const appleLoginPost = token => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: actions.AUTH_LOGIN_POST,
    apple_signin: {token},
    ref: state.ui.authPanel.register.ref
  });
};

export const loginPostSuccess = data => dispatch => {
  dispatch({
    type: actions.AUTH_LOGIN_POST_SUCCESS,
    data,
  });
  dispatch(authClose());
};

export const loginPostFailed = error => dispatch => {
  dispatch({
    type: actions.AUTH_LOGIN_POST_ERROR,
    error,
  });
};

export const recoverPassPost = email => dispatch => {
  dispatch({
    type: actions.AUTH_RECOVER_PASS_POST
  });

  CMX.post('/recoverPass', {email})
      .then(data => dispatch(recoverPassSuccess(data)))
      .catch(error => dispatch(recoverPassFailed(error)));
};

const recoverPassSuccess = data => dispatch => {
  dispatch({
    type: actions.AUTH_RECOVER_PASS_SUCCESS,
    data,
  });
};

const recoverPassFailed = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.AUTH_RECOVER_PASS_ERROR,
    error,
  });
};

export const resetPassPost = (code, password, action) => dispatch => {
  dispatch({
    type: actions.AUTH_RESET_PASS_POST
  });

  CMX.post('/resetPass', {code, password, action})
      .then(data => dispatch(resetPassSuccess(data)))
      .catch(error => dispatch(resetPassFailed(error)));
};

const resetPassSuccess = data => dispatch => {
  dispatch({
    type: actions.AUTH_RESET_PASS_SUCCESS,
    data,
  });
};

const resetPassFailed = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.AUTH_RESET_PASS_ERROR,
    error,
  });
};

export const facebookLogin = (callback, options) => dispatch => {
  dispatch({
    type: actions.AUTH_FB_LOGIN,
  });
  
  window.FB.login(res => {
    if ('connected' === res.status) {
      if (callback) {
        return callback(res.authResponse);
      } else {
        dispatch(facebookLoginSuccess(res.authResponse));
      }
    } else {
      dispatch(facebookLoginFailure());
    }
  }, Object.assign({}, {scope: 'public_profile,email'}, options));
};

const facebookLoginSuccess = ({accessToken}) => dispatch => {
  dispatch({
    type: actions.AUTH_FB_LOGIN_SUCCESS,
    accessToken,
  });
  dispatch(facebookAccessTokenLogin(accessToken));
};

const facebookLoginFailure = () => dispatch => {
  dispatch(dialogAlert('No se pudo completar el ingreso con Facebook.'));
  dispatch({
    type: actions.AUTH_FB_LOGIN_ERROR,
  });
};

const facebookAccessTokenLogin = accessToken => (dispatch, getState) => {
  const state = getState();
  
  CMX.post('/login', {
    fbaccess_token: accessToken,
    fbauto_register: 1,
    ref: state.ui.authPanel.register.ref,
    fingerprint: getFingerprint(state)
  })
      .then(data => dispatch(loginPostSuccess(data)))
      .catch(error => dispatch(loginPostFailed(error)));
};

export const registerPost = (params) => (dispatch, getState) => {
  const state = getState();
  const isApple = state.auth.register === 'apple';
  
  dispatch({
    type: actions.AUTH_REGISTER_POST
  });

  let path;

  if (isApple) {
    path = '/login';
    params = {
      apple_signin: params,
      fingerprint: getFingerprint(state)
    };
  } else {
    path = '/register';
  }

  params.ref = state.ui.authPanel.register.ref;
  
  CMX.post(path, params)
      .then(data => dispatch(registerPostSuccess(data)))
      .catch(error => dispatch(registerPostFailed(error)));
};

const registerPostSuccess = data => dispatch => {
  dispatch({
    type: actions.AUTH_REGISTER_POST_SUCCESS,
    data,
  });
  dispatch(authClose());
  AnalyticsUsers.trackSignUp();
};

const registerPostFailed = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.AUTH_REGISTER_POST_ERROR,
    error,
  });
  if (('response' in error) && ('errorcode' in error.response) && error.response.errorcode === 'needs-confirmation') {
    dispatch(authClose());
  }
};

export const logout = () => ({
  type: actions.AUTH_LOGOUT,
});

export const setRegisterRef = ref => dispatch => {
  dispatch({
    type: actions.AUTH_SET_REF,
    ref
  });
};

export const AuthPinDialogClose = () => ({
  type: actions.AUTH_LOGIN_REQUEST_PIN_CLOSE
});

export const renewAuthToken = (token, appId) => ({
  type: actions.AUTH_RENEW_TOKEN,
  token,
  appId
});

export const onSignUpCheckEmailSubmit = fields => ({
  type: actions.AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT,
  ...fields
});

export const onSignUpCheckEmailSuccess = data => ({
  type: actions.AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS,
  data
});

export const onSignUpCheckEmailError = (error, action) => ({
  type: actions.AUTH_SIGN_UP_CHECK_EMAIL_ERROR,
  error,
  ...action
});

export const authPinSubmit = (pin, context, payload) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: actions.AUTH_PIN_SUBMIT,
    pin,
    context: getAuthPinDialogContext(state),
    payload: getAuthPinDialogPayload(state)
  });
}