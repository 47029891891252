import * as actions from '../actions/types';
import { AnalyticsUsers } from '../utils/analytics';

export const analyticsMiddleware = ({ getState, dispatch }) => next => async action => {
    next(action);

    switch (action.type) {
        case actions.IE_CODE_SUBMIT_SUCCESS: return AnalyticsUsers.trackIeLink('manual');
        case actions.IE_SIGN_UP_SUCCESS: return ieSignUpSuccess(getState, dispatch, action);
        case actions.AUTH_LOGIN_POST_SUCCESS:
        case actions.AUTH_FB_LOGIN_SUCCESS: return AnalyticsUsers.trackSignIn();
        case actions.AUTH_LOGOUT_SUCCESS: return AnalyticsUsers.trackSignOut();
        case actions.IE_NIP_UPDATE_SUCCESS: return AnalyticsUsers.trackIeNipChange();
    }
};

function ieSignUpSuccess(getState, dispatch, {data}) {
    switch (data.action) {
        case 'ie-sign-up': return data.already_registered ? AnalyticsUsers.trackIeLink('sign-up') : AnalyticsUsers.trackIeSignUp(data.level);
        case 'ie-migration': return AnalyticsUsers.trackIeMigration(data.is_current ? 'maintain' : 'upgrade', data.level);
        case 'ie-renewal': return AnalyticsUsers.trackIeRenewal(data.is_current ? 'maintain' : 'upgrade', data.level);
    }
}