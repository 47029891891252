import React from 'react';
import {connect} from 'react-redux';
import {authShowLogin, authShowRegister, authShowRegisterManual} from '../../actions/creators';
import {registerPost,facebookLogin,setRegisterRef, onSignUpCheckEmailSubmit} from '../../actions/creators/auth';
import { openPopup } from '../../actions/creators/auth/apple';
import SignUpManual from './SignUpManual';
import { dialogAlert } from '../../actions/creators/dialogs';
import Recaptcha from 'react-google-invisible-recaptcha';
import { getFingerprint } from '../../reducers';

class RegisterPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onDisagreeChange = this.onDisagreeChange.bind(this);
    this.appleLogin = this.appleLogin.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);
    this.registerPost = this.registerPost.bind(this);
    this.checkEmailPost = this.checkEmailPost.bind(this);
    this.onResolve = this.onResolve.bind(this);

    this.tmpFields = null;

    this.state = {
        disagree: false,
    };
  }

  onDisagreeChange() {
    this.setState((oldState) => ({disagree: !oldState.disagree}));
  }

  appleLogin() {
    if (this.state.disagree) {
      this.showDisagreeAlert();
      return;
    }

    this.props.appleLogin();
  }

  facebookLogin() {
    if (this.state.disagree) {
      this.showDisagreeAlert();
      return;
    }
    
    this.props.facebookLogin();
  }

  registerPost(fields) {
    fields.fingerprint = this.props.fingerprint;
    this.props.registerPost(fields);
  }

  checkEmailPost(fields) {
    this.tmpFields = fields;
    this.recaptcha.execute();
  }

  onResolve() {
    this.tmpFields.captcha = this.recaptcha.getResponse();
    this.recaptcha.reset();
    this.props.onCheckEmailSubmit(this.tmpFields);
    this.tmpFields = null;
  }

  showDisagreeAlert() {
    this.props.displayAlert('Debes aceptar las condiciones antes de continuar.');
  }

  componentDidMount() {
    const m = document.location.search.match(/ref=(\b.+\b)/);
    m && this.props.setRegisterRef(m[1]);
  }

  render() {
    return (
        <React.Fragment>
          <Recaptcha
              ref={ ref => this.recaptcha = ref }
              locale="es"
              sitekey={window.localConfig.recaptcha.key}
              onResolved={ this.onResolve } />
          <SignUpManual
              step="1"
              showRegister={this.props.showLogin}
              apple={this.props.apple}
              registerPost={this.registerPost}
              checkEmailPost={this.checkEmailPost}
              onLoginClick={this.props.showLogin}
            />
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  manual: state.auth.register === 'manual' || state.auth.register === 'apple',
  apple: state.auth.register === 'apple',
  fingerprint: getFingerprint(state),
});

const mapDispatchToProps = dispatch => ({
  showLogin: () => dispatch(authShowLogin()),
  showRegister: () => dispatch(authShowRegister()),
  showManual: () => dispatch(authShowRegisterManual()),
  registerPost: fields => dispatch(registerPost(fields)),
  onCheckEmailSubmit: fields => dispatch(onSignUpCheckEmailSubmit(fields)),
  facebookLogin: () => dispatch(facebookLogin()),
  appleLogin: () => dispatch(openPopup()),
  displayAlert: msg => dispatch(dialogAlert(msg)),
  setRegisterRef: ref => dispatch(setRegisterRef(ref)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterPanel);