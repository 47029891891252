import React from 'react';
import { AnalyticsPromos } from '../utils/analytics';
import styled from 'styled-components';
import { breakpoints } from '../utils/styles';

const ImgDesktop = styled.img`
    display: none;
    @media screen and (min-width: ${props => breakpoints.desktop}px) { display: block; }
`;

const ImgMobile = styled.img`
    @media screen and (min-width: ${props => breakpoints.desktop}px) { display: none; }
`;

type Promo = {
    id:number,
    url:string,
    image_web_url:string,
    image_app_url:string,
    target:string,
    name:string,
};

type Props = {
    promo:Promo
};

type State = {
    analytics_setup:boolean
};

class PromoWideFixed extends React.PureComponent<Props, State> {
    private ref:React.RefObject<HTMLAnchorElement> = React.createRef();

    constructor(props:Props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.state = { analytics_setup: false };
    }

    componentDidMount() {
        this.setup();
    }
    
    componentDidUpdate() {
        this.setup();
    }

    setup() {
        if (!this.ref.current || this.state.analytics_setup) return;

        this.setState({ analytics_setup: true });
        const { promo } = this.props;
        
        setTimeout(() => {
            AnalyticsPromos.observe('promo_wide', promo.id, promo.name, this.ref.current);
        }, 1500);
    }

    onClick(event:React.MouseEvent<HTMLElement>) {
        const { promo } = this.props;
        const openInNewWindow:boolean = promo.target !== '_self' || event.altKey || event.ctrlKey || event.shiftKey;

        AnalyticsPromos.trackClick('promo_wide', promo.id, promo.name, openInNewWindow ? null : () => {
            document.location = promo.url;
        });

        if (!openInNewWindow) event.preventDefault();
    }

    render() {
        const { promo } = this.props;

        if (!promo) {
            return null;
        }

        return <a href={promo.url || '#'} className="u-rounded-mask" target={promo.target} ref={this.ref} onClick={this.onClick}>
            <ImgDesktop src={promo.image_web_url} alt={promo.name} />
            <ImgMobile src={promo.image_app_url} alt={promo.name} />
        </a>;
    }
}

export default PromoWideFixed;