import React from 'react';
import { connect } from 'react-redux';
import Button from './Button';
import FieldRow from './FieldRow';
import { authShowLogin } from '../actions/creators';
import {resetPassPost} from '../actions/creators/auth';
import {isUiAuthPanelProcessing,isUiAuthPanelResetPassSent} from '../reducers';
import * as validators from '../utils/validation';
import FieldPasswordRow from './FieldPasswordRow';

class CompleteSignUpPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      password: '',
      passRepeat: '',
    };
  }

  onChange(prop, value) {
    this.setState({[prop]: value});
  }

  onSubmit(e) {
    e.preventDefault();

    const {password} = this.state;
    const {action, code} = this.props;

    this.props.resetPassPost(code, password, action);
  }

  onClose() {
    this.props.history.push('/');
    this.props.showLogin();
  }

  render() {
    return (
        <React.Fragment>
          <div className="px-5 py-4">
            <h3 className="h3 mb-3">
              {this.props.title}
            </h3>

            {this.props.sent ? (
                <React.Fragment>
                  <p className="text-primary lead">¡Listo! Ya puedes ingresar con tu nueva contraseña</p>
                  <div className="mt-5 mb-5">
                    <Button primary block onClick={this.onClose}>Ingresar</Button>
                  </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                  <p>Ingresa una contraseña segura. Esta contraseña junto con tu email te servirán para ingresar a tu cuenta de Cinemex.</p>
                  <form onSubmit={this.onSubmit} autoComplete='off'>
                    <FieldPasswordRow onChange={(e) => this.onChange('password', e.target.value)} value={this.state.password} />
                    <FieldRow type="password" label="Repetir contraseña" onChange={(e) => this.onChange('passRepeat', e.target.value)} value={this.state.passRepeat} autoComplete='new-password'/>
                    <div className="form-group text-right mt-5 mb-5">
                      <Button submit primary disabled={!validators.validatePassword(this.state.password) || this.state.password !== this.state.passRepeat || this.props.processing}>Guardar</Button>
                    </div>
                  </form>
                </React.Fragment>
            )}
          </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sent: isUiAuthPanelResetPassSent(state),
  processing: isUiAuthPanelProcessing(state),
});

const mapDispatchToProps = dispatch => ({
  showLogin: () => dispatch(authShowLogin()),
  resetPassPost: (code, password, action) => dispatch(resetPassPost(code, password, action)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteSignUpPanel);