import * as actions from '../../types';
import {dialogAlert} from '../dialogs';
import CMX from '../../../api';
import {nextStep} from '../checkout';
import { getCheckoutSeatsToSelect } from '../../../reducers';

export const seatSelect = seat => ({
  type: actions.CHECKOUT_SEAT_SELECT,
  seat
});

export const seatsSelectionSubmit = () => (dispatch, getState) => {
  const state = getState();
  const {seats, layout, needsToSubmitSeats} = state.checkoutData;
  const seatsToSelect = getCheckoutSeatsToSelect(state);
  const {transactionId} = state.checkoutData.order;
  const session = state.checkoutData.data;
  const cinema = state.entities.cinemas[state.checkoutData.data.cinema_id];

  if (seats.length < seatsToSelect) {
    dispatch(dialogAlert(`Por favor, selecciona ${seatsToSelect === 1 ? 'tu asiento' : 'tus asientos'}.`));
    return;
  }

  if (needsToSubmitSeats) {
    dispatch(seatsSelectionProcessing(true));

    validateSeatsSelection(layout, seats, dispatch)
        .then(() => seatsSelectionPost(session, cinema, transactionId, seats, dispatch))
        .finally(() => dispatch(seatsSelectionProcessing(false)));
  } else {
    dispatch(nextStep());
  }
};

const seatsSelectionProcessing = processing => ({
  type: actions.CHECKOUT_SEATS_SELECTION_PROCESSING,
  processing
});

const validateSeatsSelection = (layout, seats, dispatch) =>
    new Promise((resolve, reject) => {
      /*if (isMoreThanOneSeatEmpty(layout, seats) && isJustOneSeatEmptyNextToSelectedSeats(layout, seats)) {
        reject();
        displaySingleSeatValidationError(dispatch);
        return;
      }*/

      resolve();
    });

const isMoreThanOneSeatEmpty = (layout, seats) => {
  // @todo Needs to be tested.
  let empty = 0;

  layout.forEach(row => row.seats.forEach(seat => seats.indexOf(seat.id) === -1 && '0' === seat.status && empty++));

  return empty > (seats.length + 1);
};

const isJustOneSeatEmptyNextToSelectedSeats = (layout, seats) => {
  const flat = layout.map(row => row.seats.map(seat => seats.indexOf(seat.id) > -1 ? 's' : seat.status).join(''));
  const l = flat.length;

  for (let i = 0; i < l; i++) {
    if (flat[i].match(/E0s0|10s|s0s|s01|0s0E/) || flat[i].match(/^0s0/) || flat[i].match(/0s0$/)) {
      return true;
    }
  }

  return false;
};

const displaySingleSeatValidationError = (dispatch) => {
  dispatch(dialogAlert('No puedes dejar sólo un asiento libre entre los asientos ocupados y los que has seleccionado.'));
};

const seatsSelectionPost = (session, cinema, transactionId, seats, dispatch) => {
  return CMX.post(
      'buy/selectSeats',
      {
        session_id: session.id,
        transaction_id: transactionId,
        seats: seats,
      }
  )
      .then(data => dispatch(seatsSelectionSuccess(data)))
      .then(() => dispatch(nextStep()))
      .catch(error => dispatch(seatsSelectionFailure(error)));
};

const seatsSelectionSuccess = data => ({
  type: actions.CHECKOUT_SEATS_SELECTION_SUCCESS,
  data
});

const seatsSelectionFailure = ({message, response}) => dispatch => {
  dispatch(dialogAlert(message));
  dispatch({
    type: actions.CHECKOUT_SEATS_SELECTION_FAILURE,
    response
  });
};
