import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { userIeCardFetch } from "../../actions/creators/user";
import { getIeCardData, isUserLogged, isUserStuffProcessing } from "../../reducers";
import IePointsBlock from "../checkout/IePointsBlock";
import { dialogAlertEasyClose } from "../../actions/creators/dialogs";
import Header from "../Header";
import Error404Page from "../Error404Page";
import { asset } from "../../utils/misc";
import ErrorPage from "../ErrorPage";
import FullColumnLayout from "../FullColumnLayout";
import IeCardFront from "./IeCardFront";

function getLevelColor(level) {
    switch(level) {
        case 'one': return '#C0BCBD';
        case 'basic': return '#E43651';
        case 'gold': return '#FFBF00';
        default: return 'black';
    }
}

const Container = styled.div`
    background: white;
    color: #AAAAAA;
    margin-bottom: -6rem;
    min-height: 100vh;
    padding: 2rem;
    padding-top: 6rem;

    &:before {
        width: 200%;
        height: 333px;
        flex-shrink: 0;
        border-radius: 100%;
        background: linear-gradient(180deg, #000 24.95%, #555 87.84%);
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -35%);
    }
`;
const Subtitle = styled.p`
    color: #555;
    font-size: 1.15em;
    margin-bottom: 0;
`;
const VisitsCount = styled.span`
    background: ${props => props.color};
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.35);
    color: white;
    display: block;
    float: right;
    font-size: 0.82em;
    line-height: 2em;
    padding: 0 1.25em;

    & > i { margin-right: 0.5em; }
`;
const RequiredVisits = styled.ul`
    font-size: 0.9em;
    list-style-type: none;
    margin-bottom: 0;
    padding: 1em 0;

    & > li {
        padding: 0.5em 0 0.5em 2em;
        position: relative;
    }
`;
const VisitsFlag = styled.span`
    & > .fa-solid {
        color: ${props => getLevelColor(props.level)};
        font-size: 1.5em;
        position: absolute;
        top: 0.35em;
        left: 0;
    }
    & .small {
        font-size: 0.7em;
        left: 0.2em;
        position: absolute;
        top: 0.78em;
        color: ${props => props.level === 'gold' ? '#555' : 'white'};
    }
`;
const BenefitsButton = styled.div`
    margin-top: 0.5em;
    padding-left: 3em;
    position: relative;

    & > i {
        font-size: 1.75em;
        left: 0;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
    }
`;
const BenefitsContainer = styled.div`
    background: white;
    border-radius: 20px;
    bottom: 0;
    height: 98%;
    left: 0;
    overflow: auto;
    padding: 3em;
    position: fixed;
    text-align: center;
    transform: translateY(${props => props.visible ? `0` : `100%`});
    transition: all 0.5s;
    width: 100%;
    z-index: 1050;

    & > header {
        color: #555;
        font-size: 0.85em;
        line-height: 1.5em;
        margin-bottom: 3em;

        & > i { font-size: 3em; margin-bottom: 0.5em; }
    }
`;
const CloseCross = styled.button`
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    background: none;
    border: none;
    outline: none;
    font-size: 1.5em;
    color: #666;

    &:focus { outline: none; }
`;
const BenefitsList = styled.ul`
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;
const BenefitItem = styled.li`
    position: relative;
    margin-bottom: 4%;
    width: 48%;

    & > img {
        border-radius: 1em;
        position: relative;
        width: 100%;
        z-index: 2;
    }
`;
const ProgressBar = styled.div`
    margin-top: 2.5em;
    margin-bottom: 2em;
    position: relative;

    & .progress-bar {
        background: #aaa;
        height: 5px;
        overflow: hidden;
        width: 100%;
    }

    & ${VisitsFlag} {
        position: absolute;
        top: -2em;
        margin-left: -0.25em;
        
        &:nth-child(1) { left: 25%; }
        &:nth-child(2) { left: 50%; }
        &:nth-child(3) { left: 100%; }
    }
`;
const BarCodeImage = styled.div`
    height: 3rem;
    overflow: hidden;

    & > svg {
        width: 100%;
        padding: 0 1rem;
        height: auto;
    }
`;
const CardNumber = styled.p`
    color: #000;
    font-family: monospace;
    margin-bottom: 1em;
    padding: 0.25em 1rem 1em;
    text-align: center;
`;

class UserIeCard extends React.Component {
    constructor(props) {
        super(props);
        
        this.onBenefitsClick = this.onBenefitsClick.bind(this);
        this.state = {
            benefitsVisible: false
        };
    }

    onBenefitsClick() {
        this.setState({benefitsVisible: !this.state.benefitsVisible});
    }

    componentDidMount() {
        if (this.props.user) this.props.fetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && !prevProps.user) this.props.fetch();
    }

    render() {
        if (!this.props.user) return <Error404Page/>;
        if (this.props.isProcessing) return '';

        const {data} = this.props;

        if (!data) return '';

        if (data.error) return <ErrorPage message="Ocurrió un error obtener la información. Por favor, inténtalo nuevamente."/>;

        return <Container>
            <div>
                <IeCardFront data={data} flip={false} />
                <BarCodeImage dangerouslySetInnerHTML={{__html:data.barcode_image}}/>
                <CardNumber>{data.card_number.split('').join(' ')}</CardNumber>
            </div>
            <ProgressBar>
                <VisitsFlag level="basic"><i class="fa-solid fa-flag-swallowtail"></i><span className="small">{data.basic_required_visits}</span></VisitsFlag>
                <VisitsFlag level="gold"><i class="fa-solid fa-flag-swallowtail"></i><span className="small">{data.gold_required_visits}</span></VisitsFlag>
                <VisitsFlag level="premium"><i class="fa-solid fa-flag-swallowtail"></i><span className="small">{data.premium_required_visits}</span></VisitsFlag>
                <div className="progress-bar"><div className="progress" style={{width: `${data.visits * 100 / data.premium_required_visits}%`, background: data.color}}></div></div>
            </ProgressBar>
            <div className="d-flex justify-content-between">
                <Subtitle>Visitas acumuladas</Subtitle>
                <VisitsCount color={data.color}>
                    <i class="fa-light fa-clapperboard"></i> {data.visits}
                </VisitsCount>
            </div>
            <RequiredVisits>
                <li><VisitsFlag level="basic"><i class="fa-solid fa-flag-swallowtail"></i></VisitsFlag> {data.basic_required_visits} visitas necesarias para subir a Nivel Básico</li>
                <li><VisitsFlag level="gold"><i class="fa-solid fa-flag-swallowtail"></i></VisitsFlag> {data.gold_required_visits} visitas necesarias para subir a Nivel Oro</li>
                <li><VisitsFlag level="premium"><i class="fa-solid fa-flag-swallowtail"></i></VisitsFlag> {data.premium_required_visits} visitas necesarias para subir a Nivel Premium</li>
            </RequiredVisits>
            {data.message ? <p>{data.message}</p> : ''}
            <IePointsBlock points={data.points}/>
            {data.benefits.length ? <React.Fragment>
                <BenefitsButton onClick={this.onBenefitsClick}>
                    <i class="fa-light fa-crown"></i>
                    <Subtitle>Beneficios</Subtitle>
                    <p className="small">Da click para ver el listado</p>
                </BenefitsButton>
                <BenefitsContainer visible={this.state.benefitsVisible}>
                    <CloseCross onClick={this.onBenefitsClick}><i class="fal fa-times"></i></CloseCross>
                    <header>
                        <i class="fa-light fa-crown"></i>
                        <p>Estos son los beneficios con los que cuentas por ser <strong>Invitado Especial</strong>.</p>
                    </header>
                    <BenefitsList>
                        {data.benefits.map(benefit => <BenefitItem color={data.color} level={data.level} onClick={() => this.props.dialogOpen(benefit.description)}><img src={benefit.thumb} alt={benefit.title} /></BenefitItem>)}
                    </BenefitsList>
                </BenefitsContainer>
            </React.Fragment> : ''}
        </Container>;
    }
}

const mapStateToProps = state => ({
    data: getIeCardData(state),
    isProcessing: isUserStuffProcessing(state),
    user: isUserLogged(state)
});

const mapDispatchToProps = dispatch => ({
    dialogOpen: message => dispatch(dialogAlertEasyClose(message)),
    fetch: () => dispatch(userIeCardFetch())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(props => <FullColumnLayout Content={UserIeCard} {...props} bgWhite headerTransparent footerContent={false} />);