import React from 'react';
import NavBarLayout from './NavBarLayout';
import CorpSalesForm from './CorpSalesForm';
import SpecialEventsForm from './SpecialEventsForm';

const routes = [
  {
    title: 'Ventas corporativas',
    slug: 'ventas-corporativas---principal',
    path: '/ventas-corporativas',
  },
  {
    title: 'Preguntas frecuentes',
    slug: 'ventas-corporativas---preguntas-frecuentes',
    path: '/ventas-corporativas/faq',

  },
  {
    title: 'Informes y contacto',
    component: CorpSalesForm,
    path: '/ventas-corporativas/informes',
  }
];

export default props => <NavBarLayout routes={routes}/>