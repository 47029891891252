export const isAmex = cardNumber => /^(34|37)/.test(cardNumber);
export const isVisa = cardNumber => /^4/.test(cardNumber);
export const isMaster = cardNumber => {
  let first2, first4, numberLength;

  cardNumber += ''; // Make sure is a string.
  numberLength = cardNumber.length;

  if (numberLength >= 2) {
    first2 = parseInt(cardNumber.substr(0, 2), 10);
    if (first2 >= 51 && first2 <= 55) {
      return true;
    }
  }

  if (numberLength >= 4) {
    first4 = parseInt(cardNumber.substr(0, 4), 10);
    if (first4 >= 2221 && first4 <= 2720) {
      return true;
    }
  }

  return false;
};
export const isCarnet = cardNumber => {
  let numberLength, first4, first2;

  cardNumber += ''; // Make sure is a string.
  numberLength = cardNumber.length;

  if (numberLength >= 2) {
    first2 = parseInt(cardNumber.substr(0, 2), 10);
    if (first2 === 28 || first2 === 60 || first2 === 62 || first2 === 63) {
      return true;
    }
  }

  if (numberLength >= 4) {
    first4 = parseInt(cardNumber.substr(0, 4), 10);
    if (first4 >= 5062 && first4 < 5065) {
      return true;
    }
  }

  return false;
};

export const formatCc = v => {
  v = v.replace(/[^\d]/g, '');

  return formatCcModules(v);
};

export const formatCcModules = v => {
  const result = [];
  let mods;

  if (!v) {
    return '';
  }

  if (isAmex(v)) {
    mods = [4,6,5];
  } else {
    mods = [4,4,4,4];
  }

  let s = 0;
  for (let i = 0; i < mods.length; i++) {
    let w = mods[i];
    if (s >= v.length) {
      break;
    }
    result.push(v.substr(s,w));
    s += w;
  }

  return result.join(' ');
}

export const formatCvv = v => v.replace(/[^\d]/g, '').substr(0, 4);

export const formatExpireDate = v => {
  v = v.replace(/[^\d]/g, '');
  let result = v.substr(0, 2);
  
  if (result.length >= 2) {
    result += '/' + v.substr(2, 2);
  }

  return result;
}

export const getBrand = cardNumber => {
  const fns = {
    'visa': isVisa,
    'master': isMaster,
    'amex': isAmex,
    'carnet': isCarnet
  };
  const keys = Object.keys(fns);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (fns[key](cardNumber)) {
      return key;
    }
  }

  return '';
};