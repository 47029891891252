import * as actions from '../../actions/types';
import {combineReducers} from 'redux';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_IS_LOADING:
      return action.isLoading;
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_ERROR:
      return false;
    default:
      return state;
  }
};

const loadError = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_IS_LOADING:
      return false;
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS:
      return false;
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_ERROR:
      return true;
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS:
      return [].concat(action.data.messages);
    case actions.CHECKOUT_TICKET_QTY_INCREASE:
    case actions.CHECKOUT_TICKET_QTY_DECREASE:
    case actions.CHECKOUT_CANDYBAR_ITEM_ADD:
    case actions.CHECKOUT_CANDYBAR_ITEM_REMOVE:
    case actions.CHECKOUT_CANDYBAR_OPTION_SELECT:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return [];
    default:
      return state;
  }
};

const nonRedeemableTickets = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS:
      // return ('tickets' in action.data.non_redeemable_items) ? [].concat(action.data.non_redeemable_items.tickets) : [];
    case actions.CHECKOUT_TICKET_QTY_INCREASE:
    case actions.CHECKOUT_TICKET_QTY_DECREASE:
    case actions.CHECKOUT_CANDYBAR_ITEM_ADD:
    case actions.CHECKOUT_CANDYBAR_ITEM_REMOVE:
    case actions.CHECKOUT_CANDYBAR_OPTION_SELECT:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return [];
    default:
      return state;
  }
};

const options = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS:
      return Object.assign({}, action.data.payment_options);
    case actions.CHECKOUT_TICKET_QTY_INCREASE:
    case actions.CHECKOUT_TICKET_QTY_DECREASE:
    case actions.CHECKOUT_CANDYBAR_ITEM_ADD:
    case actions.CHECKOUT_CANDYBAR_ITEM_REMOVE:
    case actions.CHECKOUT_CANDYBAR_OPTION_SELECT:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return {};
    default:
      return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS:
      return Object.keys(action.data.payment_options)[0];
    case actions.CHECKOUT_PAYMENT_SUGGESTIONS_SELECT:
      return action.optionId;
    case actions.CHECKOUT_TICKET_QTY_INCREASE:
    case actions.CHECKOUT_TICKET_QTY_DECREASE:
    case actions.CHECKOUT_CANDYBAR_ITEM_ADD:
    case actions.CHECKOUT_CANDYBAR_ITEM_REMOVE:
    case actions.CHECKOUT_CANDYBAR_OPTION_SELECT:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  loadError,
  messages,
  nonRedeemableTickets,
  options,
  selected,
});