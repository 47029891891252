import React from 'react';
import {connect} from 'react-redux';
import {goToMasterpass} from '../../actions/creators/checkout/masterpass';

class MasterpassButton extends React.PureComponent {
  render() {
    return (
        <div className="p-5">
          <div className="mt-5 text-center">
            <h3 className="h4 mb-5">Haz click en el botón para comenzar el pago con Masterpass</h3>
            <img src="https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg" onClick={this.props.goToMasterpass} alt="Botón Masterpass"/>
            <br/>
            <a href="https://www.mastercard.com/mc_us/wallet/learnmore/es/MX/" className="learn-more" target="_blank" rel="noreferrer noopener">Aprende más</a>
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  goToMasterpass: () => dispatch(goToMasterpass()),
});

export default connect(
    undefined,
    mapDispatchToProps
)(MasterpassButton);