import {combineReducers} from 'redux';
import * as actions from '../actions/types';

const codes = (state = null, action) => {
    switch (action.type) {
        case actions.REFUND_REQUEST_CHOOSE_SUBMIT_SUCCESS:
            return action.data.codes || null;
        default:
            return state;
    }
};

const isProcessing = (state = false, action) => {
    switch (action.type) {
        case actions.REFUND_REQUEST_VALIDATE_SUBMIT:
        case actions.REFUND_REQUEST_CHOOSE_SUBMIT:
        case actions.REFUND_REQUEST_COMPLETE_SUBMIT:
            return true;
        case actions.REFUND_REQUEST_VALIDATE_SUBMIT_SUCCESS:
        case actions.REFUND_REQUEST_VALIDATE_SUBMIT_ERROR:
        case actions.REFUND_REQUEST_CHOOSE_SUBMIT_SUCCESS:
        case actions.REFUND_REQUEST_CHOOSE_SUBMIT_ERROR:
        case actions.REFUND_REQUEST_VALIDATE_ALREADY_PROCESSED:
        case actions.REFUND_REQUEST_COMPLETE_SUBMIT_SUCCESS:
        case actions.REFUND_REQUEST_COMPLETE_SUBMIT_ERROR:
            return false;
        default:
            return state;
    }
};

const processedData = (state = null, action) => {
    switch (action.type) {
        case actions.REFUND_REQUEST_VALIDATE_SUBMIT_SUCCESS:
        case actions.REFUND_REQUEST_VALIDATE_ALREADY_PROCESSED:
            return action.data;
        default:
            return state;
    }
}

const step = (state = 'FIRST_QUESTION', action) => {
    switch (action.type) {
        case actions.REFUND_REQUEST_BEGIN:
            return 'ENTER_DATA';
        case actions.REFUND_REQUEST_VALIDATE_SUBMIT_SUCCESS:
            return 'LIST_TRANSACTIONS';
        case actions.REFUND_REQUEST_SELECT_TRANSACTION:
            return 'MORE_QUESTIONS';
        case actions.REFUND_REQUEST_QUESTIONS_COMPLETE:
            return 'CHOOSE_OPTION'
        case actions.REFUND_REQUEST_CHOOSE_SUBMIT_SUCCESS:
            return 'SHOW_RESULT';
        case actions.REFUND_REQUEST_VALIDATE_ALREADY_PROCESSED:
            return 'ALREADY_PROCESSED';
        case actions.REFUND_REQUEST_COMPLETE_SUBMIT_SUCCESS:
            return 'SHOW_MESSAGE';
        default:
            return state;
    }
};

const transaction = (state = null, action) => {
    switch (action.type) {
        case actions.REFUND_REQUEST_SELECT_TRANSACTION:
            return action.transaction;
        default:
            return state;
    }
}

export default combineReducers({
    codes,
    isProcessing,
    processedData,
    step,
    transaction,
});