import React from "react";
import styled from "styled-components";
import LinkButton from "../LinkButton";
import IeSignUpOptions from "../user/IeSignUpOptions";
import { breakpoints } from "../../utils/styles";

const Container = styled.div`
    background: white;
    bottom: ${props => props.visible ? 0 : '-100%'};
    height: 98%;
    position: fixed;
    right: 0;
    width: 100%;
    overflow-y: auto;
    transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out;

    @media screen and (min-width: ${breakpoints.tablet}px) {
        height: 100%;
        right: ${props => props.visible ? 0 : '-66.67%'};
        top: 0;
        width: 66.66%;
    }
`;

function SignUpManualIEOptions(props) {
    return <Container className="px-5 py-4" visible={props.visible}>
        <h3 className="h3 mb-5">
            <LinkButton className="text-muted mr-3" onClick={props.close}><i className="fal fa-arrow-left fa-xs"></i></LinkButton>
            Selecciona tu beneficio de bienvenida de Invitado Especial
        </h3>
        <IeSignUpOptions levels={props.options} onLevelSelect={props.onIEOptionSelect} hidePriceZero altStyles />
    </Container>;
}

export default SignUpManualIEOptions;