import React from 'react';

class SeatsLabels extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.labels !== this.props.labels;
    }

    componentDidUpdate() {
        const el = this.ref.current;
        el.classList.remove('fade-in')
        setTimeout(() => el.classList.add('fade-in'), 10);
    }

    render() {
        return <p ref={this.ref} className="mb-0">{this.props.labels}</p>
    }
}

export default SeatsLabels;