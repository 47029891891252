import React from 'react';
import MDSpinner from "react-md-spinner";
import UserCinemaNav from './UserCinemaNav';

class PromosPageSidebar extends React.Component {
  render() {
    let promo = this.props.selectedPromo;

    return (
        <React.Fragment>
          <UserCinemaNav/>
          {promo ? (
              <div className="p-5 fade-in" key={promo.id}>
                <h2 className="mb-3">{promo.title}</h2>
                {promo.subtitle && <h4 className="my-3">{promo.subtitle}</h4>}
                {promo.content && <p dangerouslySetInnerHTML={{__html: promo.content}} />}
                {promo.image_web_v2 && <p>{promo.url ? (
                    <a href={promo.url}><img src={promo.image_web_v2} className="img-fluid rounded" alt=""/></a>
                ) : (
                    <img src={promo.image_web_v2} className="img-fluid rounded" alt=""/>
                )}</p>}
              </div>
          ) : (
              <div className="p-5 text-center"><MDSpinner singleColor="var(--primary)"/></div>
          )}
        </React.Fragment>
    );
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.selectedPromo && !nextProps.selectedPromo)
        || (!this.props.selectedPromo && nextProps.selectedPromo)
        || (this.props.selectedPromo && nextProps.selectedPromo && this.props.selectedPromo.id !== nextProps.selectedPromo.id);
  }
}

export default PromosPageSidebar;