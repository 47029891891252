import React from 'react';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import Footer from "./Footer";
import Header from './Header';
import * as vars from '../utils/styles';

const Sidebar = styled.div`
  z-index: 1031;
  ${props => props.fixed && `
      @media (min-width: ${vars.breakpoints.desktop}px) {
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          position: fixed;
          right: 0;
          top: 0;
        }
  `}
`;

class TwoColumnLayout extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
    this.renderSidebarContent = this.renderSidebarContent.bind(this);

    this.state = {
      sidebarOpen: false,
    };
  }

  toggle(e) {
    e.preventDefault();

    this.setState(state => ({sidebarOpen: !state.sidebarOpen}));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sidebarOpen !== this.props.sidebarOpen && this.props.sidebarOpen) {
      this.toggle(new Event('click'));
    }
  }

  render() {
    const {props} = this;

    return (
        <React.Fragment>
          <div id="the-body" className={'l-wrapper' + (props.sidebarMobileToggle ? ' l-wrapper--toggle-mobile' : '') + (this.state.sidebarOpen ? '  is-active' : '')}>
            <div className="l-wrapper__content top-nav-pad">
              <Header/>

              <div className="container">
                <div className="row mt-4">
                  <div className="col-12">
                    <props.MainContent {...props}/>
                  </div>
                </div>
              </div>
            </div>
            {props.SidebarContent && (
                <Sidebar fixed={props.fixedSidebar} className="l-wrapper__sidebar" id="the-sidebar">
                  {props.sidebarMobileToggle && <a className="l-wrapper__sidebar-toggle" onClick={this.toggle}>
                    <i className="fal fa-angle-left"></i>
                  </a>}
                  {this.renderSidebar()}
                </Sidebar>
            )}
          </div>
          <Footer/>
        </React.Fragment>
    );
  }

  renderSidebar() {
    if (this.props.stickySidebar === false || this.props.fixedSidebar) {
      return this.renderSidebarContent();
    }

    return (
        <Sticky bottomBoundary="#the-sidebar" enableTransforms={false}>
          {this.renderSidebarContent()}
        </Sticky>
    );
  }

  renderSidebarContent() {
    return (
        <div className="l-wrapper__sidebar-content">
          <this.props.SidebarContent {...this.props}/>
        </div>
    );
  }
}

export default TwoColumnLayout;