import { ieSignUpError, ieSignUpSuccess } from '../actions/creators';
import { dialogClose, dialogOpen } from '../actions/creators/dialogs';
import { fetchIeSignUpOptionsError, fetchIeSignUpOptionsSuccess, getIePaymentUrlSuccess, ieCodeValidateFailure, ieCodeValidateSuccess, nipCodeUpdateFailure, nipCodeUpdateSuccess } from '../actions/creators/ie';
import { updateInfo } from '../actions/creators/user';
import * as actions from '../actions/types';
import CMX from '../api';
import { getIeCodeValidateCardNumber, getIeNewNip, getUserInfo } from '../reducers';
import { onApiError } from './misc';
import cookies from 'browser-cookies';

export const ieMiddleware = ({ getState, dispatch }) => next => async action => {
    pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function pre(action, getState, dispatch) {
    switch (action.type) {
        case actions.IE_CODE_VALIDATE_SUCCESS:
            return onIeCodeValidateSuccess(action, getState, dispatch);
    }
}

function post(action, getState, dispatch) {
    switch (action.type) {
        case actions.IE_CODE_VALIDATE_SUBMIT:
            return ieValidatePost(action, getState, dispatch);
        case actions.IE_NIP_UPDATE_SUBMIT:
            return ieNipUpdatePost(action, getState, dispatch);
        case actions.IE_CODE_VALIDATE_FAILURE:
            return onApiError(dispatch, Object.assign({context: 'ie_link'}, action));
        case actions.IE_NIP_UPDATE_FAILURE:
            return onApiError(dispatch, Object.assign({context: 'ie_nip_update'}, action));
        case actions.IE_SIGN_UP_SUCCESS:
            return dispatch(updateInfo({}));
        case actions.IE_SIGN_UP_FORM_SHOW:
            return fetchIeSignUpOptions(action, getState, dispatch);
        case actions.IE_SIGN_UP_FORM_SUBMIT:
            return onIeSignUpFormSubmit(action, getState, dispatch);
        case actions.IE_SIGN_UP_ERROR:
        case actions.IE_FETCH_SIGN_UP_OPTIONS_ERROR:
            return onApiError(dispatch, action);
        case actions.AUTH_LOGIN_POST_SUCCESS:
        case actions.USER_INFO_FETCH_SUCCESS:
        case actions.USER_UPDATE_INFO_SUCCESS:
        case actions.USER_PRE_CHECKOUT_FETCH_SUCCESS:
            return onUserInfoUpdated(action, getState, dispatch);
    }
}

function onIeCodeValidateSuccess(action, getState, dispatch) {
    if (action.status !== 'completed') return;
    
    action.type = actions.IE_CODE_SUBMIT_SUCCESS;
    action.memberId = action.member_id;
    action.emailSent = action.email_sent;
}

function ieValidatePost({auth_pin, recaptcha_code}, getState, dispatch) {
    const state = getState();
    const args = {
        iecode: getIeCodeValidateCardNumber(state),
        auth_pin,
        recaptcha_code
    };

    CMX.post('/me/iecode/validate', args)
      .then(data => dispatch(ieCodeValidateSuccess(data)))
      .catch(error => dispatch(ieCodeValidateFailure(error)))
}

function ieNipUpdatePost({code, auth_pin, recaptcha_code}, getState, dispatch) {
    const state = getState();
    const args = {
        newNip: getIeNewNip(state),
        auth_pin,
        recaptcha_code
    };

    CMX.post('/me/ie/nip/update', args)
        .then(data => dispatch(nipCodeUpdateSuccess()))
        .catch(error => dispatch(nipCodeUpdateFailure(error)))
}

function fetchIeSignUpOptions({action}, getState, dispatch) {
    let url;
    switch (action) {
        case 'ie-sign-up': url = '/me/ie/getSignUpOptions'; break;
        case 'ie-migration': url = '/me/ie/getMigrationOptions'; break;
        case 'ie-renewal': url = '/me/ie/getRenewalOptions'; break;
    }

    CMX.post(url, {})
        .then(data => {
            dispatch(fetchIeSignUpOptionsSuccess(data));
            if (('link_completed' in data) && data.link_completed === true) {
                dispatch(ieSignUpSuccess({action, already_registered:true}));
            }
        })
        .catch(error => dispatch(fetchIeSignUpOptionsError(error)))
}

function onIeSignUpFormSubmit({data, action}, getState, dispatch) {
    const {level} = data;
    const args = {
        type: action,
        amount: level.price,
        items: [
            {
                id: level.id,
                data: data.info
            }
        ]
    };

    CMX.post('/ie/payment/getPaymentUrl', args)
        .then(data => {
            dispatch(getIePaymentUrlSuccess(data));
            if (data.action === 'complete') {
                dispatch(ieSignUpSuccess(Object.assign({}, data, {action, level: level.id})));
            }
         })
        .catch(error => dispatch(ieSignUpError(error)));
}

function onUserInfoUpdated(action, getState, dispatch) {
    const state = getState();
    const info = getUserInfo(state);

    if (info.migration_available) {
        const cookieId = 'ie_migration_dialog_shown';
        const shown = cookies.get(cookieId);
        const now = (new Date()).getTime();
        const in10minutes = new Date(now + 10 * 60 * 1000);

        if (shown) return;

        cookies.set(cookieId, '1', {expires: in10minutes, secure: true});

        new Promise((resolve, reject) => {
            dispatch(dialogOpen({
                message: `Cambia tu tarjeta y sigue disfrutando los beneficios de ser Invitado Especial Cinemex.`,
                buttons: [
                {
                    label: 'Más tarde',
                    onClick: (dialog) => {
                    dispatch(dialogClose(dialog.id));
                    reject();
                    }
                },
                {
                    label: 'Continuar',
                    primary: true,
                    onClick: (dialog) => {
                    dispatch(dialogClose(dialog.id));
                    resolve();
                    }
                }
                ]
            }))
        }).then(() => document.location = '/usuario/invitado-especial/migracion');

        return;
    }

    if (info.renewal_available) {
        const cookieId = 'ie_renewal_dialog_shown';
        const shown = cookies.get(cookieId);
        const now = (new Date()).getTime();
        const in10minutes = new Date(now + 10 * 60 * 1000);

        if (shown) return;

        cookies.set(cookieId, '1', {expires: in10minutes, secure: true});

        new Promise((resolve, reject) => {
            dispatch(dialogOpen({
                message: `¡Es momento de realizar el Cambio de Nivel de tu cuenta de Invitado Especial!`,
                buttons: [
                {
                    label: 'Más tarde',
                    onClick: (dialog) => {
                    dispatch(dialogClose(dialog.id));
                    reject();
                    }
                },
                {
                    label: 'Continuar',
                    primary: true,
                    onClick: (dialog) => {
                    dispatch(dialogClose(dialog.id));
                    resolve();
                    }
                }
                ]
            }))
        }).then(() => document.location = '/usuario/invitado-especial/cambio-de-nivel');

        return;
    }
}