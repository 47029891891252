import React from 'react';
import styled from 'styled-components';

const Panel = styled.div`
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1060;
    width: 100%;
    height: 100%;
    transition: all 1s;
    ${props => props.isMounted ? 'left: 0;' : ''}
`;

class ModalPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMounted: false };
    }

    componentDidMount() {
        this.setState({isMounted: true});
    }

    render() {
        return <Panel className={this.props.className} isMounted={this.state.isMounted}>{this.props.children}</Panel>;
    }
}

export default ModalPanel;