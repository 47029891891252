import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getLandingSelectedArea, getSelectedArea } from '../../reducers';
import LandingShowtimesMovieContent from './LandingShowtimesMovieContent';
import { landingShowtimesFetch } from '../../actions/creators';
import { areaSelectorOpen } from '../../actions/creators/ui';

// @todo Implement this
const date = '2024-04-22';

const MovieBoxContent = styled.div`
  // padding: 2em;
`;

const MovieTitle = styled.p`
  cursor: pointer;
  margin: 0;
  padding: 0.75em 2em;
  font-weight: 500;
  transition: all 0.25s ease-out;

  ${props => props.current
    ? 'background: #f8f8f8;'
    : `&:hover {
      color: #fff;
      background: var(--primary);
    }`}

  & > i { margin-left: 0.75em; }
`;

const MovieBox = styled.div`
  background: white;
  border: 1px solid #ddd;
  margin-bottom: 1em;
  border-radius: 10px;
  overflow: hidden;

  & ${MovieBoxContent} {
    ${props => !props.open ? 'display: none;' : ''}
  }
`;

class LandingContentShowtimes extends React.Component {
  constructor(props) {
    super(props);

    this.onMovieClick = this.onMovieClick.bind(this);

    this.state = {
      movieId: null
    };
  }

  componentDidMount() {
    if ((!this.props.area || !this.props.area.entity) && this.props.openAreaSelector) {
      return this.props.openAreaSelector();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.movieId || !this.props.area || !this.props.area.entity) return;
    
    const areaChanged = (!prevProps.area && this.props.area) || (prevProps.area.type !== this.props.area.type) || (prevProps.area.entity.id !== this.props.area.entity.id);
    const movieChanged = prevState.movieId !== this.state.movieId;
    if (areaChanged || movieChanged) {
      this.props.fetch(this.props.id, this.props.area, this.state.movieId);
    }
  }

  onMovieClick(movieId) {
    this.setState({movieId: movieId === this.state.movieId ? null : movieId});
  }
  
  render() {
    const {content, data} = this.props;
    let {movies} = content;
    let {path} = this.props;
    
    if (typeof path !== 'string') {
      path = path[1];
    }
    movies = movies.map(movie => ({
        ...movie,
        url: movie.url.replace(/.*\/pelicula\//, path + '/pelicula/')
    }));

    return (
        <React.Fragment>
          {content.html && <div dangerouslySetInnerHTML={{__html: content.html}} />}
          <div>
            {Object.values(movies).map((movie, i) => {
              const current = movie.id === this.state.movieId;
              return <MovieBox key={movie.id} open={current}>
                <MovieTitle current={current} onClick={() => this.onMovieClick(movie.id)}>{movie.name} <i class={'fa-light fa-circle-' + (current ? 'minus' : 'plus')}></i></MovieTitle>
                <MovieBoxContent>
                  {current ? <LandingShowtimesMovieContent pageId={this.props.id} movie={movie} area={this.props.area} openAreaSelector={this.props.openAreaSelector}/> : ''}
                </MovieBoxContent>
              </MovieBox>;
            })}
          </div>
        </React.Fragment>
    );
  }

};

const mapStateToProps = (state, props) => {
  let area = getLandingSelectedArea(state);
  if (!area) {
   area = { type: 'area', entity: getSelectedArea(state) };
  }
  if (!area.entity) area = null;

  return {
    area
  }
};

const mapDispatchToProps = dispatch => ({
  fetch: (pageId, area, movieId, date) => dispatch(landingShowtimesFetch(pageId, area, movieId, date)),
  openAreaSelector: () => dispatch(areaSelectorOpen({ context: 'landing' }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingContentShowtimes);