import React from 'react';
import styled from 'styled-components';
import {formatCcModules} from '../../utils/creditCards';
import WalletCardContainer from './WalletCardContainer';
import cardLogoAmex from '../../assets/svg/card-logo-amex.svg';
import cardLogoVisa from '../../assets/svg/card-logo-visa.svg';
import cardLogoMaster from '../../assets/svg/card-logo-mastercard.svg';

const DeleteButton = styled.button`
    border: 0;
    cursor: pointer;
    outline: none;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 1em;
    font-size: 1rem;
    position: absolute;
    right: 1em;
    top: 1em;
    display: none;

    &:focus { outline: none; }
`;
const Card = styled(WalletCardContainer)`
    ${props => {
        switch (props.brand) {
            case 'visa':
                return `background: rgb(119,89,41);
                    background: linear-gradient(0deg, rgba(119,89,41,1) 0%, rgba(157,136,77,1) 100%);`;
            case 'amex':
                return `background: rgb(1,76,128);
                    background: linear-gradient(0deg, rgba(1,76,128,1) 0%, rgba(40,165,208,1) 100%);`;
            case 'mastercard':
            case 'master':
                return `background: rgb(123,23,46);
                    background: linear-gradient(0deg, rgba(123,23,46,1) 0%, rgba(181,58,63,1) 100%);`;
            default:
                return `background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(129,129,129,1) 100%);`;
        }
    }}
    color: white;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.68rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    ${props => props.focused === 'csc' && `transform: rotateY(180deg);`}

    &:hover > ${DeleteButton} {
        display: block;
    }
`;
const Content = styled.div`
    background: rgba(255,255,255,0);
    background: linear-gradient(60deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 45%, rgba(255,255,255,0.07) 46%, rgba(255,255,255,0) 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    & > * {
        position: absolute;
    }
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;

    ${props => props.back && `
        overflow:hidden;
        z-index:-1;
        display: block;
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        box-sizing: border-box;
        &:before{
          content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -ms-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            transform: rotateY(180deg);
        }`}

    ${props => props.front && `
        padding: 8%;
        transform: rotateY(0deg);
    `}

    transition: all .5s linear;
`;
const Logo = styled.img`
    width: 17%;
`;

const getCardLogo = brand => {
    switch(brand) {
        case 'visa': return cardLogoVisa;
        case 'amex': return cardLogoAmex;
        case 'master':
        case 'mastercard': return cardLogoMaster;
        default: return null;
    }
}

const Svg = styled.svg`
    width: 100%;left: 0;top: 0;
`;

class WalletCard extends React.Component {

    render() {
        return <Card {...this.props}>
            <Content front>
                <Svg viewBox="0 0 100 63">
                    <text x="8" y="42" fill="#ffffff" font-size="0.65em">{formatCcModules(this.props.number)}</text>
                    <text x="8" y="54" fill="#ffffff" font-size="0.42em">{this.props.cardHolderName}</text>
                    <text x="80" y="54" fill="#ffffff" font-size="0.4em">{this.props.expMonth || '--'}/{this.props.expYear.toString().substr(2,2) || '--'}</text>
                </Svg>
                <Logo src={getCardLogo(this.props.brand)} brand={this.props.brand} alt=""/>
            </Content>
            <Content back>
                <Svg viewBox="0 0 100 63">
                    <rect x="0" width="100" y="11" height="12" style={{fill:'rgba(255,255,255,0.35)'}}></rect>
                    <text x="40" y="18" fill="#ffffff" font-size="0.25em">Código de seguridad</text>
                    <text x="79" y="19" fill="#ffffff" font-size="0.5em">{this.props.csc || '000'}</text>
                </Svg>
            </Content>
            <DeleteButton onClick={this.props.onDeleteClick}><i className="fa fa-trash-alt"></i></DeleteButton>
        </Card>
    }
    
}

export default WalletCard;