import {checkPassStrength} from './passwords';
import moment from 'moment';

export const validateCc = ccNumber => {
  const regexs = {
    visa: /^4\d{3}[\s-]?\d{4}[\s-]?\d{4}[\s-]?\d{4}$/, // Visa: length 16, prefix 4, dashes optional.
    mc: /^5[1-5]\d{2}[\s-]?\d{4}[\s-]?\d{4}[\s-]?\d{4}$/,// Mastercard: length 16, prefix 51-55, dashes optional.
    disc: /^6011[\s-]?\d{4}[\s-]?\d{4}[\s-]?\d{4}$/, // Discover: length 16, prefix 6011, dashes optional.
    amex: /^3[4,7]\d{2}[\s-]?\d{6}[\s-]?\d{5}$/, // American Express: length 15, prefix 34 or 37.
    diners: /^3[0,6,8]\d{12}$/, // Diners: length 14, prefix 30, 36, or 38.
    carnet1: /^506[2,3,4][\s-]?\d{4}[\s-]?\d{4}[\s-]?\d{4}$/, // Carnet: length 16
    carnet2: /^28[\s-]?\d{14}$/, // Carnet: length 16
    carnet3: /^6[0,2,3][\s-]?\d{14}$/ // Carnet: length 16
  };

  let regex_passed = false;

  for (let i in regexs) {
    if (regexs[i].test(ccNumber)) {
      regex_passed = true;
      break;
    }
  }
  if (!regex_passed)
    return false;

  // Remove spaces and dashes for the checksum checks to eliminate negative numbers
  ccNumber = ccNumber.replace(/\s|-/g, '');
  // Checksum ("Mod 10")
  // Add even digits in even length strings or odd digits in odd length strings.
  let checksum = 0;
  for (let i = (2 - (ccNumber.length % 2)); i <= ccNumber.length; i += 2) {
    checksum += parseInt(ccNumber.charAt(i - 1), 10);
  }
  // Analyze odd digits in even length strings or even digits in odd length strings.
  for (let i = (ccNumber.length % 2) + 1; i < ccNumber.length; i += 2) {
    let digit = parseInt(ccNumber.charAt(i - 1), 10) * 2;
    if (digit < 10) {
      checksum += digit;
    } else {
      checksum += (digit - 9);
    }
  }

  return ((checksum % 10) === 0);
};

export const validateFloat = v => /^[0-9]+\.[0-9]{2}$/.test(v);

export const validateMovieCard = ccNumber => /^3084[6-7][0-9]{11}$/.test(String(ccNumber.replace(/[^\d]/g, '')));

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const validateCvv = cvv => {
  const re = /^[0-9]{3,4}$/;

  return re.test(String(cvv));
};

export const validateIeNip = nip => {
  const re = /^[0-9]{4}$/;

  return re.test(String(nip));
};

export const validateMovieCardNip = validateCvv;

export const validateNotEmpty = value => value && value.length > 0;

export const validateTrue = value => value !== false;

export const validateExpiry = date => {
  date = date.split('/');

  if (date.length !== 2) {
    return false;

  }
  const month = parseInt(date[0], 10);
  const year = parseInt('20' + date[1], 10);
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  if (month > 12 || month < 1) {
    return false;
  } else if (year > currentYear + 20) {
    return false;
  } else if (year > currentYear) {
    return true;
  } else if (year === currentYear && month >= currentMonth) {
    return true;
  } else {
    return false;
  }
};

export const validateIeCode = code => /^308[0-9]{13}$/.test(code);

export const validateIeBarCode = code => /^2[0-9]{12}$/.test(code);

export const validateFutureDate = date => {
  const m = date.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/);
  const now = new Date();
  if (!m) { return false; }
  if (parseInt(m[1], 10) > 31) { return false; }
  if (parseInt(m[2], 10) > 12) { return false; }
  if (parseInt(m[3], 10) < now.getFullYear()) { return false; }
  if (parseInt(m[3], 10) < 1) { return false; }

  return true;
};

export const validateBirthDate = date => {
  const m = date.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/);
  const now = new Date();
  if (!m) { return false; }
  if (parseInt(m[1], 10) > 31) { return false; }
  if (parseInt(m[2], 10) > 12) { return false; }
  if (parseInt(m[3], 10) > now.getFullYear()) { return false; }
  if (parseInt(m[3], 10) < 1900) { return false; }

  return true;
};

export const isAtLeast18 = birthDate => {
  const birthMoment = moment(birthDate, 'DD/MM/YYYY');
  const age = moment().diff(birthMoment, 'years');

  return age >= 18;
};

export const validateHour = hour => {
  const h = hour.match(/^(\d{2}):(\d{2})$/);
  if (!h) { return false; }
  if (h[1] > 24) { return false; }
  if (h[2] > 59) { return false; }

  return true;
};

export const validatePassword = pass => checkPassStrength(pass) !== 'weak';

const validateNumberNDigits = (v, n) => !isNaN(v) && v.toString().length === n;

export const validateNumber = v => !!v.toString().match(/^\d+$/);
export const validateNumber3Digits = v => validateNumberNDigits(v, 3);
export const validateNumber4Digits = v => validateNumberNDigits(v, 4);
export const validateNumber5Digits = v => validateNumberNDigits(v, 5);
export const validateNumber6Digits = v => validateNumberNDigits(v, 6);
export const validateNumber10Digits = v => validateNumberNDigits(v, 10);
