export const extractUrlParams = (path, params) => ({
  areaId: params && extractUrlAreaId(params),
  areaType: params && params.areaType,
  date: extractUrlDate(path),
  format: extractUrlFormats(path),
  lang: extractUrlLanguages(path),
  hours: extractUrlHours(path),
  cinema: extractUrlCinema(path),
  movie: extractUrlMovie(path),
});

const extractUrlDate = path => {
  const match = path.match(/\bfecha-([0-9]{4})([0-9]{2})([0-9]{2})\b/);

  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  } else {
    return null;
  }
};

const extractUrlFormats = path => {
  const match = path.match(/\bformato-([a-z0-9,_]+)\b/);

  if (!match) {
    return [];
  } else {
    return match[1].split(',');
  }
};

const extractUrlLanguages = path => {
  const match = path.match(/\bidioma-([a-z,]+)\b/);

  if (!match) {
    return [];
  } else {
    return match[1].split(',').map(lang => 'lang_' + lang);
  }
};

const extractUrlHours = path => {
  const match = path.match(/\bhoras-(\d+)a(\d+)\b/);

  if (!match) {
    return hoursRange;
  } else {
    return {min: parseInt(match[1], 10), max: parseInt(match[2], 10)};
  }
};

const extractUrlCinema = path => extractUrlIds(path, 'cine');

const extractUrlMovie = path => extractUrlIds(path, 'pelicula');

const extractUrlIds = (path, base) => {
  const re = new RegExp('\\b' + base + '-([\\d,]+)\\b');
  const match = path.match(re);

  if (!match) {
    return [];
  } else {
    return match[1].split(',').map(id => parseInt(id, 10));
  }
};

const extractUrlAreaId = params => parseInt(params.area, 10);

export const hoursRange = {min: 9, max: 24};

