import * as actions from '../types';

export const dialogOpen = payload => ({
  type: actions.DIALOG_DISPLAY,
  payload
});

export const dialogClose = id => ({
  type: actions.DIALOG_CLOSE,
  id
});

export const dialogAlert = message => dispatch => dispatch(dialogOpen({
  message,
  buttons: [
    {
      label: 'Continuar',
      primary: true,
      onClick: (dialog) => {
        dispatch(dialogClose(dialog.id));
      }
    }
  ]
}));

export const dialogAlertEasyClose = message => dispatch => dispatch(dialogOpen({
  message,
  buttons: [
    {
      label: 'Continuar',
      primary: true,
      onClick: (dialog) => {
        dispatch(dialogClose(dialog.id));
      }
    }
  ],
  easyClose: true,
}));
