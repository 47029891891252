import { getSelectedAreaId, getSelectedCinemaId } from '../../reducers';
import * as actions from '../types';

export const cinemaSelectorOpen = () => ({
  type: actions.UI_CINEMA_SELECTOR_OPEN,
});


export const askLocationIfNotSelected = () => (dispatch, getState) => {
  const state = getState();
  const areaId = getSelectedAreaId(state);
  const cinemaId = getSelectedCinemaId(state);
  
  if (areaId && cinemaId) return;

  dispatch(cinemaSelectorOpen());
};

export const cinemaSelectorClose = () => ({
  type: actions.UI_CINEMA_SELECTOR_CLOSE,
});

export const areaSelectorOpen = (options) => ({
  type: actions.UI_AREA_SELECTOR_OPEN,
  options
});

export const areaSelectorClose = () => ({
  type: actions.UI_AREA_SELECTOR_CLOSE,
});

export const areaSelectorSelectArea = (areaId, args) => ({
  type: actions.UI_AREA_SELECTOR_SELECT_AREA,
  areaId,
  ...args
});

export const postRegisterPopupClose = args => ({
  type: actions.POST_REGISTER_POPUP_CLOSE,
  args
});

export const postPurchaseIePopupShow = data => ({
  type: actions.POST_PURCHASE_IE_POPUP_SHOW,
  data
});

export const customLoadingAnimationFetchSuccess = data => ({
  type: actions.UI_CUSTOM_LOADING_FETCH_SUCCESS,
  data
});
