import React from 'react';

let timer;

class SmartIframe extends React.Component {
    constructor(props) {
        super(props);

        this.onIframeMessage = this.onIframeMessage.bind(this);
        this.iframeRef = React.createRef();
    }

    componentDidMount() {
        this.attachIframeEventListener();
    }

    componentWillUnmount() {
        this.removeIframeEventListener();
    }

    attachIframeEventListener() {
        const win = this.iframeRef.current.contentWindow;
        timer = setInterval(function () {
            try {
                win.postMessage({ message: "requestCinemexSmartResult" }, "*");
            } catch (e) { }
        }, 1000);

        window.addEventListener("message", this.onIframeMessage);
    }

    removeIframeEventListener() {
        clearInterval(timer);
        window.removeEventListener("message", this.onIframeMessage);
    }

    onIframeMessage(e) {
        if (e.data.message === "deliverCinemexSmartResult") {
            this.removeIframeEventListener();
            this.props.onComplete(e.data.result);
        }
    }

    render() {
        return <iframe src={this.props.url} style={{ width: '100%', height: '1050px', border: 0 }} ref={this.iframeRef} />;
    }
}

export default SmartIframe;