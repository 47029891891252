import React from 'react';
import NavBarLayout from './NavBarLayout';
import IELevelsBenefitsPage from './IELevelsBenefitsPage';
import IERegistrationPage from './IERegistrationPage';
import IEPromosPage from './IEPromosPage';
import { connect } from 'react-redux';
import { getUserInfo } from '../reducers';
import { authShowLogin } from '../actions/creators';
import { hasIeAction } from '../utils/misc';

const urlPrefix = '/invitado-especial/';

class IEPage extends React.Component {
  render() {
    return <NavBarLayout fixedTitle="Invitado Especial" routes={this.getRoutes()}/>;
  }

  getRoutes() {
    const {user} = this.props;
    const routes = [
      {title: 'Nuestro Programa', path: urlPrefix + 'programa', slug: 'invitado-especial-programa'},
      {title: 'Niveles y Beneficios', path: urlPrefix + 'niveles-beneficios', component: IELevelsBenefitsPage},
      {title: 'Puntos', path: urlPrefix + 'puntos', slug: 'invitado-especial---puntos'},
      {title: 'Promociones', path: urlPrefix + 'promociones', component: IEPromosPage},
      {title: 'Preguntas Frecuentes', path: urlPrefix + 'preguntas-frecuentes', slug: 'invitado-especial---faq'},
      {title: 'Invitado Especial Uno', path: urlPrefix + 'invitado-especial-uno', slug: 'invitado-especial-faq-nivel-uno'},
      {title: 'MovieCard', path: urlPrefix + 'moviecard', slug: 'invitado-especial-moviecard', nav: false},
      {title: 'Términos y condiciones del programa Invitado Especial', path: urlPrefix + 'terminos', slug: 'invitado-especial-terminos', nav: false},
      {title: 'Términos y condiciones de MovieCard', path: urlPrefix + 'moviecard/terminos', slug: 'invitado-especial-moviecard-terminos', nav: false},
      {title: 'Cambio de Nivel 2024', path: urlPrefix + 'cambio-de-nivel-2024', slug: 'cambio-de-nivel-2024', nav: true},
    ];
    let signUpRoute = {title: 'Inscríbete aquí', path: urlPrefix + 'inscripcion', slug: 'inscripcion'};
  
    if (!user) {
      signUpRoute.path = '/login';
      signUpRoute.onClick = this.props.login;
    } else if (!user.iecode && hasIeAction(user, 'sign-up')) {
      signUpRoute.path = '/usuario/invitado-especial/inscripcion';
    } else {
      signUpRoute = null;
    }
  
    if (signUpRoute) {
      routes.splice(1, 0, signUpRoute);
    }
  
    return routes;
  }
}

const mapStateToProps = state => ({
  user: getUserInfo(state)  
});

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(authShowLogin())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IEPage);