import React from 'react';
import { trackAppStoreRedirect } from '../utils/analytics';
import LogoLoading from './LogoLoading';

const AppsRedirect = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    const device = getDeviceId(ua);
    const link = getStoreLink(device);
    
    trackAppStoreRedirect(device, () => {
        document.location = link;
    });
    
    return <LogoLoading/>;
};

const getDeviceId = ua => {
    if (ua.match(/ios|ipod|ipad|iphone/i)) return 'ios';
    if (ua.match(/android/i)) return 'android';
    return 'default';
};

const getStoreLink = device => {
    switch (device) {
        case 'ios': return 'https://itunes.apple.com/mx/app/cinemex/id418163740';
        case 'android': return 'https://play.google.com/store/apps/details?id=com.cinemex&hl=es';
        default: return 'https://cinemex.com';
    }
};

export default AppsRedirect;