import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AuthPinDialogClose, authPinSubmit, loginPinPost } from '../actions/creators/auth';
import { getAuthPinDialogContext, getAuthPinDialogMessage, getAuthPinDialogPayload } from '../reducers';
import BodyBlocker from './BodyBlocker';
import Button from './Button';
import CloseCross from './CloseCross';
import FieldRow from './FieldRow';
import { paymentPinPost } from '../actions/creators/checkout/payment';
import { ieNipUpdatePost, iePinPost } from '../actions/creators/ie';

const Popup = styled.div`    
    position: fixed;
    background: white;
    width: 90%;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    border-radius: 10px;
    padding: 3em;
    text-align: center;
    max-width: 600px;
    z-index: 1071;
`;

const Input = styled.input`
    border: none;
    border-radius: 10px;
    outline: none;
    background: #eee;
    font-size: 1.5rem;
    padding: 0.25em;
    text-align: center;
    max-width: 6em;
    margin: 0.5em 0 1.5em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`;

class AuthPinDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pin: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(pin) {
        this.setState({pin: pin.substr(0,6)});
    }

    onSubmit(e) {
        e.preventDefault();

        switch (this.props.context) {
            case 'login':
                return this.props.loginPost(this.state.pin);
            case 'payment':
                return this.props.paymentPost(this.state.pin);
            case 'ie_link':
                return this.props.iePost(this.state.pin);
            case 'ie_nip_update':
                return this.props.ieNipUpdatePost(this.state.pin);
            default:
                return this.props.submit(this.state.pin);
        }
    }

    render() {
        return <div style={{position:'fixed',zIndex:1070}}>
            <BodyBlocker overflow/>
            <Popup>
                <CloseCross theme="dark" onClick={this.props.close}/>
                <p>{this.props.message}</p>
                <form onSubmit={this.onSubmit}>
                    <Input type="number" autoFocus maxLength="6" onChange={(e) => this.onChange(e.target.value)} value={this.state.pin}/>
                    <div><Button submit primary large block disabled={this.state.pin.length !== 6}>Continuar</Button></div>
                </form>
            </Popup>
        </div>;
    }
}

const mapStateToProps = state => ({
    message: getAuthPinDialogMessage(state),
    context: getAuthPinDialogContext(state),
});

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(AuthPinDialogClose()),
    loginPost: (pin) => dispatch(loginPinPost(pin)),
    paymentPost: (pin) => dispatch(paymentPinPost(pin)),
    iePost: (pin) => dispatch(iePinPost(pin)),
    ieNipUpdatePost: (pin) => dispatch(ieNipUpdatePost(pin)),
    submit: pin => dispatch(authPinSubmit(pin)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPinDialog);