import React from "react";
import styled from 'styled-components';
import {getMethodLabelPart} from '../../utils/checkout';

const PointsPill = styled.div`
  flex: 1;
`;

const PointsMessages = styled.div`
  color: var(--primary);
  font-size: 0.9em;
  line-height: 1.2;
  padding: 0.5em 1em;
  text-align: center;
`;

class IePointsBlock extends React.Component {
    render() {
        let messages = [];
        
        return <React.Fragment>
            <div className="d-flex">
            {this.props.points.map(type => {
              const value = Math.min(type.value, type.available);
              type.messages && type.messages.length && (messages = messages.concat(type.messages));

              return (
                  <PointsPill className="border rounded text-secondary p-2 mr-2 mb-2" key={type.id}>
                    <span className="d-block">
                      <i className="fal fa-star mr-1"></i>
                      <span className="h5 mb-0 text-primary">{value}</span>
                    </span>
                    <small>{getMethodLabelPart(type.id, value)}</small>
                  </PointsPill>
              );
            })}
            {/*<div className="border rounded text-secondary p-2 mr-2 mb-2">*/}
            {/*<span className="d-block">*/}
            {/*<i className="fal fa-eye mr-1"></i>*/}
            {/*<span className="h5 mb-0 text-primary">45</span>*/}
            {/*</span>*/}
            {/*<small>visitas acumuladas</small>*/}
            {/*</div>*/}
          </div>
          {messages.length ? <PointsMessages>{messages.map((m, i) => <p key={i}>{m}</p>)}</PointsMessages> : null}
        </React.Fragment>;
    }
}

export default IePointsBlock;