import {combineReducers} from 'redux';
import * as actions from '../actions/types';

const ieCardData = (state = null, action) => {
  switch (action.type) {
    case actions.USER_IE_CARD_FETCH_SUCCESS:
      return Object.assign({}, action.data);
    case actions.USER_IE_CARD_FETCH_ERROR:
      return {error: true};
    default:
      return state;
  }
};

const token = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_REGISTER_POST_SUCCESS:
    case actions.AUTH_LOGIN_POST_SUCCESS:
      return action.data.access_token;
    case actions.AUTH_SESSION_EXPIRED:
    case actions.AUTH_LOGOUT_SUCCESS:
    case actions.USER_DELETE_ACCOUNT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const info = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_REGISTER_POST_SUCCESS:
    case actions.AUTH_LOGIN_POST_SUCCESS:
    case actions.USER_INFO_FETCH_SUCCESS:
    case actions.USER_PRE_CHECKOUT_FETCH_SUCCESS:
      return Object.assign({}, action.data.user_info);
    case actions.IE_CODE_SUBMIT_SUCCESS:
      return Object.assign({}, state, {iecode: action.memberId});
    case actions.IE_CODE_DELETE_SUCCESS:
      return Object.assign({}, state, {iecode: null});
    case actions.USER_UPDATE_INFO_SUCCESS:
    case actions.USER_AVATAR_UPLOAD_SUCCESS:
      return Object.assign({}, state, action.userInfo);
    case actions.AUTH_SESSION_EXPIRED:
    case actions.AUTH_LOGOUT_SUCCESS:
    case actions.USER_DELETE_ACCOUNT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const preferences = (state = {areaId: null, cinemaId: null}, action) => {
  switch (action.type) {
    case actions.CHANGE_PREFERRED_CINEMA:
      return Object.assign({}, state, {
        cinemaId: action.cinemaId,
        areaId: action.areaId,
      });
    default:
      return state;
  }
};

const purchasesDefaultState = {
  purchases: [],
  loading: false,
};

const purchases = (state = purchasesDefaultState, action) => {
  switch (action.type) {
    case actions.USER_PURCHASE_HISTORY_FETCH:
      return Object.assign({}, state, {purchases: [], loading: true});
    case actions.USER_PURCHASE_HISTORY_FETCH_SUCCESS:
      return Object.assign({}, state, {purchases: action.purchases, loading: false});
    case actions.USER_PURCHASE_HISTORY_FETCH_ERROR:
      return Object.assign({}, state, {purchases: [], loading: false});
    default:
      return state;
  }
};

const sessionsDefaultState = {
  sessions: [],
  loading: false,
  killing: false,
};

const sessions = (state = sessionsDefaultState, action) => {
  switch (action.type) {
    case actions.USER_SESSIONS_FETCH:
      return Object.assign({}, state, {sessions: [], loading: true});
    case actions.USER_SESSIONS_FETCH_SUCCESS:
      return Object.assign({}, state, {sessions: action.sessions, loading: false});
    case actions.USER_SESSIONS_FETCH_ERROR:
      return Object.assign({}, state, {sessions: [], loading: false});
    case actions.USER_SESSION_KILL:
      return Object.assign({}, state, {killing: true});
    case actions.USER_SESSION_KILL_ERROR:
    case actions.USER_SESSION_KILL_SUCCESS:
      return Object.assign({}, state, {killing: false});
    default:
      return state;
  }
};

const isProcessing = (state = {}, action) => {
  switch (action.type) {
    case actions.USER_INFO_FETCH:
      return Object.assign({}, state, {user_info: true});
    case actions.USER_INFO_FETCH_ERROR:
    case actions.USER_INFO_FETCH_SUCCESS:
      return Object.assign({}, state, {user_info: false});
    case actions.USER_IE_CARD_FETCH:
      return Object.assign({}, state, {ie_card: true});
    case actions.USER_IE_CARD_FETCH_SUCCESS:
    case actions.USER_IE_CARD_FETCH_ERROR:
      return Object.assign({}, state, {ie_card: false});
    case actions.IE_CODE_DELETE:
      return Object.assign({}, state, {ie: true});
    case actions.IE_CODE_DELETE_SUCCESS:
    case actions.IE_CODE_DELETE_FAILURE:
      return Object.assign({}, state, {ie: false});
    case actions.USER_AVATAR_UPLOAD:
      return Object.assign({}, state, {avatar: true});
    case actions.USER_AVATAR_UPLOAD_SUCCESS:
    case actions.USER_AVATAR_UPLOAD_ERROR:
      return Object.assign({}, state, {avatar: false});
    case actions.USER_DELETE_ACCOUNT:
      return Object.assign({}, state, {delete: true});
    case actions.USER_DELETE_ACCOUNT_SUCCESS:
    case actions.USER_DELETE_ACCOUNT_ERROR:
      return Object.assign({}, state, {delete: false});
    case actions.AUTH_LOGOUT:
      return Object.assign({}, state, {logout: true});
    default:
      return state;
  }
};

export default combineReducers({
  ieCardData,
  info,
  isProcessing,
  preferences,
  purchases,
  sessions,
  token,
});