import React from 'react';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import uniqid from 'uniqid';
import MDSpinner from "react-md-spinner";
import DrawerButton from "./DrawerButton";
import MoviePoster from "./MoviePoster";
import TinySlider from 'tiny-slider-react';
import SliderArrows from './SliderArrows';
import SliderContainer from './SliderContainer';
import {breakpoints} from '../utils/styles';

const Container = styled.section`
  position: relative;
`;

const GridTitle = styled.h1`
  margin-bottom: 0.5em;
  position: relative;

  @media screen and (min-width: ${breakpoints.desktopLarge}px) {
    margin-bottom: 0.65em;
  }
`;

const Drawer = styled.div`
  position: absolute;
  right: 0;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    position: relative; right: auto;
  }
`;

const LinksContainer = styled.div`
  background: var(--tertiary);
  box-shadow: 0 1px 3px rgba(0,0,0,0.15);
  display: none;
  padding: 0.5em 0.75em;
  position: absolute;
  right: 0;
  text-align: right;
  top: 2em;
  z-index: 1;
  
  & > a {
    color: var(--primary);
    display: block;
    margin-top: 0.5em;
    white-space: nowrap;
    
    &:first-child { margin-top: 0; }
    &:hover { color: #202325; }
  }
  
  @media screen and (min-width: ${breakpoints.tablet}px) {
    background: none;
    box-shadow: none;
    display: inline-block;
    font-size: ${props => props.size === 'large' ? '1.15em' : '1.5em'};
    margin-bottom: 0;
    padding: 0;
    position: relative;
    right: auto;
    text-align: left;
    top: auto;
  
    & > a {
      display: inline-block;
      border-left: 1px solid #bbb !important;
      margin-left: 1em;
      margin-top: 0;
      padding-left: 1em;
      vertical-align: middle;
    }
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}px) {
    font-size: ${props => props.size === 'large' ? '1.35em' : '1.6em'};
  }
`;

const Emoji = styled.span`
  font-size: 0.8em;
  margin-left: 0.25em;
`;

class MoviesGrid extends React.Component {
  constructor(props) {
    super(props);

    this.onMovieClick = this.onMovieClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);

    this.state = {
      navId: uniqid(),
      linksVisible: false,
    };
  }

  onMenuClick() {
    this.setState(state => ({linksVisible: !state.linksVisible}));
  }

  getTitleClass() {
    if (this.props.size === 'large') {
      return 'h3';
    } else {
      return 'h5';
    }
  }

  getSliderClass() {
    if (this.props.size === 'large') {
      return 'c-slider--preview';
    } else {
      return 'c-slider--preview-small';
    }
  }

  getSettings() {
    return this.props.size === 'large' ? this.getSettingsLarge() : this.getSettingsDefault();
  }

  getSettingsLarge() {
    return Object.assign(this.getSettingsDefault(), {
      items: 2.5,
      responsive: {
        '1400': {items: 6.25},
        '1200': {items: 5.25},
        '992': {items: 4.25},
        '768': {items: 3.25},
      },
    });
  }

  getSettingsDefault() {
    return {
      controlsContainer: '#' + this.state.navId,
      gutter: 18,
      items: 3.5,
      lazyload: true,
      mouseDrag: true,
      nav: false,
      responsive: {
        '1200': {items: 8.25},
        '992': {items: 6.25},
        '768': {items: 4.25},
      },
    };
  }

  onMovieClick(slide, info, e) {
    e.preventDefault();
    
    if (!this.props.onMovieSelect) {
      return;
    }

    let target = e.target;

    while (target && target.classList && !target.classList.contains('c-card__link')) {
      target = target.parentNode;
    }

    if (!target || !target.parentNode || !target.parentNode.parentNode) {
      return;
    }

    this.props.onMovieSelect(
        parseInt(target.parentNode.parentNode.getAttribute('data-movie-id'), 10),
        target.getAttribute('href'),
        this.props.history
    );
  }

  render() {
    const hasMovies = Object.values(this.props.movies).length > 0;

    if (!hasMovies && !this.props.emptyMessage) {
      return '';
    }

    const PosterElement = this.props.PosterElement || MoviePoster;

    return (
        <Container className="py-3">

          <div className="container">
            <GridTitle className={this.getTitleClass() + ' d-flex align-items-center'}>
              {this.props.title}
              {" "}
              {this.props.emoji && <Emoji>{this.props.emoji}</Emoji>}
              {" "}
              {this.renderLinks()}
            </GridTitle>
          </div>

          {this.props.loading ? (
              this.renderLoading()
          ) : (
              hasMovies ? (
                  <SliderContainer key={this.props.searchTerm}>
                    <TinySlider settings={this.getSettings()} onClick={this.onMovieClick} className={'c-slider ' + this.getSliderClass()}>
                      {this.props.movies.map(movie => <PosterElement key={movie.id} size={this.props.size} movie={movie} />)}
                    </TinySlider>
                    {this.props.movies.length > 1 && <SliderArrows navId={this.state.navId} size={this.props.size} sidebarOpen={this.props.sidebarOpen}/>}
                  </SliderContainer>
              ) : (
                  <div className="container">{typeof this.props.emptyMessage === 'function' ? this.props.emptyMessage() : this.props.emptyMessage}</div>
              )
          )}

        </Container>
    )
  }

  renderLinks() {
    if (!this.props.links) { return null; }

    return <Drawer>
      <DrawerButton onClick={this.onMenuClick}/>
      <LinksContainer size={this.props.size} className={(this.state.linksVisible ? 'd-inline-block' : 'd-none') + ' d-md-inline-block'}>{this.props.links.map(link => link)}</LinksContainer>
    </Drawer>;
  }

  renderLoading() {
    return this.props.loadingComponent
        ? <this.props.loadingComponent/>
        : <div className="container"><MDSpinner singleColor="var(--primary)"/></div>;
  }
}

export default withRouter(MoviesGrid);