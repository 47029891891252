import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {getOptionLabel} from '../utils/checkout';
import {centsToCurrencyString} from '../utils/numbers';
import Button from "./Button";
import UserCinemaNav from './UserCinemaNav';
import {nextStep} from '../actions/creators/checkout';
import {ticketSelectionSubmit} from '../actions/creators/checkout/tickets';
import {seatsSelectionSubmit} from '../actions/creators/checkout/seats';
import {candybarItemEditSubmit, candybarItemRemove} from '../actions/creators/checkout/candybar';
import LinkButton from "./LinkButton";
import IeSidebarBlock from "./checkout/IeSidebarBlock";
import {getNextStep} from '../utils/checkout';
import {parseSessionIsoDateForDisplay} from '../utils/dates';
import {ucFirst} from '../utils/strings';
import {getCheckoutSelectedPaymentOptionBookingFee, getWalletAddCardInfo, getWalletStep, isPaymentSuggestionNeeded} from '../reducers';
import {getCheckoutJsonLdObject} from '../utils/jsonLd';
import WalletCard from './checkout/WalletCard';
import SeatsLabels from './checkout/SeatsLabels';
import {t1ExistingCardPayment, t1NewCardPayment} from '../actions/creators/checkout/payment';
import { getBrand } from '../utils/creditCards';
import {breakpoints} from '../utils/styles';
import { isElementInViewport } from '../utils/misc';
import { AnalyticsBillboards } from '../utils/analytics';

let total = 0;
let ticketsCount = 0;
const orgId = '1snn5n9w';
const merchantId = 't1_cinemex';

const PlusFeeLegend = styled.span`
  display: inline-block;
  font-size: 0.75em;
  line-height: 1.2;
  opacity: 0.5;
`;

const Gradient = styled.div`
  height: 4rem;
  background: linear-gradient(0deg, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%);
`;

const getCandybarItemLabel = (item, orderItem) => {
  const label = [item.name];

  item.configs.forEach(config => {
    if (config.id in orderItem) {
      const orderConfig = orderItem[config.id];
      config.options.forEach(option => orderConfig.indexOf(option.id) > -1 && label.push(option.name));
    }
  });

  return label.join(' + ');
};

let fixedButton;
let floatingButton;
function scrollHandler() {
  if (!fixedButton) {
    return;
  }

  var visible = isElementInViewport(fixedButton);

  if (visible) {
    floatingButton.style.display = 'none';
  } else if (window.innerWidth >= breakpoints.phone) {
    floatingButton.style.display = 'block';
  }
}

class CheckoutSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.isStepCompleted = this.isStepCompleted.bind(this);
    this.paymentSubmit = this.paymentSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('DOMContentLoaded', scrollHandler, false);
    window.addEventListener('load', scrollHandler, false);
    window.addEventListener('scroll', scrollHandler, false);
    window.addEventListener('resize', scrollHandler, false);

    const { session, movie, cinema } = this.props;
    session && movie && cinema && AnalyticsBillboards.trackSession(session, movie, cinema);
  }

  componentWillUnmount() {
    window.removeEventListener('DOMContentLoaded', scrollHandler, false);
    window.removeEventListener('load', scrollHandler, false);
    window.removeEventListener('scroll', scrollHandler, false);
    window.removeEventListener('resize', scrollHandler, false);
  }

  componentDidUpdate() {
    fixedButton = document.getElementById('checkout-static-bt');
    floatingButton = document.getElementById('checkout-floating-bt');
    scrollHandler();
  }

  isStepCompleted(step) {
    const steps = ['tickets', 'seats', 'candybar', 'payment-suggestions', 'payment'];

    return steps.indexOf(step) <= steps.indexOf(this.props.step);
  }

  renderItems() {
    const ticketsBlock = this.renderTicketsSelection();
    const candybarBlock = this.renderCandybarSelection();

    if (!ticketsBlock && !candybarBlock) {
      return '';
    }

    return (
        <React.Fragment>
          <hr className="my-4"/>
          {ticketsBlock}
          {candybarBlock}
        </React.Fragment>
    );
  }

  renderTicketsSelection() {
    const items = [];
    const {seatsLabels} = this.props;

    this.props.tickets.forEach(ticket => {
      ticket.qty && items.push(ticket.qty + ' ' + ticket.name);
    });

    if (!items.length) {
      return '';
    }

    return (
      <div className="fade-in">
        <div className="row">
          <div className="col-4">
            <h3 className="h6 small text-uppercase text-muted mt-1 mb-0">Boletos</h3>
          </div>
          <div className="col-8">
            <p className="mb-0">{items.join(' + ')}</p>
          </div>
        </div>
        {seatsLabels && seatsLabels.length ? <div className="row">
          <div className="col-4">
            <h3 className="h6 small text-uppercase text-muted mt-1 mb-0">Asientos</h3>
          </div>
          <div className="col-8">
            <SeatsLabels labels={seatsLabels.join(', ')}/>
          </div>
        </div> : ''}
      </div>
    );
  }

  renderBookingFee() {
    const {bookingFee} = this.props;

    if (!bookingFee) {
      return '';
    }

    return (
        <div className="row fade-in">
          <div className="col-4">
            <h3 className="h6 small text-uppercase text-muted mt-1 mb-0">Costo por servicio</h3>
          </div>
          <div className="col-8">
            <p className="mb-0">{centsToCurrencyString(bookingFee)}</p>
          </div>
        </div>
    );
  }

  renderCandybarSelection() {
    const items = [];

    this.props.candybarOrder && Object.keys(this.props.candybarOrder)
        .forEach(itemId => {
          const item = this.props.candybarCatalog[itemId];
          const orderItem = this.props.candybarOrder[itemId];
          Object.keys(orderItem).forEach(unitId => {
            items.push(
                <p className="mb-0" key={unitId}>
                  {getCandybarItemLabel(item, orderItem[unitId])}
                  {orderItem[unitId].comments && (<React.Fragment> + <i className="fal fa-sticky-note" title={orderItem[unitId].comments}></i></React.Fragment>)}
                  <LinkButton onClick={() => this.props.candybarItemRemove(item.id, unitId)} className="text-muted ml-2"><i className="fal fa-trash-alt"></i></LinkButton>
                </p>
            );
          });
        });

    if (!items.length) {
      return '';
    }

    return (
        <div className="row fade-in">
          <div className="col-4">
            <h3 className="h6 small text-uppercase text-muted mt-1 mb-0">Dulcería</h3>
          </div>
          <div className="col-8">
            {items.map(item => item)}
          </div>
        </div>
    );
  }

  renderTotal() {
    let totalString;

    if (this.props.paymentOption) {
      totalString = getOptionLabel(this.props.paymentOption);
    } else if (total) {
      totalString = centsToCurrencyString(total);
    } else {
      return '';
    }

    return (
        <React.Fragment>
          <hr className="my-4"/>
          <div className="row align-items-center fade-in">
            <div className="col-4">
              <h3 className="h6 small text-uppercase mb-0">Total a pagar</h3>
            </div>
            <div className="col-8">
              <p className="mb-0">
                <span className="lead text-primary">{totalString}</span>
                {this.props.bookingFee === null && (
                    <PlusFeeLegend className="ml-1"> + costo por servicio</PlusFeeLegend>
                )}
              </p>
            </div>
          </div>
          <div className="mt-2">
            <PlusFeeLegend>
              Esta transacción tendrá un costo por servicio según los boletos seleccionados.
              {this.props.bookingFee === null && (
                <span> El valor se mostrará en el siguiente paso.</span>
              )}
            </PlusFeeLegend>
          </div>
        </React.Fragment>
    );
  }

  getNextButton() {
    const payload = {
      cinema: this.props.cinema,
      session: this.props.session,
      points: this.props.points,
      skipPaymentOptions: this.props.skipPaymentOptions
    };
    const currentStep = this.props.step;
    const nextStep = getNextStep(this.props.step, payload);

    return this.getNextButtonElement(currentStep, nextStep);
  }

  getNextButtonElement(currentStep, nextStep) {
    const onClick = this.getNextButtonOnClick(currentStep);

    switch (nextStep) {
      case 'seats':
        return <Button primary large block onClick={onClick}>Ir a selección de asientos</Button>;
      case 'candybar':
        return <Button primary large block onClick={onClick}>Ir a dulcería</Button>;
      case 'payment-suggestions':
        return this.getCandybarNextButton(onClick);
      case 'payment':
        return <Button primary large block onClick={onClick}>Ir a pago</Button>;
      case 'confirmation':
        return this.isPayButtonVisible() ? <Button disabled={this.isPayButtonDisabled()} primary large block onClick={this.paymentSubmit}>Finalizar</Button> : '';
      default:
        return '';
    }
  }

  getCandybarNextButton(onClick) {
    if (this.props.cinema.candybar && this.props.candybarEditing) {
      return <Button className="btn btn-lg" primary block onClick={this.props.candybarItemSave}>Guardar y volver a dulcería</Button>;
    } else {
      return <Button className="btn btn-lg" primary block onClick={onClick}>{
        (!this.props.cinema.candybar || this.props.candybarItems) ? 'Ir a forma de pago' : 'Saltear este paso'
      }</Button>;
    }
  }

  isPayButtonVisible() {
    return this.props.renderWallet && this.props.paymentGateway !== 'smart';
  }

  isPayButtonDisabled() {
    return !(this.props.renderWallet && this.props.walletAddCardInfo.valid);
  }

  paymentSubmit() {
    if (this.props.walletStep === 2) { // @todo use vars instead of harcoded numbers
      this.props.tokenCardPaymentSubmit();
    } else {
      this.props.newCardPaymentSubmit();
    }
  }

  getNextButtonOnClick(currentStep) {
    switch (currentStep) {
      case 'tickets':
        return (() => this.props.ticketSelectionSubmit(this.props.movie, this.props.tickets));
      case 'seats':
        return this.props.seatsSelectionSubmit;
      case 'candybar':
      case 'payment-suggestions':
      case 'payment':
        return this.props.nextStep;
      default:
        return null;
    }
  }

  getTotal() {
    return this.getTicketsTotal()
      + this.getCandybarTotal();
  }

  getTicketsTotal() {
    return this.props.tickets.reduce((carry, ticket) => carry + (ticket.qty * ticket.price), 0) + (this.props.bookingFee || 0);
  }

  getTicketsQty() {
    return this.props.tickets.reduce((carry, ticket) => carry + ticket.qty, 0);
  }

  getCandybarTotal() {
    let total = 0;

    this.props.candybarOrder && Object.keys(this.props.candybarOrder)
        .forEach(itemId => {
          const item = this.props.candybarCatalog[itemId];
          const orderItem = this.props.candybarOrder[itemId];
          Object.keys(orderItem).forEach(unitId => {
            const unit = orderItem[unitId];
            total += item.price;
            Object.keys(unit).forEach(configId => {
              configId !== 'comments' && unit[configId].forEach(optionId => total += this.props.candybarOptions[optionId].price);
            });
          });
        });

    return total;
  }

  render() {
    const {movie, session} = this.props;
    const date = parseSessionIsoDateForDisplay(session.datetime);

    total = this.getTotal();
    ticketsCount = this.getTicketsQty();

    if (this.props.renderWalletSidebar) {
      return this.renderWalletSidebar();
    }

    return (
        <React.Fragment>
          <Helmet>
            <title>Checkout - Cinemex</title>
            <script type="application/ld+json">
              {JSON.stringify(getCheckoutJsonLdObject(session, movie, this.props.cinema))}
            </script>
            <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&amp;display=swap" rel="stylesheet"/>
            {this.props.localSessionId && <script type="text/javascript" src={`https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${merchantId}${this.props.localSessionId}`}></script>}
          </Helmet>

          <UserCinemaNav cinemaSelector={false} showInMobile={false}/>

          <div className="px-5">
            <div className="d-flex justify-content-between align-items-center border-bottom py-2">
              <ul className="nav">
                {this.props.steps.map(step => {
                  return (
                      <li className="nav-item" key={step}>
                      <span className={'nav-link px-2 ' + (step === this.props.step ? 'text-primary' : 'text-muted')}>
                        <span className={'fal fa-sm ' + (this.isStepCompleted(step) ? 'fa-check' : 'fa-circle')}></span>
                      </span>
                      </li>
                  );
                })}
              </ul>
              <span className="text-muted">{this.props.steps.indexOf(this.props.step) + 1} - {this.props.steps.length}</span>
            </div>
          </div>

          <IeSidebarBlock/>

          <div className="px-5 py-4">

            <div className="row">
              <div className="col-4">
                <img className="img-fluid u-rounded-mask" src={movie.poster_small} alt=""/>
              </div>
              <div className="col-8">
                <h2 className="h5">{movie.name}</h2>
                <p className="text-secondary">
                  {this.props.cinema.name}
                  <br/>
                  {ucFirst(date.format('dddd D [de] MMMM'))}
                  <br/>
                  {date.format('hh:mm A')}
                  <br/>
                  Sala {session.screen_number}
                  <br/>
                  {movie.label}
                </p>
              </div>
            </div>
            {this.renderItems()}
            {this.renderBookingFee()}
            {this.renderTotal()}

          </div>

          {(total > 0 || ticketsCount > 0) && (
            <React.Fragment>
            <div id="checkout-static-bt" className="px-5 py-3 p-md-5 sm-fixed-bottom w-100 bg-white border-top">
              {this.getNextButton()}
            </div>
            <div id="checkout-floating-bt" style={{position:'fixed',width:'35%',bottom:0,display:'none'}}>
              <Gradient></Gradient>
              <div className="px-5 py-3 p-md-5 bg-white border-top">
                {this.getNextButton()}
              </div>
            </div>
            </React.Fragment>
          )}

        </React.Fragment>
    );
  }

  renderWalletSidebar() {
    const info = this.props.walletAddCardInfo.params;
    const {focused} = this.props.walletAddCardInfo;

    return <React.Fragment>
        <div className="px-5 py-5 mt-5"><WalletCard
            brand={getBrand(info.cc || '')}
            number={info.cc ? info.cc.replace(/[ ]/g, '') : ''}
            csc={info.csc}
            cardHolderName={info.name}
            expMonth={info['expire-month']}
            expYear={info['expire-year'] || ''}
            focused={focused}
        /></div>
        <div className="px-5 py-3 p-md-5 sm-fixed-bottom w-100 bg-white border-top">
            {this.getNextButton()}
        </div>
      </React.Fragment>;
  }
}

const mapStateToProps = (state, props) => {
  const {movie, cinema} = props;
  let candybarItems = 0;
  const session = state.checkoutData.data;
  const steps = ['tickets'];
  const {paymentMethod,step} = state.checkoutData;
  const candybarOrder = state.checkoutData.order.candybar;
  const candybarCatalog = state.entities.candybarItems;
  const candybarOptions = state.entities.candybarOptions;
  const {paymentSuggestions} = state.checkoutData;
  const paymentOption = paymentSuggestions.options && paymentSuggestions.options[paymentSuggestions.selected];
  const localSessionId = state.checkoutData.t1.sessionId;
  const walletStep = getWalletStep(state);
  const skipPaymentOptions = !isPaymentSuggestionNeeded(state);

  if (session) {
    session.seatallocation && steps.push('seats');
    cinema && cinema.candybar && steps.push('candybar');
  }

  if (state.checkoutData.points.length) {
    steps.push('payment-suggestions');
  }

  steps.push('payment');

  if (candybarOrder) {
    candybarItems = Object.keys(candybarOrder).length;
  }

  const renderWallet = step === 'payment' && paymentMethod === 'credit-card';
  const renderWalletSidebar = renderWallet && walletStep > 1

  return {
    bookingFee: getCheckoutSelectedPaymentOptionBookingFee(state),
    candybarCatalog,
    candybarOptions,
    candybarItems,
    candybarOrder,
    candybarEditing: !!state.checkoutData.candybar.selectedItem,
    cinema,
    localSessionId,
    movie,
    paymentGateway: state.checkoutData.paymentGateway,
    paymentMethod,
    paymentOption,
    points: state.checkoutData.points,
    renderWallet,
    renderWalletSidebar,
    session,
    skipPaymentOptions,
    step,
    steps,
    walletAddCardInfo: getWalletAddCardInfo(state),
    walletStep,
  };
};

const mapDispatchToProps = dispatch => ({
  nextStep: () => dispatch(nextStep()),
  newCardPaymentSubmit: () => dispatch(t1NewCardPayment()),
  tokenCardPaymentSubmit: () => dispatch(t1ExistingCardPayment()),
  ticketSelectionSubmit: (movie, tickets) => dispatch(ticketSelectionSubmit(movie, tickets)),
  seatsSelectionSubmit: () => dispatch(seatsSelectionSubmit()),
  candybarItemSave: () => dispatch(candybarItemEditSubmit(null)),
  candybarItemRemove: (itemId, unitId) => dispatch(candybarItemRemove(itemId, unitId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutSidebar);