import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import FullColumnLayout from './FullColumnLayout';
import { asset } from '../utils/misc';

const PageContent = props => {
  return (
      <div className="container">
        <Helmet><title>{props.title || 'No encontrado'} - Cinemex</title></Helmet>

        <div className="row justify-content-md-center mt-4">

          <div className="col-12">
            <h1 className="h2 mb-5">
              <Link to="/" className="text-muted pr-3"><i className="fal fa-arrow-left fa-xs"></i></Link>
              Volver al home
            </h1>
          </div>

          <div className="col-12 col-md-8 col-lg-6">

            <div className={'c-error ' + (props.className || '')}>
              <img className="c-error__image" src={asset('/dist/images/' + (props.image || 'error.png'))} alt=""/>
              <div className="c-error__message">
                {props.message}
              </div>
            </div>

          </div>

        </div>
      </div>
  );
};

export default props => <FullColumnLayout Content={PageContent} {...props} bgWhite />