import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from '../../utils/styles';
import IeCardFront from "./IeCardFront";
import IeCardBack from "./IeCardBack";

const Cards = styled.div`
  margin: 3em 0 1em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 3em;
    
    & > div {
      display: inline-block;
      margin: 0 1em;
      max-width: 45%;
    }
  }
`;
const SuccessContainer = styled.div`
    text-align: center;
  
    & > i { font-size: 4rem; margin-bottom: 0.5em; }
    & > h1, & > h2 { color: #000; font-weight: 700; }
    & > .btn {
      margin: 1.5em 0;
      padding-left: 1.5em;
      padding-right: 1.5em;
      width: auto;
  
      @media screen and (min-width: ${breakpoints.desktop}px) {
        margin: 1em 0;
      }
    }
    & > p { line-height: 1.75; margin: 1em 0; }
  `;

function IeSignUpSuccessMessage(props) {
    return <SuccessContainer className="fade-in">
        <i class="fa-light fa-circle-check"></i>
        <h1>¡Felicidades!</h1>
        <h2>{props.title}</h2>
        {props.cardData
            ? <Cards>
              <IeCardFront data={props.cardData} flip={false} />
              <IeCardBack data={props.cardData} flip={true} />
            </Cards>
            : ''}
        <Link className="btn btn-primary d-inline-block d-md-none" to="/usuario/invitado-especial/tarjeta">Ver tu tarjeta</Link>
        {props.content}
        <p>Sigue disfrutando <strong>#LaMagiaDelCine</strong></p>
        <Link className="btn btn-primary" to="/">Ver cartelera</Link>
    </SuccessContainer>;
}

export default IeSignUpSuccessMessage;