import React from 'react';
import {connect} from 'react-redux';
import StepHeader from './StepHeader';
import Button from '../Button';
import {promoCodePanelCancel,promoCodePanelChange,promoCodePanelSubmit} from '../../actions/creators/checkout/tickets';

const maxLength = 16;
const formatCode = v => v.replace(/[^\d]/g, '').substr(0, maxLength);

class PromoCodePanel extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    const code = e.target.value;
    this.props.onChange(code);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.code);
  }

  render() {
    return (
        <div className="col-12 col-lg-8">
          <StepHeader timer={false} onPrevClick={this.props.onCancel} label="Ingresa un código promocional" />

          <form onSubmit={this.onSubmit}>
            <div className="form-group mb-5">
              <label for="promoCode">Código:</label>
              <input type="text" className="form-control form-control-lg" id="promoCode" placeholder="0123456789" autoFocus required
                value={this.props.code} onChange={this.onChange}
              />
            </div>
            <Button submit primary large block disabled={this.props.code === ''}>Aplicar código</Button>
          </form>

        </div>
    );
  }
}

const mapStateToProps = state => ({
  code: state.checkoutData.promoCode.temp,
});

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(promoCodePanelCancel(false)),
  onChange: code => dispatch(promoCodePanelChange(code)),
  onSubmit: code => dispatch(promoCodePanelSubmit(code)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoCodePanel);