import React from 'react';
import styled from 'styled-components';

const WalletCardContainer = styled.div`
    background: #efefef;
    border-radius: 10px;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all .25s linear;
    width: 100%;
    
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 63%;
    }
    
    ${props => props.onClick && 'cursor: pointer;'}
`;

export default WalletCardContainer;