import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  ${props => {
    let padding;
    if (props.defaultPadding) {
      padding = '0.75rem';
    } else if (props.smallPadding) {
      padding = '0.5em';
    } else {
      padding = '1.5em';
    }
    
    return 'padding-right: ' + padding + ';padding-left: ' + padding + ';'; 
  }}
`;

const getClass = props => {
  const names = ['btn'];

  if (props.paypal) {
    names.push('btn-paypal');
  }

  if (props.primary) {
    if (props.disabled || props.outline) {
      names.push('btn-outline-primary');
    } else {
      names.push('btn-primary');
    }
  }

  if (props.secondary) {
    if (props.disabled || props.outline) {
      names.push('btn-outline-secondary');
    } else {
      names.push('btn-secondary');
    }
  }

  if (props.block) {
    names.push('btn-block');
  }

  if (props.large) {
    names.push('btn-lg');
  }

  return (props.className || '') + ' ' + names.join(' ');
};

class Button extends React.PureComponent {
  componentDidMount() {
    if (this.props.focus && this.btn) {
      this.btn.focus();
    }
  }

  render() {
    const props = this.props;
    const Element = props.to ? Link : StyledButton;

    return <Element
        innerRef={btn => this.btn = btn}
        type={props.to ? '' : (props.submit ? 'submit' : 'button')}
        disabled={props.disabled}
        className={getClass(props)}
        onClick={props.onClick}
        smallPadding={props.smallPadding}
        defaultPadding={props.defaultPadding}
        to={props.to}
    >
      {props.children}
    </Element>;
  }
}

Button.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  defaultPadding: PropTypes.bool,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  smallPadding: PropTypes.bool,
  to: PropTypes.string,
};

export default Button;