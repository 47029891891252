import * as actions from '../actions/types';
import {combineReducers} from 'redux';

const data = (state = {}, action) => {
  switch (action.type) {
    case actions.BILLBOARD_STATE_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.stateId + '_' + getDateFromAction(action)]: action.data.cinemas});
    default:
      return state;
  }
};

const dates = (state = {}, action) => {
  switch (action.type) {
    case actions.BILLBOARD_STATE_FETCH_SUCCESS:
      return ('dates' in action.data)
          ? Object.assign({}, state, {[action.stateId]: action.data.dates})
          : state;
    default:
      return state;
  }
};

const getDateFromAction = action => action.date || action.data.dates[0];

const isLoading = (state = {}, action) => {
  switch (action.type) {
    case actions.BILLBOARD_STATE_FETCH:
      return Object.assign({}, state, {[action.areaId + '_' + (action.date || 'initial')]: true});
    case actions.BILLBOARD_STATE_FETCH_SUCCESS:
    case actions.BILLBOARD_STATE_FETCH_ERROR:
      return Object.assign({}, state, {[action.areaId + '_' + (action.date || 'initial')]: false});
    default:
      return state;
  }
};

const lastLoadTime = (state = {}, action) => {
  switch (action.type) {
    case actions.BILLBOARD_STATE_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.areaId + '_' + (action.date || 'initial')]: new Date().getTime()});
    default:
      return state;
  }
};

export default combineReducers({
  data,
  dates,
  isLoading,
  lastLoadTime,
});