import * as actions from '../../types';
import {dialogOpen, dialogClose, dialogAlert} from '../dialogs';
import CMX from '../../../api';
import {nextStep, timerStart} from '../checkout';
import {getTicketsParam} from '../../../utils/checkout';

export const ticketQuantityIncrease = (ticket) => ({
  type: actions.CHECKOUT_TICKET_QTY_INCREASE,
  ticket,
});

export const ticketQuantityDecrease = (ticket) => ({
  type: actions.CHECKOUT_TICKET_QTY_DECREASE,
  ticket,
});

export const ticketSelectionSubmit = (movie, ticketsSelection) => (dispatch, getState) => {
  displayRatingModal(movie, dispatch)
      .then(() => displayMasterpassModal(ticketsSelection, dispatch))
      .then(() => ticketSelectionPost(getState().checkoutData.data, ticketsSelection, dispatch))
      .finally(() => dispatch(ticketSelectionProcessing(false)));
};

const ticketSelectionProcessing = processing => ({
  type: actions.CHECKOUT_TICKET_SELECTION_PROCESSING,
  processing
});

const displayRatingModal = (movie, dispatch) =>
    new Promise((resolve, reject) => {
      const adultRatings = ['B15', 'C', 'D'];
      const isAdult = adultRatings.indexOf(movie.info.rating) > -1;

      if (!isAdult) {
        resolve();
        return;
      }

      const {message,okLabel,cancelLabel} = getRatingModalLabels(movie.info.rating);
      dispatch(dialogOpen({
        message,
        buttons: [
          {
            label: cancelLabel,
            onClick: (dialog) => {
              reject();
              dispatch(dialogClose(dialog.id));
            }
          },
          {
            label: okLabel,
            primary: true,
            onClick: (dialog) => {
              resolve();
              dispatch(dialogClose(dialog.id));
            }
          }
        ]
      }));
    });

const getRatingModalLabels = rating => 'B15' === rating ? {
  message: 'Esta película está clasificada como B15. No es recomendada para menores de 15 años ya que contiene escenas de violencia explícita y lenguaje no apto para menores.',
  okLabel: 'Continuar',
  cancelLabel: 'Volver',
} : {
  message: 'Estás a punto de comprar boletos para una película con clasificación ' + rating + ', exclusiva para mayores de 18 años.',
  okLabel: 'Mis acompañantes y yo somos mayores de 18 años',
  cancelLabel: 'Tengo menos de 18 años',
};

const displayMasterpassModal = (tickets, dispatch) =>
    new Promise((resolve, reject) => {
      let masterpassTicketPresent = false;
      let masterCardOrPassTicketPresent = false;
      let message;

      tickets.forEach(ticket => {
        if (ticket.qty) {
          ticket.extra.masterpass && (masterpassTicketPresent = true);
          ticket.extra.master_card_pass && (masterCardOrPassTicketPresent = true);
        }
      });

      if (masterpassTicketPresent) {
        message = 'Los boletos que estás comprando podrán ser pagados únicamente a través de Masterpass. ¿Deseas continuar?';
      } else if (masterCardOrPassTicketPresent) {
        message = 'Los boletos que estás comprando podrán ser pagados únicamente a través de Masterpass o utilizando una tarjeta Mastercard. ¿Deseas continuar?';
      } else {
        resolve();
        return;
      }

      dispatch(dialogOpen({
        message,
        buttons: [
          {
            label: 'Cancelar',
            onClick: (dialog) => {
              reject();
              dispatch(dialogClose(dialog.id));
            }
          },
          {
            label: 'Continuar',
            primary: true,
            onClick: (dialog) => {
              resolve();
              dispatch(dialogClose(dialog.id));
            }
          }
        ]
      }));
    });

const ticketSelectionPost = (session, ticketsSelection, dispatch) => {
  dispatch(ticketSelectionProcessing(true));

  return CMX.post(
      'buy/selectTickets',
      {
        session_id: session.id,
        tickets: getTicketsParam(session.tickets, ticketsSelection),
      }
  )
      .then(data => dispatch(ticketSelectionSuccess(data)))
      .then(() => dispatch(nextStep()))
      .catch(error => dispatch(ticketSelectionFailure(error.message)));
};

const ticketSelectionSuccess = data => dispatch => {
  dispatch(timerStart(data.timeout));
  dispatch({
    type: actions.CHECKOUT_TICKET_SELECTION_SUCCESS,
    data
  });
};

const ticketSelectionFailure = error => dispatch => {
  dispatch(dialogAlert(error));
  dispatch({
    type: actions.CHECKOUT_TICKET_SELECTION_FAILURE,
    error
  });
};

export const promoCodePanelShow = () => ({
  type: actions.CHECKOUT_PROMO_CODE_SHOW,
});

export const promoCodePanelCancel = () => (dispatch, getState) => {
  dispatch({
    type: actions.CHECKOUT_PROMO_CODE_CANCEL,
    code: getState().checkoutData.promoCode.code,
  });
};

export const promoCodePanelChange = code => ({
  type: actions.CHECKOUT_PROMO_CODE_CHANGE,
  code
});

export const promoCodePanelSubmit = code => (dispatch, getState) => {
  const state = getState().checkoutData;
  const prevCode = state.promoCode.code;
  const session_id = state.data.id;

  if (prevCode === code) {
    dispatch(promoCodePanelCancel());
    return;
  }

  dispatch({
    type: actions.CHECKOUT_PROMO_CODE_SUBMIT
  });

  CMX.post('/couponCodes/getInfo', {code, session_id})
      .then(data => dispatch(promoCodePanelSubmitSuccess(code, data.tickets)))
      .catch(error => dispatch(promoCodePanelSubmitFailure(error.message)))
};

const promoCodePanelSubmitSuccess = (code, tickets) => ({
  type: actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS,
  code,
  tickets
});

const promoCodePanelSubmitFailure = error => dispatch => {
  dispatch(dialogAlert(error));
  dispatch({
    type: actions.CHECKOUT_PROMO_CODE_SUBMIT_FAILURE,
    error
  });
};
