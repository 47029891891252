import React from 'react';
import BillboardMovieDetailsBlock from "./BillboardMovieDetailsBlock";
import BillboardMovieVersion from "./BillboardMovieVersion";
import BillboardMoviePoster from "./BillboardMoviePoster";

class BillboardMovieBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);
    this.onTrailerToggle = this.onTrailerToggle.bind(this);
    this.onTrailerClose = this.onTrailerClose.bind(this);

    this.state = {
      expanded: false,
      trailer: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  onTrailerToggle() {
    this.setState(prevState => ({trailer: !prevState.trailer}));
  }

  onTrailerClose() {
    this.setState(prevState => ({trailer: false}));
  }

  render() {
    const {movie} = this.props;
    const {versions} = movie;

    if (!versions.length) {
      return '';
    }

    return (
        <div className="list-group-item p-5 billboard-movie-block" data-movie-id={movie.id}>
          <div className="row">
            <div className="col col-3 col-lg-2">
              <BillboardMoviePoster movie={movie} onTrailerToggle={this.onTrailerToggle}/>
            </div>
            <div className="col col-9 col-lg-10">
              <BillboardMovieDetailsBlock movie={movie} trailerOpen={this.state.trailer} onTrailerClose={this.onTrailerClose}/>
              {versions.map(version => <BillboardMovieVersion key={movie.id + '-' + version.id} version={version} movieName={movie.name}/>)}
            </div>
          </div>
        </div>
    );
  }
}

export default BillboardMovieBlock;