import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import LinkButton from "./LinkButton";
import MovieDetailsBlock from './MovieDetailsBlock';
import {getYouTubeEmbedUrl} from '../utils/misc';
import {getMovieJsonLdObject} from '../utils/jsonLd';
import { getAbsoluteUrl, getMoviePermalink, getUpcomingMoviePermalink } from '../utils/urls';

const YouTube = styled.div`
  &:before { content: none; }
  & > iframe {
    border: 0;
    display: block;
    height: 240px; 
    width: 100%;
  }
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  
  & > h2 {
    margin: 0;
  }
`;

const getWindowTitle = movie => movie.name + ' | '
    + (isUpcomingMovie(movie) ? 'Próximos estrenos ' : 'En cartelera')
    + ' Cinemex';

const getMetaDescription = movie => isUpcomingMovie(movie)
    ? `${movie.name}${movie.info.original_title && movie.info.original_title !== movie.name ? ` (${movie.info.original_title})` : ''} se estrena en Cinemex el ${movie.release_date}. Entra y checa el tráiler oficial y todos nuestros próximos estrenos.`
    : `¡Checa los horarios de ${movie.name} y compra tus boletos en Cinemex! Vive las mejores películas con nuestra cartelera.`;

function isUpcomingMovie(movie) { return ('release_date' in movie); }

function isGoogleBot() { return window.navigator.userAgent.toLowerCase().indexOf('google') > -1; }

class SidebarMovieInfo extends React.Component {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);

    this.state = {
      expanded: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  render() {
    const {movie} = this.props;
    const title = getWindowTitle(movie);
    const description = getMetaDescription(movie);
    const canonical = getAbsoluteUrl(isUpcomingMovie(movie) ? getUpcomingMoviePermalink(movie) : getMoviePermalink(movie));

    return (
        <main className="px-5 py-3">
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonical} />
            <meta property="og:title" content={title} data-react-helmet="true"/>
            <meta property="og:description" content={description} data-react-helmet="true"/>
            <meta property="og:url" content={canonical} data-react-helmet="true"/>
            <script type="application/ld+json">
              {JSON.stringify(getMovieJsonLdObject(movie))}
            </script>
          </Helmet>

          {this.renderRibbons()}
          {this.renderYouTube()}

          <Title className="d-md-flex justify-content-md-between">
            <h2 className="h4">{movie.name}</h2>
            {!this.props.infoVisible && (
                <LinkButton className="mt-1" onClick={this.onExpandedToggle}>
                  Info película <i className={'fal ' + (this.state.expanded ? 'fa-angle-up' : 'fa-angle-down')}></i>
                </LinkButton>
            )}
          </Title>

          {(isGoogleBot() || this.props.infoVisible || this.state.expanded) && (
            <div className="py-3 fade-in">
              <MovieDetailsBlock movie={movie} onNotificationToggle={this.props.onNotificationToggle} notificationStatus={this.props.notificationStatus}/>
            </div>
          )}
        </main>
    );
  }

  renderRibbons() {
    const {ribbons} = this.props.movie;

    if (!ribbons || !ribbons.length) {
      return '';
    }

    return (
        <div className="d-flex flex-wrap justify-content-around">
          {ribbons.map(ribbon => <span className="o-ribbon mb-4" key={ribbon.label}><span className="o-ribbon__inner">{ribbon.label}</span></span>)}
        </div>
    );
  }

  renderYouTube() {
    const {movie} = this.props;
    let youtubeUrl = getYouTubeEmbedUrl(movie);
    let args = '';

    if (!youtubeUrl) {
      return '';
    }

    if (document.location.hash === '#trailer' && this.props.open) {
      args += '?autoplay=1';
    }

    return (
        <YouTube className="o-video mb-3">
          <iframe key={'youtube-' + movie.id + args} src={youtubeUrl + args} allow="autoplay; encrypted-media" allowFullScreen title={'Trailer de ' + movie.name}></iframe>
          <span className="o-video__badge badge badge-secondary">{movie.info.rating}</span>
        </YouTube>
    );
  }
}

export default SidebarMovieInfo;