import {extractYouTubeId} from './misc';
import {getCheckoutPermalink, getCinemaPermalink, getMoviePermalink, getAbsoluteUrl} from './urls';

export const getMovieJsonLdObject = movie => {
  const result = {
    "@context": "http://schema.org",
    "@type": "Movie",
    "name": movie.name,
    "url": getAbsoluteUrl(getMoviePermalink(movie)),
    "trailer": getJsonLdTrailerObject(movie),
    "dateCreated": movie.date_created,
  };

  if (movie.poster_medium) { result.image = movie.poster_medium; }
  if (movie.info.sinopsis) { result.description = movie.info.sinopsis; }
  if (movie.info.cast) {
    result.actor = movie.info.cast.split(',').map(actor => actor.trim());
  }
  if (movie.info.director) { result.director = movie.info.director; }
  if (movie.info.country) { result.countryOfOrigin = movie.info.country; }
  if (movie.info.rating) { result.contentRating = movie.info.rating; }
  if (movie.info.duration) { result.duration = movie.info.duration; }

  return result;
};

const getJsonLdTrailerObject = movie => {
  if (!movie.info.trailer) { return {}; }

  return {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": movie.name,
    "url": movie.info.trailer,
    "thumbnailURL": 'http://i.ytimg.com/vi/' + extractYouTubeId(movie.info.trailer) + '/hqdefault.jpg',
    "description": movie.info.sinopsis,
    "uploadDate": movie.date_created,
  };
};

export const getCheckoutJsonLdObject = (session, movie, cinema) => {
  const movieJson = getMovieJsonLdObject(movie);

  return {
    "@context": "http://schema.org",
    '@type': 'ScreeningEvent',
    'workPresented': movieJson,
    "name": movie.name,
    "url": getAbsoluteUrl(getCheckoutPermalink(session)),
    "trailer": getJsonLdTrailerObject(movie),
    'image': movieJson.image,
    'description': movieJson.description,
    'actor': movieJson.actor,
    'director': movieJson.director,
    'startDate': session.datetime,
    'videoFormat': movie.label,
    'location': {
      '@type': 'Place',
      'name': cinema.name,
      'address': cinema.info.address,
      'geo': {
        '@type': 'GeoCoordinates',
        'latitude': cinema.lat,
        'longitude': cinema.lng,
      },
    },
  };
};

export const getCinemaJsonLdObject = cinema => {
  return {
    "@context": "http://schema.org",
    "@type": "MovieTheater",
    "name": cinema.name,
    "url": getAbsoluteUrl(getCinemaPermalink(cinema)),
    'image': cinema.image,
    'address': cinema.info.address,
    'telephone': cinema.info.phone,
    'geo': {
      '@type': 'GeoCoordinates',
      'latitude': cinema.lat,
      'longitude': cinema.lng,
    },
    'publicAccess': true,
    'hasMap': 'http://www.google.com/maps/place/' + cinema.lat + ',' + cinema.lng,
  };
};
