import styled from "styled-components";

const IeCardBase = styled.div`
    margin-bottom: 2rem;
    position: relative;
    backface-visibility: hidden;
    transition: transform 0.8s;
    max-width: 350px;

    & > img {
        filter: drop-shadow(0 0 4px rgba(0,0,0,0.25));
        width: 100%;
    }
`;

export default IeCardBase;