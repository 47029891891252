import React from 'react';
import styled from 'styled-components';
import * as vars from '../utils/styles';

const Panel = styled.div`
  box-shadow: ${props => props.over !== false ? '0 0 20px rgba(0,0,0,0.25)' : 'none'};
  ${props => props.onHideToggle && `
    transform: translateX(${props.isHidden ? '100%' : '0'});
    transition: 0.5s transform ease-out;
  `}
  ${props => props.maxWidth ? ('max-width: ' + props.maxWidth + ';') : ''}
  
  @media (min-width: ${vars.breakpoints.tablet}px) { z-index: ${props => props.overBlocker ? 1036 : 1031}; }
`;

const Toggle = styled.button`
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  
  &:focus { outline: 0; }
`;

const Content = styled.div`
  height: 100%;
  overflow-y: ${props => props.noScroll ? 'hidden' : 'auto'};
  overflow-x: hidden;
  position: relative;
`;

class SidebarPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.scrollTop = this.scrollTop.bind(this);

    this.ref = React.createRef();
  }

  scrollTop() {
    this.ref && this.ref.current && (this.ref.current.scrollTop = 0);
  }

  render() {
    return (
        <Panel className={'bg-white ' + (
            this.props.over !== false ? 'l-wrapper__sidebar-over slide-in-from-right'
                : 'l-wrapper__sidebar'
        )} {...this.props}>
          {this.props.onHideToggle && (
              <Toggle className="l-wrapper__sidebar-toggle" onClick={this.props.onHideToggle}>
                <i className={'fal ' + (this.props.isHidden ? 'fa-angle-left' : 'fa-angle-right')}></i>
              </Toggle>
          )}

          <Content innerRef={this.ref} noScroll={this.props.noScroll === true}>
            {this.props.children}
          </Content>
        </Panel>
    )
  }
}

export default SidebarPanel;