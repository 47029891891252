import React from 'react';
import { connect } from 'react-redux';
import LinkButton from "./LinkButton";
import Button from './Button';
import FieldRow from './FieldRow';
import {authShowLogin} from '../actions/creators';
import {isUiAuthPanelRecoverPassSent} from '../reducers';
import {recoverPassPost} from '../actions/creators/auth';
import * as validators from '../utils/validation';

class RecoverPassPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: '',
    };
  }

  onChange(prop, value) {
    this.setState({[prop]: value});
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.recoverPassPost(this.state.email);
  }

  render() {
    return (
        <React.Fragment>
          <div className="px-5 py-4">
            <h3 className="h3 mb-3">
              <LinkButton className="text-muted mr-3" onClick={this.props.showLogin}><i className="fal fa-arrow-left fa-xs"></i></LinkButton>
              Recupera tu contraseña
            </h3>

            {this.props.sent ? (
                <p className="text-primary lead fade-in">En unos instantes recibirás un email con las instrucciones para recuperar tu contraseña.</p>
            ) : (
                <React.Fragment>
                  <p>Ingresa tu email para recuperar tu contraseña.</p>
                  <form onSubmit={this.onSubmit}>
                    <FieldRow type="email" label="Email" onChange={(e) => this.onChange('email', e.target.value)} value={this.state.email}/>
                    <div className="form-group text-right mt-5 mb-5">
                      <Button className="mb-2" submit primary disabled={!validators.validateEmail(this.state.email)}>Enviar</Button>
                    </div>
                  </form>
                </React.Fragment>
            )}
          </div>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sent: isUiAuthPanelRecoverPassSent(state),
});

const mapDispatchToProps = dispatch => ({
  showLogin: () => dispatch(authShowLogin()),
  recoverPassPost: email => dispatch(recoverPassPost(email)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecoverPassPanel);