import * as actions from '../types';
import {dialogAlert} from './dialogs';
import CMX from '../../api';
import {isPromosLoading,getPromosLastLoadTime} from '../../reducers';

export const promosFetch = () => (dispatch, getState) => {
  const state = getState();

  if (isPromosLoading(state)) {
    return;
  }

  if ((new Date()).getTime() - getPromosLastLoadTime(state) < 60 * 60 * 1000) {
    return;
  }

  dispatch({
    type: actions.PROMOS_FETCH,
  });

  CMX.get('/promos/')
      .then(data => dispatch(promosFetchSuccess(data)))
      .catch(error => dispatch(promosFetchError(error)))
};

const promosFetchSuccess = data => ({
  type: actions.PROMOS_FETCH_SUCCESS,
  data: data,
});

const promosFetchError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.PROMOS_FETCH_ERROR,
    error,
  })
};
