import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {candybarFetch} from '../../actions/creators/checkout/candybar';
import CandybarCategories from "./CandybarCategories";
import CandybarItem from "./CandybarItem";
import CandybarItems from "./CandybarItems";
import StepHeader from './StepHeader';
import {prevStep} from '../../actions/creators/checkout';
import {candybarCategorySelect, candybarItemAdd, candybarItemEdit, candybarItemEditCancel} from '../../actions/creators/checkout/candybar';

const CandybarCatalog = styled.div`
  display: ${props => props.hidden ? 'none' : 'block'};
`;

class CandybarSelection extends React.Component {
  constructor(props) {
    super(props);

    this.onItemAddClick = this.onItemAddClick.bind(this);
  }

  componentWillMount() {
    if (this.props.flowDirection === 'prev') {
      return;
    }

    this.props.candybarFetch();
  }
  
  renderCatalog() {
    if (this.props.isLoading) {
      return '';
    } else if (this.props.loadError) {
      return 'Ocurrió un error.';
    }else if (!this.props.categories) {
      return '';
    }
    
    return (
        <React.Fragment>
          <CandybarCategories onSelect={this.props.onCategorySelect} categories={this.props.categories}/>
          <CandybarItems onAdd={this.onItemAddClick} onEdit={this.props.onItemEdit} items={this.props.items} order={this.props.order} />
        </React.Fragment>
    )
  }

  onItemAddClick(itemId) {
    this.props.onItemAdd(this.props.items[itemId]);
  }

  render() {
    return (
        <React.Fragment>
          <CandybarCatalog hidden={!!this.props.selectedItem}>
            <StepHeader onPrevClick={this.props.prevStep} label="Realiza tu pedido"/>
            {this.renderCatalog()}
          </CandybarCatalog>

          {this.props.selectedItem && <CandybarItem
              onClose={this.props.onItemEditCancel}
              item={this.props.selectedItem}
          />}
        </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  let categories = [], itemIds = [], items = {}, selectedItem;
  const {filterByCategory} = state.checkoutData.candybar;

  if (state.checkoutData.candybar.catalog) {
    state.checkoutData.candybar.catalog.forEach(category => {
      categories.push(category);
      if (!filterByCategory || category.id === filterByCategory) {
        category.items.forEach(item => {
          items[item.id] = item;
          itemIds.push(item.id);
        });
      }
    });
  }

  const selectedItemId = state.checkoutData.candybar.selectedItem;
  if (selectedItemId) {
    for (let i = 0, l = itemIds.length; i < l; i++) {
      if (itemIds[i] === state.checkoutData.candybar.selectedItem) {
        selectedItem = items[itemIds[i]];
        break;
      }
    }
  }

  return {
    isLoading: state.checkoutData.candybar.isLoading,
    loadError: state.checkoutData.candybar.loadError,
    order: state.checkoutData.order.candybar,
    categories,
    items,
    selectedItem,
    flowDirection: state.checkoutData.flowDirection,
  }
};

const mapDispatchToProps = dispatch => ({
  candybarFetch: () => dispatch(candybarFetch()),
  onCategorySelect: (categoryId) => dispatch(candybarCategorySelect(categoryId)),
  onItemAdd: (item) => dispatch(candybarItemAdd(item)),
  onItemEdit: (itemId, unitId) => dispatch(candybarItemEdit(itemId, unitId)),
  onItemEditCancel: () => dispatch(candybarItemEditCancel()),
  prevStep: () => dispatch(prevStep()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandybarSelection);