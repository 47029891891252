import React from 'react';
import {Link} from 'react-router-dom';
import LinkButton from "../LinkButton";
import Timer from "./Timer";

export default props => {
  let ButtonElement;

  if (props.to) {
    ButtonElement = Link;
  } else {
    ButtonElement = LinkButton;
  }

  return (
      <div className="d-flex align-items-center mb-5">
        <h1 className="h2">
          {(props.onPrevClick || props.to) && <ButtonElement onClick={props.onPrevClick} to={props.to} className="text-muted pr-3">
            <i className="fal fa-arrow-left fa-xs"></i>
          </ButtonElement>}
          {props.label}
        </h1>
        {props.timer !== false && <Timer/>}
      </div>
  );
}