import React from 'react';
import ShowtimeButton from './ShowtimeButton';

const shallowSessionsSerialize = sessions => sessions.map(session => session.id).join(',');

const getVersionLabel = version => {
  const types = version.type.filter(type => !type.match(/^lang_/));
  let label = version.label;

  if (!types.length || types.indexOf('2d') > -1) {
    label = 'Tradicional ' + label;
  }

  return label;
};

class BillboardMovieVersion extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.version.id !== nextProps.version.id
        || shallowSessionsSerialize(this.props.version.sessions) !== shallowSessionsSerialize(nextProps.version.sessions);
  }

  render() {
    const {version} = this.props;
    const label = getVersionLabel(version);

    return (
        <div className="row no-gutters border-top pt-4" key={version.id}>
          <div className="col col-12 col-lg-3">
            <h3 className="h6 text-secondary mb-4 mr-3">{label}</h3>
          </div>
          <div className="col col-12 col-lg-9">
            <div className="o-btn-group mb-4">
              {version.sessions.map(session => <ShowtimeButton key={session.id} session={session} askConfirmation={true} version={label} movieName={this.props.movieName} />)}
            </div>
          </div>
        </div>
    );
  }
}

export default BillboardMovieVersion;