import React from 'react';
import { withRouter } from 'react-router-dom';
import { AnalyticsModals } from '../utils/analytics';
import { setModalDisplayedFlag } from '../utils/modals';
import BodyBlocker from './BodyBlocker';
import CloseCross from './CloseCross';

let timer;

class ModalFullScreen extends React.Component {
    constructor(props) {
        super(props);

        this.onIframeMessage = this.onIframeMessage.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onTimeout = this.onTimeout.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        this.attachIframeEventListener();
    }

    componentWillUnmount() {
        this.stopTimer();
        this.removeIframeEventListener();
    }

    attachIframeEventListener() {
        window.addEventListener("message", this.onIframeMessage);
    }

    removeIframeEventListener() {
        window.removeEventListener("message", this.onIframeMessage);
    }

    onIframeMessage(e) {
        let event;
        if ((typeof e.data) === 'string') {
            event = e.data;
        } else {
            event = e.data.event;
        }

        switch (event) {
            case "modal.ready":
                this.setState({visible: true});
                break;
            case "modal.closeModalTimeout":
                this.close();
                break;
            case 'modal.internalLink':
                const {modal} = this.props;
                AnalyticsModals.trackClick(modal.id, modal.name, 'window', null);
                this.props.history.push(e.data.url);
                break;
        }
    }

    startTimer() {
        this.stopTimer();
        timer = setTimeout(this.onTimeout, this.props.modal.timeout * 1000);
    }

    stopTimer() {
        timer && clearTimeout(timer);
    }

    onClick(event) {
        const {modal} = this.props;
        const openInNewWindow = event.altKey || event.ctrlKey || event.shiftKey;
        AnalyticsModals.trackClick(modal.id, modal.name, 'window', openInNewWindow ? null : () => {
            document.location = modal.url;
        });
        if (!openInNewWindow) event.preventDefault();
    }
    
    onTimeout() {
        this.close();
    }

    close() {
        AnalyticsModals.trackClose(this.props.modal.id, this.props.modal.name, 'window');
        this.props.onClose();
    }

    render() {
        const {modal} = this.props;

        setModalDisplayedFlag(modal);
        AnalyticsModals.trackView(modal.id, modal.name, 'window');

        switch (modal.type) {
            case 'image':
                return this.renderImage(modal);
            case 'html5':
                return this.renderHtml(modal);
            default:
                return '';
        }
    }

    renderImage(modal) {
        const image = <img src={modal.file} style={{position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            maxHeight: '90vh',
            maxWidth: '90vw'}}/>;
            
        this.startTimer();

        return <BodyBlocker style={{zIndex:2000}}>
            {modal.url ? (<a href={modal.url} target="_blank" onClick={this.onClick}>{image}</a>) : image}
            <CloseCross onClick={this.close}/>
        </BodyBlocker>;
    }

    renderHtml(modal) {
        const url = 'https:' + modal.file.substr(0, modal.file.length - 4) + '/index.html';

        return <BodyBlocker style={{display: this.state.visible ? 'block' : 'none',zIndex:2000,background:'transparent'}}>
            <iframe src={url} style={{border:0,outline:'none',width:'100%',height:'100%'}} allowTransparency></iframe>
        </BodyBlocker>;;
    }
}

export default withRouter(ModalFullScreen);