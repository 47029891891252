import cookies from 'browser-cookies';

export const getFlagId = id => 'modalAlreadyDisplayed' + id;

export const setModalDisplayedFlag = modal => {
  const now = (new Date()).getTime();
  const inAWeek = new Date(now + 7 * 24 * 60 * 60 * 1000);

  cookies.set(getFlagId(modal.id), now.toString(), {expires: inAWeek, secure: true});
  cookies.set(getFlagId(modal.id + '-' + modal.matchedTarget), now.toString(), {expires: inAWeek, secure: true});
};