import React from 'react';
import {Link} from 'react-router-dom';
import LinkButton from './LinkButton';

class PromosPageContent extends React.Component {
  render() {
    if (this.props.isLoading) return '';

    return (
        <React.Fragment>
          <h1 className="h2 mb-5">
            <Link to="/" className="text-muted pr-3"><i className="fal fa-arrow-left fa-xs"></i></Link>
            Promociones
          </h1>

          <div className="row">
            {this.renderPromos()}
          </div>
        </React.Fragment>
    );
  }

  renderPromos() {
    const {promos} = this.props;

    return promos.map(promo => (
        <div key={promo.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-gutter">
          <div className="c-card">
            <LinkButton onClick={() => this.props.onSelect(promo)} href="#" className={'c-card__link jsToggle ' + (this.props.selectedPromo && this.props.selectedPromo.id === promo.id ? 'is-active' : '')}>
              <img className="c-card__image" src={promo.thumb_v2} alt={promo.title} />
            </LinkButton>
          </div>
        </div>
    ));
  }
}

export default PromosPageContent;