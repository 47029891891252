import React from 'react';
import Button from '../Button';
import * as validators from '../../utils/validation';
import IePanelHint from '../IePanelHint';

class IeCodePanelStep2 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onBarCodeChange = this.onBarCodeChange.bind(this);
    this.onBirthDateChange = this.onBirthDateChange.bind(this);
  }

  onBarCodeChange(e) {
    const barCode = e.target.value;
    this.props.onBarCodeChange(barCode);
  }

  onBirthDateChange(e) {
    const birthDate = e.target.value;
    this.props.onBirthDateChange(birthDate);
  }

  render() {
    return (
      <div>
        <form onSubmit={this.props.onSubmit}>
          <div className="form-group mb-5">
            <label htmlFor="barCode">Código de barras:</label>
            <input type="text" className="form-control form-control-lg" id="barCode" autoFocus required
                   value={this.props.barCode} onChange={this.onBarCodeChange} disabled={this.props.processing}
            />
          </div>
          <div className="form-group mb-5">
            <label htmlFor="birthDate">Fecha de nacimiento:</label>
            <input type="text" className="form-control form-control-lg" id="birthDate" required
                   value={this.props.birthDate} onChange={this.onBirthDateChange} disabled={this.props.processing}
                   placeholder="dd/mm/aaaa"
            />
          </div>
          <Button submit primary large block disabled={!validators.validateIeBarCode(this.props.barCode) || !validators.validateBirthDate(this.props.birthDate) || this.props.processing}>Ingresar</Button>
        </form>
        <IePanelHint step="barcode" checkout={this.props.checkout} />
      </div>
    );
  }
}

export default IeCodePanelStep2;