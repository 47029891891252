import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {getPaymentString} from '../../utils/checkout';
import {parseSessionIsoDateForDisplay} from '../../utils/dates';
import {centsToCurrencyString} from '../../utils/numbers';
import {ucFirst} from '../../utils/strings';
import { AnalyticsCheckout } from '../../utils/analytics';
import { breakpoints } from '../../utils/styles';

const PaymentMethodRow = styled.div`
  font-size: 0.8em;
  line-height: 1.75em;
`;

const PaymentMethodLogo = styled.img`
  vertical-align: middle;
  margin: -2px 0.5em 0;
`;

const PayPalLogo = styled(PaymentMethodLogo)`
  max-width: 70px;
`;

const PromoContanier = styled.div`
  display: none;
  padding-right: 3rem;
  position: absolute;
  right: -16rem;
  top: 0;
  
  & > img {
    height: 25rem;
    border-radius: 1.5rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`;

const TicketLastColumn = styled.div`
  &:before {
    ${props => (props.dynamicStyles && ('bg_color' in props.dynamicStyles)) ? `border-color: ${props.dynamicStyles.bg_color[0]};` : ''}}
  }
`;

const Messages = styled.div`
  color: var(--primary);
  font-size: 0.9em;
  line-height: 1.35;
`;

const PosterContainer = styled.div`
  background-position: center;

  & > img { display: none; }
`

class Confirmation extends React.PureComponent {
  componentDidMount() {
    AnalyticsCheckout.trackTransaction(this.props.data);
  }

  render() {
    const {data} = this.props;

    if (!data) {
      return '';
    }

    const date = parseSessionIsoDateForDisplay(data.session.timestamp);
    const paymentMethodsString = getPaymentString(data.payment.methods);
    const seatsString = data.seats ? data.seats.join('-') : '';
    const bookingPdfUrl = data.qr.replace('bookingQr', 'bookingPdf').replace(/\.(jpg|png)$/, '.pdf');

    return (
        <div className="c-ticket-wrap py-5 d-flex align-items-md-center slide-in-from-left" style={this.getContainerStyles()}>
          <div className="container">
            <div className="row">

              <div className="col-12 col-xl-10">

                <h1 className="h2 mb-5">
                  <Link to="/" className="text-white">
                    <i className="fal fa-arrow-left fa-xs pr-3"></i>
                    Volver a la Cartelera
                  </Link>
                </h1>

                <div style={{position:'relative'}}>
                  <div className="c-ticket">
                    <PosterContainer className="c-ticket__image" style={{backgroundImage:'url(' + data.movie.cover + ')'}}>
                      <img src={data.movie.cover} alt=""/>
                    </PosterContainer>
                    <div className="c-ticket__primary">
                      <div className="row">
                        <div className="col-12">
                          <p className="lead">
                            <small className="text-muted d-block">{data.cinema.name}</small>
                            {data.movie.name}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <small className="text-muted d-block">Fecha</small>
                            {ucFirst(date.format('dddd DD [de] MMMM'))}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            <small className="text-muted d-block">Hora</small>
                            {date.format('hh:mm A')}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {seatsString && (
                          <div className="col-6">
                            <p>
                              <small className="text-muted d-block">Asientos</small>
                              {seatsString}
                            </p>
                          </div>
                        )}
                        <div className="col-6">
                          <p>
                            <small className="text-muted d-block">Sala</small>
                            {data.auditorium_number}
                          </p>
                        </div>
                      </div>
                      {data.candybar && (
                        <div className="row">
                          <div className="col-12">
                            <p className="text-truncate">
                              <small className="text-muted d-block">Dulcería</small>
                              1 Super burguer + Bacón, 2 Burguer Extreme lorem ipsum dolor sit amet
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <small className="text-muted d-block">Tus boletos</small>
                            {centsToCurrencyString(data.payment.details.tickets)}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            <small className="text-muted d-block">Costo por servicio</small>
                            {centsToCurrencyString(data.payment.details.fees.booking)}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p>
                            <small className="text-muted d-block">Total pagado</small>
                            {paymentMethodsString}
                          </p>
                        </div>
                      </div>
                      {('extra' in data.payment) && data.payment.extra.paypal && (
                          <PaymentMethodRow className="row">
                            <div className="col-12">
                              <p>
                                <small className="text-muted d-block">
                                  Pagado con
                                  <PayPalLogo src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal Logo"/>
                                </small>
                                <small className="text-muted d-block">
                                  Transacción {data.payment.extra.paypal}
                                </small>
                              </p>
                            </div>
                          </PaymentMethodRow>
                      )}
                      {('extra' in data.payment) && data.payment.extra.masterpass && (
                          <PaymentMethodRow className="row">
                            <div className="col-12">
                              <p>
                                <small className="text-muted d-block">
                                  Pagado con
                                  <PaymentMethodLogo src="https://static.masterpass.com/dyn/img/acc/global/mp_mark_hor_blk.svg" alt="Masterpass Logo"/>
                                </small>
                              </p>
                            </div>
                          </PaymentMethodRow>
                      )}
                      {data.messages && data.messages.length ? (
                        <div className="row">
                          <Messages className="col-12">
                            {data.messages.map(m => <React.Fragment>{m}<br/></React.Fragment>)}
                          </Messages>
                        </div>
                      ) : ''}
                    </div>
                    <TicketLastColumn className="c-ticket__secondary" dynamicStyles={this.props.data.styles}>
                      <span className="o-site-logo mx-auto mb-4"></span>
                      <p className="mb-4">
                        <span className="d-block">Código de compra</span>
                        <strong>{data.confirmation_code}</strong>
                      </p>
                      <img className="img-fluid" src={data.qr} alt="" style={{width:'150px'}}/>
                      <a href={bookingPdfUrl} className="btn btn-primary btn-block mt-4" target="_blank" rel="noopener noreferrer">Descargar</a>
                    </TicketLastColumn>
                  </div>

                  {this.renderPromo()}
                </div>

                {data.extra_buttons && data.extra_buttons.length ? (
                <div style={{marginBottom:'2rem'}}>
                  {data.extra_buttons.map(b => <a href={b.url} className="btn btn-light mr-3" target='_blank'>{b.label}</a>)}
                </div>
                ) : ''}

                <div style={{color:'#fff',color:'rgba(255,255,255,0.75)',fontSize:'0.75em',letterSpacing:'0.05em',padding:'0'}}>
                  Hemos enviado la confirmación de compra y tus boletos a la dirección de correo {data.confirmation_email}. Si no la recibiste, te recomendamos descargarla haciendo <a href={bookingPdfUrl} target="_blank" rel="noopener noreferrer" style={{color:'#fff',textDecoration:'underline'}}>clic aquí</a> o en el botón "Descargar" para que puedas ingresar el cine sin hacer filas.
                </div>

              </div>

              {this.props.ad && (
                  <div className="col-12 col-xl-2 mt-5 mt-xl-0 text-center">
                    <a href="tickets-7.php" className="d-inline-block">
                      <img className="img-fluid" src="uploads/ad-cocacola-150x460.jpg" alt=""/>
                    </a>
                  </div>
              )}

            </div>
          </div>

        </div>
    );
  }

  getContainerStyles() {
    if (!this.props.data.styles || !this.props.data.styles.bg_color) return {};

    return {
      background: `linear-gradient(to right, ${this.props.data.styles.bg_color[0]}, ${this.props.data.styles.bg_color[1]})`
    };
  }

  renderPromo() {
    if (!this.props.data.side_banner) return null;

    return <PromoContanier>
      <img src={this.props.data.side_banner} alt='' />
    </PromoContanier>;
  }
}

export default Confirmation;