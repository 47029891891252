import React from 'react';
import {connect} from 'react-redux';
import {nipCodeUpdateSubmit} from '../../actions/creators/ie';
import LinkButton from "../LinkButton";
import Button from '../Button';
import BodyBlocker from "../BodyBlocker";
import * as validators from '../../utils/validation';

const formatNipCode = v => {
  const maxLength = 4;

  return v.replace(/[^\d]/g, '').substr(0, maxLength);
};

class UserNipCode extends React.Component {
  constructor(props) {
    super(props);

    this.onPrevClick = this.onPrevClick.bind(this);
    this.onNipChange = this.onNipChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      code: formatNipCode(''),
    };
  }

  onNipChange(e) {
    const {value} = e.target;
    this.setState(() => ({code: formatNipCode(value)}));
  }

  onPrevClick() {
    this.props.onCancel()
  }

  onSubmit(e) {
    e.preventDefault();

    const {code} = this.state;
    this.props.onSubmit({code});
  }

  onCancel() {
    this.props.onCancel();
  }

  renderPanel() {
    const submitDisabled = !validators.validateIeNip(this.state.code)
        || this.props.processing;
    
    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group mb-5">
          <label htmlFor="nipCode">Ingresa el nuevo NIP (4 dígitos):</label>
          <input type="text" className="form-control form-control-lg" id="birthDate" required
                 value={this.state.code} onChange={this.onNipChange} disabled={this.props.processing}
          />
        </div>
        <Button submit primary large block disabled={submitDisabled}>Modificar</Button>
      </form>
    );
  }

  render() {
    return (
        <div>
          {this.props.processing && <BodyBlocker color="255,255,255"/>}
          <h1 className="c-entry__title">
            <LinkButton onClick={this.onCancel} className="text-muted pr-3">
              <i className="fal fa-arrow-left fa-xs"></i>
            </LinkButton>
            Modificar NIP
          </h1>
          {this.renderPanel()}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  processing: state.checkoutData.ui.ieCodePanel.processing,
  step: state.checkoutData.ui.ieCodePanel.step,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: payload => dispatch(nipCodeUpdateSubmit(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserNipCode);