import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';
import {getCinemas} from '../reducers';
import {sortByProperty} from '../utils/arrays';
import { compareIgnoringAccents } from '../utils/strings';

const Styled = styled.div`
  .react-autosuggest__suggestions-list { border-left: 1px solid #eee; border-right: 1px solid #eee; list-style-type: none; padding: 0; }
  .react-autosuggest__suggestion { cursor: pointer; border-bottom: 1px solid #eee; padding: .375rem .75rem; }  
  .react-autosuggest__suggestion--highlighted { color: white; background: var(--primary); }
`;

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
    </div>
);

const inputElement = props => <input {...props} className="form-control"/>;

class CinemaSelectorAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);

    this.state = {
      value: '',
      suggestions: [],
    };
  }

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestions(value) {
    value = value.toLowerCase();
    return value.length === 0 ? [] : this.props.cinemas.filter(cinema => compareIgnoringAccents(cinema.name.toLowerCase(), value.toLowerCase()));
  }

  onChange = (event, {newValue, method}) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    this.props.onSelect(parseInt(suggestion.area.id, 10), parseInt(suggestion.id, 10));
  }

  render() {
    const {value, suggestions} = this.state;

    const inputProps = {
      placeholder: 'Ingresa el nombre del cine',
      value,
      onChange: this.onChange
    };

    return (
        <Styled className="mb-5">
          <p>O escribe el nombre de tu cine favorito:</p>
          <Autosuggest
              renderInputComponent={inputElement}
              suggestions={suggestions}
              onSuggestionSelected={this.onSuggestionSelected}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
          />
        </Styled>
    );
  }
}

const mapStateToProps = state => ({
  cinemas: getCinemas(state).sort(sortByProperty('name'))
});

export default connect(
    mapStateToProps
)(CinemaSelectorAutocomplete);