import React from 'react';
import NavBarLayout from './NavBarLayout';

const routes = [
  {
    title: 'Términos y condiciones',
    slug: 'terminos-y-condiciones',
    path: '/terminos'
  },
  {
    title: 'Política de precios',
    slug: 'politica-de-precios',
    path: '/politica-precios'
  },
  {
    title: 'Política de reembolsos',
    slug: 'politica-de-reembolsos',
    path: '/politica-de-reembolsos'
  },
  {
    title: 'Comunicados',
    slug: 'comunicados',
    path: '/comunicados'
  },
  {
    title: 'Términos y condiciones de compra',
    slug: 'terminos-y-condiciones-de-compra',
    path: '/terminos-y-condiciones-de-compra',
    nav: false
  },
];

export default props => <NavBarLayout fixedTitle="Términos y condiciones" routes={routes}/>