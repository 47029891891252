import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ieCodePanelShow } from '../actions/creators/ie';
import { postRegisterPopupClose } from '../actions/creators/ui';
import { asset } from '../utils/misc';
import BodyBlocker from './BodyBlocker';
import Button from './Button';
import CloseCross from './CloseCross';

const Modal = styled.div`
    position: fixed;
    background: white;
    width: 90%;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    border-radius: 4px;
    padding: 2em 4em;
    max-width: 480px;
    font-size: 0.85em;
    text-align: center;
`;

const Icon = styled.img`
    width: 4em;
    margin-bottom: 2em;
`;

const Title = styled.h1`
    font-size: 1.65em;
    margin-bottom: 1em;
`;

const Buttons = styled.div`
    margin: 2em 0;

    & > .btn {
        font-size: 1.1em;
        width: 100%;
        margin: 0.35em 0;
    }
`;

class PostRegisterPopup extends React.Component {
    render() {
        return <React.Fragment>
            <BodyBlocker onClick={this.props.close}/>
            <Modal>
                <CloseCross theme="dark" onClick={this.props.close}/>
                {this.props.purchase ? this.getAfterPurchaseContent() : this.getAfterSignUpContent()}
            </Modal>    
        </React.Fragment>;
    }

    getAfterPurchaseContent() {
        return <section>
            <Icon src={asset('/dist/images/icon-corwn.svg')} alt=""/>
            <Title>¡No te pierdas los beneficios de Invitado Especial!</Title>
            <p>Con tu última compra hubieses sumado hasta {this.getPointsString()} y 1 visita :(</p>
            <p className="small text-muted">* Acumulación basada en el nivel Premium</p>
            <p>Si ya eres Invitado Especial, no olvides vincular tu tarjeta a tu cuenta para poder usar tus puntos y pases, además de acumular puntos y visitas.</p>
            <Buttons>
                <Button primary onClick={this.props.showIeAndClose}>Vincular mi cuenta de Invitado Especial</Button>
                <Button onClick={this.props.close}>Recordarme más tarde</Button>
            </Buttons>
            <p className="small text-muted">Si aún no eres parte de Invitado Especial, <a href="/invitado-especial/programa">haz clic aquí</a> para conocer más sobre nuestro programa de lealtad.</p>
            <p><a href="#" onClick={() => this.props.close({remember: true})}>No volver a mostrar este mensaje</a></p>
        </section>;
    }
    
    getAfterSignUpContent() {
        return <section>
            <Icon src={asset('/dist/images/icon-party.svg')} alt=""/>
            <Title>¡Bienvenido a Cinemex!</Title>
            <p>Ya estás listo para vivir La Magia del Cine. En tu cuenta puedes revisar tu historial de compras, recuperar tus tickets activos, marcar cines como favoritos, agendar recordatorios para los estrenos de tus películas favoritas ¡y mucho más!</p>
            <p>Además, si eres Invitado Especial puedes vincular tu tarjeta a esta cuenta y sumar puntos y visitas por todas tus compras, además de redimir tus puntos.</p>
            <Buttons>
                <Button primary onClick={this.props.showIeAndClose}>Vincular mi cuenta de Invitado Especial</Button>
                <Button onClick={this.props.close}>Recordarme más tarde</Button>
            </Buttons>
            <p className="small text-muted">Si aún no eres parte de Invitado Especial, <a href="/invitado-especial/programa">haz clic aquí</a> para conocer más sobre nuestro programa de lealtad.</p>
        </section>;
    }

    getPointsString() {
        const points = Math.floor(this.props.purchase.amount * 0.1);
        
        return points + ' punto' + (points === 1 ? '' : 's');
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    showIeAndClose: () => { dispatch(ieCodePanelShow()); dispatch(postRegisterPopupClose()); },
    close: (args = {}) => dispatch(postRegisterPopupClose(args)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostRegisterPopup);