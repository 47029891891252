import {toRad} from './numbers';

export const haversine = (pos_a, pos_b) => {
  const lat2 = pos_b.lat,
      lon2 = pos_b.lng,
      lat1 = pos_a.lat,
      lon1 = pos_a.lng
  ;
  
  const R    = 6371; // km
  //has a problem with the .toRad() method below.
  const x1   = lat2 - lat1;
  const dLat = toRad(x1);
  const x2   = lon2 - lon1;
  const dLon = toRad(x2);
  const a    = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
  const c    = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d    = R * c;

  return d;
};

const sortByDistance = (a, b) => {
  if (a.d === b.d) {
    return 0;
  } else if (a.d < b.d) {
    return -1;
  } else {
    return 1;
  }
};

export const filterCinemasNearLatLng = (cinemas, lat, lng, count = 3) => {
  const distances = cinemas.map(cinema => ({
    d: haversine({lat, lng}, cinema),
    cinema: cinema
  }));

  distances.sort(sortByDistance);

  return distances.slice(0, count).map(result => result.cinema);
};