import {combineReducers} from 'redux';
import * as actions from '../actions/types';


// const landings = (state = [], action) => {
//   return state;
// };

// const isLoading = (state = false, action) => {
//   switch (action.type) {
//     case actions.LANDING_FETCH: return true;
//     case actions.LANDING_FETCH_SUCCESS: return false;
//     case actions.IE_BENEFITS_FETCH_ERROR: return false;
//     default: return state;
//   }
// };

const lastLoadTime = (state = {}, action) => {
  switch (action.type) {
    case actions.LANDING_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.slug]: new Date().getTime()});
    default:
      return state;
  }
};

const isLoading = (state = {}, action) => {
  switch (action.type) {
    case actions.LANDING_FETCH:
      return Object.assign({}, state, {[action.slug]: true});
    case actions.LANDING_FETCH_SUCCESS:
    case actions.LANDING_FETCH_ERROR:
      return Object.assign({}, state, {[action.slug]: false});
    default:
      return state;
  }
};

const showtimes = (state = null, action) => {
  switch (action.type) {
    case actions.LANDING_SHOWTIMES_FETCH_SUCCESS:
      return Object.assign({}, state, {[`${action.pageId}-${action.area.type}-${action.area.entity.id}-${action.movieId}`]: action.data});
    default:
      return state;
  }
}

export default combineReducers({
  lastLoadTime,
  isLoading,
  showtimes,
});