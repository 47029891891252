import React from 'react';

class BillboardCinemaHeading extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.cinema.id !== nextProps.cinema.id;
  }

  render() {
    const {cinema} = this.props;

    return (
        <React.Fragment>
          <hgroup>
            <h2 className="h4 text-primary">{cinema.name}</h2>
            <div className="cinema-head-info">
              {cinema.info.address && <p className="text-muted mb-0">Dirección: {cinema.info.address}</p>}
              {cinema.info.phone && <p className="text-muted mb-0">Teléfono: {cinema.info.phone}</p>}
            </div>
          </hgroup>
        </React.Fragment>
    )
  }
}

export default BillboardCinemaHeading;