import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {getUserInfo,getUserToken,getUserProfileEdit} from '../../reducers';
import {updateInfo,avatarUpload,setUserProfileEdit, fetchUserInfo} from '../../actions/creators/user';
import {ieCodeDelete} from '../../actions/creators/ie';
import Button from '../Button';
import LinkButton from "../LinkButton";
import * as validators from '../../utils/validation';
import {checkPassStrength,getPassStrengthLabel} from '../../utils/passwords';
import UserIeCode from "./UserIeCode";
import UserNipCode from './UserNipCode';
import { hasIeAction, onAvatarLoadError } from '../../utils/misc';
import Hint from '../forms/Hint';
import FieldRow from '../FieldRow';
import FieldPasswordRow from '../FieldPasswordRow';
import IconButton from '../IconButton';

const Avatar = styled.div`
  cursor: pointer;
  width: 100%;

  & > img { width: 100%; }
`;

const FileField = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.mapPropsToState = this.mapPropsToState.bind(this);
    this.setEdit = this.setEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.getPasswordLabel = this.getPasswordLabel.bind(this);
    this.onAvatarSelect = this.onAvatarSelect.bind(this);

    this.state = {};
  }

  componentDidMount() {
    const isChangeNipUrl = document.location.pathname.split('/').filter(part => part).pop() === 'cambiar-nip';
    this.props.fetchUserInfo();
    this.props.setEdit(isChangeNipUrl ? 'nip' : null);
    this.mapPropsToState();
  }
  

  componentDidUpdate(prevProps) {
    if (this.props.userToken !== prevProps.userToken) {
      this.mapPropsToState();
    }
  }

  mapPropsToState() {
    this.setState(() => ({
      first_name: this.props.userInfo.first_name,
      last_name: this.props.userInfo.last_name,
      email: this.props.userInfo.email,
    }));
  }

  setEdit(field) {
    this.setState(() => ({
      password: '',
      passRepeat: '',
      passCheck: '',
    }));
    this.props.setEdit(field);
  }

  onFieldChange(field, value) {
    this.setState(() => ({[field]: value}));
  }

  onSubmit(e) {
    e.preventDefault();

    const {edit} = this.props;
    const values = {};

    this.props.setEdit(null);

    switch (edit) {
      case 'name':
        values.first_name = this.state.first_name;
        values.last_name = this.state.last_name;
        break;
      case 'email':
        values.email = this.state.email;
        values.password_check = this.state.passCheck;
        break;
      case 'password':
        values.password = this.state.password;
        values.password_check = this.state.passCheck;
        break;
      default:
        return;
    }

    this.props.updateInfo(values);
  }

  onCancel() {
    this.props.setEdit(null);
  }

  onAvatarSelect(e) {
    const {files} = e.target;
    if (!files.length) {
      return;
    }

    this.props.avatarUpload(files[0]);
  }

  render() {
    return <React.Fragment>
      <Helmet><title>Mis datos - Cinemex</title></Helmet>
      {this.renderContent()}
    </React.Fragment>;
  }

  renderContent() {
    switch (this.props.edit) {
      case 'name':
        return this.renderEditName();
      case 'password':
        return this.renderEditPassword();
      case 'ie':
        return this.renderEditIe();
      case 'nip':
        return this.renderEditNip();
      default:
        return this.renderProfile();
    }
  }

  renderProfile() {
    const {userInfo} = this.props;

    const ieSignUpEnabled = hasIeAction(userInfo, 'sign-up');

    return (
        <div className="fade-in" key="profile">

          <div className="row">
            <div className="col-3 col-md-2 mb-3">
              <Avatar className="o-edit-icon rounded">
                <i className="fal fa-camera fa-2x"></i>
                <img src={userInfo.avatar.replace('-64x64', '-360x360')} className="img-fluid" alt="" onError={onAvatarLoadError}/>
                <FileField type="file" onChange={this.onAvatarSelect}/>
              </Avatar>
            </div>
            <div className="col-12 col-md-10">
              <form>
                <div className="form-group row c-reveal-child align-items-end">
                  <div className="col-8">
                    <label htmlFor="userName">Nombre</label>
                    <input type="text" className="form-control" id="userName" value={this.state.first_name + ' ' + this.state.last_name} disabled/>
                  </div>
                  <div className="col-4 c-reveal-child__item">
                    <Button primary block onClick={() => this.setEdit('name')}>
                      <span className="d-inline d-md-none"><i className="fal fa-pencil"></i></span>
                      <span className="d-none d-md-inline">Modificar</span>
                    </Button>
                  </div>
                </div>
                <div className="form-group row c-reveal-child align-items-end">
                  <div className="col-8">
                    <label htmlFor="userPass">Contraseña</label>
                    <input type="password" className="form-control" id="userPass" value="xxxxxxxxxxx" disabled/>
                  </div>
                  <div className="col-4 c-reveal-child__item">
                    <Button primary block onClick={() => this.setEdit('password')}>
                      <span className="d-inline d-md-none"><i className="fal fa-pencil"></i></span>
                      <span className="d-none d-md-inline">Modificar</span>
                    </Button>
                  </div>
                </div>
                <div className="form-group row c-reveal-child align-items-end">
                  <div className="col-8">
                    <label htmlFor="userEmail">Email</label>
                    <input type="email" className="form-control" id="userEmail" value={this.state.email} disabled/>
                  </div>
                </div>
                <div className="form-group row c-reveal-child align-items-end">
                  <div className="col-8">
                    <label htmlFor="userInvitadoEspecial">Invitado Especial</label>
                    <input type="text" className="form-control" id="userInvitadoEspecial" value={userInfo.iecode || 'No hay número asignado'} disabled/>
                  </div>
                  <div className="col-4 c-reveal-child__item">
                    {userInfo.iecode ? (
                        <Button primary block onClick={this.props.ieCodeDelete}>
                          <span className="d-inline d-md-none"><i className="fal fa-times"></i></span>
                          <span className="d-none d-md-inline">Desvincular</span>
                        </Button>
                    ) : ''}
                  </div>
                </div>
                {userInfo.iecode && (
                  <div className="form-group row c-reveal-child align-items-end">
                    <div className="col-8">
                      <label htmlFor="userNip">NIP</label>
                      <input type="password" className="form-control" id="userNip" value="xxxx" disabled/>
                    </div>
                    <div className="col-4 c-reveal-child__item">
                      <Button primary block onClick={() => this.setEdit('nip')}>
                        <span className="d-inline d-md-none"><i className="fal fa-pencil"></i></span>
                        <span className="d-none d-md-inline">Modificar</span>
                      </Button>
                    </div>  
                  </div>
                )}
              </form>
              <div className="mt-5">
                {!userInfo.iecode
                  ? <IconButton title="Vincula tu Tarjeta Invitado Especial"
                    small="Si ya cuentas con una Tarjeta de Invitado Especial, haz click aquí para vincularla"
                    onClick={() => this.setEdit('ie')}
                    icon={<i class="fa-light fa-credit-card"></i>}/>
                  : ''}
                {ieSignUpEnabled
                  ? <IconButton title="Inscripción Invitado Especial"
                    small="Haz click aquí para ser parte del programa"
                    to="/usuario/invitado-especial/inscripcion"
                    icon={<i class="fa-light fa-crown"></i>}
                    color="primary"/>
                  : ''}
                {userInfo.iecode && userInfo.migration_available
                  ? <IconButton title="Cambia tu tarjeta Invitado Especial"
                      small="Haz click aquí para ser parte de nuestro nuevo programa"
                      to="/usuario/invitado-especial/migracion"
                      icon={<i class="fa-light fa-arrow-right-arrow-left"></i>}/>
                  : ''}
                  
                {userInfo.iecode && userInfo.renewal_available
                  ? <IconButton title="Cambio de Nivel Invitado Especial"
                      small="Haz click aquí para mantener o subir tu nivel"
                      to="/usuario/invitado-especial/cambio-de-nivel"
                      icon={<i class="fa-light fa-arrow-rotate-left"></i>}/>
                  : ''}
              </div>
            </div>
          </div>
        </div>
    )
  }

  renderEditName() {
    return (
        <div className="fade-in">
          <h1 className="c-entry__title">
            <LinkButton onClick={this.onCancel} className="text-muted pr-3">
              <i className="fal fa-arrow-left fa-xs"></i>
            </LinkButton>
            Modificar tu nombre
          </h1>
          <form className="row" onSubmit={this.onSubmit}>
            <div className="col-12 col-lg-10">
              <FieldRow label="Nombre" value={this.state.first_name} onChange={(e) => this.onFieldChange('first_name', e.target.value)} placeholder="Ingresa tu nombre"/>
              <FieldRow label="Apellido" value={this.state.last_name} onChange={(e) => this.onFieldChange('last_name', e.target.value)} placeholder="Ingresa tu apellido"/>
            </div>
            <div className="form-group col-12 col-lg-10">
              <Button submit primary>Modificar</Button>
            </div>
          </form>
        </div>
    );
  }

  renderEditPassword() {
    return (
        <div className="fade-in">
          <h1 className="c-entry__title">
            <LinkButton onClick={this.onCancel} className="text-muted pr-3">
              <i className="fal fa-arrow-left fa-xs"></i>
            </LinkButton>
            Modificar tu contraseña
          </h1>
          <form className="row" onSubmit={this.onSubmit}>
            
            
            <div className="col-12 col-lg-10">
              <FieldRow type="password" label="Contraseña actual" onChange={(e) => this.onFieldChange('passCheck', e.target.value)} value={this.state.passCheck} placeholder="Ingresa tu contraseña actual"/>
              <FieldPasswordRow onChange={(e) => this.onFieldChange('password', e.target.value)} value={this.state.password}
                hint="Ingresa al menos 8 caracteres. Utiliza mayúsculas, minúsculas, números y símbolos. Evita utilizar caracteres y números consecutivos."
              />
              <FieldRow type="password" label="Repetir contraseña" onChange={(e) => this.onFieldChange('passRepeat', e.target.value)} value={this.state.passRepeat} autoComplete='new-password'/>
            </div>
            <div className="form-group col-12 col-lg-10">
              <Button submit primary disabled={!(this.state.passCheck !== '' && validators.validatePassword(this.state.password) && this.state.password === this.state.passRepeat)}>Modificar</Button>
            </div>
          </form>
        </div>
    );
  }

  getPasswordLabel() {
    const pass = this.state.password;
    if (!pass || !pass.length) return 'Nueva contraseña';
    return getPassStrengthLabel(checkPassStrength(pass));
  }

  renderEditIe() {
    return <UserIeCode onCancel={() => this.setEdit(null)}/>;
  }

  renderEditNip() {
    return <UserNipCode onCancel={() => this.setEdit(null)}/>;
  }

}

const mapStateToProps = state => ({
  edit: getUserProfileEdit(state),
  userInfo: getUserInfo(state),
  userToken: getUserToken(state),
});

const mapDispatchToProps = dispatch => ({
  fetchUserInfo: () => dispatch(fetchUserInfo()),
  updateInfo: values => dispatch(updateInfo(values)),
  avatarUpload: file => dispatch(avatarUpload(file)),
  ieCodeDelete: () => dispatch(ieCodeDelete()),
  setEdit: section => dispatch(setUserProfileEdit(section)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile);