import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {centsToCurrencyString} from '../../utils/numbers';
import Button from '../Button';
import StepHeader from './StepHeader';
import {ticketQuantityIncrease,ticketQuantityDecrease,promoCodePanelShow} from '../../actions/creators/checkout/tickets';
import LinkButton from "../LinkButton";
import PromoCodePanel from "./PromoCodePanel";

const TicketRow = styled.div`
  ${props => props.disabled ?
    'opacity: 0.5; cursor: not-allowed;'
    :
    'opacity: 1'
    };
`;

const TicketDescription = styled.span`
    font-size: 0.9em;
    line-height: 1.2;
`;

function getTicketPriceString(ticket) {
  if (ticket.extra.is_pass) {
    return ticket.pcmultiplier + ' pase' + (ticket.pcmultiplier.toString() === '1' ? '' : 's') + ' c/u';
  }

  if (ticket.price === 0) {
    return 'Gratis';
  }
  
  return `${centsToCurrencyString(ticket.price)} c/u`;
}

class TicketsSelection extends React.Component {
  renderTicketRow(ticket) {
    return (
        <TicketRow disabled={!ticket.enabled} key={ticket.id}>
          <div className="d-flex justify-content-between align-items-center">
            <Button className="btn-white shadow" defaultPadding disabled={!ticket.enabled || !this.canDecreaseTicket(ticket)} onClick={() => this.props.ticketDecrease(ticket)}>
              <i className="fal fa-minus"></i>
            </Button>
            <div className="text-center">
              <span className="text-secondary d-block">
                <span className={'display-4 d-block ' + (ticket.qty ? 'text-primary' : '')}>{ticket.qty}</span>
                {ticket.name}
              </span>
              {ticket.description ? <TicketDescription className="text-secondary d-block">{ticket.description}</TicketDescription> : ''}
              <span className="text-primary">{getTicketPriceString(ticket)}</span>
            </div>
            <Button className="btn-white shadow" defaultPadding disabled={!ticket.enabled || !this.canIncreaseTicket(ticket)} onClick={() => this.props.ticketIncrease(ticket)}><i className="fal fa-plus"></i></Button>
          </div>
          <hr/>
        </TicketRow>
    );
  }

  canIncreaseTicket(ticket) { return (ticket.qty < ticket.max) && (this.props.ticketsSum < this.props.session.tickets_limit) }

  canDecreaseTicket(ticket) { return ticket.qty > 0 }

  render() {
    if (this.props.promoCodesPanel.visible) {
      return <PromoCodePanel processing={this.props.promoCodesPanel.processing}/>;
    }

    const benefits = this.props.tickets.filter(ticket => ticket.extra.recognition);
    const regular = this.props.tickets.filter(ticket => !ticket.extra.recognition || ticket.extra.coupon);

    return (
        <React.Fragment>
          <StepHeader label="Selecciona tus boletos" timer={false} to="/"/>
          {benefits.length > 0 && <div>
            <h3>Tus beneficios</h3>
            {benefits.map(ticket => this.renderTicketRow(ticket))}
          </div>}
          
          {benefits.length > 0 && <h3 style={{marginTop:'2.5em'}}>Boletos generales</h3>}
          {regular.map(ticket => this.renderTicketRow(ticket))}
          <p className="mt-5 lead text-center"><LinkButton onClick={this.props.promoCode ? null : this.props.showPromoCodePanel} disabled={!!this.props.promoCode}>¿Tienes un código promocional?</LinkButton></p>
          {this.props.promoCode
            ? <p className='checkout-disclaimer text-center mb-5'>Solo es posible usar un código promocional/tarjeta Cinefan por transacción.<br/>Si deseas usar uno diferente al que ingresaste, puedes refrescar la página o <a href="javascript:location.reload()">hacer click aquí</a>.</p>
            : ''
          }
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  promoCodesPanel: state.checkoutData.ui.promoCodesPanel,
  promoCode: state.checkoutData.promoCode.code
});

const mapDispatchToProps = dispatch => ({
  ticketIncrease: ticket => dispatch(ticketQuantityIncrease(ticket)),
  ticketDecrease: ticket => dispatch(ticketQuantityDecrease(ticket)),
  showPromoCodePanel: () => dispatch(promoCodePanelShow(true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketsSelection);