import * as actions from '../../types';
import CMX from '../../../api';
import {dialogAlert} from '../dialogs';

const generateKey = bits => {
  const length = bits / 8;
  let result = '';

  for (let i = 0 ; i < length ; i++) {
    result += String.fromCharCode(Math.round(Math.random() * 255));
  }

  return result;
};

const masterpassRequestToken = (transactionId, sessionId, dispatch) => {
  const key = btoa(generateKey(256));
  const url = CMX.getMasterpassWalletUrl(
      transactionId, sessionId, key
  );

  const win = window.open(url);
  let timer = setInterval(function() {
    if (!win || !win.window || win.closed) {
      clearInterval(timer);
      dispatch(requestFailed());
      return;
    }

    try {
      win.postMessage({message: "requestCinemexMasterpassResult"}, "*");
    } catch (e) {}
  }, 1000);

  window.addEventListener("message", function(e) {
    if (e.data.message === "deliverCinemexMasterpassResult") {
      clearInterval(timer);
      e.data.result.key = key;
      if (e.data.result.success) {
        dispatch(requestSuccess(e.data.result));
      } else {
        dispatch(requestFailed());
      }
      e.source.close();
    }
  });
};

export const goToMasterpass = () => (dispatch, getState) => {
  const state = getState().checkoutData;
  masterpassRequestToken(
      state.order.transactionId,
      state.data.id,
      dispatch
  );

  dispatch({
    type: actions.CHECKOUT_MASTERPASS_OPEN,
  });
};

const requestSuccess = data => ({
  type: actions.CHECKOUT_MASTERPASS_SUCCESS,
  data
});

const requestFailed = () => dispatch => {
  dispatch(dialogAlert('Has cancelado tu compra con Masterpass.'));
  dispatch({
    type: actions.CHECKOUT_MASTERPASS_ERROR,
  })
};