import React from 'react';

const onContentClick = e => {
  const target = e.target;
  let container;
  if (target.classList.contains('cinemaprende-class')) {
    container = target;
  } else if (target.classList.contains('cinemaprende-class-title')) {
    container = target.parentElement;
  } else {
    return;
  }

  container.classList.toggle('open');
};

class LandingContentHtml extends React.Component {
  constructor(props) {
    super(props);

    this.div = React.createRef();
  }

  componentDidMount() {
    this.div.current && this.div.current.querySelectorAll('script').forEach(s => eval(s.innerHTML));
  }

  render() {
    return <div ref={this.div} dangerouslySetInnerHTML={{ __html: this.props.content.html }} onClick={onContentClick} />;
  }
}

export default LandingContentHtml;