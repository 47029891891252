import React from 'react';
import "./BubblesAnimation.css";

class BubblesAnimation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {display: true};
    }
    
    componentDidMount() {
        setTimeout(() => this.setState({display: false}), 15000);
    }

    render() {
        if (!this.state.display) return null;

        return <div class="bubbles-container">
            {Array.from(Array(11).keys(), (x) => {
                const letter = String.fromCharCode('a'.charCodeAt(0) + x);
                const size = Math.round((Math.random() * 80) + 40) + 'px';
                return <span className={'bub ' + letter} style={{width:size, height:size}}></span>
            })}
        </div>;
    }
}

export default BubblesAnimation;