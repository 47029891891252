import * as actions from '../../types';
import CMX from '../../../api';
import {isUserLogged} from '../../../reducers';
import {dialogAlert} from '../dialogs';
import {loginPostSuccess} from '../auth';

export const pointsFetch = sessionId => (dispatch, getState) => {
  const state = getState();

  if (!isUserLogged(state)) {
    console.log('no user logged in');
    return;
  }

  dispatch({
    type: actions.USER_PRE_CHECKOUT_FETCH,
  });

  return CMX.post('/me/preCheckout', {sessionId})
      .then(data => dispatch(preCheckoutFetchSuccess(data)))
      .catch(error => dispatch(preCheckoutFetchError(error)));
};

const preCheckoutFetchSuccess = data => dispatch => {
  const {ie, wallet, tickets} = data;

  if (!ie.success) {
    dispatch(pointsFetchError());
  } else {
    dispatch(pointsFetchSuccess(ie.data || [], tickets || []));
  }

  if (wallet) {
    dispatch(walletFetchSuccess(wallet));
  }

  dispatch({
    type: actions.USER_PRE_CHECKOUT_FETCH_SUCCESS,
    data
  });
};

const preCheckoutFetchError = error => dispatch => {
  dispatch(pointsFetchError(error));
};

const walletFetchSuccess = wallet => ({
  type: actions.USER_WALLET_FETCH_SUCCESS,
  data: wallet,
});

const pointsFetchSuccess = (points, tickets) => ({
  type: actions.USER_POINTS_FETCH_SUCCESS,
  points,
  tickets
});

const pointsFetchError = error => dispatch => {
  // dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.USER_POINTS_FETCH_ERROR,
    error,
  })
};

export const purchaseHistoryFetch = params => dispatch => {
  dispatch({
    type: actions.USER_PURCHASE_HISTORY_FETCH,
  });

  CMX.get('/me/purchaseHistory', params)
      .then(data => dispatch(purchaseHistoryFetchSuccess(data)))
      .catch(error => dispatch(purchaseHistoryFetchError(error)))
};

const purchaseHistoryFetchSuccess = data => ({
  type: actions.USER_PURCHASE_HISTORY_FETCH_SUCCESS,
  purchases: data.data,
});

const purchaseHistoryFetchError = error => ({
  type: actions.USER_PURCHASE_HISTORY_FETCH_ERROR,
  error,
});

export const userSessionsFetch = params => dispatch => {
  dispatch({
    type: actions.USER_SESSIONS_FETCH,
  });

  CMX.get('/me/sessions', params)
      .then(data => dispatch(userSessionsFetchSuccess(data)))
      .catch(error => dispatch(userSessionsFetchError(error)))
};

const userSessionsFetchSuccess = data => ({
  type: actions.USER_SESSIONS_FETCH_SUCCESS,
  sessions: data.data,
});

const userSessionsFetchError = error => ({
  type: actions.USER_SESSIONS_FETCH_ERROR,
  error,
});

export const userSessionKill = sessionId => dispatch => {
  dispatch({
    type: actions.USER_SESSION_KILL,
  });

  CMX.post('/me/sessions/kill', {session_id: sessionId})
      .then(data => dispatch(userSessionKillSuccess(data)))
      .catch(error => dispatch(userSessionKillError(error)))
};

const userSessionKillSuccess = data => ({
  type: actions.USER_SESSION_KILL_SUCCESS,
  sessions: data.data,
});

const userSessionKillError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.USER_SESSION_KILL_ERROR,
    error,
  })
};

export const fetchUserInfo = () => ({
  type: actions.USER_INFO_FETCH,
});

export const updateInfo = values => dispatch => {
  dispatch({
    type: actions.USER_UPDATE_INFO_PUT,
    values,
  });

  CMX.put('/me/', values)
      .then(data => dispatch(updateInfoSuccess(data)))
      .catch(error => dispatch(updateInfoError(error)));
};

const updateInfoSuccess = response => dispatch => {
  let userInfo;

  if (response.access_token) {
    userInfo = response.user_info;
    dispatch(loginPostSuccess(response));
  } else {
    userInfo = response;
  }

  dispatch({
    type: actions.USER_UPDATE_INFO_SUCCESS,
    userInfo,
  });
};

const updateInfoError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.USER_UPDATE_INFO_ERROR,
    error,
  });
};

export const avatarUpload = file => dispatch => {
  dispatch({
    type: actions.USER_AVATAR_UPLOAD,
  });

  CMX.post('/me/avatar', {}, {file})
      .then(data => dispatch(avatarUploadSuccess(data)))
      .catch(error => dispatch(avatarUploadError(error)));
};

const avatarUploadSuccess = userInfo => ({
  type: actions.USER_AVATAR_UPLOAD_SUCCESS,
  userInfo,
});

const avatarUploadError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.USER_AVATAR_UPLOAD_ERROR,
    error,
  });
};

export const setUserProfileEdit = section => ({
  type: actions.UI_USER_PROFILE_EDIT,
  value: section
});


export const deleteAccount = (history, params) => dispatch => {
  dispatch({
    type: actions.USER_DELETE_ACCOUNT
  });

  CMX.post('/me/delete-account', params)
      .then(data => dispatch(deleteAccountSuccess(history, data)))
      .catch(error => dispatch(deleteAccountError(error)));
};

const deleteAccountSuccess = history => dispatch => {
  history.push('/cuenta-eliminada');
  
  return dispatch({
    type: actions.USER_DELETE_ACCOUNT_SUCCESS
  });
};

const deleteAccountError = error => dispatch => {
  dispatch(dialogAlert(error.message));
  dispatch({
    type: actions.USER_DELETE_ACCOUNT_ERROR,
    error,
  });
};

export const userIeCardFetch = () => ({
  type: actions.USER_IE_CARD_FETCH
});