import React from 'react';
import {connect} from 'react-redux';
import {postFetch} from '../actions/creators/posts';
import {getPost, isPostLoading} from '../reducers';
import MDSpinner from "react-md-spinner";

class DynamicPost extends React.Component {
  componentWillMount() {
    if (!this.props.post) {
      this.props.fetch(this.props.slug);
    }
  }

  render() {
    if (!this.props.post || this.props.isLoading) {
      return <div className="p-5 text-center"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    const posts = Array.isArray(this.props.post) ? this.props.post : [this.props.post];
    const content = posts.map(p => {
      let result = '';

      if (this.props.includeTitle || posts.length > 1) {
        result += `<h1 class="c-entry__title">${p.title}</h1>`
      }
      result += p.content;
      
      return result;
    }).join('<p>&nbsp;</p>');

    return <div dangerouslySetInnerHTML={{__html: content}}/>;
  }
}

const mapStateToProps = (state, props) => ({
  post: getPost(state, props.slug),
  isLoading: isPostLoading(state, props.slug),
});

const mapDispatchToProps = dispatch => ({
  fetch: slug => dispatch(postFetch(slug)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DynamicPost);