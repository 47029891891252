import * as actions from '../../types';
import CMX from '../../../api';
import { getCheckoutTransactionId } from '../../../reducers';
import { dialogAlert } from '../dialogs';

export const paymentSuggestionsFetch = params => dispatch => {
  dispatch(paymentSuggestionsIsLoading(true));

  CMX.post('buy/paymentSuggestions', params)
      .then(data => dispatch(paymentSuggestionsFetchSuccess(data)))
      .then(() => dispatch(paymentSuggestionsIsLoading(false)))
      .catch(() => dispatch(paymentSuggestionsFetchError()));
};

export const paymentSuggestionsIsLoading = (isLoading) => ({
  type: actions.CHECKOUT_PAYMENT_SUGGESTIONS_IS_LOADING,
  isLoading,
});

export const paymentSuggestionsFetchError = () => ({
  type: actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_ERROR,
});

export const paymentSuggestionsFetchSuccess = data => ({
  type: actions.CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS,
  data,
});

export const paymentSuggestionSelect = optionId => ({
  type: actions.CHECKOUT_PAYMENT_SUGGESTIONS_SELECT,
  optionId,
});

export const nipRequestCancel = () => ({
  type: actions.CHECKOUT_NIP_REQUEST_CANCEL
});

export const nipRequestSubmit = nip => (dispatch, getState) => {
  const state = getState();
  const transactionId = getCheckoutTransactionId(state);

  dispatch({
    type: actions.CHECKOUT_NIP_REQUEST_SUBMIT,
    nip
  });

  CMX.post('buy/setIeNip', {
    transaction_id: transactionId,
    nip
  })
      .then(data => {
        if (data.valid) {
          dispatch({
            type: actions.CHECKOUT_NIP_REQUEST_SUCCESS,
            nip
          });
        } else {
          dispatch(dialogAlert('Por favor, verifica el NIP ingresado.'));
          dispatch({
            type: actions.CHECKOUT_NIP_REQUEST_ERROR
          });
        }
      })
      .catch(error => {
        dispatch(dialogAlert(error.message));
        dispatch({
          type: actions.CHECKOUT_NIP_REQUEST_ERROR
        });
      });

};