import React from 'react';
import styled from 'styled-components';
import Lottie from "lottie-react";
import BodyBlocker from './BodyBlocker';
import logo from './LoadingLogo.jsx';
import { connect } from 'react-redux';
import { getUiCustomLoadingAnimation } from '../reducers';

const Container = styled.div`
  position: fixed;
  z-index: 1060;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Animation = styled.div`
  width: 7.5rem;
`;

let timer;

class LogoLoading extends React.Component {
    componentDidMount() {
        if (this.props.customAnimation) return;

        var animations = [];
        animations.push(buildAnimation(7, 'tf-mask-', 'topfrontmask'));
        animations.push(buildAnimation(4, 'tb-mask-', 'topbackmask'));
        animations.push(buildAnimation(7, 'mf-mask-', 'midfrontmask'));
        animations.push(buildAnimation(3, 'mb-mask-', 'midbackmask'));
        animations.push(buildAnimation(6, 'bf-mask-', 'bottomfrontmask'));
        
        function buildAnimation(frames, maskPrefix, maskId) {
            var shapes = [];
            for (var i = 1; i <= frames; i++) {
              shapes.push(document.getElementById(maskPrefix + i).getAttribute('d'));
            }
            
            return {
              shapes,
              frames,
              mask: document.getElementById(maskId)
            };
        }

        let a = 0;
        let f = 0;
        let direction = 1;
        
        function animate() {
          const animation = animations[a];
          const {mask, frames, shapes} = animation;
          mask.setAttribute('d', shapes[f]);
          
          if (direction > 0) {
            f++;
            if (f >= frames) {
              f = 0;
              a++;
              
              try { animate(); } catch (e) {}
            }
            if (a >= animations.length) {
              a--;
              direction = -1;
              f = animations[a].frames - 1
            }
          } else {
            f--;
            if (f < 0) {
              a--;
              if (a < 0) {
                direction = 1;
                a = 0;
                f = 0;
              } else {
                f = animations[a].frames - 1;
                try { animate(); } catch (e) {}
              }
            }
          }
        }
        
        timer = setInterval(animate, 75);
    }

    componentWillUnmount() {
        clearInterval(timer);
    }

    render() {
        return <div>
            <BodyBlocker overSidebars solidBg/>
            <Container>
              {!this.props.customAnimation
                ? <Animation dangerouslySetInnerHTML={{__html:logo}}/>
                : <Lottie animationData={this.props.customAnimation}/>
              }
            </Container>
        </div>;
    }
}

const mapStateToProps = state => ({
  customAnimation: getUiCustomLoadingAnimation(state)
});

export default connect(mapStateToProps)(LogoLoading);