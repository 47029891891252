import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../utils/styles';

const Container = styled.div`
    margin: 3em 0 2em;
    font-size: 0.95em;
    text-align: center;

    @media screen and (min-width: ${breakpoints.desktop}px) {
        margin-top: 0;
        position: absolute;
        text-align: left;
        top: ${props => props.checkout ? '0' : '4rem'};
        left: ${props => props.checkout ? '110%' : '80%'};
        width: 27vw;
        max-width: 400px;
    }

    ${props => props.checkout && `
        @media screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktopLarge - 1}px) {
            font-size: 0.85em;
            max-width: 300px;
        }
    `}
`;

const Text = styled.div`
    line-height: 1.5;
    margin-bottom: 1.5em;

    @media screen and (min-width: ${breakpoints.desktop}px) {
        padding-left: 3em;
        position: relative;
    }
`;

const Icon = styled.i`
    display: block;
    font-size: 2em;
    margin-bottom: 0.5em;
    
    @media screen and (min-width: ${breakpoints.desktop}px) {
        font-size: 1.75em;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const Card = styled.img`
    width: 100%;
    height: auto;
    box-shadow: 0 0 50px rgb(0 0 0 / 25%);
    border-radius: 1em;
`;

class IePanelHint extends React.Component {
    render() {
        const isStepIe = this.props.step === 'ie';
        return <Container checkout={this.props.checkout}>
          <Text>
            <Icon className="fal fa-exclamation-circle"></Icon>
            {isStepIe ? 'Encuentra el número de Invitado Especial donde indica la imagen.'
                : 'Encuentra el número del código de barras donde indica la imagen.'}
          </Text>
          <Card src={`/dist/images/ie-card-ref-${isStepIe ? 'num' : 'barcode'}.png`} alt="" />
        </Container>;
    }
}

export default IePanelHint;