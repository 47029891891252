import React from 'react';
import {connect} from 'react-redux';
import Error404Page from './Error404Page';
import ErrorPage from './ErrorPage';
import CheckoutContent from './CheckoutContent';
import CheckoutSidebar from './CheckoutSidebar';
import TwoColumnLayout from './TwoColumnLayout';
import TimedOut from "./checkout/TimedOut";
import {fingerprintSet} from '../actions/creators';
import {sessionFetch, onCheckoutUnmount, invalidateTransaction, timerStop} from '../actions/creators/checkout';
import {pointsFetch} from '../actions/creators/user';
import {getUserInfo,getCheckoutTimedOut, getCheckoutOrderSeatsLabels, getCheckoutOrderTicketsCount, getCheckoutSession, getCheckoutTicketsWithQuantity} from '../reducers';
import {getMovieStartedTimeUnix} from '../utils/showtimes';
import { AnalyticsCheckout } from '../utils/analytics';
import BubblesAnimation from './checkout/BubblesAnimation';

let lastTracked;

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);

    this.setPointer = this.setPointer.bind(this);
    this.unsetPointer = this.unsetPointer.bind(this);
    this.setCursorPosition = this.setCursorPosition.bind(this);

    this.cursor = React.createRef();
  }

  componentWillMount() {
    this.props.sessionFetch(this.props.match.params.id).then(() => this.props.pointsFetch(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.abandon();
    this.unsetPointer();
  }

  componentDidMount() {
    this.setPointer();
  }

  setPointer() {
    if (!matchMedia('(pointer:fine)').matches || !this.props.data || !this.props.data.custom_pointer) return;

    document.body.classList.add('js-cursor');
    document.addEventListener('mousemove', this.setCursorPosition);
  }

  unsetPointer() {
    document.body.classList.remove('js-cursor');
    document.removeEventListener('mousemove', this.setCursorPosition);
  }

  setCursorPosition(e) {
    const cursor = this.cursor.current;
    if (!cursor) return;

    var x = e.clientX;
    var y = e.clientY;
    cursor.style.left = x + 'px';
    cursor.style.top = y + 'px';
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.sessionFetch(this.props.match.params.id).then(() => this.props.pointsFetch(this.props.match.params.id));
    }

    if (this.props.userId !== prevProps.userId || this.props.userIeCode !== prevProps.userIeCode) {
      this.props.pointsFetch(this.props.match.params.id);
    }

    if (this.props.data && this.props.movie && this.props.cinema && this.props.data.id !== lastTracked) {
      lastTracked = this.props.data.id;
      AnalyticsCheckout.trackStep('tickets', {session: this.props.data, movie: this.props.movie, cinema: this.props.cinema});
    }
    
    this.setPointer();
  }

  render() {
    if (this.props.isLoading) return null;

    if (this.props.loadError) {
      if (this.props.loadError === 404) {
        return <Error404Page/>;
      } else {
        return <ErrorPage/>;
      }
    }

    if (this.props.timedOut) {
      return <TimedOut/>;
    }

    if (!this.props.data) {
      return null;
    }

    if (this.props.movieStarted) {
      return <ErrorPage title="Horario no disponible" message="El horario que estas buscando ya no esta disponible."/>;
    }

    return <React.Fragment>
      <TwoColumnLayout MainContent={CheckoutContent} SidebarContent={CheckoutSidebar} {...this.props}/>  
      {this.props.data.extra.bubbles_animation ? <BubblesAnimation/> : null}
      {this.props.data.custom_pointer ? <img ref={this.cursor} src={this.props.data.custom_pointer} style={{position:'fixed',pointerEvents:'none',zIndex:999999}}/> : null}
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  const userInfo = getUserInfo(state);
  const data = getCheckoutSession(state);

  return {
    cinema: data ? state.entities.cinemas[data.cinema_id] : null,
    data,
    movie: data ? state.entities.movies[data.movie_id] : null,
    movieStarted: data && (getMovieStartedTimeUnix() > data.date),
    isLoading: state.checkoutData.isLoading,
    loadError: state.checkoutData.loadError,
    tickets: getCheckoutTicketsWithQuantity(state),
    ticketsSum: getCheckoutOrderTicketsCount(state),
    seatsLabels: getCheckoutOrderSeatsLabels(state),
    timedOut: getCheckoutTimedOut(state),
    userId: userInfo && userInfo.id,
    userIeCode: userInfo && userInfo.iecode,
  };
};

const mapDispatchToProps = dispatch => ({
  sessionFetch: sessionId => dispatch(sessionFetch(sessionId)),
  pointsFetch: sessionId => dispatch(pointsFetch(sessionId)),
  abandon: () => dispatch(onCheckoutUnmount()),
  fingerprintSet: id => dispatch(fingerprintSet(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutPage);