import * as actions from '../actions/types';
import CMX from '../api';
import { dialogClose, dialogOpen } from '../actions/creators/dialogs';
import { fetchSmartIframeUrl, paymentPost } from '../actions/creators/checkout/payment';
import { fetchStripeRedirectUrl } from '../actions/creators/checkout/stripe';
import { getCheckoutSeatsToSelect, getCheckoutSelectedSeats, getCheckoutSession, getCheckoutTransactionId, getUserInfo, getUserOptions, isCheckoutTermsChecked, isUserLogged } from '../reducers';
import { updateInfo, updateInfoSuccess } from '../actions/creators/user';
import { displayNoTermsCheckedDialog, timerStop } from '../actions/creators/checkout';
import { customLoadingAnimationFetchSuccess } from '../actions/creators/ui';
import { onApiError } from './misc';

export const checkoutMiddleware = ({ getState, dispatch }) => next => async action => {
    pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function pre(action, getState, dispatch) {
    switch (action.type) {
        case actions.CHECKOUT_ABANDON:
            return onCheckoutAbandonPre(dispatch, getState);
        case actions.CHECKOUT_SEAT_SELECT:
            return onSeatSelect(action, getState, dispatch);
        case actions.AUTH_LOGOUT:
            return localStorage.removeItem('after_purchase_ir_reminder');
    }
}

function post(action, getState, dispatch) {
    switch (action.type) {
        case actions.CHECKOUT_TIMER_TIMEOUT:
            return document.body.classList.remove('js-cursor');
        case actions.CHECKOUT_PAYMENT_SUCCESS:
            return onCheckoutPaymentSuccess(action, getState, dispatch);
        case actions.POST_REGISTER_POPUP_CLOSE:
            return onPostRegisterPopupClose(action, getState, dispatch);
        case actions.POST_PURCHASE_IE_POPUP_SHOW:
            return onPostPurchaseIePopupshow();
        case actions.CHECKOUT_ABANDON:
            return onCheckoutAbandon(dispatch, getState);
        case actions.CHECKOUT_SESSION_FETCH_SUCCESS:
            return onSessionFetchSuccess(action, getState, dispatch);
        case actions.CHECKOUT_PAYMENT_METHOD_SELECT_BEFORE:
            return onPaymentMethodSelect(getState, dispatch, action);
        case actions.CHECKOUT_PAYMENT_FORM_SUBMIT:
            return onPaymentPost(getState, dispatch, action);
        case actions.CHECKOUT_PAYMENT_ERROR:
            return onPaymentError(dispatch, action);
        default:
            return;
    }
}

function onPaymentMethodSelect(getState, dispatch, action) {
    const state = getState();

    if (!isCheckoutTermsChecked(state)) {
        return dispatch(displayNoTermsCheckedDialog());
    }

    if (('mayNeedExternalUrl' in action) && action.mayNeedExternalUrl) {
        const { paymentGateway } = state.checkoutData;

        if (paymentGateway === 'smart') {
            dispatch(fetchSmartIframeUrl());
        } else if (paymentGateway === 'stripe' || paymentGateway === 'deuna') {
            dispatch(fetchStripeRedirectUrl());
        }
    }

    dispatch({
        type: actions.CHECKOUT_PAYMENT_METHOD_SELECT,
        method: action.method,
    });
}

function onPaymentPost(getState, dispatch, action) {
    dispatch(paymentPost());
}

function onPaymentError(dispatch, action) {
    const {error, errorcode} = action;

    // if (errorcode === 'possible-duplicate') {
    //     dispatch(dialogOpen({
    //         message: error.message,
    //         buttons: [
    //             {
    //                 label: 'Cancelar',
    //                 onClick: (dialog) => {
    //                     dispatch(dialogClose(dialog.id));
    //                 }
    //             },
    //             {
    //                 label: 'Confirmar',
    //                 primary: true,
    //                 onClick: (dialog) => {
    //                     dispatch(dialogClose(dialog.id));
    //                     params.repeat_key = error.response.repeat_key;
    //                     dispatch(paymentIsProcessing(true));
    //                     dispatch(actualPaymentPost(params));
    //                 }
    //             }
    //         ]
    //     }));

    //     return;
    // }

    return onApiError(dispatch, Object.assign({context: 'payment'}, action));
}

function onCheckoutPaymentSuccess(action, getState, dispatch) {
    const state = getState();

    dispatch(timerStop());
    
    if (!isUserLogged(state)) return;

    const userInfo = getUserInfo(state);

    if (userInfo.iecode) return;
    if (userInfo.post_purchase_ie_reminder_disabled) return;

    const {payment} = action.data;
    const amount = (('cash' in payment.methods) ? payment.methods.cash : 0) / 100;
    if (!amount) return;
        
    localStorage.setItem('after_purchase_ir_reminder', JSON.stringify({
        amount
    }));
}

function onPostRegisterPopupClose(action, getState, dispatch) {
    if (action.args && action.args.remember) {
        dispatch(updateInfo({
            post_purchase_ie_reminder_disabled: true
        }));
    }
}

function onPostPurchaseIePopupshow() {
    localStorage.removeItem('after_purchase_ir_reminder');
}

function onCheckoutAbandonPre(dispatch, getState) {
    invalidateTransaction(getState);
}

function onCheckoutAbandon(dispatch, getState) {
    dispatch(timerStop());
}

function invalidateTransaction(getState) {
    const state = getState();
    const transactionId = getCheckoutTransactionId(state);
    const session = getCheckoutSession(state);

    if (transactionId && session) {
        CMX.post('/buy/invalidateTransaction', {
            transaction_id: transactionId,
            session_id: session.id,
        });
    } else {
        console.log('do not invalidate transaction, no transaction id');
    }
}

function onSessionFetchSuccess(action, getState, dispatch) {
    if (!action.data.loading_override) return;
 
    fetch(action.data.loading_override.sprite)
        .then(res => res.json())
        .then(data => dispatch(customLoadingAnimationFetchSuccess({data})));
}

function onSeatSelect(action, getState, dispatch) {
    const state = getState();
    const oldSelection = getCheckoutSelectedSeats(state);
    const seatsToSelect = getCheckoutSeatsToSelect(state);
    const { seat } = action;
        
    if (seat.status !== '0' || oldSelection.indexOf(seat.id) > -1) {
        action.seats = oldSelection;
        return;
    }

    const itemsToRemove = seatsToSelect === oldSelection.length ? 1 : 0;
    const newSelection = oldSelection.slice(itemsToRemove);
    newSelection.push(seat.id);

    action.seats = newSelection;
}