import React from 'react';
import BodyBlocker from '../BodyBlocker';
import styled from 'styled-components';
import { breakpoints } from '../../utils/styles';
import Button from '../Button';
import FieldRow from '../FieldRow';

const PanelContainer = styled.div`
  @media screen and (min-width: ${breakpoints.desktop}px) {
    max-width: 70%;
  }
`;

class UserDeleteAccount extends React.Component {
    constructor(props) {
        super(props);

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmChange = this.onConfirmChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.appleLogin = this.appleLogin.bind(this);
        this.facebookLogin = this.facebookLogin.bind(this);

        this.state = {
            password: '',
            confirm: false,
            sso: null,
        };
    }

    onPasswordChange(e) {
        const password = e.target.value;
        this.setState(() => ({ password }));
    }

    onConfirmChange(e) {
        const confirm = e.target.checked;
        this.setState(() => ({ confirm }));
    }

    onSubmit(e) {
        e.preventDefault();

        const params = {
            password: this.state.password
        }
        
        if (this.state.sso) {
            params.sso = {
                service: this.state.sso.service,
                data: this.state.sso.data,
            };
        }

        this.props.onSubmit(params);
    }

    appleLogin() {
        this.props.appleLogin(result => {
            if (result) {
                this.setState({
                    password: '',
                    sso: {
                        service: 'apple',
                        data: result
                    }
                });
            }
        });
    }

    facebookLogin() {
        this.props.facebookLogin(result => {
            if (result) {
                this.setState({
                    password: '',
                    sso: {
                        service: 'facebook',
                        data: result
                    }
                });
            }
        }, {auth_type: 'reauthenticate'});
    }

    render() {
        return (
            <div>
                <PanelContainer>
                    <h1 className="c-entry__title">
                        Eliminar cuenta
                    </h1>
                    <p>
                        Estás a punto de eliminar tu cuenta. Esta acción no puede deshacerse.
                    </p>
                    <div>
                        <form onSubmit={this.onSubmit}>
                            {!this.state.sso && (
                                <React.Fragment>
                                    <div className="mb-3">
                                        <FieldRow type="password" label="Para continuar ingresa tu contraseña:" autoFocus required
                                            value={this.props.password} onChange={this.onPasswordChange} disabled={this.props.processing}
                                        />
                                    </div>
                                    <p className="text-center mb-3">
                                        <small className="d-block text-secondary">O ingresa con tu cuenta de Facebook o Apple</small>
                                    </p>
                                    <div className="row">
                                        <div className="col-6">
                                            <Button onClick={this.facebookLogin} block smallPadding className="btn-facebook mb-2"><i className="fab fa-facebook-f"></i></Button>
                                        </div>
                                        <div className="col-6">
                                            <Button onClick={this.appleLogin} block smallPadding className="btn-apple mb-2"><i className="fab fa-apple"></i></Button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="text-center form-group my-5">
                                <input type="checkbox" onChange={this.onConfirmChange} />&nbsp; Confirmo que deseo eliminar mi cuenta.
                            </div>
                            <Button submit primary large block disabled={!this.state.confirm || (!this.state.password && !this.state.sso) || this.props.processing}>Eliminar cuenta</Button>
                        </form>
                    </div>

                </PanelContainer>
            </div>
        );
    }
}

export default UserDeleteAccount;