import React from 'react';
import ErrorPage from '../ErrorPage';

const reload = (e) => {
  e.preventDefault();
  document.location.reload();
};

const message = <p>
  Has superado el tiempo necesario para poder realizar tu compra.<br/>
  ¿Deseas <a href="#" onClick={reload}>comenzar de nuevo</a>?
</p>;

export default props => <ErrorPage message={message} title="Checkout"/>
