import React from 'react';
import ArcoFormPage from './ArcoFormPage';
import NavBarLayout from './NavBarLayout';

const routes = [
  {
    title: 'Aviso de privacidad para usuarios y cineusuarios de Cinemex',
    anchor: 'Aviso de Privacidad Integral',
    slug: 'aviso-de-privacidad',
    path: '/privacidadvisitantes',
  },
  {
    title: 'Aviso de privacidad integro para facturación',
    anchor: 'Privacidad Facturación',
    slug: 'privacidad-facturacion',
    path: '/privacidadfacturacion',
  },
  {
    title: 'Aviso de privacidad integro para proveedores',
    anchor: 'Privacidad Proveedores',
    slug: 'privacidad-proveedores',
    path: '/privacidadproveedores',
  },
  {
    title: 'Aviso de privacidad integro solicitantes y candidatos',
    anchor: 'Privacidad Candidatos',
    slug: 'privacidad-candidatos',
    path: '/privacidadcandidatos',
  },
  {
    title: 'Aviso de privacidad integro para ex empleados',
    anchor: 'Privacidad Ex Empleados',
    slug: 'privacidad-ex-empleados',
    path: '/privacidadexempleados',
  },
  {
    title: 'Forma de derechos ARCO',
    anchor: 'Formulario ARCO',
    component: ArcoFormPage,
    path: '/arco/formulario',
    nav: false,
  },
];

export default props => <NavBarLayout fixedTitle="Avisos de privacidad" routes={routes}/>