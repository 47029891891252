import * as actions from '../types';
import CMX from '../../api';
import {isBillboardLoading, getBillboardLastLoadTime} from '../../reducers';

export const billboardFetch = (areaType, areaId, date) => (dispatch, getState) => {
  const state = getState();

  if (isBillboardLoading(state, areaType, areaId, date)) {
    return;
  }

  if ((new Date()).getTime() - getBillboardLastLoadTime(state, areaType, areaId, date) < 60 * 60 * 1000) {
    return;
  }

  if (areaType === 'state') {
    dispatch(stateFetch(areaId, date));
  } else {
    dispatch(areaFetch(areaId, date));
  }
};

const areaFetch = (areaId, date) => dispatch => {
  dispatch({
    type: actions.BILLBOARD_AREA_FETCH,
    areaId,
    date,
  });

  const params = {include_dates: 1};

  if (date) {
    params.date = date;
  } else {
    params.initial = 1;
  }

  CMX.get('/cinemas/area/' + areaId + '/movies/', params)
      .then(data => dispatch(billboardAreaFetchSuccess(data, areaId, date)))
      .catch(error => dispatch(billboardAreaFetchError(error)));
};

const billboardAreaFetchSuccess = (data, areaId, date) => ({
  type: actions.BILLBOARD_AREA_FETCH_SUCCESS,
  areaId,
  data,
  date,
});

const billboardAreaFetchError = error => ({
  type: actions.BILLBOARD_AREA_FETCH_ERROR,
  error
});

const stateFetch = (stateId, date) => dispatch => {
  dispatch({
    type: actions.BILLBOARD_STATE_FETCH,
  });

  const params = {include_dates: 1};

  if (date) {
    params.date = date;
  } else {
    params.initial = 1;
  }

  CMX.get('/cinemas/state/' + stateId + '/movies/', params)
      .then(data => dispatch(stateFetchSuccess(data, stateId, date)))
      .catch(error => dispatch(stateFetchError(error)));
};

const stateFetchSuccess = (data, stateId, date) => ({
  type: actions.BILLBOARD_STATE_FETCH_SUCCESS,
  stateId,
  data,
  date,
});

const stateFetchError = error => ({
  type: actions.BILLBOARD_STATE_FETCH_ERROR,
  error
});
