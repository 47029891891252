import * as actions from '../../types';
import { uniqueId } from "lodash";
import CMX from "../../../api";
import { dialogAlert } from "../dialogs";
import { appleLoginPost, loginPostSuccess } from './index';

let win;
let timer;

export const openPopup = callback => dispatch => {
    const onMessage = (e) => {
        if (e.data.message === "deliverCinemexAppleSigninResult") {
            win.close();
            windowClosed();
            const {result} = e.data;

            if (!('errorcode' in result)) {
                if (callback) {
                    return callback(result);
                } else {
                    return dispatch(appleLoginPost(result.token));
                }
            }

            if (result.errorcode === 'user-cancelled') {
                return;
            }

            if (result.errorcode === 'missing-fields' && !callback) {
                return dispatch({
                    type: actions.AUTH_SHOW_REGISTER_APPLE,
                    token: result.token,
                });
            }

            dispatch(dialogAlert(result.message || 'Ocurrió un error. Por favor, inténtalo nuevamente.'));
        }
    }
  
    const windowClosed = () => {
        window.removeEventListener("message", onMessage);
        clearInterval(timer);
        timer = null;
    };

    const clientId = 'com.cinemex.Cinemex.service';
    const redirectUrl = CMX.getAppleSigninRedirectUrl().replace('http:', 'https:');
    const state = uniqueId();
    const nonce = uniqueId();
    const oauthUrl = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&` +
        `client_id=${clientId}&scope=name+email&response_mode=form_post&` +
        `state=${state}&redirect_uri=${redirectUrl}&nonce=${nonce}`
    const windowWidth = 450
    const windowHeight = 600
    const left = window.screen.width / 2 - windowWidth / 2
    const top = window.screen.height / 2 - windowHeight / 2
    
    win = window.open(oauthUrl, 'Apple Sign-In',`menubar=no,location=no,scrollbars=no,status=` + `no,width=${windowWidth},height=${windowHeight},top=${top},left=${left}`);
    window.addEventListener("message", onMessage);
    timer = setInterval(function () {
        if (win.closed) {
            windowClosed();
            return;
        }

        try {
            win.postMessage({ message: "requestCinemexAppleSigninResult" }, "*");
        } catch (e) { }
    }, 1000);    
};