import { combineReducers } from 'redux';
import * as actions from '../actions/types';

const email = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_OPEN:
    case actions.AUTH_CLOSE:
      return null;
    case actions.AUTH_LOGIN_POST:
      return !('apple_signin' in action) ? action.email : null;
    default:
      return state;
  }
};

const isProcessing = (state = false, action) => {
  switch (action.type) {
    case actions.AUTH_PIN_SUBMIT:
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT:
      return true;
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS:
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_ERROR:
      return false;
    default:
      return state;
  }
}

const open = (state = false, action) => {
  switch (action.type) {
    case actions.AUTH_OPEN:
    case actions.AUTH_SHOW_LOGIN:
    case actions.AUTH_SHOW_RECOVER_PASS:
    case actions.AUTH_SHOW_RESET_PASS:
    case actions.AUTH_SHOW_REGISTER:
    case actions.AUTH_SHOW_REGISTER_MANUAL:
    case actions.AUTH_SHOW_REGISTER_APPLE:
      return true;
    case actions.AUTH_CLOSE:
      return false;
    default:
      return state;
  }
};

const panel = (state = 'login', action) => {
  switch (action.type) {
    case actions.AUTH_OPEN:
    case actions.AUTH_SHOW_LOGIN:
      return 'login';
    case actions.AUTH_SHOW_RECOVER_PASS:
      return 'recover-pass';
    case actions.AUTH_SHOW_RESET_PASS:
      return 'reset-pass';
    case actions.AUTH_SHOW_REGISTER:
    case actions.AUTH_SHOW_REGISTER_MANUAL:
    case actions.AUTH_SHOW_REGISTER_APPLE:
      return 'register';
    default:
      return state;
  }
};

const password = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_OPEN:
    case actions.AUTH_CLOSE:
      return null;
    case actions.AUTH_LOGIN_POST:
      return !('apple_signin' in action) ? action.password : null;
    default:
      return state;
  }
};

const preFillInfo = (state = {}, action) => {
  switch (action.type) {
    case actions.AUTH_OPEN:
    case actions.AUTH_CLOSE:
    case actions.AUTH_SHOW_REGISTER:
      return {};
    case actions.AUTH_SHOW_REGISTER_APPLE:
      return {token: action.token};
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT:
      return {email: action.email};
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS:
      return Object.assign(state, action.data);
    default:
      return state;
  }
};

const register = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_SHOW_REGISTER:
      return 'init';
    case actions.AUTH_SHOW_REGISTER_MANUAL:
      return 'manual';
    case actions.AUTH_SHOW_REGISTER_APPLE:
      return 'apple';
    default:
      return state;
  }
};

const token = (state = null, action) => {
  switch (action.type) {
    case actions.AUTH_SHOW_REGISTER_APPLE:
      return action.token;
    default:
      return state;
  }
};

export default combineReducers({
  email,
  isProcessing,
  open,
  panel,
  password,
  preFillInfo,
  register,
  token
});