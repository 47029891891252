import * as actions from '../actions/types';
import { combineReducers } from 'redux';

const areaMovies = (state = {}, action) => {
  switch (action.type) {
    case actions.AREA_MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.areaId]: action.movies.map(movie => movie.id)});
    default:
      return state;
  }
};

const billboardMovies = (state = {}, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.cinemaId]: action.movies.map(movie => movie.id)});
    default:
      return state;
  }
};

const areBillboardMoviesLoading = (state = {}, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIES_FETCH:
      return Object.assign({}, state, {[action.cinemaId]: true});
    case actions.CINEMA_MOVIES_FETCH_SUCCESS:
    case actions.CINEMA_MOVIES_FETCH_ERROR:
      return Object.assign({}, state, {[action.cinemaId]: false});
    default:
      return state;
  }
};

const billboardMoviesPopularity = (state = {}, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.cinemaId]: action.movies.reduce((result, movie) => {
        result[movie.id] = movie.popularity;
        return result;
      }, {})});
    default:
      return state;
  }
};

const billboardPopularMovies = (state = {}, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.cinemaId]: parsePopularMovies(action)});
    default:
      return state;
  }
};

const parsePopularMovies = action => {
  const result = [];

  action.movies.forEach(movie => movie.popularity > 0 && result.push([movie.id, movie.popularity]));
  result.sort(sortByPopularity);

  return result.map(m => m[0]);
};

const sortByPopularity = (a, b) => {
  a = a[1];
  b = b[1];

  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

const defaultBillboardMovies = (state = [], action) => {
  switch (action.type) {
    case actions.MOVIES_FETCH_SUCCESS:
      return action.movies.map(movie => movie.id);
    default:
      return state;
  }
};

const searchTerm = (state = '', action) => {
  switch (action.type) {
    case actions.HEADER_SEARCH_TERM_CHANGE:
      return action.term;
    default:
      return state;
  }
};

export default combineReducers({
  areaMovies,
  areBillboardMoviesLoading,
  billboardMovies,
  billboardMoviesPopularity,
  billboardPopularMovies,
  defaultBillboardMovies,
  searchTerm,
});