import React from 'react';
import {connect} from 'react-redux';
import FullColumnLayout from './FullColumnLayout';
import NewsPageContent from './NewsPageContent';
import {newsFetch} from '../actions/creators';
import {getNews,isNewsLoading} from '../reducers';

class NewsPage extends React.Component {
  componentDidMount() {
    if (!this.articles || !this.articles.length) {
      this.props.fetch();
    }
  }

  render() {
    return <FullColumnLayout Content={NewsPageContent} open={!!this.props.match.params.id} {...this.props}/>
  }
}

const mapStateToProps = state => ({
  articles: getNews(state),
  isLoading: isNewsLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(newsFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsPage);