import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {centsToCurrencyString} from '../../utils/numbers';
import {candybarOptionSelect, candybarUnitSelect, candybarUnitCommentUpdate} from '../../actions/creators/checkout/candybar';
import LinkButton from "../LinkButton";
import Timer from './Timer';

const ConfigItem = styled.div`
  padding: 0;
`;

const ConfigItemLabel = styled.label`
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  margin-bottom: 0;
`;

const UnitBlock = styled.div`
  & > .unit-options {
    display: ${props => props.expanded ? 'block' : 'none'};
  }
`;

const ConfigOption = props => {
  const {config, option} = props;
  const multiple = config.max !== 1;
  const type = multiple ? 'checkbox' : 'radio';

  return (
      <ConfigItem className="list-group-item list-group-item-action list-group-item-tertiary">
        <ConfigItemLabel className={'custom-control custom-' + type}>
          <input className="custom-control-input"
                 type={type} name={props.name} value={option.id} onChange={props.onOptionSelect}
                 checked={props.checked}
          />
          <span className="custom-control-label d-flex align-items-center d-block pl-2">
            {option.name}
            {option.price > 0 && <span className="text-muted ml-auto">{centsToCurrencyString(option.price)}</span>}
          </span>
        </ConfigItemLabel>
      </ConfigItem>
  );
};

const TabButton = ({label, id, active, onClick}) => (
    <li className="nav-item">
      <LinkButton className={'nav-link ' + (active ? 'active' : '')}
         id={id + '-tab'} data-target={id} role="tab"
         onClick={onClick}
         aria-controls="optionals" aria-selected={active}>{label}</LinkButton>
    </li>
);

class CandybarItem extends React.Component {
  constructor(props) {
    super(props);

    this.onTabChange = this.onTabChange.bind(this);

    this.state = {
      currentTab: 'optionals',
    };
  }

  UnitHeader({item, unitId, expanded}) {
    return (
        <div className="list-group-item p-4 d-flex align-items-center" onClick={() => this.props.onUnitSelect(expanded ? null : unitId)}>
          <img src="uploads/superburger-130x130.jpg" alt="" height="50" className="mr-2"/>
          <h2 className="h4 mb-0">{item.name}</h2>
          <p className="text-primary ml-auto mb-0">{centsToCurrencyString(this.getItemUnitPrice(item, unitId))}</p>
        </div>
    );
  }

  getItemUnitPrice(item, unitId) {
    const unit = this.props.order[unitId];
    let total = item.price;

    Object.keys(unit)
        .forEach(configId => configId !== 'comments' && unit[configId]
            .forEach(optionId => total += this.props.candybarOptions[optionId].price));

    return total;
  }

  onTabChange(e) {
    e.preventDefault();
    const currentTab = e.target.getAttribute('data-target');
    this.setState(() => ({currentTab}));
  }

  UnitOptions({item, unitId}) {
    return (
        <div className="unit-options">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <TabButton label="Opcionales" id="optionals" active={this.state.currentTab === 'optionals'} onClick={this.onTabChange}/>
            <TabButton label="Instrucciones especiales" id="instructions" active={this.state.currentTab === 'instructions'} onClick={this.onTabChange}/>
          </ul>
          <div className="tab-content py-4" id="myTabContent">
            <div className={'tab-pane fade ' + (this.state.currentTab === 'optionals' ? 'show active' : '')} id="optionals" role="tabpanel"
                 aria-labelledby="optionals-tab">

              <div className="list-group mb-4">
                {item.configs.map(config => (
                    <div className="p-5" key={unitId + '-' + config.id}>
                      <h2 className="h3">{config.name}</h2>
                      {config.options.map(option => {
                        const checked = this.isOptionChecked(unitId, config.id, option.id);
                        const name = unitId + '-' + option.id;

                        return (
                            <ConfigOption key={name} name={name} option={option} config={config} checked={checked}
                                          onOptionSelect={(e) => { this.props.onOptionSelect(item.id, unitId, config, option.id, !checked); }}
                            />
                        );
                      })}
                    </div>
                ))}
              </div>

            </div>
            <div className={'tab-pane fade ' + (this.state.currentTab === 'instructions' ? 'show active' : '')} id="instructions" role="tabpanel" aria-labelledby="instructions-tab">

              <div className="list-group mb-4">
                <div className="list-group-item p-4">
                  <div className="form-group form-group--label-helper">
                    <textarea className="form-control" rows="5" placeholder="Ingresa tus instrucciones"
                      maxLength={100} onChange={(e) => this.props.onCommentUpdate(item.id, unitId, e.target.value)}
                      value={'comments' in this.props.order[unitId] ? this.props.order[unitId].comments : ''}
                    />
                    <label htmlFor="instructionsField">Ingresa tus instrucciones</label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
    );
  }

  isOptionChecked(unitId, configId, optionId) {
    const unit = this.props.order[unitId];
    const selected = (configId in unit) && unit[configId].indexOf(optionId) > -1;

    return selected;
  }

  render() {
    const {item,order} = this.props;
    const unitIds = order ? Object.keys(order) : [];
    const qty = unitIds.length;

    if (!order || !qty) {
      return '';
    }

    return (
        <div className="container top-nav-pad">
          <div className="row mt-4">
            <div className="col-12">

              <div className="d-flex align-items-center mb-5">
                <h1 className="h2">
                  <LinkButton onClick={this.props.onClose} className="text-muted pr-3">
                    <i className="fal fa-arrow-left fa-xs"></i>
                  </LinkButton>
                  Personalizar {item.name} {qty > 1 && '(' + qty + ')'}
                </h1>
                <Timer/>
              </div>

              {unitIds.map(unitId => {
                const expanded = unitIds.length === 1 || unitId === this.props.selectedUnitId;
                return (
                    <UnitBlock key={unitId} expanded={expanded}>
                      {this.UnitHeader({item, unitId, expanded})}
                      {this.UnitOptions({item, unitId})}
                    </UnitBlock>
                )
              })}

            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  order: state.checkoutData.order.candybar[state.checkoutData.candybar.selectedItem],
  selectedUnitId: state.checkoutData.candybar.selectedUnit,
  candybarOptions: state.entities.candybarOptions,
});

const mapDispatchToProps = dispatch => ({
  onOptionSelect: (itemId, unitId, config, optionId, selected) => dispatch(candybarOptionSelect(itemId, unitId, config, optionId, selected)),
  onUnitSelect: unitId => dispatch(candybarUnitSelect(unitId)),
  onCommentUpdate: (itemId, unitId, comment) => dispatch(candybarUnitCommentUpdate(itemId, unitId, comment)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandybarItem);