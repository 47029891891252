import React from 'react';
import FloatingPost from '../FloatingPost';
import FieldRow from '../FieldRow';
import { connect } from 'react-redux';
import { isCheckoutTermsChecked } from '../../reducers';
import { setCheckoutTermsCheck } from '../../actions/creators/checkout';

class TermsCheckbox extends React.Component {
    constructor(props) {
        super(props);
    
        this.onTermsLinkClick = this.onTermsLinkClick.bind(this);
        this.onPrivacyLinkClick = this.onPrivacyLinkClick.bind(this);
        this.onTermsModalClose = this.onTermsModalClose.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
          termsOpen: false,
          privacyOpen: false
        };
    }

    onTermsLinkClick(e) {
        e.preventDefault();
        this.setState({ termsOpen: true });
    }

    onPrivacyLinkClick(e) {
        e.preventDefault();
        this.setState({ privacyOpen: true });
    }

    onTermsModalClose(e) {
        e.preventDefault();
        this.setState({ privacyOpen: false, termsOpen: false });
    }

    onChange(e) {
        this.props.onCheck(e.target.checked);
    }
    
    render() {
        return <React.Fragment>
            <FieldRow label={this.getLabel()} type="checkbox" onChange={this.onChange} checked={this.props.checked}/>
            {this.state.termsOpen ? <FloatingPost slug="terminos-y-condiciones-de-compra" onClose={this.onTermsModalClose}/> : ''}
            {this.state.privacyOpen ? <FloatingPost slug="aviso-de-privacidad" onClose={this.onTermsModalClose}/> : ''}
        </React.Fragment>;
    }

    getLabel() {
        return <React.Fragment>
            Acepto los <a href="/terminos" target="_blank" style={{ textDecoration: 'underline' }} onClick={this.onTermsLinkClick}>Términos y Condiciones</a> y el <a href="/privacidadvisitantes" target="_blank" style={{ textDecoration: 'underline' }} onClick={this.onPrivacyLinkClick}>Aviso de Privacidad</a>
        </React.Fragment>;
    }
}

const mapStateToProps = state => ({ 
    checked: isCheckoutTermsChecked(state),
});

const mapDispatchToProps = dispatch => ({
    onCheck: checked => dispatch(setCheckoutTermsCheck(checked)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsCheckbox);