import * as actions from '../types';
import CMX from '../../api';
import {dialogOpen, dialogClose} from './dialogs';
import {getCheckoutSession,getCheckoutCinema,getCheckoutStep,getCheckoutTransactionId, isPaymentSuggestionNeeded} from '../../reducers';

export const sessionFetch = (sessionId) => {
  return dispatch => {
    dispatch({type: actions.CHECKOUT_INIT});
    dispatch(sessionIsLoading(true));

    return CMX.get('sessions/' + sessionId)
        .then(data => {
          dispatch(sessionFetchSuccess(data));

          return Promise.all([
              CMX.get('movies/' + data.movie_id)
                  .then(data => dispatch(movieFetchSuccess(data))),
              CMX.get('cinemas/' + data.cinema_id)
                  .then(data => dispatch(cinemaFetchSuccess(data))),
          ]);
        })
        .then(() => dispatch(sessionIsLoading(false)))
        .catch((error) => dispatch(sessionFetchError(error)) );
  }
};

export const sessionIsLoading = (isLoading) => ({
  type: actions.CHECKOUT_SESSION_IS_LOADING,
  isLoading,
});

export const sessionFetchError = error => ({
  type: actions.CHECKOUT_SESSION_FETCH_ERROR,
  error
});

export const sessionFetchSuccess = (data) => ({
  type: actions.CHECKOUT_SESSION_FETCH_SUCCESS,
  data,
});

export const movieFetchSuccess = (data) => ({
  type: actions.CHECKOUT_MOVIE_FETCH_SUCCESS,
  data,
});

export const cinemaFetchSuccess = (data) => ({
  type: actions.CHECKOUT_CINEMA_FETCH_SUCCESS,
  data,
});

export const nextStep = () => (dispatch, getState) => {
  const state = getState();
  const session = getCheckoutSession(state);
  const cinema = getCheckoutCinema(state);
  const skipPaymentOptions = !isPaymentSuggestionNeeded(state);

  dispatch(leaveStep(state));
  dispatch({
    type: actions.CHECKOUT_NEXT_STEP,
    session,
    cinema,
    skipPaymentOptions
  });
};

export const prevStep = payload => (dispatch, getState) => {
  const state = getState();
  const session = getCheckoutSession(state);
  const cinema = getCheckoutCinema(state);
  const skipPaymentOptions = !isPaymentSuggestionNeeded(state);

  dispatch(leaveStep(state));
  dispatch({
    type: actions.CHECKOUT_PREV_STEP,
    session,
    cinema,
    skipPaymentOptions
  });
};

const leaveStep = state => ({
  type: actions.CHECKOUT_LEAVE_STEP,
  oldStep: getCheckoutStep(state),
});

export const paymentMethodSelect = (method, {mayNeedExternalUrl})  => ({
  type: actions.CHECKOUT_PAYMENT_METHOD_SELECT_BEFORE,
  method,
  mayNeedExternalUrl
});

export const paymentMethodCancel = () => ({
  type: actions.CHECKOUT_PAYMENT_METHOD_CANCEL,
});

export const onCheckoutUnmount = () => ({
  type: actions.CHECKOUT_ABANDON,
});

let timer = null;

export const timerStart = timeoutUnix => dispatch => {
  clearInterval(timer);
  timer = setInterval(() => dispatch(timerTick()), 1000);
  dispatch({type: actions.CHECKOUT_TIMER_START, timeoutUnix});
  dispatch(timerTick());
};

export const timerTick = () => (dispatch, getState) => {
  if (getState().checkoutData.timer.time <= 0) {
    dispatch(timerTimeout());
  } else {
    dispatch({
      type: actions.CHECKOUT_TIMER_TICK,
    });
  }
};

export const timerStop = () => dispatch => {
  clearInterval(timer);
  dispatch({type: actions.CHECKOUT_TIMER_STOP});
};

export const timerTimeout = () => dispatch => {
  clearInterval(timer);
  dispatch({type: actions.CHECKOUT_TIMER_TIMEOUT});
};

export const preCheckoutConfirm = payload => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(dialogOpen({
      message: `Has seleccionado ${payload.movie} - ${payload.version} para el día ${payload.date} a las ${payload.time} en ${payload.cinema}.`,
      buttons: [
        {
          label: 'Volver',
          onClick: (dialog) => {
            dispatch(dialogClose(dialog.id));
            reject();
          }
        },
        {
          label: 'Continuar',
          primary: true,
          onClick: (dialog) => {
            dispatch(dialogClose(dialog.id));
            setTimeout(function() { // For some reason at some version of Chrome it started crashing here, so this is a workaround.
              resolve();
            }, 10);
          }
        }
      ]
    }));
  });
};

export const setCheckoutTermsCheck = checked => ({
  type: actions.CHECKOUT_SET_TERMS_CHECK,
  checked
});

export const displayNoTermsCheckedDialog = () => dispatch => {
  dispatch(dialogOpen({
    message: 'Para continuar debes aceptar los términos y condiciones y el aviso de privacidad.',
    buttons: [
        {
            label: 'Entendido',
            primary: true,
            onClick: (dialog) => {
                dispatch(dialogClose(dialog.id));
            }
        },
    ]
  }));
}