import * as actions from '../../actions/types';
import {combineReducers} from 'redux';

const defaultPromoCodesPanelState = {
  visible: false,
};

const promoCodesPanel = (state = defaultPromoCodesPanelState, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PROMO_CODE_SHOW:
      return Object.assign({}, state, {visible: true, processing: false});
    case actions.CHECKOUT_PROMO_CODE_SUBMIT:
      return Object.assign({}, state, {processing: true});
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_FAILURE:
      return Object.assign({}, state, {processing: false});
    case actions.CHECKOUT_PROMO_CODE_CANCEL:
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS:
    case actions.CHECKOUT_INIT:
      return Object.assign({}, state, {visible: false, processing: false});
    default:
      return state;
  }
};

const defaultIeCodePanelState = {
  visible: false,
  processing: false,
  step: 1,
  tempMemberId: '',
};

const ieCodePanel = (state = defaultIeCodePanelState, action) => {
  switch (action.type) {
    case actions.IE_CODE_PANEL_SHOW:
      return Object.assign({}, state, {visible: true, step: 1});
    case actions.IE_CODE_BEGIN:
      return Object.assign({}, state, {step: 1});
    case actions.IE_CODE_CANCEL:
      return Object.assign({}, state, {visible: false});
    case actions.IE_CODE_VALIDATE_SUBMIT:
    case actions.IE_CODE_SUBMIT:
    case actions.IE_NIP_UPDATE_SUBMIT:
      return Object.assign({}, state, {processing: true});
    case actions.IE_CODE_VALIDATE_SUCCESS:
      return Object.assign({}, state, {processing: false, step: 2, tempMemberId: action.member_id});
    case actions.IE_CODE_VALIDATE_FAILURE:
    case actions.IE_CODE_SUBMIT_FAILURE:
    case actions.IE_NIP_UPDATE_SUCCESS:
    case actions.IE_NIP_UPDATE_FAILURE:
      return Object.assign({}, state, {processing: false});
    case actions.IE_CODE_SUBMIT_SUCCESS:
      return Object.assign({}, state, {processing: false, step: 3, emailSent: action.emailSent});
    case actions.IE_CODE_PANEL_PREV_STEP:
      return Object.assign({}, state, {step: Math.max(1, state.step - 1)});
    default:
      return state;
  }
};

export default combineReducers({
  promoCodesPanel,
  ieCodePanel,
});