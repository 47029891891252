import React from 'react';
import {connect} from 'react-redux';
import uniqid from 'uniqid';
import Blocker from "./Blocker";
import Button from './Button';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatTimeHm} from '../utils/dates';
import {getCinemas,isCorpSalesFormProcessing,isCorpSalesFormSubmitted} from '../reducers';
import {corpSalesFormShow, specialEventsFormSubmit} from '../actions/creators';
import {sortByName} from '../utils/arrays';
import { asset } from '../utils/misc';

const getCinemasChoices = (props, state) => props.cinemas;

const dates = [
  {id: '13/10/2023', name: 'Viernes 13 de Octubre de 2023'},
  {id: '14/10/2023', name: 'Sábado 14 de Octubre de 2023'},
  {id: '15/10/2023', name: 'Domingo 15 de Octubre de 2023'},
  {id: '19/10/2023', name: 'Jueves 19 de Octubre de 2023'},
  {id: '20/10/2023', name: 'Viernes 20 de Octubre de 2023'},
  {id: '21/10/2023', name: 'Sábado 21 de Octubre de 2023'},
  {id: '22/10/2023', name: 'Domingo 22 de Octubre de 2023'},
];

const fields = {
  name: {
    label: 'Nombre completo',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu nombre.'
  },
  email: {
    label: 'Email',
    type: 'email',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateEmail],
    validationMessage: 'Ingresa tu email.'
  },
  company: {
    label: 'Compañía',
    required: false,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu dirección.'
  },
  phone: {
    label: 'Teléfono',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu teléfono.'
  },
  cinema: {
    label: 'Complejo Cinemex',
    required: true,
    id: uniqid(),
    getChoicesMethod: getCinemasChoices,
    validationMessage: 'Selecciona un cine.',
    validationFns: [validators.validateNotEmpty],
  },
  event_date: {
    label: 'Fecha del evento',
    required: true,
    id: uniqid(),
    choices: dates,
    validationFns: [validators.validateFutureDate],
    validationMessage: 'Ingresa la fecha del evento.',
  },
  event_time: {
    label: 'Horario del evento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateHour],
    validationMessage: 'Ingresa el horario del evento.',
    placeholder: 'hh:mm',
    formatterFn: formatTimeHm
  },
  capacity: {
    label: '¿Para cuántos Invitados buscas rentar la sala?',
    required: true,
    id: uniqid(),
    choices: [
      {id:'1-40', name: 'De 1 hasta 40 Invitados para sala Tradicional o Premium'},
      {id:'1-20', name: 'De 1 hasta 20 Invitados para sala Platino'}
    ],
    expanded: true,
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa la capacidad requerida.',
    half: false
  },
  need_candy: {
    label: '¿Requiere de productos de dulcería?',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'si', name: 'Sí'},
      {id: 'no', name: 'No'},
    ],
    // placeholder: 'Selecciona una opción',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona una opción.'
  },
  candybar_detail: {
    label: 'Especifique cuáles',
    required: false,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Especifique los productos de dulcería.'
  },
  comments: {
    label: 'Comentarios',
    id: uniqid(),
    type: 'textarea',
    half: false
  },
};

const fieldSets = [
  {
    label: '',
    fields: ['name', 'email', 'company', 'phone', 'event_date', 'event_time', 'cinema', 'capacity', 'need_candy', 'candybar_detail', 'comments'],
  }
];

class SpecialEventsForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    const state = {};

    Object.keys(fields).forEach(key => {
      state[key + '_value'] = '';
      state[key + '_valid'] = null;
    });

    state['candybar_detail_visible'] = false;

    this.state = state;
  }

  componentDidMount() {
    this.props.show();
  }

  componentDidUpdate(prevProps, prevState) {
    const needCandyChanged = prevState.need_candy_value !== this.state.need_candy_value;
    if (needCandyChanged) {
      const needCandy = this.state.need_candy_value === 'si';
      this.setState(state => ({
        candybar_detail_visible: needCandy,
        candybar_detail_value: needCandy ? state.candybar_detail_value : '',
      }));
    }
  }

  render() {
    return this.props.isSubmitted ? this.renderSubmitMessage() : this.renderForm();
  }

  renderSubmitMessage() {
    return (
        <div className="row fade-in">
          <div className="col-12 col-lg-7">
            <p><i className="fal fa-envelope-open fa-4x text-primary"></i></p>
            <h1 className="h2">¡Gracias por tu interés!</h1>
            <p className="text-secondary mb-5">
              Tu solicitud ha sido enviada, espera nuestra respuesta al correo que registraste. Te contactaremos a la brevedad.
            </p>
            <Button to="/" outline primary large>Volver al inicio</Button>
          </div>
        </div>
    );
  }

  renderForm() {
    return (
        <React.Fragment>
          <img src={asset('/dist/images/special-events-taylor-banner.jpg')} alt="" className='img-fluid mb-3'/>
          {this.props.isProcessing && <Blocker/>}
          <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
            {fieldSets.map(fieldSet => (
                <fieldset key={fieldSet.label} className="mb-4">
                  <legend className="h5">{fieldSet.label}</legend>
                  <div className="form-row">
                    {fieldSet.fields.map(fieldName => {
                      const field = fields[fieldName];
                      const fieldValue = this.state[fieldName + '_value'];
                      const valid = this.state[fieldName + '_valid'];
                      const validationError = this.state[fieldName + '_validationError'];
                      const visible = this.state[fieldName + '_visible'] !== false;

                      if (!visible) {
                        return null;
                      }

                      return (
                          <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                            <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                      onChange={(e) => this.onChange(fieldName, e.target.value)}
                                      onBlur={() => this.validate(fieldName)}
                                      value={fieldValue}
                                      valid={valid}
                                      validationError={validationError}
                                      inputProps={field.inputProps || {}}
                                      choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, fieldValue))}
                                      id={field.id}
                                      required={field.required}
                                      expanded={field.expanded}
                            />
                          </div>
                      );
                    })}
                  </div>
                </fieldset>
            ))}

            <div className="text-right">
              <button type="submit" name="submit" className="btn btn-outline-primary btn-lg px-5">Enviar</button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  onChange(prop, value) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (value === '0' && (field.choices || field.getChoicesMethod)) {
      value = '';
    }

    this.setState((state) => ({[prop + '_value']: value}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {};

    Object.keys(fields).forEach(field => params[field] = this.state[field + '_value']);

    this.props.submit(params);
  }

  validateForm() {
    let validForm = true;

    Object.keys(fields).forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({
        [prop + '_valid']: valid,
        [prop + '_validationError']: validationError
      }));
      validForm &= valid !== false;
    });

    return validForm;
  }

  validate(prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({
      [prop + '_valid']: valid,
      [prop + '_validationError']: validationError
    }));
  }

  validateField(prop) {
    const value = this.state[prop + '_value'];
    const visible = this.state[prop + '_visible'] !== false;
    const {required, validationFns, validationMessage} = fields[prop];
    let valid = true;

    if (!visible || (!validationFns || !validationFns.length) || (!value && !required)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }
}

const mapStateToProps = state => ({
  cinemas: getCinemas(state).sort(sortByName),
  isProcessing: isCorpSalesFormProcessing(state),
  isSubmitted: isCorpSalesFormSubmitted(state),
});

const mapDispatchToProps = dispatch => ({
  show: () => dispatch(corpSalesFormShow()),
  submit: data => dispatch(specialEventsFormSubmit(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpecialEventsForm);