export const cleanForSlug = s => {
  const accents = {
    'á':'a','é':'e','í':'i','ó':'o','ú':'u','ü':'u',
    'Á':'a','É':'e','Í':'i','Ó':'o','Ú':'u',
    'ñ':'n','Ñ':'Ñ',
  };
  const re = new RegExp('[' + Object.keys(accents).join('') + ']', 'g');

  return s.toLowerCase()
      .replace(re, (letter) => accents[letter])
      .replace(/\s/g, '-').replace(/[^A-Za-z0-9-]/g, '');
};

export const getAreaBillboardPermalink = area => '/cartelera/zona-' + area.id + '/' + cleanForSlug(area.name);
export const getStateBillboardPermalink = state => '/cartelera/estado-' + state.id + '/' + cleanForSlug(state.name);
export const getCinemaPermalink = cinema => '/cine/' + cinema.id + '/' + cleanForSlug(cinema.name);
export const getCinemaNowPermalink = cinema => '/por-comenzar/cine-' + cinema.id + '/' + cleanForSlug(cinema.name);
export const getMoviePermalink = movie => '/pelicula/' + movie.id + '/' + cleanForSlug(movie.name);
export const getUpcomingMoviePermalink = movie => '/proximamente/' + movie.id + '/' + cleanForSlug(movie.name);
export const getCheckoutPermalink = session => '/checkout/' + session.id;
export const getAbsoluteUrl = relativeUrl => 'https://' + document.location.host + relativeUrl;

export const cinemexAbsoluteToRelative = url => url.replace(/^https?:\/\/([a-z0-9]+\.)?cinemex\.com/, '');

export const fixMovieUrl = url => {
  return url && url
          .replace(/^.*\/(movie\/upcoming|proximamente)\//, '/proximamente/')
          .replace(/^.*\/(movie|pelicula)\//, '/pelicula/')
      ;
};
