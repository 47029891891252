import React from 'react';
import { connect } from 'react-redux';
import uniquid from 'uniqid';
import AppleSignin from 'react-apple-signin-auth';
import LinkButton from "../LinkButton";
import Button from '../Button';
import FieldRow from '../FieldRow';
import { authShowRecoverPass, authShowRegister } from '../../actions/creators';
import {loginPost,facebookLogin} from '../../actions/creators/auth';
import * as validators from '../../utils/validation';
import { openPopup } from '../../actions/creators/auth/apple';
import Recaptcha from 'react-google-invisible-recaptcha';

let emailId, passId;

class LoginPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResolve = this.onResolve.bind(this);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    emailId = uniquid();
    passId = uniquid();
  }

  onChange(prop, value) {
    this.setState({[prop]: value});
  }

  onSubmit(e) {
    e.preventDefault();

    this.recaptcha.execute();
  }

  onResolve() {
    const {email, password} = this.state;
    const captcha = this.recaptcha.getResponse();
    this.recaptcha.reset();

    this.props.loginPost(email, password, captcha);
  }

  render() {
    return (
        <React.Fragment>
          <Recaptcha
              ref={ ref => this.recaptcha = ref }
              locale="es"
              sitekey={window.localConfig.recaptcha.key}
              onResolved={ this.onResolve } />
          <div className="px-5 py-4">
            <h3 className="h3 mb-3">¡Hola!</h3>
            <p>Ingresa con tu usuario y contraseña.</p>
            
            <form onSubmit={this.onSubmit}>
              <FieldRow id={emailId} type="email" label="Email" onChange={(e) => this.onChange('email', e.target.value)} value={this.state.email}/>
              <FieldRow id={passId} type="password" label="Contraseña" onChange={(e) => this.onChange('password', e.target.value)} value={this.state.password}/>
              <div className="mt-3 mb-5">
                <LinkButton className="d-block text-muted mb-1" onClick={this.props.showRecoverPass}>¿Olvidaste tu contraseña?</LinkButton>
                <LinkButton className="d-block text-muted mb-1" onClick={this.props.showRegister}>
                  <span className="text-secondary">¿No tienes cuenta?</span> <span className="text-primary">Regístrate aquí</span>
                </LinkButton>
              </div>
              <div className='pb-5'>
                  <Button submit primary large block disabled={!validators.validateEmail(this.state.email) || !this.state.password}>Ingresar</Button>
              </div>
            </form>
            
            <div className="row pt-5">
              <div className="col-6">
                <Button onClick={this.props.facebookLogin} block smallPadding className="btn-facebook mb-2"><i className="fab fa-facebook-f"></i></Button>
              </div>
              <div className="col-6">
                <Button onClick={this.props.appleLogin} block smallPadding className="btn-apple mb-2"><i className="fab fa-apple"></i></Button>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  showRecoverPass: () => dispatch(authShowRecoverPass()),
  showRegister: () => dispatch(authShowRegister()),
  loginPost: (email, password, captcha) => dispatch(loginPost(email, password, captcha)),
  facebookLogin: () => dispatch(facebookLogin()),
  appleLogin: () => dispatch(openPopup())
});

export default connect(
    null,
    mapDispatchToProps
)(LoginPanel);