import React from 'react';
import UserCinemaNavLinks from './UserCinemaNavLinks';

class UserCinemaNav extends React.PureComponent {
  render() {
    return (
        <nav className={(this.props.padding !== false ? 'px-5 py-3' : '') + (this.props.showInMobile === false ? ' d-none d-md-block' : '')}>
          <ul className="nav flex-nowrap justify-content-end align-items-center c-nav c-nav--big">
            <UserCinemaNavLinks cinema={this.props.cinema} cinemaSelector={this.props.cinemaSelector !== false}/>
          </ul>
        </nav>
    );
  }
}

export default UserCinemaNav;