import defaultAvatar from '../assets/img/default-avatar.jpg';

export const extractYouTubeId = url => {
  const re = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = url.match(re);

  if (match && match.length >= 1) {
    return match[1];
  } else {
    return false;
  }
};

export const getYouTubeEmbedUrl = movie => {
  const url = movie.info.trailer;

  try {
    return 'https://www.youtube.com/embed/' + extractYouTubeId(url);
  } catch (e) {}

  return null;
};

export const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  const difference = to - (window === element ? element.scrollY : element.scrollTop);
  const perTick = difference / duration * 10;

  setTimeout(function() {
    if (window === element) {
      element.scroll(window.scrollY + perTick, 0);
    } else {
      element.scrollTop = element.scrollTop + perTick;
    }
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
};

export const onAvatarLoadError = e => {
  if (e.target.src !== defaultAvatar) {
    e.target.src = defaultAvatar;
  }
};

export function isElementInViewport (el) {
  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function asset(path) {
  return (process.env.REACT_APP_ASSETS_BASE_URL || '') + path;
}

export function hasIeAction(userInfo, action) {
  return ('ie-actions' in userInfo) && userInfo['ie-actions'].filter(a => a.action === action).length > 0;
}