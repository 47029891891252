import React from 'react';
import {Link} from 'react-router-dom';
import ErrorPage from './ErrorPage';

const message = <p>
  Lo que sea que estés buscando<br />
  no está por aquí. Si fuera tú, regresaría<br />
  al <Link to="/">Home</Link> lo antes posible.
</p>;

const Error404Page = props => <ErrorPage image="404.png" message={message} className="c-error--404"/>

export default Error404Page;