import * as actions from '../../types';
import CMX from "../../../api";
import { getCheckoutNipCode } from "../../../reducers";
import { getPaymentParams } from "./payment";
import { dialogAlert } from '../dialogs';

export const fetchStripeRedirectUrl = (extraParams) => (dispatch, getState) => {
  const state = getState();
  const params = getPaymentParams(getState());
  params.nip = getCheckoutNipCode(state);
  //params.fingerprint = getFingerprint(state);

  if (extraParams && ('user_info' in extraParams)) {
    params.user_info = extraParams.user_info;
  }

  dispatch(getStripeRedirectUrlIsProcessing());

  CMX.post('buy/getPaymentUrl', params)
      .then(data => dispatch(getStripeRedirectUrlSuccess(data)))
      .catch(error => dispatch(getStripeRedirectUrlError(error, params)));
};

const getStripeRedirectUrlIsProcessing = () => ({
  type: actions.GET_STRIPE_URL
});

const getStripeRedirectUrlSuccess = data => dispatch => {
  dispatch({
    type: actions.GET_STRIPE_URL_SUCCESS,
    data,
  });
  /*dispatch({
    type: actions.CHECKOUT_STRIPE_OPEN,
    url: data.url
  });

  openStripeWindow(data.url, dispatch);*/
};

const getStripeRedirectUrlError = (error) => dispatch => {
  dispatch(dialogAlert(error.message || 'Ocurrió un error. Por favor, inténtalo nuevamente.'));
  dispatch({
    type: actions.GET_STRIPE_URL_ERROR
  });
};
  

const openStripeWindow = (url, dispatch) => {
  const win = window.open(url);
  let timer = setInterval(function() {
    if (!win || !win.window || win.closed) {
      clearInterval(timer);
      dispatch(requestFailed());
      return;
    }

    try {
      win.postMessage({message: "requestCinemexSmartResult"}, "*");
    } catch (e) {}
  }, 1000);

  window.addEventListener("message", function(e) {
    if (e.data.message === "deliverCinemexSmartResult") {
      console.log('message', e);
      clearInterval(timer);
      
      if (e.data.result.success) {
        dispatch(requestSuccess(e.data.result));
      } else {
        dispatch(requestFailed());
      }
      e.source.close();
    }
  });
};

const requestSuccess = data => ({
  type: actions.CHECKOUT_STRIPE_SUCCESS,
  data
});

const requestFailed = () => dispatch => {
  //dispatch(dialogAlert('Has cancelado tu compra con Masterpass.'));
  dispatch({
    type: actions.CHECKOUT_STRIPE_ERROR,
  })
};