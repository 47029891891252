import React from 'react';
import MoviePoster from "./MoviePoster";

const filterPremiereRibbon = ribbons => {
  ribbons = ribbons.filter(ribbon => ribbon.label.toLowerCase() !== 'estreno');
  return (ribbons.length && ribbons[0]) || null;
};

const MoviePosterPremiere = props => <MoviePoster ribbonPickerFn={filterPremiereRibbon} {...props} />;

export default MoviePosterPremiere;