import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { postFetch } from '../actions/creators/posts';
import BodyBlocker from './BodyBlocker';
import DynamicPost from './DynamicPost';
import CloseCross from './CloseCross';

const Modal = styled.div`
    position: fixed;
    background: white;
    width: 90%;
    height: 90%;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    border-radius: 10px;
    padding: 3em;
    max-width: 1000px;
`;

const Scroller = styled.div`
    position: relative;
    overflow-y: auto;
    height: 100%;
`;

class FloatingPost extends React.Component {
    render() {
        return <React.Fragment>
            <BodyBlocker />
            <Modal>
                <CloseCross theme={'dark'} onClick={this.props.onClose}/>
                <Scroller>
                    <DynamicPost slug={this.props.slug} includeTitle={true}/>
                </Scroller>
            </Modal>
        </React.Fragment>;
    }
}

export default FloatingPost;